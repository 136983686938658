import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { Device } from 'twilio-client';
import { Dialog } from 'primereact/dialog';
import ApiListFile from '../ApiListFile';
import { fetchMethod } from '../ApiHeader';
import FeatherIcons from 'feather-icons-react';
import { Button } from 'primereact/button';
import { contextVar } from '../ContextVar';

function IncomingCall(props) {
    // Context and API
    const { settoNumber, phoneNumber, callStatus, setcallDispositionModalStatus,setcallStatus,setparentSid } = useContext(contextVar);
    // const { setcallStatus } = useContext(contextVar);
    const { API_OUTGOING_CALL_TOKEN, API_GET_PARENT_SID } = ApiListFile();

    // State
    const [activeCall, setActiveCall] = useState(null);
    const [visible, setVisible] = useState(false);
    const [status, setStatus] = useState('Incoming...');
    const [isMuted, setIsMuted] = useState(false);
    const [dialogState, setDialogState] = useState("normal");
    const [timer, setTimer] = useState(0);
    const [isCallActive, setIsCallActive] = useState(false);

    // Refs
    const callStartTimeRef = useRef(null);
    const timerIntervalRef = useRef(null);

    // 1. Device Setup and Initialization
    const setupDevice = useCallback(() => {
        fetchMethod("GET", `${API_OUTGOING_CALL_TOKEN}`, {})
            .then(data => {
                Device.setup(data.token, { debug: true });
                Device.on('ready', (conn) => console.log('Twilio Device Ready!', conn));
                Device.on('error', error => console.error('Twilio Device Error: ', error));
                Device.on('incoming', handleIncomingCall);
                Device.on('disconnect', () => console.log('Device disconnected'));
            })
            .catch(error => console.error('Error fetching Twilio token:', error));
    }, [API_OUTGOING_CALL_TOKEN]);


    useEffect(() => {
        if (phoneNumber) {
            settoNumber(phoneNumber);
            setupDevice();
        }
        return () => {
            Device.destroy();
            resetCallState();
            disconnectMicrophone();
        };
    }, [phoneNumber, setupDevice]);

  
    // 2. Incoming Call Handling
    const handleIncomingCall = useCallback((connection) => {
        setStatus('Incoming...');
        console.log("incoming call connection",connection)
        props?.setfromNumber(connection.parameters.From);
        // settoNumber(phoneNumber);
        setActiveCall(connection);
        setVisible(true);

        connection.on('accept', () => {
            setStatus('In Call');
            setIsCallActive(true);
            setcallStatus('connected');
            startTimer();
        });

        connection.on('disconnect', handleDisconnect);
        connection.on('reject', handleReject);
        connection.on('cancel', handleCancel);
        connection.on('busy', () => setcallStatus('busy'));
    }, [ props, setcallStatus]);

    // 3. Call Control Functions
    const acceptCall = useCallback(() => {
        handleMicrophonePermission();
        activeCall?.accept();
        startTimer();
    }, [activeCall]);

    const hangUp = useCallback(() => {
        activeCall?.disconnect();
        handleCallEnd();
    }, [activeCall]);

    // const rejectCall = useCallback(() => {
    //     hangUp()
    //     activeCall?.reject();
    //     fetchParentSid(activeCall.parameters.CallSid);
    //     // handleCallEnd();
    //     setStatus('Disconnected');
    // }, [activeCall]);
    const rejectCall = useCallback(() => {
            hangUp();
            activeCall?.reject();
            fetchParentSid(activeCall.parameters.CallSid);
            setStatus('Disconnected');
            callStartTimeRef.current = null;
       
    }, [activeCall]);


    console.log("active call ",activeCall)
    // 4. Call State Management
    const handleDisconnect = useCallback((conn) => {
        handleCallEnd(conn);
        console.log("Missed call",callStatus)
        if (callStatus !== 'connected') {
            setcallStatus('missed');
            // setStatus('Missed call');
        }
    }, [callStatus]);

    const handleReject = useCallback((conn) => {
        setStatus('Call declined');
        handleCallEnd(conn);
    }, []);

    const handleCancel = useCallback((conn) => {
        setVisible(false);
        disconnectMicrophone();
        setStatus('Call canceled');
        setcallStatus('missed');
        
    }, []);

    useEffect(() => {
     if(callStatus == 'missed' ){
        fetchParentSid(activeCall.parameters.CallSid);
        props?.setcallDuration('00:00:00')
     }
    }, [callStatus])
    

   console.log("Missed call 2..",callStatus)

    const resetCallState = useCallback(() => {
        setActiveCall(null);
        setVisible(false);
        setIsCallActive(false);
        setcallStatus('');
        disconnectMicrophone();
        setTimer(0);
        // callStartTimeRef.current = null;
        stopTimer();
    }, [setcallStatus]);

    useEffect(() => {
        return () => {
            resetCallState();
            Device.destroy(); // Properly clean up Twilio device
            stopTimer();      // Clear timer interval on unmount
            disconnectMicrophone(); // Stop microphone streams
        };
    }, []);

    // 5. Timer Functions
    const startTimer = useCallback(() => {
        callStartTimeRef.current = Date.now();
        setTimer(0); // Reset timer state when the call starts
    
        timerIntervalRef.current = setInterval(() => {
            setTimer((prevTimer) => Math.floor((Date.now() - callStartTimeRef.current) / 1000)); // Update timer based on time elapsed
        }, 1000);
    }, []);
    
    const stopTimer = useCallback(() => {
        if (timerIntervalRef.current) {
            clearInterval(timerIntervalRef.current);
            timerIntervalRef.current = null; // Reset interval reference
        }
    }, []);

    const formatTimer = useCallback((seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }, []);

    // 6. Utility Functions
    const fetchParentSid = useCallback((childCallSid) => {
        fetchMethod("GET", `${API_GET_PARENT_SID}/${childCallSid}`, {})
            .then((data) => {
                props?.setcallSid(data?.parentCallSid);
                setparentSid(true)
              
            })
            .catch((error) => console.error(error));
    }, [API_GET_PARENT_SID, props]);

    const logCallDuration = useCallback(() => {
        let formattedDuration = '00:00:00';
        if (callStartTimeRef.current) {
            const callEndTime = Date.now();
            const durationInMilliseconds = callEndTime - callStartTimeRef.current;
            if (durationInMilliseconds > 0) {
                const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60));
                const minutes = Math.floor((durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((durationInMilliseconds % (1000 * 60)) / 1000);
                formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            }
        }
        // Functional state update ensures the correct value is being set
        props?.setcallDuration((prevDuration) => formattedDuration);
    }, [props]);

    // 7. Audio Handling
    const handleMicrophonePermission = useCallback(() => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: false })
            .then(stream => {
                window.localStream = stream;
            })
            .catch(err => console.error('Error obtaining media stream:', err));
    }, []);

    const disconnectMicrophone = useCallback(() => {
        if (window.localStream) {
            window.localStream.getTracks().forEach(track => track.stop());
            window.localStream = null;
        } else {
            console.log('No microphone stream to disconnect');
        }
    }, []);

    const handleMuteAudio = useCallback(() => {
        if (activeCall) {
            activeCall.mute(true);
            setIsMuted(true);
        }
    }, [activeCall]);

    const handleUnMuteAudio = useCallback(() => {
        if (activeCall) {
            activeCall.mute(false);
            setIsMuted(false);
        }
    }, [activeCall]);

    // 8. UI Handling
    const handleMaximize = useCallback(() => {
        setDialogState("maximized");
        document.body.style.overflow = 'visible';
    }, []);

    const handleRestore = useCallback(() => {
        setDialogState("normal");
        document.body.style.overflow = 'hidden';
    }, []);

    const closeCallModal = useCallback(() => {
        rejectCall();
        setVisible(false);
        setDialogState("normal");
    }, [rejectCall]);

    const handleCallEnd = useCallback((conn) => {
        stopTimer();
        if (conn) fetchParentSid(conn.parameters.CallSid);
        logCallDuration();
        setVisible(false);
        setIsCallActive(false);
        setActiveCall(null);
        resetCallState(); 
        disconnectMicrophone();
        // resetCallState();
        props?.setcallDispositionModal(true);
        setcallDispositionModalStatus(true)
        localStorage.setItem('modalState', 'open');
    }, [logCallDuration, fetchParentSid, disconnectMicrophone]);




    // 9. Render
    return (
        <div>
            {activeCall && (
                <Dialog
                    header={
                        <div style={{ marginTop: '-0.5rem' }} className="d-flex justify-content-between align-items-center">
                            <h5 className='mt-1'>Call</h5>
                            <div className=''>
                                {dialogState === "normal" && (
                                    <Button icon="pi pi-window-minimize" className="p-mr-2" text onClick={handleMaximize} />
                                )}
                                {dialogState === "minimized" && (
                                    <Button icon="pi pi-window-maximize" className="p-mr-2" text onClick={handleRestore} />
                                )}
                                {dialogState === "maximized" && (
                                    <Button icon="pi pi-window-maximize" className="p-mr-2" text onClick={handleRestore} />
                                )}
                            </div>
                        </div>
                    }
                    visible={visible}
                    modal={false}
                    onHide={closeCallModal}
                    style={
                        dialogState === "normal"
                            ? { width: "25vw" }
                            : dialogState === "minimized"
                                ? { display: "none" }
                                : { width: "18vw", height: "8vh", marginRight: "80px", marginBottom: "0" }
                    }
                    position={dialogState === "normal" ? "top-right" : dialogState === "minimized" ? "" : "bottom-right"}
                    draggable={false}
                    resizable={false}
                    className={dialogState === "normal" ? "dialog-top-right" : dialogState === "minimized" ? "" : "dialog-bottom-right"}
                >
                    <div className="d-flex">
                        <span>
                            <i className="pi pi-user" style={{ color: 'green', background: "#dcffde", padding: '10px', borderRadius: "50%", border: "1px solid #b0ffb4" }}></i>
                        </span>
                        <div className="ms-3 mb-4">
                            <h3>{activeCall.parameters.From}</h3>
                            {status === 'In Call' ? <p>{formatTimer(timer)}</p> : <p>{status}</p>}
                            {status !== 'In Call' &&
                                <span>
                                    <i className="pi pi-phone call-buttons-call cursor-pointer" onClick={acceptCall}></i>
                                </span>
                            }
                            {status === 'In Call' &&
                                <>
                                    {isMuted ? (
                                        <span>
                                            <FeatherIcons icon={'mic-off'} onClick={handleUnMuteAudio} className="icon-dual call-buttons-unmute cursor-pointer" />
                                        </span>
                                    ) : (
                                        <span>
                                            <i className="pi pi-microphone call-buttons-mute cursor-pointer" onClick={handleMuteAudio}></i>
                                        </span>
                                    )}
                                </>
                            }
                            <span>
                                <i className="pi pi-phone call-buttons-disconnect cursor-pointer" onClick={rejectCall}></i>
                            </span>
                        </div>
                    </div>
                </Dialog>
            )}
        </div>
    );
}

export default IncomingCall;








