import React, { useEffect, useRef, useState } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { Container } from "react-bootstrap";

const AdminReports = () => {

useEffect(() => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-project-0-pwxaa',
    });
  
    const dashboard = sdk.createDashboard({
      dashboardId: 'bf7de311-a99f-414c-9243-2d3d2d3b8013'
    });
    
    dashboard.render(document.getElementById('dashboard')).then(() => {
    }).catch((e) => console.error(e));
  
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts
  

  return (
    <div className="content-page pt-5" style={{ height: '100vh', width: '100vw' }}>
      <div className="content" style={{ height: '100%', width: '100%' }}>
        <Container fluid className="mt-4" style={{ height: '100%', width: '100%', padding: 0 }}>
          <div id="dashboard" style={{ height: '100%', width: '100%' }}></div>
        </Container>
      </div>
    </div>
  );
}

export default AdminReports;