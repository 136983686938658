import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import ApiListFile from '../../Components/ApiListFile';
import ApiHeader, { fetchMethod } from '../../Components/ApiHeader';
import { Skeleton } from 'primereact/skeleton';

const MainDataTable = (props) => {
    const sequenceId = props?.sequenceId;
    const dt = useRef(null);

    const { API_SEQUENCE_EMAIL } = ApiListFile();
    const [data, setData] = useState([]);
    const [totalRecords, settotalRecords] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null
    });

    useEffect(() => {
        setisLoading(true);
        let filterValue = '';

        if (props?.activeTab === 0) {
            filterValue = 'scheduled';
        } else if (props?.activeTab === 1) {
            filterValue = 'sent';
        } else if (props?.activeTab === 2) {
            filterValue = 'opened';
        } else if (props?.activeTab === 3) {
            filterValue = 'unopened';
        } else if (props?.activeTab === 4) {
            filterValue = 'replied';
        } else if (props?.activeTab === 5) {
            filterValue = 'opt_out';
        } else if (props?.activeTab === 6) {
            filterValue = 'bounced';
        }

        if (filterValue !== '') {
            fetchMethod("POST",`${API_SEQUENCE_EMAIL}/${sequenceId}/${filterValue}`,{
                page: lazyState.page,
                page_size: lazyState.rows,
                search_query: globalFilter,
                sort_order: lazyState.sortOrder,
                sort_field: lazyState.sortField
            })
                .then((data) => {
                    setData(data?.items);
                    settotalRecords(data?.total_count)
                    setisLoading(false);
                    setSelectedRows([])
                })
                .catch((err) => console.error(err));
        }
    }, [props?.activeTab, globalFilter, lazyState, sequenceId]);
    


    const onPage = (event) => {
        setlazyState({
            ...lazyState,
            first: event.first,
            rows: event.rows,
            page: event.page + 1
        });
    };
    const onSort = (event) => {
        setlazyState({ ...lazyState, sortOrder: event.sortOrder, sortField: event.sortField });
    }
    const handleSelectionChange = (e) => {
        setSelectedRows(e.value);
    };

    const generateCSV = (data) => {
        const fields = ['to', 'from', 'step', 'email_status', 'timestamp'];
        const headers = ['To', 'From', 'Step', 'Email Status', 'Date & Time'];
    
        // Extract the rows
        const rows = data.map(obj => fields.map(field => {
            let value = obj[field];
            if (field == 'timestamp') {
                value = new Date(value).toLocaleString();
            }
            // Wrap each value in double quotes
            return `"${value}"`;
        }).join(',')).join('\n');
    
        return headers.join(',') + '\n' + rows;
    };    

    // Function to export CSV with custom filename
    const exportCSV = () => {
        // Generate CSV from the selectedRows
        const csvData = generateCSV(selectedRows);

        // Create a blob from the CSV
        const blob = new Blob([csvData], { type: 'text/csv' });

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'reports.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const capitalizeFirstLetter = (str) => {
        return str?.replace(/\b\w/g, char => char?.toUpperCase());
    };

    const emailStatusTemplate = (email_statuses) => {

        return (
            <div className="d-flex gap-3 text-center align-items-center justify-content-around position-relative">
                {email_statuses.map((items) => (
                    <div className="stepper-section">

                        <span className={items.status == 1 ? 'border rounded-status-icon' : 'border rounded-status-icon-deactive '} style={{}}>{items?.status == 1 ? <i className="pi pi-check" style={{ color: 'slateblue', fontSize: '0.9rem' }}></i> : <i className="pi pi-times" style={{ color: 'gray', fontSize: '0.9rem' }}></i>}</span>
                        <p className="label-status-text">{capitalizeFirstLetter(items?.key)}</p>
                    </div>
                ))}
            </div>
        );
    }
    const columns = [
        { field: 'to', header: 'To' },
        { field: 'from', header: 'From' },
        { field: 'step', header: 'Step' },
        {
            field: 'email_status',
            header: 'Email Status',
            body: (rowData) => emailStatusTemplate(rowData?.email_statuses) // Capitalize first letter of each word
        },
        {
            field: 'timestamp',
            header: () => <span>Date & Time</span>, // Header function
            body: (rowData) => new Date(rowData.timestamp).toLocaleString() // Body function to format timestamp
        }
    ];

    return (
        <>
            <div className="export-button-sequence">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type='search' placeholder="Search" className="form-control-sm" onInput={(e) => setGlobalFilter(e.target.value)} />
                </span>
                {/* Filter button here */}
                <Button
                    type="button"
                    size="small"
                    icon="pi pi-download"
                    label="Export"
                    className="p-button-primary btn-small"
                    onClick={exportCSV}
                    disabled={!selectedRows || selectedRows.length === 0}
                />

            </div>
            {isLoading ? (
                <div className="skeleton-container">
                    <DataTable
                        value={[{}, {}, {}, {}, {}]}
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                        {columns.map((column, index) => (
                            <Column key={index} header={column.header} body={<Skeleton />} />
                        ))}
                    </DataTable>
                </div>
            ) : (
                <DataTable
                style={{ minWidth: '50rem' }}
                    ref={dt}
                    value={data}
                    selectionMode="checkbox"
                    selection={selectedRows}
                    onSelectionChange={handleSelectionChange}
                    dataKey="_id"
                    tableStyle={{ minWidth: '50rem' }}
                    lazy
                    first={lazyState.first}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    globalFilter={globalFilter}
                    onSort={onSort}
                    sortField={lazyState.sortField}
                    sortOrder={lazyState.sortOrder}
                    rows={lazyState.rows}
                    paginator
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                    {columns.map((column, index) => (
                        <Column
                            key={index}
                            field={column.field}
                            header={column.header}
                            body={column.body}
                        />
                    ))}
                </DataTable>
            )}
        </>

    );
};

export default MainDataTable;
