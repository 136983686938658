  import React, { useState, useEffect, useContext  } from "react";
  import { Container } from 'react-bootstrap'
  import { Card } from 'primereact/card';
  import "./SalesExecutionReport.scss"
  import { Dropdown } from 'primereact/dropdown';
  import { Accordion, AccordionTab } from 'primereact/accordion';
  import { DataTable } from 'primereact/datatable';
  import { Column } from 'primereact/column';
  import ReactApexChart from 'react-apexcharts';
  import { Calendar } from "primereact/calendar";
  import { Skeleton } from "primereact/skeleton";
  import Footer from "../Components/Footer/Footer";
  import ApiListFile from "../Components/ApiListFile";
  import ApiHeader from "../Components/ApiHeader";
import { contextVar } from "../Components/ContextVar";


  const SalesExecutionReport = () => {
      const [selectedFilterUser, setSelectedFilterUser] = useState(null);
      const [selectedFilterTeams, setSelectedFilterTeams] = useState(null);
      const [date, setDate] = useState([new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date()]);
      const oneMonthBack = new Date();oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
      const [firstRange, setFirstRange] = useState([oneMonthBack, new Date()]);
      const [secondRange, setSecondRange] = useState([oneMonthBack, new Date()]);
      const [userLoading, setUserLoading] = useState(true)
      const [selectedTeamMemberIds, setSelectedTeamMemberIds] = useState([]);
      const [loading, setLoading] = useState(false)
      const [users, setUsers] = useState([]);
      const [dateRangeOption, setDateRangeOption] = useState("Monthly");
      const dateRangeOptions = [
        { label: 'Weekly', value: 'Weekly' },
        { label: 'Monthly', value: 'Monthly' },
        { label: 'Quarterly', value: 'Quarterly' },
        { label: 'Yearly', value: 'Yearly' }
      ];

      const {
        API_GET_REPORT_USERS,
        API_GET_TEAMS_LIST,
        API_GET_SALES_EXECUTION_REPORT,
        API_ZYLERERP_ASSIGNED_TO_STAFF,
        API_ZYLERERP_OUTBOUND_VS_ANSWERED,
      } = ApiListFile()

      const { setnetworkError } = useContext(contextVar)
      const updateDateRanges = (firstRangeStart, firstRangeEnd) => {
        let secondRangeStart = new Date(firstRangeStart);
        let secondRangeEnd = new Date(firstRangeEnd);
    
        switch (dateRangeOption) {
            case 'Weekly':
                secondRangeStart.setDate(secondRangeStart.getDate() - 7);
                secondRangeEnd.setDate(secondRangeEnd.getDate() - 7);
                break;
            case 'Monthly':
                secondRangeStart.setMonth(secondRangeStart.getMonth() - 1);
                secondRangeEnd.setMonth(secondRangeEnd.getMonth() - 1);
                break;
            case 'Quarterly':
                secondRangeStart.setMonth(secondRangeStart.getMonth() - 3);
                secondRangeEnd.setMonth(secondRangeEnd.getMonth() - 3);
                break;
            case 'Yearly':
                secondRangeStart.setFullYear(secondRangeStart.getFullYear() - 1);
                secondRangeEnd.setFullYear(secondRangeEnd.getFullYear() - 1);
                break;
            default:
                secondRangeStart.setMonth(secondRangeStart.getMonth() - 1);
                secondRangeEnd.setMonth(secondRangeEnd.getMonth() - 1);
        }
    
        setFirstRange([firstRangeStart, firstRangeEnd]);
        setSecondRange([secondRangeStart, secondRangeEnd]);
    };
    

      useEffect(() => {
        const today = new Date();
        let startDate = new Date();
    
        switch (dateRangeOption) {
            case 'Weekly':
                startDate.setDate(today.getDate() - 7);
                break;
            case 'Monthly':
                startDate.setMonth(today.getMonth() - 1);
                break;
            case 'Quarterly':
                startDate.setMonth(today.getMonth() - 3);
                break;
            case 'Yearly':
                startDate.setFullYear(today.getFullYear() - 1);
                break;
            default:
                // Default to Monthly if option is unrecognized
                startDate.setMonth(today.getMonth() - 1);
        }
    
        updateDateRanges(startDate, today);
    }, [dateRangeOption]);
    
      const [teams, setTeams] = useState([]);
      const [selectedEmail, setSelectedEmail] = useState("")
      const [dataTableData, setDataTableData] = useState([])
      const selectedFilterUserData = [
          { name: 'New York', code: 'NY' },
          { name: 'Rome', code: 'RM' },
          { name: 'London', code: 'LDN' },
          { name: 'Istanbul', code: 'IST' },
          { name: 'Paris', code: 'PRS' }
      ];

      const [chartData, setChartData] = useState({
        series: [
          {
            name: 'series1',
            data: [31, 40, 28, 51, 42, 109, 100],
          },
          {
            name: 'series2',
            data: [11, 32, 45, 32, 34, 52, 41],
          },
        ],
        options: {
          chart: {
            height: 350,
            type: 'area',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          xaxis: {
            type: 'sting',
            categories: [
              'Prospects contacted',
              'Through sequences',
              'Emails delivered',
              'Calls - outbound',
              'Other tasks completed', 
            ],
          },
          tooltip: {
            x: {
              format: '',
            },
          },
        }})
      const [range, setRange] = useState('March 12 - Apr 10, 2023 vs Apr 11 - May 10, 2023');
      const ranges = [
          { name: 'March 12 - Apr 10, 2023 vs Apr 11 - May 10, 2023', code: 'NY' },
          { name: 'Rome', code: 'RM' },
          { name: 'London', code: 'LDN' },
          { name: 'Istanbul', code: 'IST' },
          { name: 'Paris', code: 'PRS' }
      ];
      const [products, setProducts] = useState([]);

      const fetchUsers = async () => {
        setUserLoading(true)
        try {
          const response = await fetch(`${API_GET_REPORT_USERS}/${localStorage.getItem('company_id')}`, {
            method: 'GET',
            headers: ApiHeader()
          });
          const data = await response.json();
          setUserLoading(false);
          const formattedData = data.map(item => ({
            value: item.email,
            label: item.name
        }));
          setUsers(formattedData)
        } catch (error) {
          console.error("Error signing up", error);
          if (error instanceof TypeError) {
            setnetworkError(true)
            setUserLoading(false);
            return;
          }
        } finally {
          setUserLoading(false);
        }
      }

      const fetchTeams = async () => {
        try {
            const response = await fetch(
                `${API_GET_TEAMS_LIST}/${localStorage.getItem('company_id')}`,
                {
                    headers: ApiHeader(),
                    method: 'GET'
                }
            );
    
            if (response.ok) {
                const teamsData = await response.json();
                const formattedTeams = teamsData?.teams?.map(team => ({
                    ...team,
                    label: team.team_name,
                    value: team._id
                }));
                setTeams(formattedTeams);
            } else {
                console.error('Failed to fetch teams data');
            }
        } catch (error) {
          console.error("Error signing up", error);
          if (error instanceof TypeError) {
            setnetworkError(true)
            setUserLoading(false);
            return;
          }
        } 
    };
    const handleTeamSelection = (selectedTeamId) => {
      setSelectedFilterTeams(selectedTeamId);
      const selectedTeam = teams.find(team => team.value === selectedTeamId);
      const teamMemberIds = selectedTeam?.team_members?.map(member => member.value);
      setSelectedTeamMemberIds(teamMemberIds);
  };

  const handleFirstRangeChange = (e) => {
    if (e.value) {
        if (e.value.length === 1) {
            // Only start date is selected, reset end date to the same day
            setFirstRange([e.value[0], e.value[0]]);
        } else if (e.value.length === 2) {
            // Both start and end dates are selected
            setFirstRange(e.value);
        }
    }
};
  
      useEffect(() => {
        fetchUsers()
        fetchTeams()
      }, [])

      useEffect(() => {
        (async () => {
          setLoading(true)
          const postData = {
            first_range: [new Date(firstRange[0]).toISOString(), new Date(firstRange[1]).toISOString()],
            second_range: [new Date(secondRange[0]).toISOString(), new Date(secondRange[1]).toISOString()],
            company_id: localStorage.getItem('company_id'),
            email: selectedFilterUser
        }; 
        // Include team members' IDs if a team is selected
        if (selectedFilterTeams) {
            postData.team_members = selectedTeamMemberIds;
        }
          if (firstRange.every(element => element !== null) && secondRange.every(element => element !== null)) {
            const response = await fetch(`${API_GET_SALES_EXECUTION_REPORT}`, {
                method: 'POST',
                headers: ApiHeader(),
                
                body: JSON.stringify(postData)
            });
            //test
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const data = await response.json();
            const formattedData = [
              {
                id: '1000',
                code: 'Companies assigned',
                name: 0,
                category: 0,
            },
              {
                  id: '1000',
                  code: 'Prospects contacted',
                  name: data.prospected_contacted.first_range.toString(),
                  category: data.prospected_contacted.second_range.toString(),
              },
              {
                  id: '1002',
                  code: 'Through sequences',
                  name: data.prospected_contacted_through_sequences.first_range.toString(),
                  category: data.prospected_contacted_through_sequences.second_range.toString(),
              },
              {
                  id: '1004',
                  code: 'Emails delivered',
                  name: data.emails_delivered.first_range.toString(),
                  category: data.emails_delivered.second_range.toString(),
              },
              {
                  id: '1006',
                  code: 'Calls - outbound',
                  name: '0',  // Replace with actual data if available
                  category: '0',  // Replace with actual data if available
              },
              {
                  id: '1008',
                  code: 'Other tasks completed',
                  name: data.other_tasks_completed.first_range.toString(),
                  category: data.other_tasks_completed.second_range.toString(),
              },
          ];

          const formattedChartdata = {
            series: [
              {
                name: firstRange[0] && firstRange[1] ? `${firstRange[0].toLocaleDateString()} - ${firstRange[1].toLocaleDateString()}` : '',
                data: [0,data.prospected_contacted.first_range, data.prospected_contacted_through_sequences.first_range, data.emails_delivered.first_range, 0, data.other_tasks_completed.first_range],
              },
              {
                name: secondRange[0] && secondRange[1] ? `${secondRange[0].toLocaleDateString()} - ${secondRange[1].toLocaleDateString()}` : '',
                data: [0,data.prospected_contacted.second_range, data.prospected_contacted_through_sequences.second_range, data.emails_delivered.second_range, 0, data.other_tasks_completed.second_range],
              },
            ],
            options: {
              chart: {
                height: 350,
                type: 'area',
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: 'smooth',
              },
              xaxis: {
                type: 'sting',
                categories: [
                  'Companies assigned',
                  'Prospects contacted',
                  'Through sequences',
                  'Emails delivered',
                  'Calls - outbound',
                  'Other tasks completed', 
                ],
              },
              tooltip: {
                x: {
                  format: '',
                },
              },
            }}
          // setChartData(formattedChartdata)


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdGFmZmlkIjo0MywicGhwc2VydmVyIjoiZ3JlZW5qZWV2YS56eWxlcmVycC5jb20iLCJpYXQiOjE3MDQ3ODgwMjV9.FKaT3Ku1Y4hSCnYHZaqRFhI9LxvJvKBO9tLWtPqbvu0");

        var raw = JSON.stringify({
          "email": selectedFilterUser == null ? "" : selectedFilterUser,
          "from_date": firstRange[0],
          "to_date": firstRange[1]
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        await fetch(`${API_ZYLERERP_OUTBOUND_VS_ANSWERED}`, requestOptions)
          .then(response => response.json())
          .then(result => {
            formattedData[4]['name'] = result.data.no_of_outbound_calls
            formattedChartdata['series'][0]['data'][4] = result.data.no_of_outbound_calls
          })
          .catch(error => console.error(error));

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdGFmZmlkIjo0MywicGhwc2VydmVyIjoiZ3JlZW5qZWV2YS56eWxlcmVycC5jb20iLCJpYXQiOjE3MDQ3ODgwMjV9.FKaT3Ku1Y4hSCnYHZaqRFhI9LxvJvKBO9tLWtPqbvu0");
  
          var raw = JSON.stringify({
            "email": selectedFilterUser == null ? "" : selectedFilterUser,
            "from_date": secondRange[0],
            "to_date": secondRange[1]
          });
  
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
  
          await fetch(`${API_ZYLERERP_OUTBOUND_VS_ANSWERED}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              formattedData[4]['category'] = result.data.no_of_outbound_calls
              formattedChartdata['series'][1]['data'][4] = result.data.no_of_outbound_calls
            })
            .catch((error) => {
              console.error(error);
              if (error instanceof TypeError) {
                setnetworkError(true)
                return;
              }
            });
            var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdGFmZmlkIjoxMzIsInBocHNlcnZlciI6ImdqY2EuenlsZXJlcnAuY29tIiwiaWF0IjoxNzA0ODc4MjUwfQ.AWTawD-VvsRyz-gS9h8dzDJuzheLrMLibDv27NnCxqo");

          var raw = JSON.stringify({
            "email": selectedFilterUser == null ? "" : selectedFilterUser,
            "from_date": firstRange[0],
            "to_date": firstRange[1]
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          await fetch(`${API_ZYLERERP_ASSIGNED_TO_STAFF}`, requestOptions)
            .then(response => response.json())
            .then(canada_result_first_range => {
              var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdGFmZmlkIjoxMzIsInBocHNlcnZlciI6ImdqY2EuenlsZXJlcnAuY29tIiwiaWF0IjoxNzA0ODc4MjUwfQ.AWTawD-VvsRyz-gS9h8dzDJuzheLrMLibDv27NnCxqo");

          var raw = JSON.stringify({
            "email": selectedFilterUser == null ? "" : selectedFilterUser,
            "from_date": secondRange[0],
            "to_date": secondRange[1]
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          fetch(`${API_ZYLERERP_ASSIGNED_TO_STAFF}`, requestOptions)
            .then(response => response.json())
            .then(canada_result_second_range => {
              var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdGFmZmlkIjoxMzIsInBocHNlcnZlciI6ImdyZWVuamVldmEuenlsZXJlcnAuY29tIiwiaWF0IjoxNzA0ODY2MTk1fQ.yefmMyjkd3oOtnEi-9_KLtWd73BkebXisNJHT61aOdo");

          var raw = JSON.stringify({
            "email": selectedFilterUser == null ? "" : selectedFilterUser,
            "from_date": firstRange[0],
            "to_date": firstRange[1]
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          fetch(`${API_ZYLERERP_ASSIGNED_TO_STAFF}`, requestOptions)
            .then(response => response.json())
            .then(us_result_first_range => {
              var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdGFmZmlkIjoxMzIsInBocHNlcnZlciI6ImdyZWVuamVldmEuenlsZXJlcnAuY29tIiwiaWF0IjoxNzA0ODY2MTk1fQ.yefmMyjkd3oOtnEi-9_KLtWd73BkebXisNJHT61aOdo");

          var raw = JSON.stringify({
            "email": selectedFilterUser == null ? "" : selectedFilterUser,
            "from_date": secondRange[0],
            "to_date": secondRange[1]
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          fetch(`${API_ZYLERERP_ASSIGNED_TO_STAFF}`, requestOptions)
            .then(response => response.json())
            .then(us_result_second_range => {
              formattedData[0]['name'] = canada_result_first_range.total_assigned_company + us_result_first_range.total_assigned_company
              formattedData[0]['category'] = canada_result_second_range.total_assigned_company + us_result_second_range.total_assigned_company
              formattedChartdata['series'][0]['data'][0] = canada_result_first_range.total_assigned_company + us_result_first_range.total_assigned_company
              formattedChartdata['series'][1]['data'][0] = canada_result_second_range.total_assigned_company + us_result_second_range.total_assigned_company
              setProducts(formattedData);
              setChartData(formattedChartdata)
              setLoading(false)
              // formattedChartdata['series'][0]['data'][3] = result.data.no_of_outbound_calls
            })
            .catch(error => console.error(error));
            })
            .catch(error => console.error(error));
            })
            .catch(error => console.error(error));
            })
            .catch(error => console.error(error));
          }
        })()
          
        }, [secondRange, firstRange, selectedFilterUser, selectedTeamMemberIds, selectedFilterTeams]);


      const CustomHeader = () => {
        let oldValue, newValue;
        // Ensure the values are numbers and calculate the percentage change
        if (products && products.length > 0) {
          oldValue = Number(products[1]?.name);
          newValue = Number(products[1]?.category);
      } else{
        oldValue = 0;
          newValue = 0;
      }
        let percentageChange = 0;
      
        if (oldValue !== 0) { // To avoid division by zero
          percentageChange = ((newValue - oldValue) / oldValue) * 100;
        }
      
        // Format the percentage change
        const formattedPercentage = percentageChange.toFixed(2) + '%';
      
        return (
          <>
            <div className="sales-execuation-report-card-accrodian-header">
              <h4>Prospects contacted</h4>
              {loading ? (<><Skeleton width="10rem" height="35px" className="m-3" /></>) : (<><div className="sales-execuation-report-card-accrodian-header-numbers">
                <h5 className={percentageChange >= 0 ? "positive" : "negative"}>{formattedPercentage}</h5>
                <h6>{`${oldValue} vs ${newValue}`}</h6>
              </div></>)}
            </div>
          </>
        );
      };
      
  
    return (
      <div  className="content-page">
          <div className="content">
            <Container fluid className="mt-3 p-0 sales-execution-report-container">
              <Card className="p-0">
                  <div className="sales-execuation-report-card">
                  <div className="sales-execuation-report-card-heading">
                      <h4>Sales execution report</h4>
                      <p className="m-0">
                      Get a holistic view of activity across channels to uncover what contributed to your outcomes.
                      </p>
                  </div>
                  <div className="SalesExecutionReport-timeframe-timerange mb-3">
                      <div className="d-flex">
                       
                      </div>
                      <div className="d-flex flex justify-content-center">
                        <Dropdown 
                          value={selectedFilterTeams} 
                          onChange={(e) => {handleTeamSelection(e.value);}} 
                          options={teams} 
                          optionLabel="label" 
                          optionValue="value"
                          showClear 
                          placeholder="Filter Teams" 
                          className="me-2" 
                      />
                          <Dropdown value={selectedFilterUser}
                          onChange={(e) => {setSelectedFilterUser(e.value);}}
                          options={users} 
                          optionLabel="label" 
                          optionValue="value"
                          showClear 
                          placeholder="Filter Users" 
                          className="me-2" />
                        
                        <Dropdown 
                          value={dateRangeOption} 
                          options={dateRangeOptions} 
                          onChange={(e) => setDateRangeOption(e.value)}
                          className="me-2"
                          placeholder="Select Range"
                        />

                        <Calendar
                            value={secondRange}
                            onChange={(e) => {
                              setSecondRange(e.value);
                            }}
                            selectionMode="range"
                            showIcon
                            className="mx-3"
                            dateFormat="dd/mm/yy"
                          />     
                             <h6 className="mt-3">vs</h6>
                        <Calendar
                            value={firstRange}
                            onChange={handleFirstRangeChange}
                            selectionMode="range"
                            showIcon
                            className="mx-3"
                            dateFormat="dd/mm/yy"
                        />
                        </div>
                  </div>
                  {/* <div className="SalesExecutionReport-span-lastupdated">
                      <span>last updated <strong> May 11,2023</strong> <i class='bx bx-time'></i></span>
                  </div> */}
                  <div className="collpase-SalesExecutionReport-timeframe-timerange">
                      <Accordion multiple activeIndex={[0]}>
                        
                          <AccordionTab header={<CustomHeader/>}>
                            {loading ? (<>
                            <div className="row">
                              <div className="col-md-6"><Skeleton width="100%" height="200px" /></div>
                              <div className="col-md-6"><Skeleton width="100%" height="200px" /></div>
                            </div>
                            </>): (<><div className="row">
                              <div className="col-md-6">
                                  <div className="collpase-SalesExecutionReport-table-card table-responsive">
                                      <DataTable value={products} tableStyle={{ minWidth: '100%' }}>
                                          <Column field="code" header="Activity" style={{width:"50%"}}></Column>
                                          <Column 
                                            field="name" 
                                            header={firstRange[0] && firstRange[1] ? 
                                                `${firstRange[0].toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' })} - ${firstRange[1].toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' })}` 
                                                : ''}  
                                            style={{width:"25%"}}
                                        ></Column>
                                        <Column 
                                            field="category" 
                                            header={secondRange[0] && secondRange[1] ? 
                                                `${secondRange[0].toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' })} - ${secondRange[1].toLocaleDateString('default', { month: 'short', day: 'numeric', year: 'numeric' })}` 
                                                : ''}  
                                            style={{width:"25%"}}
                                        ></Column>

                                      </DataTable>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="collpase-SalesExecutionReport-table-card">
                                  <div id="chart">
                                      <ReactApexChart
                                          options={chartData.options}
                                          series={chartData.series}
                                          type="area"
                                          height={350}
                                      />
                                      </div>
                                  </div>
                              
                              </div>
                          </div></>)}
                          </AccordionTab>
                      </Accordion>
                  </div>
                  </div>
              </Card>
            </Container>
          </div>
          <Footer />
      </div>
    )
  }

  export default SalesExecutionReport