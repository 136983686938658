import React, { useState, useEffect, useRef, useContext } from 'react';
import { Form, Modal, Container, Col, Row, Card } from 'react-bootstrap';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from "primereact/dialog";
import Footer from "../Components/Footer/Footer";
import ApiListFile from "../Components/ApiListFile";
import ApiHeader, { fetchMethod } from "../Components/ApiHeader";
import { contextVar } from '../Components/ContextVar';
import Select from "react-select";

const Groups = () => {
  const {
    API_GET_GROUPS_LIST,
    API_UPDATE_GROUPS_LIST,
    API_DELETE_GROUPS,
    API_MOVE_GROUPS
  } = ApiListFile()
  const { setnetworkError, userGroup } = useContext(contextVar)
  const [groups, setGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({
    group_name: ''
  });
  const [validation, setValidation] = useState({
    group_name: '',
  });
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: 'timestamp',
    sortOrder: -1
  });
  const [totalRecords, setTotalRecords] = useState(0)
  const [loading, setLoading] = useState(true);
  const buttonEl = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [changeGrpModal, setchangeGrpModal] = useState(false)
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [selectGroup, setselectGroup] = useState(null);
  const [delGrpId, setdelGrpId] = useState('')

  const dt = useRef(null);
  const validateForm = () => {
    let isValid = true;
    let newValidation = { group_name: '' };
    if (!currentGroup.group_name) {
      isValid = false;
      newValidation.group_name = 'Group name is required';
    }

    setValidation(newValidation);
    return isValid;
  };

  const handleSelectionChange = (e) => {
    const selectedValues = e.value;

    setSelectedRows((prevSelectedRows) => {
      const selectedIds = new Set(selectedValues.map(row => row._id));

      // Create a new selection that merges previous and current selections
      const mergedSelection = [
        ...prevSelectedRows.filter(row => selectedIds.has(row._id) || !groups.some(currentRow => currentRow._id === row._id)),
        ...selectedValues.filter(row => !prevSelectedRows.some(selectedRow => selectedRow._id === row._id))
      ];

      return mergedSelection;
    });
  };

  // Handle header checkbox selection
  const handleHeaderCheckboxChange = (e) => {
    const allSelected = e.checked;
    if (allSelected) {
      // Add all rows from the current page to the selection
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = [...prevSelectedRows];
        groups.forEach(row => {
          if (!newSelectedRows.some(selectedRow => selectedRow._id === row._id)) {
            newSelectedRows.push(row);
          }
        });
        return newSelectedRows;
      });
    } else {
      // Remove all rows from the current page from the selection
      setSelectedRows((prevSelectedRows) => {
        return prevSelectedRows.filter(row => !groups.some(currentRow => currentRow._id === row._id));
      });
    }
  };

  const fetchGroups = () => {
    setLoading(true)
    fetchMethod("POST", `${API_GET_GROUPS_LIST}`, {
      page: lazyState.page,
      page_size: lazyState.rows,
      search_query: globalFilter,
      sort_field: lazyState.sortField,
      sort_order: lazyState.sortOrder
    })
      .then(data => {
        setGroups(data.items);
        setTotalRecords(data.total_count)
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setLoading(false);
          return;
        }
      });
  }

  useEffect(() => {
    document.title = "Groups";
  }, [])

  useEffect(() => {
    fetchGroups()
  }, [lazyState, globalFilter, currentGroup._id]);

  const handleShow = (group) => {
    setValidation({ group_name: '' })
    setCurrentGroup(group);
    setShowModal(true);
  }

  const handleClose = () => {
    setCurrentGroup({
      group_name: ''
    });
    setShowModal(false);
  }

  const bodyTemplate = (rowData, column) => {
    const displayText = `${rowData.group_name} (${rowData.recipient_count})`;

    // Truncate the text if it is too long
    const maxLength = 50;
    const truncatedText = displayText.length > maxLength ? `${displayText.substring(0, maxLength)}...` : displayText;

    // Define the URL
    const url = `/leads/${encodeURIComponent(rowData.group_name)}/${encodeURIComponent(rowData._id)}`;
    // Return the link with a tooltip
    return (
      <>
        <Tooltip target=".tooltip-note-text" />
        <a href={url} className="tooltip-note-text" data-pr-tooltip={displayText}>
          {truncatedText}
        </a>
      </>
    );
  };

  const handleUpdate = async () => {
    if (!validateForm()) return;
    setLoading(true); // Activate loading only when updating

    try {
      const data = await fetchMethod("PUT", `${API_UPDATE_GROUPS_LIST}/${currentGroup._id}`, currentGroup);
      setLoading(false); // Deactivate loading after the request completes
      if (data.status === "success") {
        setGroups(groups.map(group => group._id === data.group_id ? currentGroup : group));
        handleClose();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: data.message,
        });
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Error",
          detail: data.message,
        });
      }
    } catch (error) {
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Request Failed",
        detail: "Could not update the group",
      });
    }
  }

  const handleAdd = async () => {
    if (!validateForm()) return;
    setLoading(true)
    currentGroup.recipient_count = 0; // Set the initial value for recipient_count

    const data = await fetchMethod("POST", `${API_UPDATE_GROUPS_LIST}`, currentGroup)
    setLoading(false)
    if (data.status === "success") {
      setGroups([{ ...currentGroup, _id: data.group_id }, ...groups]);
      setTotalRecords(totalRecords + 1)
      handleClose();
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: data.message,
      });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Error",
        detail: data.message,
      });
    }
  }

  const accept = (id) => {
    handleDelete(id)
  }

  const reject = (id) => {

  }

  const confirmdelete = (id) => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => accept(id),
      reject: () => reject(id),
      draggable: false
    });
  };

  const handleDelete = async (id) => {
    setLoading(true)

    const data = await fetchMethod("DELETE", `${API_UPDATE_GROUPS_LIST}/${id}`)
    setLoading(false)
    if (data.status === "success") {
      // Remove the item with the given id
      fetchGroups()
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Group Deleted Successfully",
      });
      setTotalRecords(totalRecords - 1)
      setSelectedRows([])
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: data.message,
      });
    }
  }

  const generateCSV = (data) => {
    const fields = ['group_name'];
    const headers = ['Group Name'];

    // Extract the rows
    const rows = data.map(obj => fields.map(field => obj[field]).join(',')).join('\n');

    return headers.join(',') + '\n' + rows;
  };

  const exportCSV = () => {
    // Generate CSV from the selectedRows
    const csvData = generateCSV(selectedRows);
    // Create a blob from the CSV
    const blob = new Blob([csvData], { type: 'text/csv' });
    // Create a link element and trigger the download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'groups.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  };

  const handleBulkDelete = async (selectionOnly) => {
    if (selectionOnly) {
      const idArray = selectedRows.map(item => item._id);

      try {
        await fetchMethod("DELETE", `${API_DELETE_GROUPS}`, { ids: idArray })
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Groups Deleted Successfully",
        });
        setTotalRecords(totalRecords - idArray.length);
        setSelectedRows([]);
        fetchGroups(); // Assuming this function fetches the updated template list
        setDeleteLoading(false);
      } catch (error) {
        // Handle fetch error...
        console.error('Error during fetch:', error);
        setDeleteLoading(false);
      }
    }
  }

  const deleteSelectedRecords = (selectionOnly) => {
    confirmDialog({
      message: "Do you want to delete these records?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        setDeleteLoading(true);
        handleBulkDelete(selectionOnly)
      },
      draggable: false
    });
  };

  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" className="form-control-sm" onInput={(e) => {
          setGlobalFilter(e.target.value)
          setLoading(true)
        }} placeholder="Search..." />
      </span>
      <div>
        <Button
          size="small"
          label="Export"
          severity="help"
          icon="uil uil-down-arrow"
          onClick={exportCSV}
          disabled={!selectedRows || selectedRows.length === 0}
        />

        {userGroup?.group?.bulk_group_delete?.D &&
          <Button
            className="ms-2"
            size="small"
            onClick={() => deleteSelectedRecords(true)}
            label="Delete"
            severity="danger"
            icon="uil uil-trash"
            disabled={!selectedRows || selectedRows.length === 0}
            loading={deleteLoading}
          />}
      </div>

    </div>
  );

  const actionTemplate = (rowData) => {
    console.log("row data in action template", rowData)
    return (
      <div className="p-d-flex p-flex-wrap">
        <Tooltip target=".tooltip-edit-text" />
        <Tooltip target=".tooltip-delete-text" />
        {userGroup?.group?.update_group?.C &&
          <Button className='tooltip-edit-text' icon="uil uil-edit-alt" rounded text aria-label="Edit" data-pr-tooltip="Edit" loading={loading} onClick={() => handleShow(rowData)} />}
        {userGroup?.group?.delete_group?.D &&
          <Button className='tooltip-delete-text' ref={buttonEl} icon="uil uil-trash" rounded text aria-label="Delete" data-pr-tooltip="Delete" severity="danger" loading={loading} onClick={() => handleModal(rowData)} />}
      </div>
    );
  };



  const onPage = (event) => {
    setlazyState({
      ...lazyState,
      first: event.first,
      rows: event.rows,
      page: event.page + 1 // Increment the page number by 1 since the event.page is 0-indexed
    });
  };

  const onSort = (event) => {
    setlazyState({
      ...lazyState,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };


  const handleModal = (rowdata) => {
    if (rowdata?.recipient_count == 0) {
      confirmdelete(rowdata._id)
    }
    else {
      openChangeGrpModal(rowdata._id)
    }
  }

  const formattedGroups = groups.map(group => ({
    label: group.group_name,  // Display group name in dropdown
    value: group._id,          // Use _id as unique value
    ...group
  }));

  const openChangeGrpModal = (grpId) => {
    console.log("Excluding grpId...", grpId);
    setdelGrpId(grpId)
    setchangeGrpModal(true);
    const filtered = formattedGroups.filter(group => group.value !== grpId); // Exclude group with grpId
    setFilteredGroups(filtered);
  };


  const handleMovingLeads = async () => {
    setLoading(true)

    const data = await fetchMethod("POST", `${API_MOVE_GROUPS}`, {
      old_group_id: delGrpId,
      new_group_id: selectGroup?._id,
    })
    setLoading(false)
    if (data.status === "success") {
      // Remove the item with the given id
      fetchGroups()
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: data?.message,
      });
      setTotalRecords(totalRecords - 1)
      setSelectedRows([])
      setchangeGrpModal(false)
      setFilteredGroups(formattedGroups)
      setselectGroup(null)
      setdelGrpId('')
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: data.message,
      });
    }
  }

  const handleCloseGrp = () => {
    setchangeGrpModal(false)
    setFilteredGroups(formattedGroups)
    setselectGroup(null)
    setdelGrpId('')
  }

  console.log("delGrpId...", delGrpId);
  console.log("selectGroup?._id...", selectGroup?._id);

  return (
    <div>
      <div className="content-page">
        <div className="content">
          <Container fluid>
            <ConfirmDialog />
            <Toast ref={toast} />

            <Row>
              <Col>
                <div className="page-title-box">
                  <h4 className="page-title">Groups</h4>
                  <div className="page-title-right">
                    <div className="row g-2">
                      <div className="col-md-auto">
                        {userGroup?.group?.create_group?.A &&
                          <Button label="Add Group" icon="uil uil-plus" size="small" onClick={() => {
                            handleShow({ group_name: '' }); setValidation({
                              group_name: ''
                            })
                          }} />}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="page-title-caption-box">
                  <h6 className="page-title-caption mb-4">Organize your contacts into groups to target specific audiences with tailored messaging.</h6>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>

                  {/*  table */}
                  {loading ? (
                    <DataTable
                      header={header}
                      value={[...Array(5)]} // Use an array of length 5 to render 5 rows of skeletons
                      tableStyle={{ minWidth: '50rem' }}
                    >
                      <Column
                        field="group_name"
                        header="Group Name"
                        body={() => <Skeleton></Skeleton>}
                      />
                      <Column
                        field="Action"
                        header="Action"
                        body={() => <Skeleton ></Skeleton>}
                      />
                    </DataTable>
                  ) : (
                    <DataTable ref={dt} value={groups}
                      lazy
                      selection={selectedRows}
                      onSelectionChange={handleSelectionChange}
                      onHeaderCheckboxToggle={handleHeaderCheckboxChange}
                      selectionMode="checkbox"
                      onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                      first={lazyState.first}
                      filters={lazyState.filters}
                      onPage={onPage}
                      globalFilter={globalFilter}
                      header={header}
                      totalRecords={totalRecords}
                      removableSort paginator rows={lazyState.rows} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                      currentPageReportTemplate="{first} to {last} of {totalRecords}"  >
                      <Column
                        selectionMode="multiple"
                        headerStyle={{ width: "3rem" }}
                      ></Column>

                      <Column
                        field="group_name"
                        sortable
                        header="Group Name"
                        className='cursor-pointer'
                        body={bodyTemplate}
                      ></Column>
                      <Column header="Action" body={actionTemplate}></Column>
                    </DataTable>
                  )}

                </Card>
              </Col>
            </Row>

            {/* Form section */}
            <Dialog header={currentGroup._id ? 'Update Group' : 'Add Group'}
              visible={showModal}
              onHide={() => setShowModal(false)}
              style={{ width: "30vw" }}
              breakpoints={{ "960px": "75vw", "641px": "100vw" }}
              draggable={false}
              resizable={false}
            >
              <Form>
                <Form.Group controlId="formGroupName">
                  <Form.Label>Group Name <span className="text-danger">*</span></Form.Label>
                  <Form.Control type="text" placeholder="Enter Group Name" value={currentGroup.group_name} onChange={(e) => {
                    // Check if the input starts with a space
                    if (e.target.value && e.target.value.startsWith(' ')) {
                      return;  // If it starts with a space, do not update the state
                    }
                    setCurrentGroup({ ...currentGroup, group_name: e.target.value });
                  }} maxLength={100} />
                  <div className="text-danger">{validation.group_name}</div>
                </Form.Group>
              </Form>
              <div className='d-flex justify-content-end mt-2'>
                <Button variant="secondary" onClick={handleClose} size='small' outlined>Close</Button>
                <Button size="small"
                  label="Save"
                  icon="pi pi-check" onClick={currentGroup._id ? handleUpdate : handleAdd} loading={loading} className='ms-2' />
              </div>
            </Dialog>


            {/* Move lead to another group */}
            <Dialog header='Move Lead to another group'
              visible={changeGrpModal}
              onHide={handleCloseGrp}
              style={{ width: "30vw" }}
              breakpoints={{ "960px": "75vw", "641px": "100vw" }}
              draggable={false}
              resizable={false}
            >
              <Form>
                <Form.Group controlId="formGroupName">
                  <Form.Label>Group Name <span className="text-danger">*</span></Form.Label>
                  <Select
                    options={filteredGroups} // Show only filtered groups excluding the specified grpId
                    value={filteredGroups.find(option => option.value === selectGroup?.value)}
                    onChange={(selectedGroup) => setselectGroup(selectedGroup)}
                    isSearchable={false}
                  />
                  <div className="text-danger">{validation.group_name}</div>
                </Form.Group>
              </Form>
              <div className='d-flex justify-content-end mt-2'>
                <Button variant="secondary" onClick={handleCloseGrp} size='small' outlined>Close</Button>
                <Button size="small"
                  label="Move Lead"
                  icon="pi pi-check" onClick={handleMovingLeads} loading={loading} className='ms-2' />
              </div>
            </Dialog>
          </Container>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Groups;
