import Cookies from 'js-cookie';

export default function ApiHeader() {
    const headers = {
        "Authorization": 'Bearer '+ Cookies.get('accessToken'),
        "Content-Type": 'application/json'
    }
    return headers;
}

export function fetchMethod(method,endpoint,params={}) {
    if(method != 'GET'){
        return fetch(endpoint, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('accessToken')
            },
            body: JSON.stringify(params)
        }).then((response) => response.json())
    } else {
        return fetch(endpoint, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('accessToken')
            }
        }).then((response) => response.json())
    }
}