import React, { useState, useEffect, useRef, useContext } from "react";
import { Form, Modal, Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import he from "he";
import "react-quill/dist/quill.snow.css"; // import the styles
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import "./Template.scss"
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Dialog } from "primereact/dialog";
import Footer from "../Components/Footer/Footer";
import ApiListFile from "../Components/ApiListFile";
import { fetchMethod } from "../Components/ApiHeader";
import { contextVar } from "../Components/ContextVar";
const Templates = () => {
  const [templates, setTemplates] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [signature, setSignature] = useState("")
  const dt = useRef(null);
  const toast = useRef(null);
  const buttonEl = useRef(null);
  const [showTemplateTypeModal, setShowTemplateTypeModal] = useState(false)
  const [templateBodyModal, settemplateBodyModal] = useState(false)
  const [viewTemplateBody, setviewTemplateBody] = useState()
  const { setnetworkError, userGroup } = useContext(contextVar)
  const navigate = useNavigate()
  const {
    API_DISPLAY_SIGNATURE,
    API_TEMPLATE,
    API_BULK_DELETE,
    API_PAGINATED_TEMPLATE
  } = ApiListFile()
  const [totalRecords, setTotalRecords] = useState(0)
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: 'timestamp',
    sortOrder: -1
  });
  useEffect(() => {
    document.title = "Templates";
    fetchTemplate()
  }, [lazyState, globalFilter]);

  const fetchTemplate = () => {
    setIsLoading(true);
    fetchMethod("POST", `${API_PAGINATED_TEMPLATE}`, {
      page: lazyState.page,
      page_size: lazyState.rows,
      search_query: globalFilter,
      sort_field: lazyState.sortField,
      sort_order: lazyState.sortOrder
    }).then((data) => {
      setTemplates(data.items);
      setTotalRecords(data.total_count)
      setIsLoading(false);
    })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setIsLoading(false);
          return;
        }
      });
  }

  const onPage = (event) => {
    setlazyState({
      ...lazyState,
      first: event.first,
      rows: event.rows,
      page: event.page + 1 // Increment the page number by 1 since the event.page is 0-indexed
    });
  };

  const onSort = (event) => {
    setlazyState({
      ...lazyState,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  useEffect(() => {
    fetchMethod("GET", `${API_DISPLAY_SIGNATURE}`,{})
      .then((data) => {
        let htmlString = data.signature;

        // This regular expression matches the start of the first tag after potentially leading whitespace
        let modifiedHtmlString = htmlString.replace(/(^\s*<\w+\b)(?!.*\bid=)/, '$1 id="signature_start"');

        // Now, data.signature has the modified HTML string
        data.signature = modifiedHtmlString;
        setSignature(data.signature)
      })
      .catch((error) => {
        console.error("errrr",error);
        // if (error instanceof TypeError) {
        //   setnetworkError(true)
        //   return;
        // }
      });
  }, [])

  const handleDelete = (templateId) => {
    setIsLoading(true);
    fetchMethod("DELETE", `${API_TEMPLATE}/${templateId}`, {})
      .then(() =>
        fetchTemplate()
      ) // Refresh templates
      .then((data) => {
        setTemplates(data);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Template Deleted Successfully",
        });
        setSelectedRows([])
      })

      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setIsLoading(false);
          return;
        }
      });
  };

  const handleBulkDelete = async (selectionOnly) => {
    if (selectionOnly) {
        const idArray = selectedRows.map(item => item._id);

        try {
            await fetchMethod("DELETE", `${API_BULK_DELETE}`, { ids: idArray })
            setIsLoading(false);
            toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "Templates Deleted Successfully",
            });
            setTotalRecords(totalRecords - idArray.length);
            setSelectedRows([]);
            fetchTemplate(); // Assuming this function fetches the updated template list
            setDeleteLoading(false);
        } catch (error) {
            // Handle fetch error...
            console.error('Error during fetch:', error);
            setDeleteLoading(false);
        }
    }
}

  const deleteSelectedRecords = (selectionOnly) => {
    confirmDialog({
        message: "Do you want to delete these records?",
        header: "Delete Confirmation",
        icon: "pi pi-info-circle",
        acceptClassName: "p-button-danger",

        accept: () => {
            setDeleteLoading(true);
            handleBulkDelete(selectionOnly)
        },
        draggable: false
    });
};

const extractText = (html) => {
  // Remove HTML tags
  let doc = new DOMParser().parseFromString(html, 'text/html');
  let text = doc.body.textContent || "";
  // Decode HTML entities
  return he.decode(text);
};

  const actionTemplate = (rowData) => {
    return (
      <div className="p-d-flex p-flex-wrap">
        <Tooltip target=".tooltip-text" />
        {userGroup?.template?.update_template?.C &&
          <Button
          className="tooltip-text"
          data-pr-tooltip="Edit"
          icon="uil uil-edit-alt"
          rounded
          text
          aria-label="Edit"
          loading={isLoading}
          onClick={() => navigate(`/add-template/editTemplate/${rowData._id}`)}
        />}
        {userGroup?.template?.delete_template?.D &&
          <Button
          className="tooltip-text"
          ref={buttonEl}
          icon="uil uil-trash"
          data-pr-tooltip="Delete"
          rounded
          text
          aria-label="Delete"
          severity="danger"
          loading={isLoading}
          onClick={() => confirmdelete(rowData._id)}
        />}
      </div>
    );
  };

const bodyTemplate = (rowData, column) => {
  const templateBody = rowData[column.field];
  if (typeof templateBody === 'string') {
      const cleanedBody = templateBody?.replace(/(<([^>]+)>)/gi, "");
      const maxLength = 50;
      const truncatedBody =
          cleanedBody?.length > maxLength
              ? `${cleanedBody.substring(0, maxLength)}...`
              : cleanedBody;
      return (
          <>
              <Tooltip target=".tooltip-note-text" />
              <span 
                  className="tooltip-note-text" 
                  data-pr-tooltip={templateBody}
                  style={{ cursor: 'pointer' }}  // Adding inline style for cursor
              >
                  {truncatedBody}
              </span>
          </>
      );
  }
};

const templateBody = (rowData,column) => {
  const plainText = extractText(rowData.template_body);
  const maxLength = 50;
  const truncatedBody =
  plainText?.length > maxLength
          ? `${plainText.substring(0, maxLength)}...`
          : plainText;
  return <span>{truncatedBody}</span>;
}

  const handleSelectionChange = (e) => {
    const selectedValues = e.value;
    setSelectedRows((prevSelectedRows) => {
        const selectedIds = new Set(selectedValues.map(row => row._id));
        // Create a new selection that merges previous and current selections
        const mergedSelection = [
            ...prevSelectedRows.filter(row => selectedIds.has(row._id) || !templates.some(currentRow => currentRow._id === row._id)),
            ...selectedValues.filter(row => !prevSelectedRows.some(selectedRow => selectedRow._id === row._id))
        ];
  
        return mergedSelection;
    });
  };
  
  // Handle header checkbox selection
  const handleHeaderCheckboxChange = (e) => {
    const allSelected = e.checked;
    if (allSelected) {
        // Add all rows from the current page to the selection
        setSelectedRows((prevSelectedRows) => {
            const newSelectedRows = [...prevSelectedRows];
            templates.forEach(row => {
                if (!newSelectedRows.some(selectedRow => selectedRow._id === row._id)) {
                    newSelectedRows.push(row);
                }
            });
            return newSelectedRows;
        });
    } else {
        // Remove all rows from the current page from the selection
        setSelectedRows((prevSelectedRows) => {
            return prevSelectedRows.filter(row => !templates.some(currentRow => currentRow._id === row._id));
        });
    }
  };

  const accept = (id) => {
    handleDelete(id)
  }

  const reject = (id) => {

  }

  const confirmdelete = (id) => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => accept(id),
      reject: () => reject(id),
      draggable: false
    });
  };

  const generateCSV = (data) => {
    const fields = ['template_name', 'template_subject', 'template_body'];
    const headers = ['Template Name', 'Template Subject', 'Template Body'];
    const rows = data.map(obj => {
      return fields.map(field => {
        let value = obj[field];
        // Check if the value is an array and join it with a separator, e.g., comma or space.
        if (Array.isArray(value)) {
          value = value.join(", ");
        }
        if (field === 'template_body') {
          // Assuming the array (if it was an array) is now a string, replace newline characters
          value = value.replace(/\n/g, " ");
        }
        // Escape double quotes by doubling them and wrap the value in double quotes
        return `"${value.replace(/"/g, '""')}"`;
      }).join(',');
    }).join("\n");

    return headers.join(",") + "\n" + rows;
  };

  const exportCSV = (selectionOnly) => {
    if (selectionOnly) {
      // Generate CSV from the selectedRows
      const csvData = generateCSV(selectedRows);
      // Create a blob from the CSV
      const blob = new Blob([csvData], { type: 'text/csv' });
      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'templates.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      dt.current.exportCSV(selectionOnly);
    }
  };

  return (
    <div>
      <div className="content-page">
        <div className="content">
          <Container fluid>
            <ConfirmDialog />
            <Toast ref={toast} />
            <Row>
              <Col>
                <div className="page-title-box">
                  <h4 className="page-title">Templates</h4>
                  <div className="page-title-right">
                    <div className="row g-2">
                      <div className="col-md-auto">
                        {userGroup?.template?.add_template?.A &&
                          <Button
                          size="small"
                          label="Add Template"
                          icon="uil uil-plus"
                          // onClick={handleAddTemplate}
                          onClick={() => setShowTemplateTypeModal(true)}
                        />}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="page-title-caption-box">
                  <h6 className="page-title-caption mb-4">Create, customize, and save reusable email templates to streamline your campaign design process.</h6>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      type="search"
                      className="form-control-sm"
                      onInput={(e) => setGlobalFilter(e.target.value)}
                      placeholder="Search..."
                    />
                  </span>
                  <div className={""}>
                  <Button
                    size="small"
                    label="Export"
                    severity="help"
                    icon="uil uil-down-arrow"
                    onClick={() => exportCSV(true)}
                    disabled={!selectedRows || selectedRows.length === 0}  // Disable button if no rows are selected
                  />

                  {userGroup?.template?.bulk_template_delete?.D &&
                    <Button
                    className="ms-2"
                    size="small"
                    onClick={() => deleteSelectedRecords(true)}
                    label="Delete"
                    severity="danger"
                    icon="uil uil-trash"
                    disabled={!selectedRows || selectedRows.length === 0}
                    loading={deleteLoading}
                  />}
                  </div>
                </div>
                <Card>
                  {/*  table */}
                  {isLoading ? (
                    <DataTable
                      value={[...Array(5)]} // Use an array of length 5 to render 5 rows of skeletons
                      tableStyle={{ minWidth: "50rem" }}
                    >
                      <Column
                        field="template_name"
                        header="Template Name"
                        body={() => <Skeleton></Skeleton>}
                      />
                      <Column
                        field="template_subject"
                        header="Template Subject"
                        body={() => <Skeleton></Skeleton>}
                      />
                      <Column
                        field="template_body"
                        header="Template Body"
                        body={() => <Skeleton></Skeleton>}
                      />
                      <Column
                        field="Action"
                        header="Action"
                        body={() => <Skeleton></Skeleton>}
                      />
                    </DataTable>
                  ) : (
                    <DataTable
                      lazy
                      ref={dt}
                      value={templates}
                      selection={selectedRows}
                      onSelectionChange={handleSelectionChange}
                      onHeaderCheckboxToggle={handleHeaderCheckboxChange}
                      selectionMode="checkbox"
                      onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                      first={lazyState.first}
                      filters={lazyState.filters}
                      onPage={onPage}
                      globalFilter={globalFilter}
                      // header={header}
                      removableSort paginator rows={lazyState.rows} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                      currentPageReportTemplate="{first} to {last} of {totalRecords}" totalRecords={totalRecords}
                    >
                      <Column
                        selectionMode="multiple"
                        headerStyle={{ width: "3rem" }}
                      ></Column>
                      <Column
                        field="template_name"
                        sortable
                        header="Template Name"
                        body={bodyTemplate}
                      ></Column>
                      <Column
                        field="template_subject"
                        sortable
                        header="Template Subject"
                        body={bodyTemplate}
                      ></Column>
                      <Column
                        field="template_body"
                        sortable
                        header="Template Body"
                        body={templateBody}
                      ></Column>
                      <Column
                        field="Action"
                        header="Action"
                        body={actionTemplate}
                      ></Column>
                    </DataTable>
                  )}
                </Card>
              </Col>
            </Row>
            <Dialog
              className="addTemplate"
              style={{ width: "40vw" }}
              breakpoints={{ "960px": "75vw", "641px": "100vw" }}
              header={"Template Body"}
              visible={templateBodyModal}
              draggable={false}
              resizable={false}
              onHide={() => settemplateBodyModal(false)}
            >
              <div dangerouslySetInnerHTML={{ __html: viewTemplateBody }}></div>
            </Dialog>
         
            <Dialog
              className="addTemplate"
              style={{ width: "40vw" }}
              breakpoints={{ "960px": "75vw", "641px": "100vw" }}
              header={"Create a template"}
              visible={showTemplateTypeModal}
              draggable={false}
              resizable={false}
              onHide={() => setShowTemplateTypeModal(false)}
            >

              <div className="Add_template_create_template">
                <div className="preformatted-scratched-card" onClick={() => navigate(`/pre-template`)}>
                  <i className="pi pi-folder-open" style={{ fontSize: '2rem' }}></i>
                  <h5>Pre-formatted</h5>
                  <p>Start with one of our sequence templates</p>
                </div>
                <div className="preformatted-scratched-card" onClick={() => navigate(`/add-template/newTemplate/0`)}>
                  <i className="pi pi-send" style={{ fontSize: '2rem' }}></i>
                  <h5>From Scratch</h5>
                  <p>Create a new template from scratch</p>
                </div>
              </div>
            </Dialog>
          </Container>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Templates;
