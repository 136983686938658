import React, { useState, useEffect, useContext } from 'react';
import { Button, Table, Form, Modal, Container, Row, Col, Card } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import FeatherIcon from 'feather-icons-react';
import ApiHeader from '../Components/ApiHeader';
import ApiListFile from '../Components/ApiListFile';
import { contextVar } from '../Components/ContextVar';
// import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Campaigns = () => {
  const { API_CAMPAIGN, API_TEMPLATE } = ApiListFile();
  const { setnetworkError } = useContext(contextVar)

  const [campaigns, setCampaigns] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentCampaign, setCurrentCampaign] = useState({
    campaign_name: '',
    campaign_description: '',
    template_id: '',
    start_date: new Date().toISOString(),
    end_date: new Date().toISOString()
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${API_CAMPAIGN}`, {
      headers: ApiHeader()
    })
      .then(response => response.json())
      .then(data => {
        setCampaigns(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setLoading(false);
          return;
        }
      });

    fetch(`${API_TEMPLATE}`, {
      headers: ApiHeader(),
    })
      .then(response => response.json())
      .then(data => {
        setTemplates(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setLoading(false);
          return;
        }
      });
  }, []);

  const handleShow = (campaign) => {
    setCurrentCampaign(campaign);
    setShowModal(true);
  }

  const handleClose = () => {
    setCurrentCampaign({
      campaign_name: '',
      campaign_description: '',
      template_id: '',
      start_date: new Date().toISOString(),
      end_date: new Date().toISOString()
    });
    setShowModal(false);
  }

  const handleUpdate = async () => {
    const payload = {
      ...currentCampaign,
      creation_date: new Date().toISOString(),
    };
    const response = await fetch(`${API_CAMPAIGN}/${currentCampaign._id}`, {
      method: 'PUT',
      headers: ApiHeader(),
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      let updatedCampaignId = await response.text();
      updatedCampaignId = JSON.parse(updatedCampaignId);
      setCampaigns(campaigns.map(campaign => campaign._id === updatedCampaignId ? currentCampaign : campaign));
      handleClose();
    } else {
      // Handle the error...
    }
  }

  const handleAdd = async () => {
    const payload = {
      ...currentCampaign,
      creation_date: new Date().toISOString(),
    };
    const response = await fetch(`${API_CAMPAIGN}`, {
      method: 'POST',
      headers: ApiHeader(),
      body: JSON.stringify(payload),
    });
    if (response.ok) {
      const newCampaignId = await response.text();
      setCampaigns([...campaigns, { ...currentCampaign, _id: newCampaignId }]);
      handleClose();
    } else {
      // Handle the error...
    }
  }

  const handleDelete = async (campaign) => {
    const response = await fetch(`${API_CAMPAIGN}/${campaign._id}`, {
      method: 'DELETE',
      headers: ApiHeader(),
    });

    if (response.ok) {
      setCampaigns(campaigns.filter(c => c._id !== campaign._id));
    } else {
      // Handle the error...
    }
  }

  if (loading) {
    return <p>Loading...</p>;
  }

  return (

    <div className="content-page">
      <div className="content">
        <Container fluid>

          <Row>
            <Col>
              <div className="page-title-box">
                <h4 className="page-title">Campaigns</h4>
                <div className="page-title-right">
                  <div className="row g-2">
                    <div className="col-md-auto">
                      <Button variant="primary" size="sm" onClick={() => handleShow({
                        campaign_name: '',
                        campaign_description: '',
                        template_id: '64b117fa68b94270102a2348',
                        start_date: new Date().toISOString(),
                        end_date: new Date().toISOString()
                      })}> <i className="uil uil-plus"></i> Add Campaign</Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Campaign Name</th>
                        <th>Campaign Description</th>
                        <th>Template Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {campaigns.map((campaign) => (
                        <tr key={campaign._id}>
                          <td>{campaign.campaign_name}</td>
                          <td>{campaign.campaign_description}</td>
                          <td>{campaign.template_id}</td>
                          <td>{campaign.start_date}</td>
                          <td>{campaign.end_date}</td>
                          <td>
                            <Button variant='' className="text-primary" onClick={() => handleShow(campaign)}> <i className="uil uil-edit-alt"></i></Button>
                            <Button variant='' className="text-danger" onClick={() => handleDelete(campaign)}><i className="uil uil-trash"></i></Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{currentCampaign._id ? 'Update Campaign' : 'Add Campaign'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Campaign Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={currentCampaign.campaign_name || ''}
                    onChange={e => setCurrentCampaign({ ...currentCampaign, campaign_name: e.target.value })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Campaign Description</Form.Label>
                  <Form.Control
                    type="text"
                    value={currentCampaign.campaign_description || ''}
                    onChange={e => setCurrentCampaign({ ...currentCampaign, campaign_description: e.target.value })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Template Name</Form.Label>
                  <Form.Control as="select" value={currentCampaign.templateName} onChange={e => setCurrentCampaign({ ...currentCampaign, template_id: e.target.value })}>
                    <option>--Select--</option>
                    {templates && templates.map((template) => {
                      return (
                        <option value={template._id}>{template.template_name}</option>
                      )
                    })}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Start Date</Form.Label>
                  <DatePicker selected={new Date(currentCampaign.start_date)} onChange={e => setCurrentCampaign({ ...currentCampaign, start_date: e.toISOString() })} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>End Date</Form.Label>
                  <DatePicker selected={new Date(currentCampaign.end_date)} onChange={e => setCurrentCampaign({ ...currentCampaign, end_date: e.toISOString() })} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={currentCampaign._id ? handleUpdate : handleAdd}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>

    </div>
  );
}

export default Campaigns;
