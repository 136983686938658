import React, { useEffect, useState, useRef, useContext } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dropdown as DDown } from "react-bootstrap";
import { Toast } from "primereact/toast";
import "moment-timezone";
import ApiHeader, { fetchMethod } from "../Components/ApiHeader";
import ApiListFile from "../Components/ApiListFile";
import { contextVar } from "../Components/ContextVar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Chip } from "primereact/chip";
import { Tooltip } from "primereact/tooltip";

const timezoneOptions = [
  "Pacific Time",
  "Mountain Time",
  "Central Time",
  "Eastern Time",
  "UTC",
  "Europe/London",
  "Paris",
  "Athens",
  "Australia Eastern Standard Time",
  "Australia Western Standard Time",
  "Australia Central Standard Time",
  "Middle East Time",
  "Near East Time",
  "Pakistan Lahore Time",
  "Indian Standard Time",
  "Bangladesh Standard Time",
  "Vietnam Standard Time",
  "Japan Standard Time",
  "Solomon Standard Time",
  "New Zealand Standard Time",
  "Midway Islands Time",
  "Hawaii Standard Time",
  "Alaska Standard Time",
  "Puerto Rico and US Virgin Islands Time",
  "Canada Newfoundland Time",
  "Brazil Eastern Time",
];

const Schedules = () => {
  const { API_POST_USER_TIMEZONES, API_GET_SCHEDULE, API_PAGINATED_SCHEDULE, API_DEFAULT_SCHEDULE } = ApiListFile()
  const { setnetworkError, userGroup } = useContext(contextVar)
  const [timezone, setTimezone] = useState('Pacific Time');
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [timeSetModal, settimeSetModal] = useState(false);
  const [value, setValue] = useState('');
  const [totalRecords, settotalRecords] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [warnMsg, setwarnMsg] = useState(false)
  const [valueError, setValueError] = useState('');
  const [timezoneError, setTimezoneError] = useState('');
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState({});
  const [screenChange, setScreenChange] = useState(1)
  const [addButtonVisible, setaddButtonVisible] = useState(true)
  const [startTime, setstartTime] = useState()
  const [endTime, setendTime] = useState()
  const [dayStorage, setdayStorage] = useState('')
  const [timeStorage, settimeStorage] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  })
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "timestamp",
    sortOrder: -1,
  });

  const dt = useRef(null);


  const onPage = (event) => {
    setlazyState({
      ...lazyState,
      first: event.first,
      rows: event.rows,
      page: event.page + 1
    });
  };
//   const onSort = (event) => {
//     setlazyState({
//         ...lazyState,
//         sortOrder: event.sortOrder,
//         sortField: event.sortField,
//     });
// };

const onSort = (event) => {
  let newSortField = event.sortField;
  let newSortOrder = event.sortOrder;

  // Toggle the sorting behavior
  if (newSortField !== lazyState.sortField) {
      // If a new column is clicked, start sorting ascending (1)
      newSortOrder = 1;
  } else {
      // If the same column is clicked again, toggle between ascending, descending, and unsorted
      if (lazyState.sortOrder === 1) {
          newSortOrder = -1;  // Switch to descending
      } else if (lazyState.sortOrder === -1) {
          newSortOrder = 0;   // Remove sorting (unsorted)
          newSortField = null; // Set field to null to remove sorting
      } else {
          newSortOrder = 1;    // Back to ascending
      }
  }

  // Update the lazyState with the new sorting state
  setlazyState({
      ...lazyState,
      sortField: newSortField,
      sortOrder: newSortOrder,
  });
};

  // Handle selection changes
  const handleSelectionChange = (e) => {
    const selectedValues = e.value;

    setSelectedRows((prevSelectedRows) => {
      const selectedIds = new Set(selectedValues.map(row => row._id));

      // Create a new selection that merges previous and current selections
      const mergedSelection = [
        ...prevSelectedRows.filter(row => selectedIds.has(row._id) || !data.some(currentRow => currentRow._id === row._id)),
        ...selectedValues.filter(row => !prevSelectedRows.some(selectedRow => selectedRow._id === row._id))
      ];

      return mergedSelection;
    });
  };

  // Handle header checkbox selection
  const handleHeaderCheckboxChange = (e) => {
    const allSelected = e.checked;
    if (allSelected) {
      // Add all rows from the current page to the selection
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = [...prevSelectedRows];
        data.forEach(row => {
          if (!newSelectedRows.some(selectedRow => selectedRow._id === row._id)) {
            newSelectedRows.push(row);
          }
        });
        return newSelectedRows;
      });
    } else {
      // Remove all rows from the current page from the selection
      setSelectedRows((prevSelectedRows) => {
        return prevSelectedRows.filter(row => !data.some(currentRow => currentRow._id === row._id));
      });
    }
  };

  // Persist selection state across pages
  useEffect(() => {
    if (dt.current) {
      dt.current.value = selectedRows;
    }
  }, [selectedRows]);

  const generateCSV = (data) => {

    // Mapping full day names to their abbreviations
    const dayAbbreviations = {
      Monday: 'Mon',
      Tuesday: 'Tues',
      Wednesday: 'Wed',
      Thursday: 'Thurs',
      Friday: 'Fri',
      Saturday: 'Sat',
      Sunday: 'Sun'
    };

    // Headers for the CSV
    const headers = ['Name', 'Time Zone', 'Day'];

    // Rows for the CSV
    let rows = [];

    data.forEach(obj => {
      if (obj.dayTime) {
        let daySet = new Set();
        Object.keys(obj.dayTime).forEach(day => {
          const abbreviatedDay = dayAbbreviations[day] || day;
          daySet.add(abbreviatedDay);
        });

        // Convert set to array and join into a single string
        const dayString = `${Array.from(daySet).join(', ')}`;

        rows.push([
          `"${obj.name}"`,
          `"${obj.timezone}"`,
          `"${dayString}"`
        ].join(','));
      } else {
        rows.push([
          `"${obj.name}"`,
          `"${obj.timezone}"`,
          `"[]"` // Handle case with no dayTime
        ].join(','));
      }
    });

    // Combine headers and rows
    return headers.join(',') + '\n' + rows.join('\n');
  };

  // Function to export CSV with custom filename
  const exportCSV = () => {
    // Generate CSV from the selectedRows
    const csvData = generateCSV(selectedRows);

    // Create a blob from the CSV
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a link element and trigger the download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'schedule.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClose = () => {
    setValue('')
    setTimezone('Pacific Time')
    setValueError('');
    setVisible(false)
  }

  const handleSubmit = () => {
    setisLoading(true);
    let isValid = true;

    if (!value) {
      isValid = false;
      setValueError('Schedule name is required');
      setisLoading(false);
    } else {
      setValueError('');
    }

    if (!timezone) {
      isValid = false;
      setTimezoneError('Select Timezone');
      setisLoading(false);
    } else {
      setTimezoneError('');
    }

    if (isValid) {
      fetchMethod('POST', `${API_GET_SCHEDULE}`, {
        name: value,
        timezone: timezone
      })
        .then((data) => {
          if (data.status === 'success') {
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: data.message,
            });
            setScreenChange(2)
            setVisible(false);
          } else {
            toast.current.show({
              severity: 'warn',
              summary: 'Error',
              detail: data.message,
            });
            setScreenChange(1)
          }

          settotalRecords(data?.total_count);
          setDataEdit({ '_id': data?.data, 'name': value, 'timezone': timezone })
          settimeStorage([])
          setTimezone('Pacific Time')

          setValue('');
          // setTimezone('')
          setaddButtonVisible(false)
          // fetchScheduleList();
        })
        .catch((err) => {
          console.error(err);
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred while processing your request.',
          });
        })
        .finally(() => {
          setisLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchScheduleList()
  }, [globalFilter, lazyState]);

  const fetchScheduleList = () => {
    setisLoading(true);
    fetchMethod("POST", `${API_PAGINATED_SCHEDULE}`, {
      page: lazyState.page,
      page_size: lazyState.rows,
      search_query: globalFilter,
      sort_order: lazyState.sortOrder,
      sort_field: lazyState.sortField,
    })
      .then((data) => {
        setData(data?.items);
        settotalRecords(data?.total_count)
        setisLoading(false);
        // setSelectedRows([])
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    fetchMethod("GET", `${API_POST_USER_TIMEZONES}`, {})
      .then((data) => {
        if (data?.item != null) {
          setTimezone(data.item.timezone);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          return;
        }
      });
  }, []);

  const handleSave = (id) => {
    setisLoading(true);
    let isValid = true;
    if (!dataEdit.name) {
      isValid = false;
      setValueError('Schedule name is required');
      setisLoading(false);
    } else {
      setValueError('');
    }

    if (!dataEdit.timezone) {
      isValid = false;
      setTimezoneError('Select Timezone');
      setisLoading(false);
    } else {
      setTimezoneError('');
    }
    if (typeof timeStorage === 'object' && timeStorage !== null && Object.keys(timeStorage).length === 0) {
      setwarnMsg(true);
      setisLoading(false);
      return;
    }

    if (isValid) {
      fetchMethod("PUT", `${API_GET_SCHEDULE}/${id}`, {
        name: dataEdit.name,
        timezone: dataEdit.timezone,
        dayTime: timeStorage,
      })
        .then((data) => {

          if (data.status === 'success') {
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: data.message,
            });
            setScreenChange(1);

          } else {
            toast.current.show({
              severity: 'warn',
              summary: 'Error',
              detail: data.message,
            });
            setScreenChange(2);
          }

          // Reset warning message and loading state
          setwarnMsg(false);
          setisLoading(false);
          setTimezone('Pacific Time')

          // Update screen and fetch new schedule list
          fetchScheduleList();

          // Reset global filter and make the add button visible
          setGlobalFilter(null);
          setaddButtonVisible(true);
        })
        .catch((err) => {
          console.error(err);
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred while processing your request.',
          });
          setisLoading(false);
        });
    }
  };

  const bodyTemplate = (rowData, field) => {
    const displayText = rowData[field];
    const maxLength = 20;  // Set the maximum length for text display
    const truncatedText = displayText && displayText.length > maxLength ? `${displayText.substring(0, maxLength)}...` : displayText;

    return (
      <>
        <Tooltip target=".tooltip-note-text" >
        </Tooltip>
        <span className="tooltip-note-text" data-pr-tooltip={displayText}>
          {truncatedText}
        </span>
      </>
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name' && value.startsWith(' ')) {
      return;
    }
    setDataEdit(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  const handleTimezoneChange = (e) => {
    setTimezone(e.target.value);
    setDataEdit(prevState => ({
      ...prevState,
      timezone: e.target.value
    }));
  };

  const onRowClick = (rowData) => {
    settimeStorage(rowData?.dayTime)
    setDataEdit(rowData)
    setScreenChange(2)
    setaddButtonVisible(false)
  };


  const renderDay = (dayTime) => {
    // Mapping of full day names to abbreviated forms
    const dayAbbreviations = {
      Sunday: 'Sun',
      Monday: 'Mon',
      Tuesday: 'Tue',
      Wednesday: 'Wed',
      Thursday: 'Thu',
      Friday: 'Fri',
      Saturday: 'Sat'
    };

    // Create an array to store day abbreviations 
    const daysData = [];

    // Iterate through each day in the dayTime object
    for (const day in dayTime) {
      if (dayTime.hasOwnProperty(day)) {
        // Push the abbreviation of the current day to the array
        daysData.push(dayAbbreviations[day]);
      }
    }
    // Sort the array of day abbreviations
    daysData.sort((a, b) => {
      const dayOrder = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      return dayOrder.indexOf(a) - dayOrder.indexOf(b);
    });


    // Join the array elements with a comma separator and return as a single string
    return daysData.join(', ');
  };

  const columns = [
    {
      field: 'name', header: 'Name',
      body: rowData => (
        <>
          <Tooltip target={`.tooltip-b-${rowData._id}`} />
          <span className={`tooltip-note-text tooltip-b-${rowData._id}`}
            data-pr-tooltip={rowData.name.length > 30 ? rowData.name : ''}>
            {rowData.name.length > 30 ? `${rowData.name.slice(0, 30)}...` : rowData.name}
          </span>
        </>
      ),
      sortable: true

    },
    { field: 'timezone', header: 'Time Zone' ,sortable: true},
    {
      field: 'dayTime',
      header: 'Day',
      body: rowData => renderDay(rowData.dayTime) ,// Rendering only the day part
      sortable: true
    },
    {
      field: 'default',
      body: rowData => (
        rowData.default === true ? <Chip label="Default" className="ms-2" /> : null
      ),
      
    },
    {
      header: 'Actions', body: (rowData) => (
        <>

          <DDown className=" action-more" align="end">
            <DDown.Toggle
              as="a"
              className="arrow-none text-muted cursor-pointer"
            >
              <i className="uil uil-ellipsis-v"></i>
            </DDown.Toggle>
            <DDown.Menu>
              {!rowData.default && userGroup?.schedules?.make_default_schedule?.A &&
                <DDown.Item onClick={() => handleDefaultValue(rowData._id)}>
                  <i className="pi pi-check-circle me-2"></i>Make Default
                </DDown.Item>}
              {!rowData.default && userGroup?.schedules?.delete_schedule?.D &&
                <DDown.Item
                  className="text-danger"
                  onClick={() => confirmdelete(rowData._id)}
                >
                  <i className="pi pi-trash me-2"></i>Delete
                </DDown.Item>
              }
              {userGroup?.schedules?.update_schedules?.C &&
                <DDown.Item onClick={() => onRowClick(rowData)}>
                  <i className="pi pi-pencil me-2"></i>Edit
                </DDown.Item>}
            </DDown.Menu>
          </DDown>
        </>
      )
    },
  ];

  const handleDefaultValue = (id) => {
    setisLoading(true);
    fetchMethod("PUT", `${API_DEFAULT_SCHEDULE}/${id}`, {})
      .then((data) => {
        if (data.status == 'success') {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: data.message,
          });

        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: data.message,
          });
        }
        setisLoading(false)
        setScreenChange(1)
        // fetchScheduleList()
        setlazyState({
          first: 0,
          rows: 10,
          page: 1,
          sortField: "timestamp",
          sortOrder: -1,
        })
      })
      .catch((err) => {
        console.error(err)
        setisLoading(false);
      });
  }

  const accept = (id) => {
    handleDelete(id)
  }

  const confirmdelete = (id) => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => accept(id),
      draggable: false
    });
  };

  const handleDelete = (scheduleId) => {
    setisLoading(true);
    fetchMethod("DELETE", `${API_GET_SCHEDULE}/${scheduleId}`, {})
      .then(() =>
        fetchScheduleList(),
        setisLoading(false)
      )
      .then((data) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Schedule Deleted Successfully",
        });
        setSelectedRows([])
      })

      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setisLoading(false);
          return;
        }
      });
  };

  const handleBackButton = () => {
    setwarnMsg(false)
    setValueError('')
    setScreenChange(1)
    setwarnMsg(false)
    settimeStorage([])
    setaddButtonVisible(true)
    fetchScheduleList()
  }

  const sectionHeader = (
    <div className="d-flex justify-content-between align-item-center">
      {screenChange == 2 && <span className=" cursor-pointer back-btn-arrow " onClick={() => handleBackButton()}><i className="pi pi-arrow-left" style={{ fontSize: '1rem' }}></i> </span>}
      <h6 className="mb-0 ms-2">Schedules</h6>
    </div>
  )
  const generateTimeSlots = () => {
    const time = [];
    for (let hour = 0; hour < 24; hour++) {
      const hourString = hour === 0 || hour === 12 ? '12' : (hour < 12 ? hour : hour - 12).toString();
      const period = hour < 12 ? 'AM' : 'PM';
      const timeString = `${hourString} ${period}`;
      time.push({ name: timeString, time: timeString });
    }
    return time;
  };
  const time = generateTimeSlots();


  const handleTimeBlockModal = (days) => {
    setdayStorage(days)
    settimeSetModal(true)
    setstartTime(time[8]); // Set to the first option in the 'time' array
    setendTime(time[17]); // Set to the second option in the 'time' array
  }

  const handleTimeStorage = () => {
    if (startTime?.time && endTime?.time) {
      const newTimeBlock = { startTime: startTime.time, endTime: endTime.time };

      // Helper function to check if a time is 12 AM
      // Check if start time and end time are the same
      if (newTimeBlock.startTime === newTimeBlock.endTime) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: "Start time and end time cannot be the same",
        });
        return; // Exit the function early
      }


      const parseTime = (timeString) => {
        const [time, period] = timeString.split(' ');
        let [hours] = time.split(':').map(Number);
        if (period === 'PM' && hours !== 12) hours += 12;
        if (period === 'AM' && hours === 12) hours = 0;
        return hours * 60; // Convert to minutes
      };

      const start = parseTime(newTimeBlock.startTime);
      const end = parseTime(newTimeBlock.endTime);

      // Check if start time is greater than or equal to end time
      if (start >= end) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: "Start time must be earlier than end time",
        });
        return; // Exit the function early
      }


      // Access existing time blocks for the selected day
      const existingTimeBlocks = timeStorage[dayStorage] || [];

      // Check if the new time block already exists in the storage for the same day
      const isDuplicate = existingTimeBlocks.some(
        (block) => block.startTime === newTimeBlock.startTime && block.endTime === newTimeBlock.endTime
      );

      if (isDuplicate) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: "This time block already exists for the selected day",
        });
      } else {
        // Update the timeStorage state immutably if it's not a duplicate
        settimeStorage((prevTimeStorage) => ({
          ...prevTimeStorage,
          [dayStorage]: [...existingTimeBlocks, newTimeBlock],
        }));
        setstartTime('');
        setendTime('');
        settimeSetModal(false);
      }
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Error",
        detail: "Please select start and end time",
      });
    }
  };


  const clearTimeStorage = (day) => {
    settimeStorage(prevTimeStorage => {
      const updatedStorage = { ...prevTimeStorage };
      delete updatedStorage[day]; // Remove entry for the specified day
      return updatedStorage;
    });
  };
  const closeModal = () => {
    setstartTime('');
    setendTime('');
    settimeSetModal(false)
  }

  const handleTimeModal = () => {
    settimeSetModal(false)
    setstartTime('');
    setendTime('');
  }

  const hideAddModal = () => {
    setVisible(false)
    setValueError('');
    setValue('')
    setTimezone('Pacific Time')
  }

  const footerContent = (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={closeModal} />
      <Button disabled={!startTime || !endTime} label="Add Time Block" icon="pi pi-check" onClick={handleTimeStorage} autoFocus />
    </div>
  );

  return (

    <>
      <ConfirmDialog />
      {/* Edit  */}
      <Row>
        <Col md={12}>
          <Toast ref={toast} />

          <Panel header={
            <div className="panel-header">
              {sectionHeader}
              {addButtonVisible && userGroup?.schedules?.add_schedule?.A &&
                <Button className="ms-auto" label="Add Schedules" size="small" icon="pi pi-plus" onClick={() => { setVisible(true); setTimezone('Pacific Time'); }} />}
            </div>
          }>
            {screenChange == 1 ? <div className="card p-4">
              <div className="export-button-sequence">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText type='search' placeholder="Search" className="form-control-sm" onInput={(e) => setGlobalFilter(e.target.value)} />
                </span>
                {/* Filter button here */}
                <Button
                  type="button"
                  size="small"
                  icon="pi pi-download"
                  label="Export"
                  className="p-button-primary btn-small"
                  onClick={exportCSV}
                  disabled={!selectedRows || selectedRows.length === 0}
                />
              </div>
              {isLoading ? (
                <div className="skeleton-container">
                  <DataTable
                    value={[{}, {}, {}, {}, {}]}
                  >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                    {columns.map((column, index) => (
                      <Column key={index} header={column.header} body={<Skeleton />} />
                    ))}
                  </DataTable>
                </div>
              ) : (

                <DataTable
                  ref={dt}
                  value={data}
                  selectionMode="checkbox"
                  selection={selectedRows}
                  onSelectionChange={handleSelectionChange}
                  onHeaderCheckboxToggle={handleHeaderCheckboxChange}
                  dataKey="_id"
                  tableStyle={{ minWidth: '50rem' }}
                  lazy
                  first={lazyState.first}
                  totalRecords={totalRecords}
                  onPage={onPage}
                  globalFilter={globalFilter}
                  onSort={onSort}
                  sortField={lazyState.sortField}
                  sortOrder={lazyState.sortOrder}
                  rows={lazyState.rows}
                  paginator
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                // onRowClick={(e) => onRowClick(e.data)}
                >
                  <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                  {columns.map((column, index) => (
                    <Column
                      className="cursor-pointer"
                      key={index}
                      field={column.field}
                      header={column.header}
                      body={column.body ? (rowData) => column.body(rowData, column.field, bodyTemplate) : (rowData) => bodyTemplate(rowData, column.field)}
                      sortable={column?.sortable}
                    />
                  ))}
                </DataTable>
              )}
            </div> :
              <div className="">
                <Form >
                  <div className="row">
                    <Form.Group controlId="name" className="mb-3 col-md-6">
                      <Form.Label className="w-100">Name <span className="text-danger">*</span></Form.Label>
                      {/* <InputText value={dataEdit?.name} className="w-100" onChange={e => {
                        const value = e.target.value;
                        if ((/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/.test(value) && value[0] !== ' ') || value === '') {
                          handleChange()  
                        }
                      }}/> */}
                      <InputText name='name' value={dataEdit?.name} className="w-100" onChange={handleChange} />
                      {valueError && <small className="text-danger">{valueError}</small>}
                    </Form.Group>
                    <Form.Group controlId="timezoneSelect" className="mb-3 col-md-6">
                      <Form.Label className="w-100">Select Timezone <span className="text-danger">*</span></Form.Label>
                      <Dropdown
                        name="timezone"
                        value={dataEdit?.timezone}
                        onChange={handleTimezoneChange}
                        options={timezoneOptions}
                        optionLabel={(option) => option} // Display the timezone option as it is
                        placeholder="Select a Timezone"
                        className="w-full col-md-12"
                      />
                      {timezoneError && <small className="text-danger">{timezoneError}</small>}
                    </Form.Group>
                  </div>

                  {warnMsg &&
                    <h6 className="text-danger">You need to select atleast one sending window</h6>
                  }
                  <h6>Sending Windows</h6>
                  <div className="mb-2">
                    <p className="mb-2">Monday : </p>
                    <div>
                      {timeStorage.Monday && timeStorage.Monday.map((item, index) => (
                        <span key={index}>
                          {item?.startTime} - {item?.endTime}{index < timeStorage.Monday.length - 1 ? ' , ' : ''}
                        </span>
                      ))}
                    </div>
                    <div className="d-flex">
                      <span onClick={() => handleTimeBlockModal('Monday')} className="btn btn-primary me-2">Add Time Block</span> <Button type="button" label="Clear" size="small" outlined onClick={() => clearTimeStorage('Monday')} />
                    </div>
                  </div>
                  <div className="mb-2">
                    <p className="mb-2">Tuesday : </p>
                    {timeStorage.Tuesday && timeStorage.Tuesday.map((item, index) => (
                      <span key={index}>
                        {item?.startTime} - {item?.endTime}{index < timeStorage.Tuesday.length - 1 ? ' , ' : ''}
                      </span>
                      // <p className="mb-2">{item?.startTime} - {item?.endTime}</p>
                    ))}
                    <div className="d-flex">
                      <span onClick={() => handleTimeBlockModal('Tuesday')} className="btn btn-primary me-2">Add Time Block</span> <Button type="button" label="Clear" size="small" outlined onClick={() => clearTimeStorage('Tuesday')} />
                    </div>
                  </div>
                  <div className="mb-2">
                    <p className="mb-2">Wednesday : </p>
                    {timeStorage.Wednesday && timeStorage.Wednesday.map((item, index) => (
                      <span key={index}>
                        {item?.startTime} - {item?.endTime}{index < timeStorage.Wednesday.length - 1 ? ' , ' : ''}
                      </span>
                    ))}
                    <div className="d-flex">
                      <span onClick={() => handleTimeBlockModal('Wednesday')} className="btn btn-primary me-2">Add Time Block</span> <Button type="button" label="Clear" size="small" outlined onClick={() => clearTimeStorage('Wednesday')} />
                    </div>
                  </div>
                  <div className="mb-2">
                    <p className="mb-2">Thursday : </p>
                    {timeStorage.Thursday && timeStorage.Thursday.map((item, index) => (
                      <span key={index}>
                        {item?.startTime} - {item?.endTime}{index < timeStorage.Thursday.length - 1 ? ' , ' : ''}
                      </span>
                    ))}
                    <div className="d-flex">
                      <span onClick={() => handleTimeBlockModal('Thursday')} className="btn btn-primary me-2">Add Time Block</span> <Button type="button" label="Clear" size="small" outlined onClick={() => clearTimeStorage('Thursday')} />
                    </div>
                  </div>
                  <div className="mb-2">
                    <p className="mb-2">Friday : </p>
                    {timeStorage.Friday && timeStorage.Friday.map((item, index) => (
                      <span key={index}>
                        {item?.startTime} - {item?.endTime}{index < timeStorage.Friday.length - 1 ? ' , ' : ''}
                      </span>
                    ))}
                    <div className="d-flex">
                      <span onClick={() => handleTimeBlockModal('Friday')} className="btn btn-primary me-2">Add Time Block</span> <Button type="button" label="Clear" size="small" outlined onClick={() => clearTimeStorage('Friday')} />
                    </div>
                  </div>
                  <div className="mb-2">
                    <p className="mb-2">Saturday : </p>
                    {timeStorage.Saturday && timeStorage.Saturday.map((item, index) => (
                      <span key={index}>
                        {item?.startTime} - {item?.endTime}{index < timeStorage.Saturday.length - 1 ? ' , ' : ''}
                      </span>
                    ))}
                    <div className="d-flex">
                      <span onClick={() => handleTimeBlockModal('Saturday')} className="btn btn-primary me-2">Add Time Block</span> <Button type="button" label="Clear" size="small" outlined onClick={() => clearTimeStorage('Saturday')} />
                    </div>
                  </div>
                  <div className="mb-2">
                    <p className="mb-2">Sunday : </p>
                    {timeStorage.Sunday && timeStorage.Sunday.map((item, index) => (
                      <span key={index}>
                        {item?.startTime} - {item?.endTime}{index < timeStorage.Sunday.length - 1 ? ' , ' : ''}
                      </span>
                    ))}
                    <div className="d-flex">
                      <span onClick={() => handleTimeBlockModal('Sunday')} className="btn btn-primary me-2">Add Time Block</span> <Button type="button" label="Clear" size="small" outlined onClick={() => clearTimeStorage('Sunday')} />
                    </div>
                  </div>


                  <Dialog header="Add Time Block" draggable={false} footer={footerContent} visible={timeSetModal} onHide={() => handleTimeModal()}
                    style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                    <div className="row">
                      <div className="col-md-6" >
                        <label className="col-md-12 mb-2">Start Time <span className="text-danger">*</span></label>
                        <Dropdown value={startTime} size={'small'} onChange={(e) => setstartTime(e.value)} options={time} optionLabel="name"
                          placeholder="Select start date" className="col-md-12" />
                      </div>
                      <div className="col-md-6" >
                        <label className="col-md-12 mb-2">End Time <span className="text-danger">*</span></label>
                        <Dropdown className="col-md-12" value={endTime} size={'small'} onChange={(e) => setendTime(e.value)} options={time} optionLabel="name"
                          placeholder="Select end date" />
                      </div>
                    </div>
                  </Dialog>

                  <Button
                    className="mt-4"
                    type="button"
                    size="small"
                    label="Save"
                    icon="pi pi-check"
                    onClick={() => handleSave(dataEdit?._id)}
                    loading={isLoading}
                  />
                </Form>
              </div>}
          </Panel>
        </Col>
      </Row>

      {/*Add User modal */}
      <Dialog
        header={'Add Schedules'}
        visible={visible}
        // onHide={() => setVisible(false)}
        onHide={hideAddModal}
        style={{ width: '30vw' }}
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        draggable={false}
        resizable={false}
      >
        <Form>
          <Form.Group controlId="timezoneSelect" className="mb-3 col-md-6">
            <Form.Label className="w-100">Name<span className="text-danger">*</span></Form.Label>
            <InputText value={value} className="w-100" onChange={e => {
              const value = e.target.value;
              if ((/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/.test(value) && value[0] !== ' ') || value === '') {
                setValue(e.target.value);
              }
            }}
              maxLength={100}
            // onChange={(e) => setValue(e.target.value)}
            />
            {valueError && <small className="text-danger">{valueError}</small>}
          </Form.Group>
          <Form.Group controlId="timezoneSelect" className="mb-3 col-md-6">
            <Form.Label className="w-100">Select Timezone <span className="text-danger">*</span></Form.Label>
            <Dropdown
              value={timezone}
              onChange={handleTimezoneChange}
              options={timezoneOptions}
              optionLabel={(option) => option} // Display the timezone option as it is
              placeholder="Select a Timezone"
              className="w-full "
            />
            {timezoneError && <small className="text-danger">{timezoneError}</small>}
          </Form.Group>


        </Form>

        <div className="d-flex justify-content-end">
          <Button onClick={handleClose} label="Close" size="small" outlined />
          <Button
            icon="pi pi-check"
            label={'Save'}
            size="small"
            className="ms-2"
            onClick={handleSubmit}
            loading={isLoading}
          // disabled={!value || !timezone}
          />
        </div>

      </Dialog>

    </>
  );
};

export default Schedules;