import "./DomyTemplate.css";

const template2HTML = `
<div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #ccc;">
  <table cellpadding="0" cellspacing="0" border="0" width="100%" style="margin-bottom: 20px;">
    <tr>
      <td align="center">
        <img src="https://placehold.co/100x40.png?text=Logo" alt="Logo" style="max-width: 100px; height: auto;"/>
      </td>
    </tr>
  </table>

  <table cellpadding="0" cellspacing="0" border="0" width="100%" style="margin-bottom: 20px;">
    <tr>
      <td align="center" style="font-size: 24px; font-weight: bold; padding: 10px 0;">
        The point of using Lorem Ipsum is that it has Your Heading
      </td>
    </tr>
  </table>

  <table cellpadding="0" cellspacing="0" border="0" width="100%" style="margin-bottom: 20px;">
    <tr>
      <td>
        <img src="https://res.cloudinary.com/dx0yui1iq/image/upload/v1727088893/placeholder_1_ruzdxp.png" alt="Placeholder" style="width: 100%; max-width: 600px; height: auto; display: block; margin-bottom: 10px;"/>
      </td>
    </tr>
    <tr>
      <td>
        <table cellpadding="0" cellspacing="0" border="0" width="100%">
          <tr>
            <td width="50%" style="padding-right: 5px;">
              <img src="https://res.cloudinary.com/dx0yui1iq/image/upload/v1727088893/placeholder_1_ruzdxp.png" alt="Placeholder" style="width: 100%; height: auto; display: block;"/>
            </td>
            <td width="50%" style="padding-left: 5px;">
              <img src="https://res.cloudinary.com/dx0yui1iq/image/upload/v1727088893/placeholder_1_ruzdxp.png" alt="Placeholder" style="width: 100%; height: auto; display: block;"/>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

  <table cellpadding="0" cellspacing="0" border="0" width="100%" style="margin-bottom: 20px;">
    <tr>
      <td style="font-size: 18px; color: #585858; padding-bottom: 10px;">
        Lorem Ipsum is simply dummy text of the printing
      </td>
    </tr>
    <tr>
      <td style="font-size: 14px; color: #888; line-height: 1.5;">
        page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal. If a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using
      </td>
    </tr>
  </table>

  <table cellpadding="0" cellspacing="0" border="0" width="100%" style="margin-bottom: 20px;">
    <tr>
      <td align="center">
        <table cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td style="background-color: #484848; padding: 10px 20px; border-radius: 5px;">
              <a href="#" style="color: #ffffff; text-decoration: none; font-weight: bold;">Call to Action</a>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  
  <table cellpadding="0" cellspacing="0" border="0" width="100%" style="background-color: #eff7ff; padding: 20px;">
    <tr>
      <td align="center" style="color: #000;">
        &copy; 2023 Your Company
      </td>
    </tr>
  </table>
</div>
`;

export default template2HTML;