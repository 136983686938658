import React from "react";
import { Container } from "react-bootstrap";
import { loadStripe } from '@stripe/stripe-js';
import { Check } from "feather-icons-react/build/IconComponents";
import "./PricingSection.css";
import ApiListFile from "../Components/ApiListFile";
import { fetchMethod } from "../Components/ApiHeader";

const stripePromise = loadStripe('pk_test_51IVZJGJbSDxwoE5W3kEDqNNgYvXgwzaNXpBze4JEfcrngGMf2hMxe5zXGIYegr1HVpfjOTIq6WjMr8carXfPWGr600CWzbWb1M'); // Use your actual publishable key

const PricingSection = () => {
    const { API_CHECKOUT_SESSION } = ApiListFile()

    const handleSubscribe = async (priceId) => {
        const stripe = await stripePromise;
        try {
            // Call your backend to create the checkout session
            const session = await fetchMethod(
                'POST', `${API_CHECKOUT_SESSION}`,
                { price_id: priceId }
            );

            // Redirect to Stripe Checkout
            const result = await stripe.redirectToCheckout({
                sessionId: session.sessionId,
            });

            if (result.error) {
                alert(result.error.message);
            }
        } catch (error) {
            console.error('Error subscribing:', error);
            alert('An error occurred. Please try again.');
        }
    };
    return (
        <div className="content-page pricingMain">
            <div className="content pt-5">
                <Container>
                    <div className="row"> 
                        {[{'name': 'Standard', 'price': 10, 'price_id': 'price_1OtkeKJbSDxwoE5WXqoBGTOM','content': 'Send the right content at the right time with testing and scheduling features.', 'features': ['Cras justo odio', 'Dapibus ac facilisis in', 'Vestibulum at eros']},{'name': 'Premium', 'price': 50, 'price_id': 'price_1OtkeiJbSDxwoE5WNqjwzODy','content': 'Sell even more with personalization, optimization tools, and enhanced automations.', 'features': ['Cras justo odio', 'Dapibus ac facilisis in', 'Vestibulum at eros']}, {'name': 'Enterprise', 'price': 100, 'price_id': 'price_1P8h4wJbSDxwoE5WqxmTdJ6h','content': 'Scale fast with dedicated onboarding, unlimited contacts, and priority support; built for teams.', 'features': ['Cras justo odio', 'Dapibus ac facilisis in', 'Vestibulum at eros']}].map((plan, idx) => (
                            <div key={idx} className="col-lg-4 col-md-12 mb-4">
                                <div className="card h-100 shadow-lg">
                                    <div className="card-body">
                                        <div className="text-center pricing-heading p-3">
                                            <h4 className="card-title">{plan.name}</h4>
                                            <p>Per User</p>
                                            <br />
                                            <span className="h2">${plan.price}</span>/month
                                            <br />
                                        </div>
                                        <p className="card-text ">{plan.content}</p>
                                    </div>
                                    <ul className="list-group list-group-flush">
                                        {plan.features.map((feature, index) => (
                                            <li key={index} className="list-group-item">
                                                <Check /> {feature}
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="card-body text-center">
                                        <button className="btn btn-outline-primary btn-lg" style={{ borderRadius: '30px' }} onClick={() => handleSubscribe(plan.price_id)}>
                                            Subscribe
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default PricingSection;
