// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-progress-spinner-circle {
    stroke: #454545 !important;
  }`, "",{"version":3,"sources":["webpack://./src/CustomProgressSpinner.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;EAC5B","sourcesContent":[".p-progress-spinner-circle {\n    stroke: #454545 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
