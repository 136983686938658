import moment from "moment-timezone";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Form, Container, Col, Row } from "react-bootstrap";
import Select from "react-select";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import { confirmDialog } from "primereact/confirmdialog";
import { Skeleton } from "primereact/skeleton";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import DatePicker from 'react-datepicker';
import { MdCalendarToday } from "react-icons/md";

import Emails from "./Email";
import Contacts from "./Contacts";
import ListView from "./ListView";
import GridView from "./GridView";
import ApiListFile from "../Components/ApiListFile";
import { fetchMethod } from "../Components/ApiHeader";
import { contextVar } from "../Components/ContextVar";

import "./Sequence.css";
import "./SequenceDetails.css";
import 'react-datepicker/dist/react-datepicker.css';
import tzMapper from "../utils/tz_mapper.json";
import { Tooltip } from "primereact/tooltip";

const SequenceDetails = () => {
  const {
    API_GET_SEQUENCE_NAME,
    API_SEQUENCE_STEPS,
    API_TEMPLATE,
    API_POST_USER_TIMEZONES,
    API_DEPENDENT_STEPS,
    API_EMAIL_SEQUENCE_STATS
  } = ApiListFile()

  const delayOptions = [
    { value: "minutes", label: "minutes" },
    { value: "hours", label: "hours" },
    { value: "days", label: "days" },
  ];

  const stepTypesOptions = [
    { value: "automatic_email", label: "Automatic Email" },
    { value: "manual_email", label: "Manual Email" },
    { value: "phone_call", label: "Phone Call" },
    { value: "action_item", label: "Action Item" },
  ];

  const toast = useRef(null);
  const { setnetworkError } = useContext(contextVar)

  const [visible, setVisible] = useState(false);
  const { id } = useParams();
  const [steps, setSteps] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [sequenceName, setSequenceName] = useState([]);
  const [sequenceDateTime, setSequenceDateTime] = useState([]);
  const [errorMsg, setErrorMsg] = useState("")
  const [currentFilter, setCurrentFilter] = useState("all");
  const [globalFilter, setGlobalFilter] = useState("");
  const [emailDetailsValue, setEmailDetailsValue] = useState({});
  const [templateLoader, settemplateLoader] = useState(true);
  const [fetchedSteps, setFetchedSteps] = useState([]);
  const [timezone, setTimezone] = useState("");
  const [dependentOnLoading, setDependentOnLoading] = useState(false);
  const [stepConditionsOptions, setStepConditionsOptions] = useState([]);
  const [initialDueDate, setInitialDueDate] = useState(null);
  const [currentStep, setCurrentStep] = useState({
    step_type: "",
    step_condition: null,
    delay_type: { value: "days", label: "days" },
    delay_duration: "",
    task_priority: "Low Priority",
    due_date: initialDueDate,
    note: "",
    template: null,
    step_value: steps[0] ? 1 : "",
    dependent_on: null,
  });
  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [sequenceStepsloading, setSequenceStepsLoading] = useState(true);
  const [sequenceNameLoading, setSequenceNameLoading] = useState(true);
  const [reportsLoading, setReportsLoading] = useState(true);
  const [addLoading, setAddLoading] = useState(false);
  const [found, setFound] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabLoading, setTabLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    step_type: "",
    step_condition: "",
    delay_duration: "",
    template: "",
    delay_type: "",
  });
  const [activeButton, setActiveButton] = useState("A");
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    fetchMethod("GET", `${API_POST_USER_TIMEZONES}`, {})
      .then((data2) => {
        if (data2.item != null) {
          setTimezone(data2.item.timezone);
        } else {
          setTimezone(undefined);
        }
      })
      .catch((error) => {
        console.error(error);
        setTimezone(undefined);
        if (error instanceof TypeError) {
          setnetworkError(true)
          return;
        }
      });

  }, []);

  useEffect(() => {
    if (timezone) {
      const tzAwareDate = moment.tz(tzMapper[timezone]).startOf('day');
      const adjustedDate = convertToBrowserLocalTimezone(tzAwareDate, tzMapper[timezone]);
      setInitialDueDate(adjustedDate);
    }
  }, [timezone]);

  useEffect(() => {
    if (activeTabIndex === 0) {
      document.title = "Sequences";
      fetchSequenceDetails();
      fetchSequenceStep()
      fetchSequenceEmailsCount();
    }
  }, [steps.length, activeTabIndex]);

  useEffect(() => {
    const isShowStepCond = showStepCondition()
    setCurrentStep(prevStep => ({
      ...prevStep,
      step_condition: isShowStepCond ? null : stepConditionsOptions.find(op => op.value === "none"),
    }))
  }, [stepConditionsOptions]);

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="custom-datepicker-input" onClick={onClick} ref={ref}>
      <input
        className="form-control"
        value={value}
        readOnly
      />
      <MdCalendarToday className="calendar-icon" />
    </div>
  ));

  const convertToBrowserLocalTimezone = (selectedTimezoneDate, selectedTimezone) => {
    const localEquivalentDate = moment.tz(selectedTimezoneDate.format('YYYY-MM-DD'), selectedTimezone).clone().tz(moment.tz.guess());
    return localEquivalentDate.format('YYYY-MM-DD');
  };

  const handleDateSelection = (selectedDateAsString) => {
    // Assuming selectedDateAsString is in "YYYY-MM-DD" format
    const dateInSelectedTimezone = moment.tz(selectedDateAsString, "DD-MM-YYYY", tzMapper[timezone]);
    setCurrentStep(prevStep => ({
      ...prevStep,
      due_date: dateInSelectedTimezone.format(),
    }));
  };

  // Function to handle adding a step
  const handleAddStep = () => {
    setCurrentStep(prevStep => ({
      ...prevStep,
      step_type: "",
      delay_type: {
        value: "days",
        label: "days",
      },
      delay_duration: "",
      task_priority: "",
      note: "",
      due_date: initialDueDate,
      template: null,
      step_condition: null,
      dependent_on: null,
    }));
    getTemplateData();
    setVisible(true);
  }

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const fetchSequenceStep = () => {
    setSequenceStepsLoading(true)
    fetchMethod("GET", `${API_SEQUENCE_STEPS}/${id}`, {})
      .then((data) => {
        if (data.status == "error") {
          setErrorMsg(data.message)
          return
        }
        if (data.length === 0) {
          setSteps([]);
          setNodes([]);
          setSequenceStepsLoading(false);
          setReportsLoading(true);
          return;
        }
        setSteps(data);
        const fetchData = async () => {
          if (data.status == "error") {
            setErrorMsg(data.message);
            return;
          }
          if (data.length) {
            const firstStep = data[0];
            firstStep.order = 1;
            data.splice(0, 1);
            const sortedData = data?.sort((a, b) => +a.dependent_on?.split("_")[1] - +b.dependent_on?.split("_")[1]);
            const nodes = [[firstStep]];
            let referenceNode = firstStep;
            referenceNode.openedList = [];
            referenceNode.notOpenedList = [];
            let openedList = referenceNode.openedList;

            let notOpenedList = referenceNode.notOpenedList;
            let currentStep;
            for (let i = 0; i < sortedData.length; i++) {
              sortedData[i].order = i + 2;
              // debugger
              for (let j = 0; j < sortedData.length; j++) {
                if (sortedData[j]._id === sortedData[i].reference_id) {
                  referenceNode = sortedData[j];
                  referenceNode.openedList = referenceNode.openedList || [];
                  referenceNode.notOpenedList = referenceNode.notOpenedList || [];
                  openedList = referenceNode.openedList;
                  notOpenedList = referenceNode.notOpenedList;
                }
              }
              if (!currentStep) {
                currentStep = sortedData[i].dependent_on;
                nodes.push([])
                if (sortedData[i].step_condition === 'opened') {
                  openedList.push(sortedData[i]);
                }
                else {
                  notOpenedList.push(sortedData[i]);
                }
              }
              else if (currentStep === sortedData[i].dependent_on) {
                // debugger
                if (sortedData[i].step_condition === 'opened') {
                  openedList.push(sortedData[i]);
                }
                else {
                  notOpenedList.push(sortedData[i]);
                }
              }
              else {
                // debugger
                currentStep = sortedData[i].dependent_on;
                for (let j = 0; j < sortedData.length; j++) {
                  if (sortedData[j]._id === sortedData[i].reference_id) {
                    referenceNode = sortedData[j];
                    referenceNode.openedList = referenceNode.openedList || [];
                    referenceNode.notOpenedList = referenceNode.notOpenedList || [];
                    openedList = referenceNode.openedList;
                    notOpenedList = referenceNode.notOpenedList;
                  }
                }
                nodes.push([])
                if (sortedData[i].step_condition === 'opened') {
                  openedList.push(sortedData[i]);
                }
                else {
                  notOpenedList.push(sortedData[i]);
                }

              }
            }
            data.unshift(firstStep);
            setNodes(nodes);
          }
        };

        fetchData();
        setSequenceStepsLoading(false);
        setReportsLoading(true);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)

          return;
        }
      })
  }

  const fetchSequenceEmailsCount = () => {
    setSequenceStepsLoading(true)
    fetchMethod("GET", `${API_EMAIL_SEQUENCE_STATS}/${id}`, {})
      .then((data) => {
        if (data.status == "error") {
          setErrorMsg(data.message)
          return
        }
        setEmailDetailsValue(data)
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)

          return;
        }
      })
  }

  const fetchSequenceDetails = () => {
    setSequenceNameLoading(true);
    fetchMethod("GET", `${API_GET_SEQUENCE_NAME}/${id}`, {})
      .then((data2) => {
        if (data2.status === "success") {
          setSequenceName(data2.message);
          setSequenceDateTime(data2.start_date)
          setSequenceNameLoading(false);
          setSequenceStepsLoading(false);
          fetchSequenceStep()
        } else {
          setFound(false);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          return;
        }
      });
  }

  const handleDepenedStep = async (selectedOption) => {
    setDependentOnLoading(true);
    fetchMethod("GET", `${API_DEPENDENT_STEPS}?sequence_id=${id}&step_condition=${selectedOption}&step_type=${currentStep.step_type.value}`, {})
      .then((data2) => {
        if (data2.status === "success") {
          // Set fetched steps to state 
          const dSteps = data2.data.map((step) => ({
            value: step._id,
            label: step.step,
          }));
          setFetchedSteps(dSteps);
        }
        setDependentOnLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)

          return;
        }
        setDependentOnLoading(false);
      });
  }

  const showStepCondition = () => {
    const isNone = stepConditionsOptions.some(options => options.value === 'none')
    if (currentStep.step_type.value === "automatic_email") {
      return isNone ? false : true;
    }
    return (stepConditionsOptions.length === 1 && isNone) ? false : true;
  }

  const handleStepType = async (selectedOption) => {
    if (selectedOption && selectedOption.value === currentStep.step_type.value) {
      return
    }
    setDependentOnLoading(true);
    setCurrentStep({
      ...currentStep,
      step_type: selectedOption,
      step_condition: null,
      dependent_on: null
    });
    // Fetch the step conditions based on the selected step_type
    fetchMethod("GET", `${API_DEPENDENT_STEPS}?sequence_id=${id}&field_name=step_condition&step_type=${selectedOption.value}`, {})
      .then((data2) => {
        if (data2.status === "success") {
          const options = data2.data.map((item) => ({
            value: item.value,
            label: item.name,
          }));
          setStepConditionsOptions(options);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true);
          return;
        }
      })
      .finally(() => {
        setDependentOnLoading(false);
      })
  };

  const getTemplateData = () => {
    setAddLoading(true);
    fetchMethod("GET", `${API_TEMPLATE}`, {})
      .then((data) => {
        console.log("data option", data)
        const templateOptions = data.map((template) => ({
          value: template._id,
          label: template.template_name,
          tempBody: template.template_body,
          file_link: template.file_links
        }));
        setTemplates(templateOptions);
        settemplateLoader(templateOptions.length === 0);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)

          return;
        }
      });

  };

  const handleClose = () => {
    setCurrentStep({
      step_type: "",
      step_condition: { value: "opened", label: "Opened" },
      delay_type: { value: "days", label: "days" },
      delay_duration: "",
      task_priority: "",
      due_date: moment(new Date(), "YYYY-MM-DD").format("D/M/YYYY"),
      note: "",
      template: null,
      dependent_on: null,
    });
    setFormErrors({
      step_type: "",
      step_condition: "",
      delay_duration: "",
      delay_type: "",
      template: "",
      task_priority: "",
    });
    setVisible(false);

  };

  const shouldShowOpenedOption = () => {
    return steps.some(step => step.step_type === "automatic_email");
  };

  const accept = (id) => {
    handleDelete(id);
  };

  const reject = (id) => { };

  const confirmdelete = (id) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => accept(id),
      reject: () => reject(id),
      draggable: false
    });
  };

  const handleSubmit = async () => {
    let isValid = true;
    let errors = {
      step_type: "",
      step_condition: "",
      delay_duration: "",
      delay_type: "",
      template: "",
      task_priority: "",
    };

    // Check if all required fields are filled
    if (currentStep.step_type === "") {
      errors.step_type = "Step Type is required";
      isValid = false;
    } else {
      if (currentStep.step_type.value === "automatic_email") {
        if (currentStep.template == null) {
          errors.template = "Template is required";
          isValid = false;
        }
      } else {
        if (currentStep.task_priority === "") {
          errors.task_priority = "Task Priority is required";
          isValid = false;
        }
      }
    }
    if (!currentStep.step_condition) {
      errors.step_condition = "Step Condition is required";
      isValid = false;
    }
    else if (currentStep.step_condition.value !== "none" && steps.length > 0) {
      if (!currentStep.dependent_on) {
        errors.dependent_on = "Dependent On is required";
        isValid = false;
      }
    }

    if (currentStep.delay_duration === "") {
      errors.delay_duration = "Delay Duration is required";
      isValid = false;
    }

    setFormErrors(errors);
    if (isValid) {
      setSequenceStepsLoading(true)
      const url = `${API_SEQUENCE_STEPS}/${id}`;
      const method = currentStep._id ? "PUT" : "POST";
      fetchMethod(method, `${url}`, {
        sequence_id: id,
        step_type: currentStep.step_type.value,
        delay_type: currentStep.delay_type.value,
        delay_duration: currentStep.delay_duration,
        task_priority: currentStep.task_priority,
        due_date: currentStep.due_date,
        note: currentStep.note,
        template: currentStep.template ? currentStep.template.value : null,
        step_condition: steps.length > 0 ? currentStep.step_condition?.value : 'none',
        dependent_on: currentStep.dependent_on?.value,
      })
        .then((data) => {
          if (data.status === "success") {
            const newStep = {
              _id: data.step_id,
              step_type: currentStep.step_type.value,
              delay_type: currentStep.delay_type.value,
              delay_duration: currentStep.delay_duration,
              task_priority: currentStep.task_priority,
              due_date: currentStep.due_date,
              note: currentStep.note,
              template: currentStep.template ? currentStep.template.tempBody : null,
              template_name: currentStep.template ? currentStep.template.label : null,
              step_condition: currentStep.step_condition.value,
              dependent_on: currentStep.dependent_on ? currentStep.dependent_on.value : null,
              timestamp: new Date(),
              scheduled: 0,
              sent: 0,
              opened: 0,
              opt_out: 0,
              task_added: 0
            };
            setSteps([...steps, newStep])
            handleClose()
            setActiveTabIndex(0)
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Error",
              detail: data.message,
            });
          }
          setSequenceStepsLoading(false)
          setVisible(false)
          fetchSequenceStep()
        })
        .catch((error) => {
          console.error(error);
          if (error instanceof TypeError) {
            setnetworkError(true)
            setSequenceStepsLoading(false)
            return;
          }
        });
    }
  };

  const handleDelete = (id) => {
    setSequenceStepsLoading(true);
    fetchMethod("DELETE", `${API_SEQUENCE_STEPS}/${id}`, {})
      .then(() => {
        setSteps(steps.filter((step) => step._id !== id));
        setSequenceStepsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)

          return;
        }
      });
  };

  const handleTabChange = (index) => {
    setTabLoading(true);
    setActiveTabIndex(index);
    setCurrentFilter('');
    setGlobalFilter('');
    setTimeout(() => {
      setTabLoading(false);
    }, 1000);
  };

  const handleLinkMailbox = () => {
    // handleLinkMailbox
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // replace with your client id
    const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI; // replace with your redirect uri
    const scope = encodeURIComponent(
      "https://www.googleapis.com/auth/gmail.send " +
      "https://www.googleapis.com/auth/userinfo.email"
    );
    const responseType = "code";
    const accessType = "offline";
    const prompt = "consent";

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&access_type=${accessType}&prompt=${prompt}`;

    // Redirect user to Google's OAuth 2.0 server
    window.location.href = authUrl;
  };

  const handleLinkOutlookMailbox = () => {
    const clientId = process.env.REACT_APP_OUTLOOK_CLIENT_ID; // replace with your client id
    const redirectUri = process.env.REACT_APP_OUTLOOK_REDIRECT_URI; // replace with your redirect uri
    const scope = encodeURIComponent(
      "https://graph.microsoft.com/Mail.Send " +
      "https://graph.microsoft.com/User.Read offline_access https://graph.microsoft.com/Mail.Read"
    );
    const responseType = "code";
    const responseMode = "query";
    const prompt = "consent";

    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&response_mode=${responseMode}&prompt=${prompt}`;

    // Redirect user to Microsoft's OAuth 2.0 server
    window.location.href = authUrl;
  };

  const handleLinkZohoMailbox = () => {
    const base_url = "https://accounts.zoho.com/oauth/v2/auth";
    const client_id = process.env.REACT_APP_ZOHO_CLIENT_ID; // Replace with your client ID
    const redirect_uri = encodeURIComponent(process.env.REACT_APP_ZOHO_REDIRECT_URI);
    const scope = encodeURIComponent("ZohoMail.messages.ALL");
    const state = "SalesAutomation"; // Replace with your unique state token

    const authUrl = `${base_url}?response_type=code&client_id=${client_id}&scope=${scope}&redirect_uri=${redirect_uri}&access_type=offline&state=${state}`;

    window.location.href = authUrl;
  };

  const handleTemplateBody = () => {
    setshowPreviewModal(true)
  }

  const handleActiveTab = (index, step, filterKey) => {
    let step_name = step.step_name
    step_name = step_name.startsWith('step_') ? step_name.replace('step_', 'Step ') : step_name
    setCurrentFilter(filterKey)
    setGlobalFilter(step_name)
    setActiveTabIndex(index)
  }

  const Divider = () => {
    return (<div style={{ height: "50px", width: "2px", backgroundColor: "lightgray", marginLeft: "auto", marginRight: "auto" }} class></div>)
  }


  const Line = () => {
    return (<div style={{ backgroundColor: "lightgray", height: "2px", width: "51.5%", marginLeft: "auto", marginRight: "auto" }}></div>)
  }

  let viewTemplateBody
  let tempBodyFile = []
  if (currentStep.template != null) {
    console.log("current step ", currentStep.template.file_link)
    viewTemplateBody = currentStep.template.tempBody
    tempBodyFile = currentStep.template.file_link || [];
  }

  const Breadcrumb = ({ items }) => {
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {items.map((item, index) => (
            <li key={index} className="breadcrumb-item">
              {item.url ? (
                <Link to={item.url}>{item.label}</Link>
              ) : (
                item.label
              )}
            </li>
          ))}
        </ol>
      </nav>
    );
  };


  return (
    <div>
      <div className="content-page">
        <div className="content">
          <Container fluid>
            {errorMsg ? (
              // <p style={{marginTop: '50px'}}>{errorMsg}</p>
              <div className="NoDefaultMailboxFound">
                <h4>Link your mailbox to get started</h4>
                <p>Setup your mailbox to scale your outbond and do more with sequence,conversations, meeting and other features. </p>
                <button onClick={() => setVisible(true)}><FeatherIcon icon="link-2" size={20} /> Link Mailbox</button>

                <Dialog
                  visible={visible}
                  onHide={() => setVisible(false)}
                  style={{ width: "30vw" }}
                  breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                  draggable={false}
                  resizable={false}
                >
                  <h4>Link your mailbox to get started</h4>
                  <p>
                    By connecting your mailbox, you'll be able to capture all your email
                    engagement in one spot. Based on your plan, you can send emails via
                    using Gmail, Microsoft Exchange, Office 365, Outlook, or your own
                    custom server. Learn more.
                  </p>
                  <Button
                    label="Link Gmail Mailbox"
                    size="small"
                    outlined
                    icon="pi pi-google"
                    className="me-4"
                    onClick={handleLinkMailbox}
                  />
                  <Button
                    label="Link Outlook Mailbox"
                    size="small"
                    outlined
                    className="me-4"
                    icon="pi pi-microsoft"
                    onClick={handleLinkOutlookMailbox}
                  />
                  <Button
                    label="Link Zoho Mailbox"
                    size="small"
                    outlined
                    className="mt-2"
                    icon="pi pi-microsoft"
                    onClick={handleLinkZohoMailbox}
                  />
                </Dialog>

              </div>

            ) : (<>{!found ? (
              <>
                <p>Sequence doesnot exist</p>
              </>
            ) : (
              <>
                <Toast ref={toast} />
                <Row>
                  <Col>
                    <div className="page-title-box">
                      <h4 className="page-title">
                        {/* {sequenceNameLoading ? (
                          <Skeleton width="200px" height="20px" />
                        ) : (
                          "Sequences / " + sequenceName
                        )} */}
                        <Breadcrumb
                          items={[
                            { label: 'Sequences', url: '/sequences' },
                            sequenceNameLoading
                              ? { label: <Skeleton width="200px" height="20px" />, url: null }
                              : { label: `${sequenceName}`, url: '#' } // Current page, no link
                          ]}
                        />
                      </h4>
                      {(!initialDueDate)? (<>
                      <Skeleton width="100px" height="40px" />
                      </>) :  (
                        <div className="page-title-right">
                        <div className="row g-2">
                          <div className="col-md-auto">
                            <Button
                              label="Add Step"
                              icon="pi pi-plus"
                              size="small"
                              onClick={handleAddStep}

                            />

                          </div>
                        </div>
                      </div>
                      )}
                    </div>
                  </Col>
                </Row>

                {/*tab section */}
                <TabView
                  className="mb-3"
                  activeIndex={activeTabIndex} // Set the active tab index
                  onTabChange={(e) => handleTabChange(e.index)} // Handle tab change
                >
                  <TabPanel
                    header="Overview"
                    leftIcon="pi pi-th-large me-2"
                    className="sequences_list"
                  >
                    <div className="chanig-tab-btn">
                      <div className="flex gap-2 mb-3">
                        <Button
                          onClick={() => {
                            setActiveIndex(0);
                            handleButtonClick("A");
                          }}
                          className=" me-2"
                          style={{
                            backgroundColor:
                              activeButton === "A" ? "#6366F1" : "#fff",
                            color: activeButton === "A" ? "white" : "#6366F1",
                            cursor: "pointer",
                            padding: "5px",
                            width: "37px",
                          }}
                          icon="pi pi-list"
                        />
                        {/* <Button
                          onClick={() => {
                            setActiveIndex(1);
                            handleButtonClick("B");
                          }}
                          style={{
                            backgroundColor:
                              activeButton === "B" ? "#6366F1" : "#fff",
                            color: activeButton === "B" ? "white" : "#6366F1",
                            cursor: "pointer",
                            padding: "5px",
                            width: "37px",
                          }}
                          className=""
                          icon="pi pi-th-large"
                        /> */}
                      </div>
                    </div>
                    {activeIndex === 0 ? (
                      <>
                        {sequenceStepsloading ? (
                          <>
                            <Skeleton
                              width="100%"
                              height="100px"
                              className="mb-3"
                            />
                            <Skeleton
                              width="100%"
                              height="100px"
                              className="mb-3"
                            />
                            <Skeleton
                              width="100%"
                              height="100px"
                              className="mb-3"
                            />
                          </>
                        ) : (
                          <ListView steps={steps} emailDetailsValue={emailDetailsValue} handleActiveTab={handleActiveTab} />
                        )}
                      </>
                    ) : (
                      <>
                        {sequenceStepsloading ? (
                          <>
                            <div className="main-branch" style={{ marginLeft: "41%" }}>
                              <Skeleton
                                width="300px"
                                height="165px"
                              />
                            </div>
                            <div className="d-flex flex-column" style={{ width: "43%", marginLeft: "28.5%" }}>
                              <Divider />
                              <Line />
                            </div>
                            <div className="tree">
                              <div className="branch-container">
                                <div className="branch left-branch d-flex flex-column">
                                  <div>
                                    <Divider />
                                  </div>
                                  <Skeleton
                                    width="300px"
                                    height="165px"
                                    className="justify-content-center"
                                  />
                                </div>
                                <div className="branch right-branch d-flex flex-column">
                                  <div>
                                    <Divider />
                                  </div>
                                  <Skeleton
                                    width="300px"
                                    height="165px"
                                    className="justify-content-center"
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <GridView Steps={steps} EmailDetails={emailDetailsValue} />
                        )}
                      </>
                    )}
                  </TabPanel>
                  <TabPanel header="Contacts" leftIcon="pi pi-users me-2">
                    <Contacts />
                  </TabPanel>
                  <TabPanel header="Emails" leftIcon="pi pi-envelope me-2">
                    <Emails onSearchStep={currentFilter} onGlobalSearchStep={globalFilter} />
                  </TabPanel>
                </TabView>
                <Dialog
                  header="Add Sequence Steps"
                  visible={visible}
                  style={{ width: "50vw" }}
                  onHide={handleClose}
                  draggable={false}
                  resizable={false}
                  className={currentStep.step_type.label === 'Automatic Email' || currentStep.step_type.label === undefined ? 'automaticEmailClass' : 'addSequenceSteps'}
                >
                  <Form>
                    <Row className="">
                      <Col xs={6}>
                        <Form.Group controlId="formStepType" className="mb-3">
                          <Form.Label>
                            Step Type <span className="text-danger">*</span>
                          </Form.Label>
                          <Select
                            value={currentStep.step_type}
                            options={stepTypesOptions}
                            onChange={(selectedOption) => { handleStepType(selectedOption) }}
                            isSearchable={false}
                          />
                          {formErrors.step_type && (
                            <Form.Text className="text-danger">
                              {formErrors.step_type}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        {currentStep.step_type.value === "automatic_email" && (
                          <Form.Group controlId="formTemplate" className="mb-3">
                            <Form.Label>
                              Template <span className="text-danger">*</span>
                            </Form.Label>
                            {templateLoader ? <Skeleton height="40px"></Skeleton> :
                              <Select
                                value={currentStep.template}
                                options={templates}
                                onChange={(selectedOption) =>
                                  setCurrentStep({
                                    ...currentStep,
                                    template: selectedOption,
                                  })
                                }
                              />
                            }
                            {formErrors.template && (
                              <Form.Text className="text-danger">
                                {formErrors.template}
                              </Form.Text>
                            )}
                          </Form.Group>
                        )}
                      </Col>
                      <Col xs={6}>
                        <Form.Group
                          controlId="formDelayDuration"
                          className="mb-3"
                        >
                          <Form.Label>
                            Delay Duration{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            min={1}
                            placeholder="Enter Duration"
                            value={currentStep.delay_duration}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              // Check if the new input value is less than or equal to 3 digits
                              if (newValue.length <= 3) {
                                setCurrentStep({
                                  ...currentStep,
                                  delay_duration: newValue
                                });
                              }
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "-" || e.key === "e" || e.key === "+") {
                                e.preventDefault();
                              }
                            }}
                          />
                          {formErrors.delay_duration && (
                            <Form.Text className="text-danger">
                              {formErrors.delay_duration}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group controlId="formDelayType" className="mb-3">
                          <Form.Label>
                            Delay Type <span className="text-danger">*</span>
                          </Form.Label>
                          <Select
                            value={currentStep.delay_type}
                            options={delayOptions}
                            onChange={(selectedOption) =>
                              setCurrentStep({
                                ...currentStep,
                                delay_type: selectedOption,
                              })
                            }
                            isSearchable={false}
                          />
                        </Form.Group>
                      </Col>

                      {steps && currentStep.step_type && showStepCondition() && (
                        <Col xs={6}>
                          <Form.Group controlId="formStepCondition" className="mb-3">
                            <Form.Label>
                              Step Condition <span className="text-danger">*</span>
                            </Form.Label>
                            <Select
                              value={currentStep.step_condition}
                              options={stepConditionsOptions.length > 0 ? stepConditionsOptions : []}
                              onChange={(selectedOption) => {
                                setCurrentStep({
                                  ...currentStep,
                                  step_condition: selectedOption,
                                  dependent_on: null
                                });
                                handleDepenedStep(selectedOption.value);
                              }}
                            />
                            {formErrors.step_condition && (
                              <Form.Text className="text-danger">
                                {formErrors.step_condition}
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                      )}

                      {steps.length > 0 && currentStep.step_condition && (currentStep.step_condition.value === "opened" ||
                        currentStep.step_condition.value === "not_opened") && (
                          <Col xs={6}>
                            <Form.Group controlId="formDependentOn" className="mb-3">
                              <Form.Label>
                                Dependent On
                                <span className="text-danger">*</span>
                              </Form.Label>
                              {dependentOnLoading ? (
                                <div className="text-center">
                                  <Skeleton width="100%" height="40px" />
                                </div>
                              ) : (
                                <Select
                                  value={currentStep.dependent_on} // Default value here
                                  options={fetchedSteps}
                                  onChange={(selectedOption) =>
                                    setCurrentStep({
                                      ...currentStep,
                                      dependent_on: selectedOption,
                                    })
                                  }
                                />
                              )}
                              {formErrors.dependent_on && (
                                <Form.Text className="text-danger">
                                  {formErrors.dependent_on}
                                </Form.Text>
                              )}
                            </Form.Group>
                          </Col>
                        )}

                      {(currentStep.step_type.value === "manual_email" ||
                        currentStep.step_type.value === "phone_call" ||
                        currentStep.step_type.value === "action_item") && (
                          <>
                            <Col xs={9}>
                              <Form.Group className="mb-3">
                                <Form.Label>
                                  Due Date <span className="text-danger">*</span>
                                  <DatePicker
                                    // className="form-control h-4px"
                                    selected={moment.tz(currentStep.due_date, tzMapper[timezone]).toDate()}
                                    onChange={date => handleDateSelection(moment.tz(date, tzMapper[timezone]).format("DD-MM-YYYY"))}
                                    minDate={new Date(moment().tz(tzMapper[timezone])?.startOf('day').format())}
                                    dateFormat="dd-MM-yyyy"
                                    customInput={<CustomInput />}
                                  />
                                </Form.Label>

                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group
                                controlId="formTaskPriority"
                                className="mb-3"
                              >
                                <Form.Label htmlFor="formTaskPriority">
                                  Task Priority{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                {[
                                  "High Priority",
                                  "Medium Priority",
                                  "Low Priority",
                                ].map((priority) => (
                                  <div key={priority}>
                                    <Form.Check
                                      type="radio"
                                      id={`priority-${priority.toLowerCase()}`} // Corrected input id
                                      label={priority}
                                      value={priority}
                                      checked={
                                        currentStep.task_priority === priority
                                      }
                                      onChange={() =>
                                        setCurrentStep({
                                          ...currentStep,
                                          task_priority: priority,
                                        })
                                      }
                                    />
                                  </div>
                                ))}
                                {formErrors.task_priority && (
                                  <Form.Text className="text-danger">
                                    {formErrors.task_priority}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </Col>
                            <Col xs={12}>
                              <Form.Group controlId="formNote" className="mb-3">
                                <Form.Label>Note</Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  value={currentStep.note}
                                  onChange={(e) =>
                                    setCurrentStep({
                                      ...currentStep,
                                      note: e.target.value,
                                    })
                                  }
                                  maxLength={500}
                                />
                              </Form.Group>
                            </Col>
                          </>
                        )}
                    </Row>
                  </Form>
                  <div className="d-flex justify-content-end mt-3">
                    {
                      currentStep.template != null && currentStep.step_type && currentStep.step_type.value === "automatic_email" &&
                      <Button onClick={handleTemplateBody} outlined size="small">
                        Preview
                      </Button>
                    }
                    <Button onClick={handleClose} outlined size="small" className="ms-2">
                      Close
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      loading={sequenceStepsloading}
                      icon="pi pi-check"
                      label="Save"
                      size="small"
                      className="ms-2"
                    />
                  </div>
                  <Dialog
                    className="addTemplate"
                    style={{ width: "40vw" }}
                    breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                    header={"Template Body"}
                    visible={showPreviewModal}
                    draggable={false}
                    resizable={false}
                    onHide={() => setshowPreviewModal(false)}
                  >
                    <Form.Label dangerouslySetInnerHTML={{ __html: viewTemplateBody }}></Form.Label>
                    {tempBodyFile.map((item, index) => (
                      <div key={index}>
                        <a
                          href={item?.Location} // The actual link
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'none', color: 'inherit' }} // Optional styling to make it look like a label
                        >
                          <Form.Label
                          className="cursor-pointer"
                            data-pr-tooltip={item?.Location} // Show only the link as plain text
                            data-pr-position="top" // Position of the tooltip
                            id={`label-${index}`} // Unique id for each label
                          >
                            {item?.Key}
                          </Form.Label>
                        </a>
                        <Tooltip target={`#label-${index}`} />
                      </div>
                    ))}

                  </Dialog>
                </Dialog>
              </>
            )}</>)}

          </Container>
        </div>
      </div>
    </div>
  );
};

export default SequenceDetails;