import moment from "moment-timezone";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { contextVar } from "../Components/ContextVar";
import ApiListFile from "../Components/ApiListFile";
import { fetchMethod } from "../Components/ApiHeader";

import tzMapper from "../utils/tz_mapper.json";
import "react-datepicker/dist/react-datepicker.css";
import "./SequenceDetails.css";
import "./Sequence.css";

const ListView = ({ steps, emailDetailsValue, handleActiveTab }) => {
  const {
    API_POST_USER_TIMEZONES,
  } = ApiListFile()

  const { setnetworkError } = useContext(contextVar)
  const [currentFilter, setCurrentFilter] = useState("all");
  const toast = useRef(null);
  const [timezone, setTimezone] = useState("");
  const [initialDueDate, setInitialDueDate] = useState(null);
  const [currentStep, setCurrentStep] = useState({
    step_type: "",
    step_condition: { value: "opened", label: "Opened" },
    delay_type: { value: "days", label: "days" },
    delay_duration: "",
    task_priority: "Low Priority",
    due_date: initialDueDate,
    note: "",
    template: null,
    step_value: steps[0] ? 1 : "",
    dependent_on: null,
  });
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [checked, setChecked] = useState(false);



  useEffect(() => {
    setCurrentFilter('all')
  }, [activeTabIndex]);

  useEffect(() => {
    fetchMethod("GET", `${API_POST_USER_TIMEZONES}`, {})
      .then((data2) => {
        if (data2.item != null) {
          setTimezone(data2.item.timezone);
        } else {
          setTimezone(undefined);
        }
      })
      .catch((error) => {
        console.error(error);
        setTimezone(undefined);
        if (error instanceof TypeError) {
          setnetworkError(true)

          return;
        }
      });

  }, []);

  useEffect(() => {
    if (timezone) {
      // This is the moment object for the current date in the selected timezone
      const tzAwareDate = moment.tz(tzMapper[timezone]).startOf('day');

      // Convert this date so that it reflects the correct day in the browser's local timezone
      const adjustedDate = convertToBrowserLocalTimezone(tzAwareDate, tzMapper[timezone]);
      setInitialDueDate(adjustedDate);
    }
  }, [timezone]);


  const convertToBrowserLocalTimezone = (selectedTimezoneDate, selectedTimezone) => {
    // Convert the timezone-aware date to the equivalent local date in the browser's timezone
    const localEquivalentDate = moment.tz(selectedTimezoneDate.format('YYYY-MM-DD'), selectedTimezone).clone().tz(moment.tz.guess());
    return localEquivalentDate.format('YYYY-MM-DD'); // Ensure the return is in 'YYYY-MM-DD' format for consistency
  };

  const handleSearchStep = (stepType, stepNum, step) => {
    handleActiveTab(2, step, stepType)
    if (stepType === 'opened') setCurrentFilter({Status: 'opened',Step: stepNum})
    else if (stepType === 'sent')  setCurrentFilter({Status: 'sent',Step: stepNum})
    else if (stepType === 'opt_out')  setCurrentFilter({Status: 'opt_out',Step: stepNum})
  };


  return (
    <div>
      {!currentFilter ?
        <Skeleton width="100%" height="60px" className="mb-3" />
        :
        (<div className="top__statistics d-flex justify-content-between">
          <div className="statistic_left col-6">
            <ul>
              <li>
                {/* {emailDetailsValue.bounced} */}
                {emailDetailsValue && emailDetailsValue.bounced ? emailDetailsValue.bounced : '0'}
                <span className="text-danger">Bounced</span>
              </li>
              <li>
                {/* {emailDetailsValue.replied} */}
                {emailDetailsValue && emailDetailsValue.replied ? emailDetailsValue.replied : '0'}
                <span className="text-success">Replied</span>
              </li>
            </ul>
          </div>

          <div className="statistic_right text-end col-6">
            <ul>
              <li>
                {/* {emailDetailsValue.scheduled - emailDetailsValue.sent - emailDetailsValue.task_added < 0 ? 0 : emailDetailsValue.scheduled - emailDetailsValue.sent - emailDetailsValue.task_added} */}
                {emailDetailsValue
                  ? Math.max(
                    0,
                    (emailDetailsValue.scheduled || 0) -
                    (emailDetailsValue.sent || 0) -
                    (emailDetailsValue.task_added || 0)
                  )
                  : '0'}
                <span>Scheduled</span>
              </li>
              <li>
                {emailDetailsValue && emailDetailsValue.task_added ? emailDetailsValue.task_added : '0'}
                <span>Tasks Added</span>
              </li>
              <li>
                {emailDetailsValue && emailDetailsValue.sent ? emailDetailsValue.sent : '0'}
                <span>Delivered</span>

              </li>
              <li>
                {emailDetailsValue && emailDetailsValue.opened ? emailDetailsValue.opened : '0'}
                <span>Opened</span>

              </li>
              <li>
                {emailDetailsValue && emailDetailsValue.opt_out ? emailDetailsValue.opt_out : '0'}
                <span>Unsubscribed</span>
              </li>
            </ul>
          </div>
        </div>)
      }
      <div className="py-3">
        <>
          {steps &&
            steps.map((step, key) => {
              // debugger
              let content = "";

              if (step.step_type === "automatic_email") {
                content = step.template.replace(/\n\s*\n/g, "").trim();


                // Check if the content contains a base64 encoded image
                // const imageMatch = content.match(/src="(data:image\/[^"]+)"/);


                // if (imageMatch) {
                //   const imageData = imageMatch[1];
                //   content = `<img src="${imageData}" alt="Email content" style="max-width: 100%;" />`;
                // }
              } else {
                content = step.note
              }
              return (
                <div key={key}>
                  <Panel
                    className="mb-3"

                    header={
                      <div className="panel-header ">
                        <div className="panel-header-left d-flex align-items-center">
                          <span>{key + 1}</span>
                          <i className="pi pi-envelope"></i>
                          <h4>
                            {step.step_type ==
                              "automatic_email"
                              ? "Automatic Email"
                              : step.step_type ==
                                "manual_email"
                                ? "Manual Email"
                                : step.step_type ==
                                  "phone_call"
                                  ? "Phone Call"
                                  : step.step_type ==
                                    "action_item" ?
                                    "Action Item"
                                    : null}
                          </h4>{" "}
                          {step.step_type ==
                            "automatic_email" ? (
                            <span
                              style={{
                                fontSize: 12,
                                fontWeight: 300,
                                marginLeft: 10,
                              }}
                            >
                              {" "}
                              Template: {step.template_name}
                            </span>
                          ) : (
                            ""
                          )}
                          <div className="statistic_left">
                            {step.step_type ==
                              "automatic_email" ? (
                              <ul>
                                {/* <li>
                                                  {step.bounced} <span>Bounced</span>
                                                </li> */}
                                {/* <li>
                                    {step.scheduled - step.sent} <span>Not sent</span>
                                    </li> */}
                              </ul>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="panel-header-right">
                          <span className="deliver_time">
                            Created on : (  {moment.tz(step.timestamp, "UTC").tz(tzMapper[timezone] || "UTC").format('D/M/YYYY h:mm A')} )
                          </span>
                          <span className="deliver_time" style={{ paddingLeft: '15px' }}>
                            {step.step_type ==
                              "automatic_email"
                              ? "Deliver email"
                              : "Add Task"}{" "}
                            in {step.delay_duration}{" "}
                            {step.delay_type}{" "}
                            {step.step_condition == "opened" && steps.length > 1
                              ? "if opened"
                              : step.step_condition ==
                                "not_opened"
                                ? "if not opened"
                                : ""}
                            {step.step_condition != "none" && steps.length > 0 && step.dependent_on != null && " depends on Step " + step.dependent_on_name.split('_')[1]}
                          </span>
                          {/* <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} className="small-switch"/> */}

                        </div>
                      </div>
                    }
                  >
                    <Row style={{
                      maxHeight: "120px",
                      overflow: "hidden",
                    }}>
                      {step.step_type ==
                        "automatic_email" ? (
                        <Col
                          md={8}
                          style={{ overflow: "hidden" }}
                          dangerouslySetInnerHTML={{
                            __html: content,
                          }}
                        />
                      ) : (
                        <Col md={8}>{step.note}</Col>
                      )}
                      <Col md={4}>
                        <div className="statistic_right">
                          {step.step_type ==
                            "automatic_email" ? (
                            <ul className="text-right">
                              <li>
                                {step && (typeof step.scheduled) === "number" && (typeof step.sent) === "number"
                                  && Math.max(step.scheduled, 0)
                                }
                                <span>Scheduled</span>
                              </li>
                              <li class="email-redirect" style={{ cursor: "pointer" }} onClick={() => handleSearchStep('sent', "step" + " " + (key + 1), step)}>
                                {step.sent + step.opened}{" "}
                                <span>Delivered</span>
                              </li>
                              <li class="email-redirect" style={{ cursor: "pointer" }} onClick={() => handleSearchStep('opened', "step" + " " + (key + 1), step)}>
                                {step.opened}{" "}
                                <span>Opened</span>
                              </li>
                              <li class="email-redirect" style={{ cursor: "pointer" }} onClick={() => handleSearchStep('opt_out', "step" + " " + (key + 1), step)}>
                                {step.opt_out}{" "}
                                <span>Unsubscribed</span>
                              </li>
                            </ul>
                          ) : (
                            <ul>
                              <li>
                                {Math.max(
                                  (step.task_scheduled || 0),
                                  0
                                )}{" "}
                                <span>Scheduled</span>
                              </li>
                              <li>
                                {step.task_added}{" "}
                                <span>Tasks added</span>
                              </li>
                            </ul>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Panel>
                </div>
              );
            }
            )}
        </>
      </div>
    </div>
  );
};

export default ListView;
