import React from "react";
import "react-quill/dist/quill.snow.css"; // import the styles
import "./Template.scss"
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import DummyTemp1 from "../assets/images/Dummy-template-1.png"
import DummyTemp2 from "../assets/images/Dummy-template-2.png"
import DummyTemp3 from "../assets/images/Dummy-template-3.png"
import Footer from "../Components/Footer/Footer";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const PreFormatTemplate = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(`/templates`)
  }

  return (
    <div>
      <div className="content-page">
        <div className="content">
          <Container fluid>
          <div className='me-4 mt-3 mb-2 cursor-pointer gobackArrow ' onClick={goBack} ><i className="pi pi-arrow-left " /></div>
            <div className="Dummy-template-img row">
              <div className="col-md-4">
                <img src={DummyTemp1} onClick={() => navigate(`/add-template/template1/0`)} />
              </div>
              <div className="col-md-4">
                <img src={DummyTemp2} onClick={() => navigate(`/add-template/template2/0`)} />
              </div>
              <div className="col-md-4">
                <img src={DummyTemp3} onClick={() => navigate(`/add-template/template3/0`)} />
              </div>
            </div>
          </Container>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PreFormatTemplate;