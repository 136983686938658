import React from 'react';

function CancelPage() {
  return (
    <div>
      <h1>Payment Canceled</h1>
      <p>You have canceled the payment process. If this was a mistake, you can return to our pricing page and try again.</p>
    </div>
  );
}

export default CancelPage;
