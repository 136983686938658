// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-country-wise .p-card .p-card-body {
    padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/Common/Chart/ReportSummary/LocationChart.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd","sourcesContent":[".data-country-wise .p-card .p-card-body {\n    padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
