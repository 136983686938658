import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const SelectSequenceChart = ({ sequenceValues }) => {
  const currentYear = new Date().getFullYear();
  // Initial state is defined here, potentially with empty data arrays or default values
  const [chartState, setChartState] = useState({
    series: [
      { name: 'Opened', data: [] },
      { name: 'Scheduled', data: [] },
      { name: 'Unsubscribed', data: [] },
      { name: 'Bounced', data: [] },
      { name: 'Sent', data: [] },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false 
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: [currentYear], 
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetX: 40
      },
      yaxis: {
        labels: {
          show: false
        }
      },
    },
  });

  // Effect hook to update chart data based on sequenceValues prop
  useEffect(() => {
    if (sequenceValues) { // Check if sequenceValues is not undefined      
      setChartState(prevState => ({
        ...prevState,
        series: [
          { name: 'Opened', data: [sequenceValues.opened] },
          { name: 'Scheduled', data: [sequenceValues.scheduled] },
          { name: 'Unsubscribed', data: [sequenceValues.opt_out] },
          { name: 'Bounced', data: [sequenceValues.bounced] },
          { name: 'Sent', data: [sequenceValues.sent] },
        ]
      }));
    }
  }, [sequenceValues]); // This effect depends on sequenceValues

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="bar"
          height={200}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default SelectSequenceChart;
