// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dasboard-view-reports {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashboard-contacts-card {
    text-align: center;
}
.dashboard-contacts-card h4 {
    color: #3b82f6;
}
.mailing-list {
    text-align: center;
    margin: 16px 0 8px;
}`, "",{"version":3,"sources":["webpack://./src/dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".dasboard-view-reports {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n.dashboard-contacts-card {\n    text-align: center;\n}\n.dashboard-contacts-card h4 {\n    color: #3b82f6;\n}\n.mailing-list {\n    text-align: center;\n    margin: 16px 0 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
