import React from "react";
const Footer = () => {
  return (
    <footer className="footer">
      <div class="mx-auto w-full px-4 md:px-4 2xl:px-4">
        <div class="text-center py-1 mx-0">
            © 2024 Dietary Business Intelligence LLC — All Rights Reserved.
        </div>
        </div>
    </footer>
  );
};
export default Footer;
