import { TabPanel, TabView } from 'primereact/tabview'
import React, { useContext, useEffect, useState } from 'react'
import "./SequenceReport.css"
import { Col, Row } from 'react-bootstrap'
import { Panel } from 'primereact/panel'
import RealTimeCampaignChart from '../Common/Chart/ReportSummary/RealTimeCampaignChart'
import OpenByTimeChart from '../Common/Chart/ReportSummary/OpenByTimeChart'
import SubjectSenderDetail from '../Common/Chart/ReportSummary/SubjectSenderDetail'
import LocationChart from '../Common/Chart/ReportSummary/LocationChart'
import "./SequenceReport.css"
import RecipientActivities from './RecipientActivities/RecipientActivities'
import BounceAutoReplay from './BounceAutoReplay/BounceAutoReplay'
import SequenceReachChart from '../Common/Chart/ReportSummary/SequenceReachChart'
import { Button } from 'primereact/button'
import { useParams } from 'react-router-dom'
import ApiHeader, { fetchMethod } from '../Components/ApiHeader'
import ApiListFile from "../Components/ApiListFile";
import { Skeleton } from 'primereact/skeleton'
import { contextVar } from '../Components/ContextVar'

const SequenceReport = () => {
  const [emailOpenedTimeChartValues, setEmailOpenedTimeChartValues] = React.useState();
  const [totalContactValues, setTotalContactValues] = React.useState();
  const [sequenceValues, setSequenceValues] = useState();
  const [isLoading, setisLoading] = useState(true);
  const { sequenceId, selectedSequenceName } = useParams();

  const {
    API_EMAIL_SEQUENCE_STATS,
    API_DASHBOARD_EMAIL_OPENED_BY_TIME_CHART,
    API_DASHBOARD_TOTAL_CONTACTS_PER_SEQUENCE,
    API_GET_SEQUENCE_NAME,
  } = ApiListFile()

  const { setnetworkError, userGroup } = useContext(contextVar)

  const goBack = () => {
    const backUrl = sequenceId;
    const backUrlName = selectedSequenceName;
    sessionStorage.setItem('backUrl', backUrl);
    sessionStorage.setItem('backUrlName', backUrlName);
    window.history.back();
  };



  useEffect(() => {
    getSequencewiseEmailDetails();
    totalContact();
    emailOpenedTimeChart();
  }, [sequenceId]);


  const getSequencewiseEmailDetails = async () => {
    setisLoading(true);
   
    try {

      const data = await fetchMethod("GET", `${API_EMAIL_SEQUENCE_STATS}/${sequenceId}`)
      setSequenceValues(data);
      setisLoading(false);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      if (error instanceof TypeError) {
        console.log("Network error occurred");
        setnetworkError(true)
        setisLoading(false);
        return;
      }
    }
  }


  const emailOpenedTimeChart = async () => {
    const requestData = {
      sequence_id: sequenceId
    };
    try {
      const data = await fetchMethod("POST", `${API_DASHBOARD_EMAIL_OPENED_BY_TIME_CHART}`, requestData)
      setEmailOpenedTimeChartValues(data);
    } catch (error) {
      if (error instanceof TypeError) {
        console.log("Network error occurred");
        setnetworkError(true)
        setisLoading(false);
        return;
      }
    }
  }

  const totalContact = async () => {
    const requestData = {
      sequence_id: sequenceId, // Ensure this is the sequence ID
    };

    try {
      const data = await fetchMethod("POST", `${API_DASHBOARD_TOTAL_CONTACTS_PER_SEQUENCE}`, requestData)
      setTotalContactValues(data);
    } catch (error) {
      if (error instanceof TypeError) {
        console.log("Network error occurred");
        setnetworkError(true)
        setisLoading(false);
        return;
      }
    }
  }

  return (
    <div className='content-page'>
      <div className='container-fluid'>
        <div className='requence-report-heading'>
          <div className='me-4 cursor-pointer gobackArrow' onClick={goBack}><i className="pi pi-arrow-left " /></div>
          <span>Analytics</span>
          <span>/</span>
          <div>
            {isLoading ? <Skeleton width='44px' height='20px'></Skeleton> : <h6>{selectedSequenceName}<small></small></h6>}
          </div>
        </div>
        <div className='requence-report-tabs'>
          <TabView>
            {userGroup?.sequence?.get_sequencewise_email_details?.R &&
              <TabPanel header="Report Summary">
              <Row className="mb-4 d-flex">
                <Col md={7}>
                  <Panel header={
                    <div className=" ">
                      <h6 className="m-0">Real-time Campaigns Data</h6>
                    </div>
                  }>
                    {isLoading ? <Skeleton height='200px'></Skeleton> :
                      <div>
                        <RealTimeCampaignChart SequenceValues={sequenceValues} />
                      </div>
                    }
                  </Panel>
                </Col>
                <Col md={5}>
                  <Panel className='' header={
                    <div className="dasboard-view-reports">
                      <h6 className="m-0">Campaigns Reach</h6>
                      {/* <Button
                        severity="info"
                        size="small"
                        label="Share Now"
                        icon="uil uil-share-alt"
                      /> */}
                    </div>
                  }>
                    {isLoading ? <Skeleton height='200px'></Skeleton> :
                      <div>
                        <SequenceReachChart TotalReach={totalContactValues} />
                      </div>
                    }
                  </Panel>
                </Col>
              </Row>
              <Row className="mb-4 d-flex">
                <Col>
                  <Panel header={
                    <div className=" ">
                      <h6 className="m-0">Open By Time</h6>
                    </div>
                  }>
                    {isLoading ? <Skeleton height='200px'></Skeleton> :
                      <div>
                        <OpenByTimeChart ChartByTime={emailOpenedTimeChartValues} />
                      </div>
                    }
                  </Panel>
                </Col>
              </Row>
              {/* <Row className="mb-4 d-flex">
                <Col>
                  <div>
                    <LocationChart />
                  </div>
                </Col>
              </Row> */}

              {/* <Row className="mb-4 d-flex">

                <Col>
                  <Panel header={
                    <div className=" ">
                      <h6 className="m-0">Subjects and Senders Details</h6>
                    </div>
                  }>
                    <div>
                      <SubjectSenderDetail />
                    </div>
                  </Panel>
                </Col>
              </Row> */}
            </TabPanel>}

            <TabPanel header="Recipient Activities">
              <RecipientActivities sequenceId={sequenceId} />
            </TabPanel>
            {/* <TabPanel header="Bounce and Auto-replies">
              <BounceAutoReplay />
            </TabPanel> */}
          </TabView>
        </div>
      </div>
    </div>
  )
}

export default SequenceReport