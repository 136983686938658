import "./DomyTemplate.css";
const template3HTML = `<div class="Template_Dummy_Tepmplate" style="text-align: center;
border: 1px solid #ccc;
padding: 22px 28px;
margin-right: 25px;
">
      
 
<table  style=" width: 100%">
  <tr>
    <td style=" font-size: 16px;font-weight:500; width:100% margin-button:20px;border: 1px solid #fff !important"><img src="https://placehold.co/100x40.png?text=Logo"/></td>
  </tr>
</table>
<table  style=" width: 100%">
  <tr>
    <td style=" font-size: 22px;font-weight:600; width:100% margin-button:20px;border: 1px solid #fff !important">The point of using Lorem Ipsum is that it has Your Heading</td>
  </tr>
</table>

<div class="text-Template_Dummy_Tepmplate" style="text-align: left;
margin: 21px 0;">
<table  style=" width: 100%">
  <tr>
      <td style="font-size: 14px;
      border:1px solid #fff !important">page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal . f a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to usi</td>
   </tr>
</table> 
</div>

<table  style=" width: 100%">

  <tr>
    <td style=" font-size: 16px;font-weight:500; margin-button:20px;border: 1px solid #fff !important">
    You can create unique layouts by placing a variety of content blocks in different sections of your template.
    To change the look of your email, visit the Styles tab to set background colors, button colors, and more.
    </td>
    <td style=" font-size: 16px;font-weight:500; margin-button:20px;border: 1px solid #fff !important"><img src="https://res.cloudinary.com/dx0yui1iq/image/upload/v1727088893/placeholder_1_ruzdxp.png"/ style="width: 100%; height: auto;" /></td>
  </tr>
</table>


<a href="#">
<table style="width: 50%; margin:0 auto">
  <tr>
    <td style="background: #484848;
    border: none;padding:10px 14px;margin-bottom:20px;color:#fff;border: 1px solid #fff !important">Call to Action</td>
  </tr>
</table>
</a>
  
<footer style="background: #eff7ff;
padding: 29px; margin-top:20px">
<table style="width: 100%; margin:0 auto">
  <tr>
    <td style="
    border: none;padding:10px 14px;margin-bottom:20px;color:#000;border: 1px solid #eff7ff !important">&copy; 2023 Your Company</td>
  </tr>
</table>
</footer>

</div>`

export default template3HTML;