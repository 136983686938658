import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const OpenByTimeChart = ({ ChartByTime }) => {
    const [series, setSeries] = useState([
        {
            name: 'No of opens',
            data: []
        }
    ]);

    const [options] = useState({
        chart: {
            height: 300,
            type: 'area',
            toolbar: {
                show: false // Hide the toolbar menu
              }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'datetime',
            categories: [
                "2018-09-19T00:00:00.000Z",
                "2018-09-19T01:00:00.000Z",
                "2018-09-19T02:00:00.000Z",
                "2018-09-19T03:00:00.000Z",
                "2018-09-19T04:00:00.000Z",
                "2018-09-19T05:00:00.000Z",
                "2018-09-19T06:00:00.000Z",
                "2018-09-19T07:00:00.000Z",
                "2018-09-19T08:00:00.000Z",
                "2018-09-19T09:00:00.000Z",
                "2018-09-19T10:00:00.000Z",
                "2018-09-19T11:00:00.000Z",
                "2018-09-19T12:00:00.000Z",
                "2018-09-19T13:00:00.000Z",
                "2018-09-19T14:00:00.000Z",
                "2018-09-19T15:00:00.000Z",
                "2018-09-19T16:00:00.000Z",
                "2018-09-19T17:00:00.000Z",
                "2018-09-19T18:00:00.000Z",
                "2018-09-19T19:00:00.000Z",
                "2018-09-19T20:00:00.000Z",
                "2018-09-19T21:00:00.000Z",
                "2018-09-19T22:00:00.000Z",
                "2018-09-19T23:00:00.000Z",
                "2018-09-19T24:00:00.000Z",
            ]
        },
        tooltip: {
            x: {
                format: 'HH:mm'
            },
        },
    });

    useEffect(() => {
        setSeries([
            {
                name: 'No of opens',
                data: ChartByTime || []
            }
        ]);
    }, [ChartByTime]);

    return (
        <div>
            <div id="chart">
                <ReactApexChart options={options} series={series} type="area" height={300} />
            </div>
            <div id="html-dist"></div>
        </div>
    );
}

export default OpenByTimeChart;
