import { useContext, useState } from 'react';
import './ErrorBoundaryModal.css'
import { contextVar } from './ContextVar';

function ErrorBoundaryModal(props) {
    const { networkError } = useContext(contextVar)
    return (
        <>
            {(props?.internetConnection && networkError) &&
                <div className='' style={{ backgroundColor: '#fb2828', padding: '6px' }}>
                    <div className="custom-modal-body ">
                        <p className="error-message text-center">Please try changing your internet connection. If the issue still persists, contact <span style={{ textDecoration: 'underline' }}>enquiry@buyersflow.com</span>
                        </p>
                    </div>
                </div>
            }
            {!props?.internetConnection &&
                <div className='' style={{ backgroundColor: '#fb2828', padding: '6px' }}>
                    <div className="custom-modal-body ">
                        <p className="error-message text-center">No internet connection </p>
                    </div>
                </div>
            }

        </>
    );
}

export default ErrorBoundaryModal;