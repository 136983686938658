import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const SequenceReachChart = ({ TotalReach }) => {
    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({
        chart: {
            type: 'donut',
            toolbar: {
                show: false // Hide the toolbar menu
              }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        labels: []
    });

    useEffect(() => {
        const totalContacts = TotalReach?.total_contacts || 0;
        setSeries([totalContacts]);
        setOptions(prevOptions => ({
            ...prevOptions,
            labels: [`${totalContacts} Total Reach`]
        }));
    }, [TotalReach?.total_contacts]);

    return (
        <div>
            <div id="chart">
                <ReactApexChart options={options} series={series} type="donut" />
            </div>
            <div id="html-dist"></div>
        </div>
    );
}

export default SequenceReachChart;
