// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.emailBody ul,
.emailBody ul li{
    list-style: inherit !important;
}
.emailBody ol,
.emailBody ol li
{
    list-style: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/email/details.css"],"names":[],"mappings":";AACA;;IAEI,8BAA8B;AAClC;AACA;;;IAGI,2BAA2B;AAC/B","sourcesContent":["\n.emailBody ul,\n.emailBody ul li{\n    list-style: inherit !important;\n}\n.emailBody ol,\n.emailBody ol li\n{\n    list-style: auto !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
