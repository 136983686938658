import React, { useState, useEffect, useRef, useContext } from "react";
import { Form, Col, Row, Dropdown } from "react-bootstrap";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';
import gmailM from "../assets/images/gmailMailbox.svg";
import { Chip } from "primereact/chip";
import outlookM from "../assets/images/outlookMailbox.svg";
import TemplateEditor from "../utils/TemplateEditor";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import ApiHeader, { fetchMethod } from "../Components/ApiHeader";
import ApiListFile from "../Components/ApiListFile";
import { contextVar } from "../Components/ContextVar";

const AccountInfo = () => {
  const API_URL = process.env.REACT_APP_API_HOST;
  const api_key = localStorage.getItem("api_key");

  const {
    API_USERS,
    API_SAVE_TOKENS,
    API_POST_USER,
    API_UNLINK_MAILBOX,
    API_CHANGE_PASSWORD,
    API_DISPLAY_SIGNATURE,
    API_SIGNATURE,
    API_MAKE_DEFAULT
  } = ApiListFile()

  const { setnetworkError, name, email, setvisibleMailboxModal, setalertGmail } = useContext(contextVar)
  const [linkedMailboxes, setLinkedMailboxes] = useState([]);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [initialName, setInitialName] = useState('');
  const [currentName, setCurrentName] = useState('');
  const [emails, setEmails] = useState('')
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [signatureLoading, setSignatureLoading] = useState(false)
  const [fetchSignatureLoading, setSignatureFetchLoading] = useState(true)
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false)
  const [signatureMsg, setSignatureMsg] = useState("")
  const [linkedBoxLength, setlinkedBoxLength] = useState('')

  const [validation, setValidation] = useState({
    template_name: "",
    template_subject: "",
    template_body: ""
  });
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false)
  const [mailboxToggoled, setMailboxToggoled] = useState("")

  const isValidPassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,14}$/;
    return regex.test(password);
  };

  useEffect(() => {
    fetchLinkedMailboxes();
  }, []);

  useEffect(() => {
    setCurrentName(name);
    setEmails(emails)
  }, [])

  const fetchLinkedMailboxes = async () => {
    setLoading(true)
    try {
      const data = await fetchMethod("GET", `${API_USERS}/mailboxes`, {})
      const splitName = data?.users_name?.split(' ');
      setLinkedMailboxes(data.linked_mailboxes);
      setLoading(false);
      if (data?.linked_mailboxes?.length == 0 || data?.linked_mailboxes?.length == undefined) {
        setvisibleMailboxModal(true)
        setlinkedBoxLength(data?.linked_mailboxes?.length)
        localStorage.setItem('pathName', window.location.pathname)
      }

    }
    catch (error) {
      console.error("Error fetching linked mailboxes:", error);
    }
  };

  useEffect(() => {
    fetchSaveToken()
  }, []);

  const fetchSaveToken = () => {
    setLoading(true)
    // Extract the code from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      // Check the current window location to determine the provider
      let email_provider = window.location.href.includes("googleapis")
        ? "google"
        : window.location.href.includes("zoho") ? "zoho" : "outlook";
      console.log("google link data 1", email_provider)
      // Send the code to your server

      fetchMethod("POST", `${API_SAVE_TOKENS}`, {
        code: code,
        email_provider: email_provider,
        redirect_uri: process.env.REACT_APP_URL + "/settings/accountinfo"
      })
        .then((data) => {
          console.log("google link data", data)
          if (data?.success === false) {
            if (data?.message === "Internal Server Error") {
              fetchSaveToken()
              setLoading(false)
            } else if (data?.message === "Permission Denied") {
              // Show alert if permission is denied
              setalertGmail(true);
              setLoading(false)
            }
            else if (data?.message === "This email is already linked.") {
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: data?.message,
              });
              setLoading(false)
            }
          }
          else {
            window.location.replace(process.env.REACT_APP_URL + "/settings/accountinfo");

          }
        });

    }

  }



  const handleChange = (e) => {
    setCurrentName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = await fetchMethod("PUT", `${API_POST_USER}/${localStorage.getItem('user_id')}`, {
      "name": currentName,
      "email": emails
    })
    if (data.status) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: data.message,
      });
      setInitialName(currentName)
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Error",
        detail: data.message,
      });
    }
    setLoading(false);
  };

  const handleLinkMailbox = () => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
    const scope = encodeURIComponent(
      "openid " +
      "https://www.googleapis.com/auth/gmail.send " +
      "https://www.googleapis.com/auth/userinfo.email"
    );
    const responseType = "code";
    const accessType = "offline";
    const prompt = "consent";

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&access_type=${accessType}&prompt=${prompt}`;

    window.location.href = authUrl;
  };

  const handleLinkOutlookMailbox = () => {
    const clientId = process.env.REACT_APP_OUTLOOK_CLIENT_ID; // replace with your client id
    const redirectUri = process.env.REACT_APP_OUTLOOK_REDIRECT_URI; // replace with your redirect uri
    // const scope = encodeURIComponent(
    //   "https://graph.microsoft.com/Mail.Send " +
    //   "https://graph.microsoft.com/User.Read offline_access https://graph.microsoft.com/Mail.Read"
    // );
    const scope = encodeURIComponent(
      "https://graph.microsoft.com/Mail.Send " +
      "https://graph.microsoft.com/User.Read " +
      "https://graph.microsoft.com/Mail.Read " +
      "https://graph.microsoft.com/Mail.ReadWrite " +
      "offline_access"
    );
    const responseType = "code";
    const responseMode = "query";
    const prompt = "consent";

    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&response_mode=${responseMode}&prompt=${prompt}`;

    // Redirect user to Microsoft's OAuth 2.0 server
    window.location.href = authUrl;
  };


  const handleLinkZohoMailbox = () => {
    const base_url = "https://accounts.zoho.com/oauth/v2/auth";
    const client_id = process.env.REACT_APP_ZOHO_CLIENT_ID; // Replace with your client ID
    const redirect_uri = encodeURIComponent(process.env.REACT_APP_ZOHO_REDIRECT_URI);
    const scope = encodeURIComponent("ZohoMail.messages.ALL");
    const state = "SalesAutomation"; // Replace with your unique state token

    const authUrl = `${base_url}?response_type=code&client_id=${client_id}&scope=${scope}&redirect_uri=${redirect_uri}&access_type=offline&state=${state}`;

    window.location.href = authUrl;
  };

  const accept = (emails) => {
    fetch(
      `${API_UNLINK_MAILBOX}/${emails}`,
      {
        headers: ApiHeader()
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.status === "success") {
          setLinkedMailboxes(linkedMailboxes.filter(
            (mailbox) => mailbox.linked_email !== emails
          ));
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Mailbox Unlinked Successfully",
          });
          fetchLinkedMailboxes()

        } else {
          toast.current.show({
            severity: "warn",
            summary: "Error",
            detail: data.message,
          });
        }
      })
      .catch((error) => {
        toast.current.show({
          severity: "warn",
          summary: "Error",
          detail: "Failed to Unlink",
        });

        if (error instanceof TypeError) {
          console.log("Network error occurred");
          setnetworkError(true)
          return;
        }
      });
  };
  const reject = (emails) => {
  }



  const confirmUnlinkMail = (emails) => {
    confirmDialog({
      message: "Note: If you unlink, the associated signature will be removed.",
      header: "Are you sure, you want to unlink this mailbox",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => accept(emails),
      reject: () => reject(emails),
      draggable: false
    });
  };


  const logoColumnGmail = (rowData) => {
    return (
      <div className="d-flex align-items-center" >
        {rowData.email_provider == "google" ? (
          <img src={gmailM} alt="" height="24" />
        ) : (
          <img src={outlookM} alt="" height="24" />
        )}
        <div className="ms-3 d-flex">
          {rowData.linked_email} <br />
          {rowData.default && <Chip label="Default" className="ms-2" />}
        </div>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {/*<SplitButton className="action-more"  model={items} raised text />*/}
        <Dropdown className="float-end action-more" align="end">
          <Dropdown.Toggle
            as="a"
            className="arrow-none text-muted cursor-pointer"
          >
            <i className="uil uil-ellipsis-v"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {!rowData.default &&
              <Dropdown.Item onClick={() => handleMakeDefault(rowData.linked_email)}>
                <i className="pi pi-check-circle me-2"></i>Make Default
              </Dropdown.Item>
            }
            <Dropdown.Item
              className="text-danger"
              onClick={() => confirmUnlinkMail(rowData.linked_email)}
            >
              <i className="uil  uil-link-broken me-2"></i>Unlink mailbox
            </Dropdown.Item>
            <Dropdown.Item onClick={() => { setMailboxToggoled(rowData.linked_email); display_signature(rowData.linked_email) }}>
              <i className="pi pi-cog me-2"></i>Manage Signature
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    let isValid = true;
    let newValidation = { template_name: "", template_subject: "", template_body: "" };

    // Validate the name field
    if (!oldPassword) {
      isValid = false;
      newValidation.old_password = "Old Password is required";
    }

    // Validate the subject field
    if (!newPassword) {
      isValid = false;
      newValidation.new_password = "New Password is required";
    }

    if (newPassword && !isValidPassword(newPassword)) {
      newValidation.new_password = "Password Format doesn't match";
    }

    // Validate the body field
    if (!confirmPassword) {
      isValid = false;
      newValidation.confirm_password = "Confirm Password is required";
    }

    // Update the validation state
    setValidation(newValidation);

    if (isValid) {
      setLoading(true);

      // Check if new password and confirm password match
      if (newPassword !== confirmPassword) {
        setLoading(false);
        toast.current.show({
          severity: "warn",
          summary: "Error",
          detail: "Password and Confirm Password do not match",
        });
        return;
      }
      fetchMethod("POST", `${API_CHANGE_PASSWORD}`, {
        old_password: oldPassword,
        new_password: newPassword,
      })
        .then((data) => {
          setLoading(false);
          if (data.status === "success") {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "Password Updated Successfully",
            });
            setVisible1(false);
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Error",
              detail: data.message,
            });
          }
        })
        .catch((error) => {
          toast.current.show({
            severity: "danger",
            summary: "Error",
            detail: "Some Error Occurred. Please Try again",
          });
          if (error instanceof TypeError) {
            console.log("Network error occurred");
            setnetworkError(true)
            return;
          }
        });
    }
  };

  // This is the function to make the API call to set default
  function handleMakeDefault(linked_email) {
    fetchMethod("GET", `${API_MAKE_DEFAULT}/${linked_email}`, {})
      .then((data) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Email set as Default",
        });
        // Update the linkedMailboxes state to mark the new default email
        setLinkedMailboxes(linkedMailboxes.map(mailbox => {
          if (mailbox.linked_email === linked_email) {
            return { ...mailbox, default: true };
          }
          // Remove the default flag from other mailboxes
          return { ...mailbox, default: false };
        }));
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setLoading(false);
          return;
        }
      });
  }



  const handleEditorChange = (text) => {
    setSignatureMsg(text)
  };

  function display_signature(emails) {
    setSignatureFetchLoading(true)
    fetchMethod("GET", `${API_DISPLAY_SIGNATURE}/${emails}`, {})
      .then((data) => {
        setSignatureFetchLoading(false)
        setSignatureMsg('<p></p>' + data.signature);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setLoading(false);
          return;
        }
      });
  }

  const handleSignatureSave = async () => {
    setSignatureLoading(true);

    // Parse the HTML string into a DOM Document
    let parser = new DOMParser();
    let doc = parser.parseFromString(signatureMsg, "text/html");

    const pTags = doc.querySelectorAll('p');
    pTags.forEach(p => {
      if (p.innerHTML === '&nbsp;') {
        p.parentNode.removeChild(p);
      }
    });

    let serializer = new XMLSerializer();
    let updatedHtmlString = serializer.serializeToString(doc);

    // Remove the <html>, <head>, and <body> tags
    updatedHtmlString = updatedHtmlString.replace(/<\/?(html|head|body)([^>]*)>/gi, '');

    // Trim leading and trailing whitespace and newlines
    updatedHtmlString = updatedHtmlString.trim();

    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-api-key", localStorage.getItem("api_key"));

      const raw = {
        email: mailboxToggoled,
        reply_content: updatedHtmlString,
      };
      console.log("raw payload",raw)

      fetchMethod("POST", `${API_SIGNATURE}`, raw)
        .then((data) => {
          console.log("data sig",data)
          if (data.status == "success") {
            if (toast.current) {
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: data.message,
              });
            }
          } else {
            if (toast.current) {
              toast.current.show({
                severity: "warn",
                summary: "Error",
                detail: data.message,
              });
            }
          }
          setMailboxToggoled("");
          setSignatureLoading(false);
          setSignatureMsg("");
          display_signature(mailboxToggoled);
        });

    } catch (error) {
      console.error(error.message);
      setSignatureLoading(false);

      if (error instanceof TypeError) {
        console.log("Network error occurred");
        setnetworkError(true);
        return;
      }
    }
  };

  return (
    <Row className="align-items-stretch">
      <ConfirmDialog draggable={false} resizable={false} />
      <Toast ref={toast} />
      {/* <Col md={5}>
        <Toast ref={toast} />
        <Panel header={loading ? <Skeleton width="200px" height="20px" /> : "Account Info"}>
          {loading ? (
            <>
              <Skeleton width="100%" height="40px" className="mb-4" />
              <Skeleton width="100%" height="40px" />
            </>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={currentName}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <br />
                <Form.Text className="fs-6 fw-semibold" style={{ color: 'black' }}>{email}</Form.Text>
              </Form.Group>
              <div className="d-flex justify-content-between">
                <Button
                  type="submit"
                  size="small"
                  label="Save"
                  icon="pi pi-check"
                  loading={loading}
                  disabled={initialName === currentName}
                />

              </div>
            </Form>
          )}

          <Dialog
            header="Change Password"
            visible={visible1}
            onHide={() => {
              setVisible1(false); setValidation({
                template_name: "",
                template_subject: "",
                template_body: ""
              })
            }}
            style={{ width: "30vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            draggable={false}
            resizable={false}
          >
            <Form>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Old Password</Form.Label>
                <div style={{ position: 'relative' }}>
                  <Form.Control
                    type={isOldPasswordVisible ? "text" : "password"}
                    placeholder="Password"
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                  <i
                    className={isOldPasswordVisible ? 'pi pi-eye-slash' : 'pi pi-eye'}
                    size="small"
                    severity="secondary"
                    onClick={(e) => { e.preventDefault(); setIsOldPasswordVisible(!isOldPasswordVisible) }}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '30px',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer'
                    }}
                  />
                </div>
                <div className="text-danger">{validation.old_password}</div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Tooltip target=".custom-target-icon" />
                <Form.Label>New Password<i className="custom-target-icon pi pi-info-circle ms-2" data-pr-tooltip="The Passsword must be in between (8-13) character containing one upper case, one lower case, one special character and one number"></i></Form.Label>
                <div style={{ position: 'relative' }}>
                  <Form.Control
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder="Password"
                    onChange={(e) => setNewPassword(e.target.value)}
                    style={{ paddingRight: '30px' }}  // Add padding to prevent text from being obscured by the icon
                  />
                  <i
                    className={isPasswordVisible ? 'pi pi-eye-slash' : 'pi pi-eye'}
                    size="small"
                    severity="secondary"
                    onClick={(e) => { e.preventDefault(); setIsPasswordVisible(!isPasswordVisible) }}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '30px',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer'
                    }}
                  />
                </div>
                <div className="text-danger">{validation.new_password}</div>

              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Confirm Password</Form.Label>
                <div style={{ position: 'relative' }}>
                  <Form.Control
                    type={isConfirmPasswordVisible ? "text" : "password"}
                    placeholder="Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    style={{ paddingRight: '30px' }}  // Add padding to prevent text from being obscured by the icon
                  />
                  <i
                    className={isConfirmPasswordVisible ? 'pi pi-eye-slash' : 'pi pi-eye'}
                    size="small"
                    severity="secondary"
                    onClick={(e) => { e.preventDefault(); setIsConfirmPasswordVisible(!isConfirmPasswordVisible) }}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '30px',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer'
                    }}
                  />
                </div>
                <div className="text-danger">{validation.confirm_password}</div>
              </Form.Group>
              <div style={{ display: 'flex' }}>
                <Button
                  type="submit"
                  size="small"
                  label="Change Password"
                  icon="pi pi-lock"
                  onClick={handleChangePassword}
                  loading={loading}
                  style={{ marginLeft: 'auto' }}  
                />
              </div>

            </Form>
          </Dialog>
        </Panel>
      </Col> */}
      <Col md={12}>
        {loading ? (
          <Skeleton width="100%" height="200px" />
        ) : linkedMailboxes?.length === 0 || linkedMailboxes === undefined ? (
          <Panel header="Email Settings">
            <div className="text-center">
              <img src="" alt="" />
              <h4>Link your mailbox to get started</h4>
              <p>
                Start managing and sending emails when you <br /> connect your
                email account.
              </p>
              <Button
                label="Link Gmail Mailbox"
                size="small"
                outlined
                icon="pi pi-google"
                className="me-4"
                onClick={handleLinkMailbox}
              />
              <Button
                label="Link Outlook Mailbox"
                size="small"
                outlined
                className="me-4"
                icon="pi pi-microsoft"
                onClick={handleLinkOutlookMailbox}
              />
            </div>
          </Panel>

        ) : (
          <Panel
            header={
              <div className="panel-header"> My mailboxes
                <Button
                  label="Link mailbox"
                  size="small"
                  icon="pi pi-link"
                  onClick={() => setVisible(true)}
                />
              </div>
            }
            className=""
          >
            {mailboxToggoled == "" ? <div className="">
              <div className="card">
                <DataTable value={linkedMailboxes}>
                  <Column
                    field="mailbox"
                    header="Mailbox"
                    body={logoColumnGmail}
                  ></Column>
                  <Column
                    field="mailbox"
                    header="Daily Limit"
                    body={() => { return '250' }}
                  ></Column>
                  {/* <Column field="synced_at" header="Last synced" body={(rowData) => {return rowData.synced_at}}></Column> */}
                  <Column body={actionBodyTemplate}></Column>
                </DataTable>
              </div>
            </div> : <>
              <div className="mailboxSettingsTestEditor">
                <h6>Email Signature</h6>
                <h6>{mailboxToggoled}</h6>
              </div>
              {!fetchSignatureLoading ? (<TemplateEditor
                template_body={signatureMsg}
                onEditorChange={handleEditorChange}
              />) : (<></>)}
              <div className="d-flex justify-content-end mt-2">
                <Button
                  style={{ marginTop: "20px" }}
                  type="submit"
                  size="small"
                  label="Save"
                  icon="pi pi-check"
                  loading={signatureLoading}
                  onClick={handleSignatureSave}
                />
                <Button
                  style={{ marginTop: "20px" }}
                  className="ms-2"
                  onClick={() => setMailboxToggoled("")}
                  size="small"
                  outlined
                >
                  Close
                </Button>
              </div>
            </>}
          </Panel>

        )}



        <Dialog
          visible={visible}
          onHide={() => setVisible(false)}
          style={{ width: "30vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          draggable={false}
          resizable={false}
        >
          <h4>Link your mailbox to get started</h4>
          <p>
            By connecting your mailbox, you'll be able to capture all your email
            engagement in one spot. Based on your plan, you can send emails via
            using Gmail, Microsoft Exchange, Office 365, Outlook, or your own
            custom server. Learn more.
          </p>
          <Button
            label="Link Gmail Mailbox"
            size="small"
            outlined
            icon="pi pi-google"
            className="me-4"
            onClick={handleLinkMailbox}
          />
          <Button
            label="Link Outlook Mailbox"
            size="small"
            outlined
            className="me-4"
            icon="pi pi-microsoft"
            onClick={handleLinkOutlookMailbox}
          />
        </Dialog>
      </Col>
    </Row>
  );
};

export default AccountInfo;