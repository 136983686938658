import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import { InputText } from 'primereact/inputtext'
import { Panel } from 'primereact/panel'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Button as Pbutton } from 'primereact/button';
import ApiListFile from '../Components/ApiListFile'
import { fetchMethod } from '../Components/ApiHeader'
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown'
import { contextVar } from '../Components/ContextVar'
import { Tooltip } from "primereact/tooltip";



const Voice = () => {
  const { API_TWILIO_NUMBER_LIST, API_CLAIM_TWILIO_NUMBER, } = ApiListFile()
  // const phone_number = localStorage.getItem("phone_number")

  // const getValidValue = (value) => (value && value !== 'undefined' ? value : '');

  const { setphoneNumber, phoneNumber, userGroup,phoneNumberDetail } = useContext(contextVar)

  const [visible, setVisible] = useState(phoneNumber ? 2 : 1);
  const [numberList, setnumberList] = useState([])
  const [countryCode, setcountryCode] = useState('')
  const [loading, setloading] = useState(false)
  const [btnLoader, setbtnLoader] = useState(null)
  const [dialogVisible, setdialogVisible] = useState(false)
  const [message, setmessage] = useState('')
  const toast = useRef(null);


  useEffect(() => {
    if (phoneNumber) {
      setVisible(2)
      fetchPhoneNumbers()
    }

  }, [phoneNumber,visible])


  const validateCountryCode = (code) => {
    const isoAlpha2Regex = /^[A-Z]{2}$/;
    const areaCodeRegex = /^\d{3}$/;
    return isoAlpha2Regex.test(code.toUpperCase()) || areaCodeRegex.test(code);
  }


  const fetchPhoneNumbers = () => {
    if (!validateCountryCode(countryCode == '' ? 'US' : countryCode)) {
      setdialogVisible(true);
      setmessage('Please enter a valid ISO Alpha-2 country code or a three-digit area code.')
      setloading(false);  // Stop the loader if validation fails
      return;
    }

    setloading(true);
    // Check the state of `phone_number` and set visibility accordingly
    if (phoneNumber) {
      setVisible(2);
    } else {
      setVisible(3);
    }
    const code = countryCode == '' ? 'US' : countryCode;
    fetchMethod("POST", `${API_TWILIO_NUMBER_LIST}`,
      {
        country: code,
      })
      .then((data) => {
        if (data.status === 'error') {
          setmessage(data.message)
          setdialogVisible(true);
          setcountryCode('')
        } else {
          setnumberList(data)
          setcountryCode('')
        }
        setloading(false)
      })
      .catch((error) => {
        if (error instanceof TypeError) {
          // setnetworkError(true)
          return;
        }
        setloading(false)
      });
  }

  const fetchNumberCodeWise = () => {
    fetchPhoneNumbers()
  }

  const acceptNumber = (phone_number) => {
    handleClaimNumber(phone_number)
  }
  const handleClaimNumber = (phone_number) => {
    console.log("claim phone number",phone_number)
    setbtnLoader(phone_number)
    fetchMethod("POST", `${API_CLAIM_TWILIO_NUMBER}`,
      {
        phone_number: phone_number,
      })
      .then((data) => {
        // localStorage.setItem('phone_number', phone_number);
        setphoneNumber(phone_number)
        if (data.status === 'success') {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: data.message,
          });
        }
        if (data.status === 'error') {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: data.message,
          });
        }
        setVisible(2)
        setbtnLoader(null)
      })
      .catch((error) => {
        if (error instanceof TypeError) {
          // setnetworkError(true)
          return;
        }
        setbtnLoader(null)
      });
  }

  const confirmPurchase = (phone_number) => {
    confirmDialog({
      message: 'Are you sure you want to claim this number ? This will Cost you $1.15 .',
      header: 'Confirm',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-blue ',
      rejectClassName: 'p-button-danger',
      accept: () => acceptNumber(phone_number),
      draggable: false
    });
  };



  const handleDelete = () => {
    setloading(true)
    fetchMethod("DELETE", `${API_CLAIM_TWILIO_NUMBER}`, {})
      .then((data) => {
        if (data.status === 'success') {
          setphoneNumber('')
          setVisible(3)
          
        }
        setloading(false)
      })
      .catch((error) => {
        if (error instanceof TypeError) {
          // setnetworkError(true)
          return;
        }
        setloading(false)
      });
  }

  const accept = () => {
    handleDelete()
  }

  const confirmdelete = () => {
    confirmDialog({
      message: `Are you sure you want to delete your phone number? Once deleted, you won't be able to reclaim the same number. ${
        phoneNumberDetail?.isDeleted === 4 
          ? 'You have reached your purchase limit for this month. You cannot purchase more numbers until next month. Once you delete this number, no further purchases will be allowed.' 
          : ''
      }`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => accept(),
      draggable: false,
      style: { width: '700px' }
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchNumberCodeWise();
    }
  };

console.log("phone number details",numberList)
  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />

      <Dialog
        header="Error"
        visible={dialogVisible}
        style={{ width: '50vw' }}
        onHide={() => setdialogVisible(false)}
      >
        <p className="m-0">
          {message}
        </p>
      </Dialog>
      <Row>
        <Col md={2} ></Col>
        <Col md={8} >
          <Panel header="Voice" className='mb-2'>
            {visible === 1 &&
              <div className="NoDefaultMailboxFound">
                <h4>No Phone Number Set Up</h4>
                <p>Get a phone number to start calling</p>
                {userGroup?.twilio?.get_available_numbers_list?.R &&
                  <button onClick={fetchPhoneNumbers}>
                    <FeatherIcon icon="phone-call" size={20} /> Set Up a Phone Number
                  </button>}
              </div>
            }

            {visible === 2 &&
              <div>
                <span className="mb-4">Buyer's Phone Number</span>
                <div className="p-inputgroup flex-1 numberinput">
                  <InputText placeholder="" value={phoneNumber} readOnly />
                  {userGroup?.twilio?.delete_claim_number &&
                    <Pbutton
                      icon="pi pi-trash"
                      className="p-button-danger numberdlt"
                      onClick={confirmdelete}
                    />}
                </div>
              </div>
            }

            {visible === 3 &&
              <div className="card">
                <div className="card-body">
                  <h5>Get New VoIP Phone Number</h5>
                  <p>
                    Please enter a three-digit US area code, or the ISO Alpha-2 code of the country that you would like to call from
                  </p>
                  <div className=" flex-1">
                    <InputText
                      placeholder="Search country code"
                      value={countryCode}
                      onChange={(e) => setcountryCode(e.target.value)}
                      onKeyPress={handleKeyPress}
                      className='w-75 me-2'
                    />
                    <Pbutton icon="pi pi-search" label="Search" onClick={fetchNumberCodeWise} loading={loading} />
                  </div>
                  <div>
                    <h6 className='my-4'>Select a phone number below:</h6>
                    <div className='phonenumber_table_heading'>
                      <span>Phone number </span>
                      <span>Region</span>
                      <span style={{ width: "70px" }}></span>
                    </div>
                    <div className='phonenumber_container'>
                      {loading ? (
                        <div className="phoneNumberCard gap-4">
                          <Skeleton height="40px" width='40%' />
                          <Skeleton height="40px" width='30%' />
                          <Skeleton height="40px" width='30%' />
                        </div>
                      ) : (
                        <>
                          {numberList?.data?.map((ele, index) => (
                            <div key={index} className="phoneNumberCard">
                              <h6>
                                <Tooltip target={`[data-tooltip="${ele.phone_number}"]`} />
                                <h6
                                  className="tooltip-note-text"
                                  data-tooltip={ele.phone_number}
                                  data-pr-tooltip={ele.phone_number}
                                >
                                  {ele.phone_number.length > 15 ? `${ele.phone_number.slice(0, 15)}...` : ele.phone_number}
                                </h6>
                              </h6>

                              <span > ({ele.iso_country})</span>
                              {userGroup?.twilio?.purchase_number &&
                                <Pbutton
                                  size="small"
                                  label="Select"
                                  onClick={() => confirmPurchase(ele.phone_number)}
                                  loading={btnLoader === ele.phone_number}
                                />}
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            }
          </Panel>
          {/* {visible === 2 && <>
            <Panel className='mb-2'>
              <div>
                <span className="mb-4">Outgoing Call</span>
                <div className="flex-1 numberinput">
                  <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name"
                    placeholder="Select a City" className='w-100' />
                </div>
              </div>
            </Panel>

            <Panel >
              <h5>Incoming Call</h5>
              <p>
                With VoIP you can only receive incoming calls on Apollo. With Bridge Calls you can receive incoming calls on your cell phone.
              </p>
              <div className="p-inputgroup flex-1 w-100 mb-4">
                <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name"
                  placeholder="Select a City" className="w-full" />
              </div>
              <div>
              <h6>Select a phone number below:</h6>
              <div>
                {loading ? (
                  <div className="phoneNumberCard gap-4">
                    <Skeleton height="40px" width='100%' />
                    <Skeleton height="40px" width='50%' />
                  </div>
                ) : (
                  <>
                    {numberList?.data?.map((ele, index) => (
                      <div key={index} className="phoneNumberCard">
                        <h6>{ele.phone_number}<span> ({ele.iso_country})</span></h6>
                        <Pbutton
                          size="small"
                          label="Select"
                          onClick={() => handleClaimNumber(ele.phone_number)}
                          loading={btnLoader === ele.phone_number}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            </Panel>
          </>
          } */}
        </Col>
      </Row>
    </>
  )
}

export default Voice