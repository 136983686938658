import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Skeleton } from "primereact/skeleton";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import TemplateEditor from "../utils/TemplateEditor";
import { Toast } from "primereact/toast";
import { fetchMethod } from "../Components/ApiHeader";
import ApiListFile from "../Components/ApiListFile";
import AWS from "aws-sdk";

const Contacts = () => {
  const { id } = useParams();
  const { API_TEMPLATE, API_DISPLAY_SIGNATURE, API_SEND_EMAIL } = ApiListFile()
  const [contacts, setContacts] = useState([])
  const [loading, setLoading] = useState(false)
  const [globalFilter, setGlobalFilter] = useState(null);
  const [dialogState, setDialogState] = useState('normal');
  const [visibleEmail, setVisibleEmail] = useState(false);
  const toast = useRef(null);
  const [currentRecipient, setCurrentRecipient] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company_name: "",
    group_id: "",
    group_name: ""
  });
  const [validation, setValidation] = useState({
    template_subject: "",
    template_body: ""
  });
  const dt = useRef(null);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "timestamp",
    sortOrder: -1
  });
  const [totalRecords, setTotalRecords] = useState(0)
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [templates, setTemplates] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [selectedFileS3, setselectedFileS3] = useState([])




  const handleEditorChange = (text) => {
    // Remove any HTML tags and trim the text
    const strippedText = text.replace(/<[^>]*>/g, '').trim();
    // Update the state
    setCurrentTemplate({ ...currentTemplate, template_body: text });
  };


  const handleFileUpload = (result) => {
    let filesArray = [];
    if (result instanceof FileList) {
      filesArray = Array.from(result);  // Convert FileList to array
    } else if (result instanceof File) {
      filesArray = [result];  // If a single file is uploaded
    } else if (Array.isArray(result)) {
      filesArray = result;  // If an array of files is uploaded
    }

    // Now, add these new files to the previous selected files
    setselectedFileS3(prevFiles => [...prevFiles, ...filesArray]);
  }




  const removeFile = (fileToRemove) => {
    setselectedFileS3((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    setCurrentTemplate((prevTemplate) => ({
      ...prevTemplate,
      file_link: prevTemplate.file_link.filter((file) => file !== fileToRemove),
    }));
  };

  const handleUploadToS3 = async () => {
    const results = [];
    for (const file of selectedFileS3) {
      const s3 = new AWS.S3();
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Key: file.name,
        Body: file,
      };
      try {
        const result = await s3.upload(params).promise();
        results.push(result);
      } catch (err) {
        console.error('Error uploading file: ', err);
      }
    }
    sendEmailToLead(results)
  };


  const handleMaximize = () => {
    setDialogState('maximized');
  };

  const handleRestore = () => {
    setDialogState('normal');
  };

 
  const sendEmailToLead = async (file) => {
    try {
      let isValid = true;
      let newValidation = { template_subject: "", template_body: "" };

      // Validate the subject field
      if (!currentTemplate.template_subject) {
        isValid = false;
        newValidation.template_subject = "Template subject is required";
      }


      let cleanBody = currentTemplate.template_body || '';
      const nonWhitespaceRegex = /[^\s]/;
      const signatureRegex = /<table id="signature_start"[\s\S]*?<\/table>|<div id="signature_start"[\s\S]*?<\/div>/g;

      // Remove signature
      cleanBody = cleanBody.replace(signatureRegex, '');

      // Extract image source if present
      const imgSrcRegex = /<img[^>]+src\s*=\s*['"]([^'"]+)['"][^>]*>/i;
      const imgMatch = cleanBody.match(imgSrcRegex);
      let imgSrc = '';

      if (imgMatch && imgMatch[1]) {
        imgSrc = imgMatch[1];
      }

      // Remove HTML tags and trim
      cleanBody = cleanBody.replace(/<\/?[^>]+(>|$)/g, '');
      cleanBody = cleanBody.replace(/&nbsp;/g, '');
      cleanBody = cleanBody.trim();


      // Check if the body is blank or consists only of whitespace, line breaks, or spaces after stripping tags
      if ((!cleanBody || !nonWhitespaceRegex.test(cleanBody)) && !imgSrc) {
        isValid = false;
        newValidation.template_body = "Template body is required";

      } else {
        // If there's an image source but no text content, use the image source as the body
        if (!cleanBody && imgSrc) {
          cleanBody = imgSrc;
        }
      }

      // Update the validation state
      setValidation(newValidation);

      if (isValid) {
        setLoading(true);

        let templateData = { ...currentTemplate };
        console.log("template data", templateData)

        // Remove the file_links key from the copied templateData
        delete templateData.file_link;
        const mergedFiles = [
          ...(currentTemplate.file_link || []), // Existing file_links (if any)
          ...file                                // New file data being uploaded
        ];
        const payload = {
          ...templateData,
          file_link: mergedFiles,
          leads: [currentRecipient],
          template_subject: currentTemplate.template_subject,
          template_body: currentTemplate.template_body,
        };

        console.log("payload...", payload)
        const data = await fetchMethod("POST", `${API_SEND_EMAIL}`, payload)
        document.body.style.overflow = 'visible';
        if (data.status === "success") {
          setLoading(false);
          setVisibleEmail(false);
          setValidation({ template_subject: "", template_body: "" });
          setCurrentTemplate({ template_subject: "" });
          display_sig();
          setSelectedTemplate("");
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Email Sent Successfully",
          });
        } else {
          throw new Error(data.message || "Failed to send email");
        }
      }
    } catch (error) {
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "An error occurred while sending email",
      });
    }
  };



  useEffect(() => {
    document.title = "Sequences";
    display_sig()
    fetchMethod("GET", `${API_TEMPLATE}`, {})
      .then((data) => {
        const options = data.map((template) => {
          let label = template.template_name;
          if (label.length > 40) {
            label = label.substr(0, 40) + '...';
          }
          return {
            value: template._id,
            label: label,
            subject: template.template_subject,
            body: template.template_body,
            file: template.file_links
          };
        });
        setTemplates(options);
      })
      .catch((err) => console.error(err));
  }, []);

  const display_sig = () => {
    fetchMethod("GET", `${API_DISPLAY_SIGNATURE}`, {})
      .then((data) => {
        setCurrentTemplate({ template_body: data.signature });
      })
      .catch((error) => console.error('Error fetching signature:', error.message))
  }

  useEffect(() => {
    setLoading(true)
    fetchMethod("POST", `${process.env.REACT_APP_API_HOST + "/sequence_contacts"}/${id}`, {
      page: lazyState.page,
      page_size: lazyState.rows,
      search_query: globalFilter,
      sort_order: lazyState.sortOrder,
      sort_field: lazyState.sortField
    })
      .then((data) => {
        setTotalRecords(data.total_count)
        setLoading(false)
        setContacts(data.items)
      })
      .catch((err) => console.error(err));
  }, [lazyState, globalFilter]);

  const header = () => {
    return (
      <div className="flex justify-content-between">

        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" className="form-control-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };
  const emailTemplate = (rowData) => {
    return (
      <p>{rowData.email}</p>
    );
  };

  const nameTemplate = (rowData) => {
    return (
      <p>{rowData.first_name}{" "}{rowData.last_name}</p>
    )
  }

  const actionTemplate = (rowData) => {
    return (
      <span className="p-buttonset">
        <Tooltip target=".send-tooltip" />
        <Button
          className="send-tooltip"
          icon="pi pi-send"
          aria-label="Send Email"
          rounded
          text
          data-pr-tooltip="Send Email"
          onClick={() => { setCurrentRecipient(rowData); setVisibleEmail(true) }}
        />
      </span>

    );
  };

  const onPage = (event) => {
    setlazyState({
      ...lazyState,
      first: event.first,
      rows: event.rows,
      page: event.page + 1 // Increment the page number by 1 since the event.page is 0-indexed
    });
  };

  const onSort = (event) => {
    setlazyState({ ...lazyState, sortOrder: event.sortOrder, sortField: event.sortField });
  }

  return (
    <>
      <div className="card mt-3 sequenceContacts">
        <Toast ref={toast} />
        {loading ? (
          <DataTable
            value={[...Array(5)]} // Use an array of length 5 to render 5 rows of skeletons
            tableStyle={{ minWidth: "50rem" }}
            header={header}
          >
            <Column
              field="name"
              header="Name"
              body={() => <Skeleton></Skeleton>}
            />
            <Column
              field="company"
              header="Email"
              body={() => <Skeleton></Skeleton>}
            />
            <Column
              field="action"
              header="Action"
              body={() => <Skeleton></Skeleton>}
            />
          </DataTable>
        ) : (
          <DataTable ref={dt}
            lazy
            first={lazyState.first}
            totalRecords={totalRecords} onPage={onPage}
            onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
            value={contacts}
            globalFilter={globalFilter}
            header={header}
            removableSort
            paginator
            rows={lazyState.rows}
            rowsPerPageOptions={[5, 10, 25, 50]}
            tableStyle={{ minWidth: "50rem" }}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}">
            <Column body={nameTemplate} style={{ width: '25%' }}></Column>
            <Column body={emailTemplate} style={{ width: '50%' }}></Column>
            <Column field="action" body={actionTemplate} style={{ width: '25%' }}></Column>
          </DataTable>
        )}
      </div>
      <Dialog
        header={
          <div className="d-flex justify-content-between align-items-center sendMail">
            <h5>Send Email</h5>
            <div>
              {dialogState === 'normal' && (
                <>
                  <Button
                    icon="pi pi-window-minimize"
                    className="p-mr-2"
                    text
                    onClick={handleMaximize}
                  />

                </>
              )}
              {dialogState === 'minimized' && (
                <Button
                  icon="pi pi-window-maximize"
                  className="p-mr-2"
                  text
                  onClick={handleRestore}
                />
              )}
              {dialogState === 'maximized' && (
                <Button
                  icon="pi pi-window-maximize"
                  className="p-mr-2"
                  text
                  onClick={handleRestore}
                />
              )}
            </div>
          </div>
        }
        visible={visibleEmail}
        modal={false}
        onHide={() => {
          setCurrentTemplate({});
          display_sig()
          setSelectedTemplate("")
          setVisibleEmail(false)
          setValidation({})
        }
        }
        style={
          dialogState === 'normal'
            ? { width: '45vw' }
            : dialogState === 'minimized'
              ? { display: 'none' }
              : { width: '15vw', height: '7vh', margin: 0 }
        }
        className="sendMailModal"
        position="bottom-right"
        draggable={false}
        resizable={false}

      >
        <Form className="pb-3 pt-1">
          <Dropdown
            value={selectedTemplate}
            onChange={(e) => {
              setSelectedTemplate(e.value);

              // Find the selected template object using the selected value
              const selectedOption = templates.find(
                (template) => template.value === e.value
              );
              if (selectedOption) {
                // Make sure the template is found
                setselectedFileS3(selectedOption.file)
                setCurrentTemplate({
                  ...currentTemplate,
                  template_subject: selectedOption.subject,
                  template_body: selectedOption.body,
                  file_link: selectedOption.file,
                });
              }
            }}
            options={templates}
            placeholder="Select a Template"
            className="w-full me-2 col-md-6"
          />
          <Form.Group
            controlId="formTemplateSubject"
            className="mb-3 mt-3"
          >
            <Form.Label>Template Subject</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter template subject"
              name="template_subject"
              value={currentTemplate.template_subject || ""}
              onChange={(e) =>
                setCurrentTemplate({
                  ...currentTemplate,
                  template_subject: e.target.value,
                })
              }
            />
            <div className="text-danger">
              {validation.template_subject}
            </div>
          </Form.Group>
          <Form.Group controlId="formTemplateBody" className="mb-2">
            <Form.Label>Template Body</Form.Label>

            <TemplateEditor
              template_body={currentTemplate.template_body}
              onEditorChange={handleEditorChange}
              onFileUpload={handleFileUpload}
            />
            <div className="text-danger" style={{ marginTop: "5rem" }}>
              {validation.template_body}
            </div>
          </Form.Group>
          {selectedFileS3.map((file) => (
            <h6>{file?.name || file.key}<i className="pi pi-times p-1 cursor-pointer" onClick={() => removeFile(file)}></i></h6>
          ))}
          <p>
            <b>Note</b>: You can use {`{{first_name}}`}, {`{{last_name}}`},{" "}
            {`{{company_name}}`}, {`{{email}}`}, and {`{{phone}}`} and{" "}
            {`{{unsubscribe}}`} as placeholders for recipient details.
          </p>
        </Form>
        <Button
          type="submit"
          size="small"
          label="Send"
          onClick={handleUploadToS3}
          icon="uil uil-message ms-2"
          iconPos="right"
          loading={loading}
        />

      </Dialog>
    </>

  )
}

export default Contacts;