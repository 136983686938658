import moment from 'moment';
import 'moment-timezone';
import React, { useState, useEffect, useRef, lazy, useContext } from "react";
import { Table, Form, Modal, Container, Col, Row, Card } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import sampleCSV from "../assets/sample-csv-formats/leads-sample.csv";
import TemplateEditor from "../utils/TemplateEditor";
import 'moment-timezone/data/packed/latest.json';
import "./Recipients.css"
import tzMapper from "../utils/tz_mapper.json";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import ApiListFile from "../Components/ApiListFile";
import ApiHeader, { fetchMethod } from "../Components/ApiHeader";
import { contextVar } from "../Components/ContextVar";
import OutgoingCall from "../Components/LeadCalling/OutgoingCall";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import CallDisposition from "../Components/LeadCalling/CallDisposition";
import { useDebounce } from "../utils/hooks"
import Cookies from 'js-cookie';
import AWS from "aws-sdk";
import { ProgressBar } from 'primereact/progressbar';
// import { io } from 'socket.io-client';


const Recipients = () => {

    const { grpName, grpId } = useParams()
    const dt = useRef(null);
    const callRef = useRef();
    const {
        API_TEMPLATE,
        API_UPDATE_GROUPS_LIST,
        API_GET_AND_DISPLAY_CSV,
        API_POST_USER_TIMEZONES,
        API_BULK_RECIPIENT_UPLOAD,
        API_GET_RECIPIENT_LIST,
        API_PAGINATED_RECIPIENT,
        API_SEND_EMAIL,
        API_GET_BULK_RECIPIENT,
        API_DISPLAY_SIGNATURE,
    } = ApiListFile()
    const { setnetworkError, callStatus, phoneNumber, userGroup, callDispositionModalStatus } = useContext(contextVar)
    const [selectedGroup, setSelectedGroup] = useState(grpId || null);
    const [networkError, setNetworkError] = useState(false);
    const [recipients, setRecipients] = useState([]);
    const [bulkRecipients, setBulkRecipients] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [showBulkModal, setShowBulkModal] = useState(false);
    const [groups, setGroups] = useState([]);
    const fileInputRef = useRef();
    const [visibleEmail, setVisibleEmail] = useState(false);
    const buttonEl = useRef(null);
    const toast = useRef(null);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [templates, setTemplates] = useState([]);
    const [currentTemplate, setCurrentTemplate] = useState({});
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileSelected, setFileSelected] = useState(false);
    const [disable, setDisable] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalRecordsModal, setTotalRecordsModal] = useState(0);
    const [dialogState, setDialogState] = useState("normal"); // 'normal', 'minimized', 'maximized'
    const [isLoading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsModal, setSelectedRowsModal] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [globalFilterModal, setGlobalFilterModal] = useState("");
    const [selectedTimezone, setSelectedTimezone] = useState("");
    const [isImportedLoading, setIsImportedLoading] = useState(false)
    const [tempLoader, settempLoader] = useState(false)
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "timestamp",
        sortOrder: -1,
    });
    const [modalLazy, setModalLazy] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "timestamp",
        sortOrder: -1,
    });
    const [currentRecipient, setCurrentRecipient] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        company_name: "",
        group_ids: [],
        group_names: [],
    });
    const [validation, setValidation] = useState({
        template_subject: "",
        template_body: "",
    });
    const [formErrors, setFormErrors] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        group_name: "",
        bulk_upload_file: "",
    });
    const [visible, setVisible] = useState(false);
    const [callingId, setcallingId] = useState('');
    const [recipientName, setrecipientName] = useState('');
    const [recipientId, setrecipientId] = useState('');
    const [callDispositionModal, setcallDispositionModal] = useState(false)
    const [callSid, setcallSid] = useState('')
    const [callDuration, setcallDuration] = useState(0)
    const [toNumber, settoNumber] = useState('')
    const [fromNumber, setfromNumber] = useState('')
    const [debouncedFilter, setDebouncedFilter] = useState(globalFilterModal);
    const [selectedFileS3, setselectedFileS3] = useState([])

    const debouncedSearch = useDebounce(globalFilter, 800)

    const navigate = useNavigate();
    const cancelTokenSource = useRef(null);




    const handleEditorChange = (text) => {
        // Remove any HTML tags and trim the text
        const strippedText = text.replace(/<[^>]*>/g, '').trim();
        // Update the state
        setCurrentTemplate({ ...currentTemplate, template_body: text });
    };


    const handleFileUpload = (result) => {
        let filesArray = [];
        if (result instanceof FileList) {
            filesArray = Array.from(result);  // Convert FileList to array
        } else if (result instanceof File) {
            filesArray = [result];  // If a single file is uploaded
        } else if (Array.isArray(result)) {
            filesArray = result;  // If an array of files is uploaded
        }

        // Now, add these new files to the previous selected files
        setselectedFileS3(prevFiles => [...prevFiles, ...filesArray]);
    }


    const removeFile = (fileToRemove) => {
        setselectedFileS3((prevFiles) => prevFiles ? prevFiles.filter((file) => file !== fileToRemove) : []);

        setCurrentTemplate((prevTemplate) => ({
            ...prevTemplate,
            file_link: prevTemplate.file_link ? prevTemplate.file_link.filter((file) => file !== fileToRemove) : [],
        }));
    };

    const handleUploadToS3 = async () => {
        const results = [];
        for (const file of selectedFileS3) {
            const s3 = new AWS.S3();
            const params = {
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Key: file.name,
                Body: file,
            };
            try {
                const result = await s3.upload(params).promise();
                results.push(result);
            } catch (err) {
                console.error('Error uploading file: ', err);
            }
        }
        sendEmailToLead(results)
    };

    console.log("selected file s3", selectedFileS3)

    const handleMaximize = () => {
        setDialogState("maximized");
        document.body.style.overflow = 'visible'
    };

    const handleRestore = () => {
        setDialogState("normal");
        document.body.style.overflow = 'hidden'
    };

    // const handleEditorChange = (text) => {
    //     setCurrentTemplate({ ...currentTemplate, template_body: text })
    // };

    useEffect(() => {
        const handler = setTimeout(() => {
            if (selectedFile) {
                setDebouncedFilter(globalFilterModal);
                handleImportCsvFile()
            }
        }, 1000); // Adjust time as needed

        return () => {
            clearTimeout(handler);
        };
    }, [modalLazy, globalFilterModal]);

    useEffect(() => {
        fetchRecipients()
    }, [callDispositionModal, callDispositionModalStatus])
    console.log("callDispositionModal", callDispositionModal)
    console.log("callDispositionModalStatus", callDispositionModalStatus)

    useEffect(() => {
        document.title = "Leads";
        // fetchUserTimeZone();
    }, []);

    useEffect(() => {
        fetchRecipients();
        fetchGroupList();
        fetchDefaultTimezone();
    }, [lazyState, debouncedSearch]);

    useEffect(() => {
        if (selectedGroup !== null) {
            fetchRecipients();
        }
    }, [selectedGroup]);  // Dependency on selectedGroup

    const resetModalLazyState = () => {
        setModalLazy({
            first: 0,
            rows: 10,
            page: 1,
            sortField: "timestamp",
            sortOrder: -1,
        });
    };

    const resetModalState = () => {
        // Reset all states to initial
        setFileSelected(false);
        setSelectedFile(null);
        setBulkRecipients([]);
        setIsImportedLoading(false);
        setDisable(false);
        setGlobalFilterModal("");
        setSelectedRowsModal([]);
        setTotalRecordsModal(0);
        fileInputRef.current.value = null;  // Clear the file input
        resetModalLazyState();  //
        setShowModal(false);
        setShowBulkModal(false);
    };

    // When closing the modal, reset the state properly
    const handleCloseBulkModal = () => {
        resetModalState();
        setShowBulkModal(false);
        setTotalRecordsModal(0);
    };

    const handleShowModal = () => {
        setShowBulkModal(true)
        setFileSelected(false);
        setSelectedFile(null);
        // setBulkRecipients([]);
        // setSelectedRowsModal([]);
        // fileInputRef.current.value = null;
    }

    const fetchDefaultTimezone = () => {
        fetchMethod("GET", `${API_POST_USER_TIMEZONES}`, {})
            .then((data) => {
                setSelectedTimezone(data?.item?.timezone)
            }).catch((err) => {
                console.log("error :", err.message)
            })
    }

    // Fetch the recipient list from the API
    const fetchRecipients = () => {
        setLoading(true);
        const body = {
            page: lazyState.page,
            page_size: lazyState.rows,
            search_query: debouncedSearch.trim(),
            sort_order: lazyState.sortOrder,
            sort_field: lazyState.sortField,
            selectedGroup: selectedGroup,
            group: grpId ? grpId : ''
        };

        if ((grpId && (globalFilter === null || globalFilter === '')) || (grpName !== null && globalFilter !== null)) {
            fetchMethod("POST", `${API_PAGINATED_RECIPIENT}`, body)
                .then((data) => {
                    if (data.status === "success") {
                        setTotalRecords(data?.total_count);
                        setRecipients(data?.items);
                        fetchRecipientTemplate();
                        fetchGroupList();
                        display_sig();
                        setLoading(false);
                    } else if (data.status === "error") {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            sticky: true,
                            detail: data.message,
                        });
                    }

                })
                .catch((error) => {
                    console.error(error);
                    if (error instanceof TypeError) {
                        setnetworkError(true)
                        setLoading(false);
                        return;
                    }
                });
        } else if (grpId !== '' && globalFilter === '') {
            // Return or perform any other action you want
            // setLoading(false);
            return;
        }
    };
    // Fetch the recipient template from the API
    const fetchRecipientTemplate = () => {
        settempLoader(true)
        document.title = "Leads";
        fetchMethod("GET", `${API_TEMPLATE}`, {})
            .then((data) => {
                console.log("recipient in ....", data)

                const options = data.map((template) => {
                    let label = template.template_name;
                    if (label.length > 40) {
                        label = label.substr(0, 40) + "...";
                    }
                    return {
                        value: template._id,
                        label: label,
                        subject: template.template_subject,
                        body: template.template_body,
                        file: template.file_links
                    };
                });
                settempLoader(false)
                setTemplates(options);

            })
            .catch((error) => {
                console.error(error);
                if (error instanceof TypeError) {
                    setnetworkError(true)
                    setLoading(false);
                    return;
                }
            });
    }

    // Fetch the group list from the API
    const fetchGroupList = () => {
        fetchMethod("GET", `${API_UPDATE_GROUPS_LIST}`, {})
            .then((data) => {
                const options = data.map((group) => ({
                    value: group._id,
                    label: group.group_name,
                }));
                setGroups(options);
            })
            .catch((error) => {
                console.error(error);
                if (error instanceof TypeError) {
                    setnetworkError(true)
                    setLoading(false);
                    return;
                }
            });
    }

    const display_sig = () => {
        fetchMethod("GET", `${API_DISPLAY_SIGNATURE}`, {})
            .then((data) => {
                setCurrentTemplate({ template_body: data.signature });
            })
            .catch((error) => console.error('Error fetching signature:', error.message))
    }

    const handleUploadCsv = (e) => {
        const file = e.target.files[0];
        // setDisable(!!file);
        if (file && file.name.endsWith(".csv")) {
            setSelectedFile(file);
        }
    };


    const handleImportCsvFile = () => {
        setDisable(true);
        setIsImportedLoading(true);
        if (selectedFile) {
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("page", modalLazy.page);
            formData.append("page_size", modalLazy.rows);
            formData.append("search_query", globalFilterModal)
            fetch(API_GET_AND_DISPLAY_CSV, {
                method: "POST",
                body: formData,
                headers: {
                    "Authorization": 'Bearer ' + Cookies.get('accessToken'),
                }
            })
                .then((response) => {
                    if (response.status === 504) {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: "Request timed out. Please try again.",
                            sticky: true,
                        });
                        setModalLazy((prev) => ({
                            ...prev,
                            page: prev.page - 1,
                        }));
                        return Promise.reject(new Error("Request timed out"));
                    }
                    return response.json();
                })
                .then((result) => {
                    if (result.status && result.status === "error") {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: result.message,
                            sticky: true,
                        });
                        setLoading(false)
                        setIsImportedLoading(false)
                    } else {
                        setBulkRecipients(result.result);
                        setFileSelected(true);
                        setTotalRecordsModal(result.total_count || 0);
                        setIsImportedLoading(false)
                    }
                })
                .catch((error) => {
                    console.error("Error uploading CSV:", error);
                    // Handle error (e.g., display an error message to the user)
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: "An error occurred while processing the CSV file. Please try again later.",
                        sticky: true,
                    });
                })
                .finally(() => {
                    setIsImportedLoading(false);
                    setDisable(false);
                });
        } else {
            toast.current.show({
                severity: "warn",
                summary: "Warning",
                detail: "Please select a CSV file first.",
                sticky: true,
            });

            setSelectedFile(selectedFile)
            setDisable(false)
            setIsImportedLoading(false);
        }
    };


    // const [message, setMessage] = useState('');
    // const [progress, setProgress] = useState(0); // State to hold the upload progress

    // useEffect(() => {
    //     const socket = io('http://localhost:5000');

    //     socket.on('connect', () => {
    //         console.log('Connected to the server');
    //         socket.emit('join', { userId: '614694' }); 
    //     });

    //     // Listen for responses from the server
    //     socket.on('response', (data) => {
    //         setMessage(data.data);
    //     });

    //     // Listen for upload progress from the server
    //     socket.on('upload_progress', (data) => {
    //         setProgress(data.percentage); // Update progress state
    //         console.log(`Upload progress: ${data.percentage}%`); // Debugging output
    //     });

    //     // Cleanup on unmount
    //     return () => {
    //         socket.disconnect();
    //     };
    // }, []);


    const onPageChange = (event) => {
        setIsImportedLoading(true);
        setModalLazy({
            ...modalLazy,
            first: event.first,
            rows: event.rows,
            page: event.page + 1,
        });
    };

    const onSearchPageChange = (event) => {
        setIsImportedLoading(true);
        setModalLazy({
            ...modalLazy,
            first: event.first,
            rows: event.rows,
            page: event.page,
        });
    };

    const handleFileUploadOnClickBtn = async () => {
        setLoading(true);
        const file = fileInputRef.current.files[0];
        if (!file) {
            setFormErrors({ ...validation, bulk_upload_file: "Please select a file to upload." });
            setLoading(false);
            return;
        }

        // Check if file is a CSV
        if (file.type !== "text/csv") {
            setFormErrors({ ...validation, bulk_upload_file: "Please upload a valid CSV file." });
            setLoading(false);
            return;
        }
        setFormErrors({ ...validation, bulk_upload_file: "" })
        setLoading(true)
        const formData = new FormData();
        formData.append("file", file);

        try {
            fetch(`${API_BULK_RECIPIENT_UPLOAD}`, {
                method: "POST",
                body: formData,
                headers: {
                    "Authorization": 'Bearer ' + Cookies.get('accessToken'),
                }
            })
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData.status === "success") {
                        setTotalRecordsModal(totalRecordsModal + responseData.inserted?.length);
                        setBulkRecipients(bulkRecipients.concat(responseData.inserted));
                        setShowBulkModal(false);
                        setFileSelected(false);
                        setDisable(false)
                        fetchRecipients()
                        setLoading(false);
                        toast.current.show({
                            severity: "success",
                            summary: "Success",
                            detail: "Leads Uploaded Successfully",
                        });
                    } else if (responseData.status === "error") {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            sticky: true,
                            detail: responseData.message,
                        });
                        setLoading(false);
                    }
                })
        } catch (error) {
            console.error("Upload failed:", error);
            toast.current.show({
                severity: "error",
                summary: "Network Error",
                detail: "Failed to upload file. Please try again."
            });
            setLoading(false);
        }
    };

    const handleShow = (recipient) => {
        setCurrentRecipient({
            ...recipient,
            phone: recipient.phone, // Ensure this is the full phone number with country code
            group_ids: recipient.group_ids || [], // Handle cases where group_ids might be undefined
            group_names: recipient.group_names || [],
        });
        setShowModal(true);
    };

    const handleClose = () => {
        setFormErrors({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            group_name: "",
            bulk_upload_file: "",
        });
        setShowModal(false);
    };

    const isFormValid = () => {
        let isValid = true;
        let errors = {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            company_name: "",
            group_name: "",
        };

        if (!currentRecipient.first_name.trim()) {
            errors.first_name = "First name is required";
            isValid = false;
        }

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!currentRecipient.email.trim()) {
            errors.email = "Email is required";
            isValid = false;
        } else if (!emailRegex.test(currentRecipient.email)) {
            errors.email = "Invalid email address";
            isValid = false;
        }

        if (!currentRecipient?.group_names?.join("").trim()) {
            errors.group_name = "Group is required";
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const onSort = (event) => {
        setlazyState({
            ...lazyState,
            sortOrder: event.sortOrder,
            sortField: event.sortField,
        });
    };

    console.log("current template", currentTemplate)
    const sendEmailToLead = async (file) => {
        try {
            let isValid = true;
            let newValidation = { template_subject: "", template_body: "" };

            // Validate the subject field
            if (!currentTemplate.template_subject) {
                isValid = false;
                newValidation.template_subject = "Template subject is required";
            }

            // // Validate the body field
            // if (!currentTemplate.template_body) {
            //     isValid = false;
            //     newValidation.template_body = "Template body is required";
            // }
            let cleanBody = currentTemplate.template_body || '';
            const nonWhitespaceRegex = /[^\s]/;
            const signatureRegex = /<table id="signature_start"[\s\S]*?<\/table>|<div id="signature_start"[\s\S]*?<\/div>/g;

            // Remove signature
            cleanBody = cleanBody.replace(signatureRegex, '');

            // Extract image source if present
            const imgSrcRegex = /<img[^>]+src\s*=\s*['"]([^'"]+)['"][^>]*>/i;
            const imgMatch = cleanBody.match(imgSrcRegex);
            let imgSrc = '';

            if (imgMatch && imgMatch[1]) {
                imgSrc = imgMatch[1];
            }

            // Remove HTML tags and trim
            cleanBody = cleanBody.replace(/<\/?[^>]+(>|$)/g, '');
            cleanBody = cleanBody.replace(/&nbsp;/g, '');
            cleanBody = cleanBody.trim();


            // Check if the body is blank or consists only of whitespace, line breaks, or spaces after stripping tags
            if ((!cleanBody || !nonWhitespaceRegex.test(cleanBody)) && !imgSrc) {
                isValid = false;
                newValidation.template_body = "Template body is required";

            } else {
                // If there's an image source but no text content, use the image source as the body
                if (!cleanBody && imgSrc) {
                    cleanBody = imgSrc;
                }
            }

            // Update the validation state
            setValidation(newValidation);

            if (isValid) {
                setLoading(true);

                let templateData = { ...currentTemplate };

                // Remove the file_links key from the copied templateData
                delete templateData.file_link;
                const mergedFiles = [
                    ...(currentTemplate.file_link || []), // Existing file_links (if any)
                    ...file                                // New file data being uploaded
                ];
                const payload = {
                    ...templateData,
                    file_link: mergedFiles,
                    leads: [currentRecipient],
                    template_subject: currentTemplate.template_subject,
                    template_body: currentTemplate.template_body,
                };
                // const payload = {
                //     leads: [currentRecipient],
                //     template_subject: currentTemplate.template_subject,
                //     template_body: currentTemplate.template_body,
                // };
                console.log("payload...", payload)
                const data = await fetchMethod("POST", `${API_SEND_EMAIL}`, payload)
                document.body.style.overflow = 'visible';
                if (data.status === "success") {
                    setLoading(false);
                    setVisibleEmail(false);
                    setValidation({ template_subject: "", template_body: "" });
                    setCurrentTemplate({ template_subject: "" });
                    display_sig();
                    setselectedFileS3([])
                    setSelectedTemplate("");
                    toast.current.show({
                        severity: "success",
                        summary: "Success",
                        detail: "Email Sent Successfully",
                    });
                } else {
                    throw new Error(data.message || "Failed to send email");
                }
            }
        } catch (error) {
            setLoading(false);
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: error.message || "An error occurred while sending email",
            });
        }
    };


    const handleCreate = async (option) => {
        setLoading(true);
        const newGroupId = await fetchMethod("POST", `${API_UPDATE_GROUPS_LIST}`, {
            group_name: option
        })
        setLoading(false);
        const newGroup = { value: newGroupId.group_id, label: option };
        setGroups([...groups, newGroup]);
        setCurrentRecipient(prevState => ({
            ...prevState,
            group_ids: [...prevState.group_ids, newGroupId.group_id],
            group_names: [...prevState.group_names, option]

        }));
    };

    const handleUpdate = async () => {
        if (!isFormValid()) return;

        try {
            setLoading(true);
            let phoneWithPlus = currentRecipient.phone;
            // Ensure phone number starts with '+'
            if (phoneWithPlus && !phoneWithPlus.startsWith('+')) {
                phoneWithPlus = `+${phoneWithPlus}`;
            }

            const updatedPhone = currentRecipient.phone.startsWith('+') ? currentRecipient.phone : `+${currentRecipient.phone}`;

            // Construct payload with changes only if they are made
            const payload = {
                ...currentRecipient,
                phone: phoneWithPlus,
                group_ids: currentRecipient.group_ids,
            };

            const data = await fetchMethod("PUT", `${API_GET_RECIPIENT_LIST}/${currentRecipient._id}`, payload);
            if (data.status === "success") {
                setRecipients(recipients.map((r) => r._id === data.recipient_id ? { ...r, ...currentRecipient, phone: updatedPhone } : r));
                fetchRecipients()
                handleClose();
                toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: data.message,
                });
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            toast.current.show({
                severity: "warn",
                summary: "Error",
                detail: error.message,
            });
        } finally {
            setLoading(false);
        }
    };


    const handleAdd = async () => {
        if (!isFormValid()) return;
        setLoading(true);
        let phoneWithPlus = currentRecipient.phone;
        if (phoneWithPlus && !phoneWithPlus.startsWith('+')) {
            phoneWithPlus = `+${phoneWithPlus}`;
        }

        const payload = {
            ...currentRecipient,
            phone: phoneWithPlus,
            group_ids: currentRecipient.group_ids,
        };

        const data = await fetchMethod("POST", `${API_GET_RECIPIENT_LIST}`, payload);
        if (data.status === "success") {
            setRecipients([...recipients, { ...currentRecipient, _id: data.recipient_id }]);
            setTotalRecords(prevRecords => prevRecords + 1);
            setLoading(false);
            handleClose();
            toast.current.show({
                severity: "success",
                summary: "Success",
                detail: data.message,
            });
            fetchRecipients();
        } else {
            setLoading(false);
            toast.current.show({
                severity: "warn",
                summary: "Error",
                detail: data.message,
            });
        }
    };


    const accept = (id) => {
        handleDelete(id);
    };

    const reject = (id) => { };

    const confirmdelete = (id) => {
        confirmDialog({
            message: "Do you want to delete this record?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => accept(id),
            reject: () => reject(id),
            draggable: false
        });
    };

    const handleDelete = async (id) => {
        setLoading(true);
        await fetchMethod("DELETE", `${API_GET_RECIPIENT_LIST}/${id}`, {})
        setLoading(false);
        toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Lead Deleted Successfully",
        });
        setTotalRecords(totalRecords - 1);
        setSelectedRows([])
        fetchRecipients();
    };

    const bodyTemplate = (rowData, column) => {
        const templateBody = rowData[column.field];
        const cleanedBody = templateBody;
        const maxLength = 50;
        const truncatedBody =
            cleanedBody?.length > maxLength
                ? `${cleanedBody.substring(0, maxLength)}...`
                : cleanedBody;
        return <><Tooltip target=".tooltip-b" /><span className="tooltip-note-text" data-pr-tooltip={templateBody}>{truncatedBody}</span></>
    };
    const statusTemplate = (rowData, column) => {
        const templateBody = rowData[column.field];
        const cleanedBody = templateBody || '';
        const maxLength = 30;
        const truncatedBody =
            cleanedBody.length > maxLength
                ? `${cleanedBody.substring(0, maxLength)}...`
                : cleanedBody;

        return (
            <>
                <Tooltip target=".tooltip-status" />
                <span
                    className="tooltip-status"
                    data-pr-tooltip={cleanedBody}
                    data-pr-position="top"
                >
                    {truncatedBody}
                </span>
            </>
        );
    };
    const phoneTemplate = (rowData) => {
        return <span>{rowData.phone}</span>
    }

    const groupNameTemplate = (rowData) => {
        const groupNamesArray = rowData?.group_names;
        // Convert array to a comma-separated string
        const groupNamesString = Array.isArray(groupNamesArray) ? groupNamesArray.join(', ') : groupNamesArray;
        // Check if the string is too long and needs truncation
        const truncatedBody = groupNamesString.length > 20
            ? `${groupNamesString.substring(0, 20)}...`
            : groupNamesString;

        return (
            <>
                <Tooltip target=".tooltip-note-text" />
                <span data-pr-tooltip={groupNamesString} className="tooltip-note-text">
                    {truncatedBody}
                </span>
            </>
        )
    };

    const contactStageTemplate = (rowData) => {
        const truncatedBody = rowData.contact_stage?.length > 10
            ? `${rowData.contact_stage.substring(0, 10)}...`
            : rowData.contact_stage;

        return (
            <>
                {/* Tooltip initialization */}
                <Tooltip target=".tooltip-note-text" />

                {/* Conditionally render based on stage_category */}
                {rowData.stage_category === 'inProgress' && (
                    <span
                        className="contact-stage-indicator-yellow tooltip-note-text"
                        data-pr-tooltip={rowData.contact_stage} // Tooltip content
                    >
                        {truncatedBody}
                    </span>
                )}
                {rowData.stage_category === 'succeeded' && (
                    <span
                        className="contact-stage-indicator-green tooltip-note-text"
                        data-pr-tooltip={rowData.contact_stage}
                    >
                        {truncatedBody}
                    </span>
                )}
                {rowData.stage_category === 'notSucceeded' && (
                    <span
                        className="contact-stage-indicator-red tooltip-note-text"
                        data-pr-tooltip={rowData.contact_stage}
                    >
                        {truncatedBody}
                    </span>
                )}
                {rowData.stage_category === 'noCategory' && (
                    <span
                        className="contact-stage-indicator-grey tooltip-note-text"
                        data-pr-tooltip={rowData.contact_stage}
                    >
                        {truncatedBody}
                    </span>
                )}
            </>
        );
    };

    const last_contacted_template = (rowData) => {
        const last_contacted_data = rowData?.last_contacted_timestamp;
        if (last_contacted_data) {
            return moment.tz(last_contacted_data, "UTC").tz(tzMapper[selectedTimezone] || "UTC").format('YYYY-MM-DD HH:mm:ss')
        } else {
            return 'NA';
        }
    }

    const ClickableError = ({ message, path }) => {


        const handleNavigate = () => {
            navigate(path);
        };

        return (
            <span style={{ color: 'blue', textDecoration: 'none', cursor: 'pointer' }} onClick={handleNavigate}>
                {message}
            </span>
        );
    };


    const convertTimestampToTimezone = (rowData) => {
        let timestamp = rowData.timestamp
        if (!rowData || !selectedTimezone) {
            return 'Invalid Date';
        }
        try {
            const date = new Date(timestamp + 'Z');
            if (isNaN(date.getTime())) {
                throw new Error('Invalid date');
            }

            const ianaTimezone = tzMapper[selectedTimezone] || selectedTimezone;
            const dateOptions = {
                timeZone: ianaTimezone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            };

            const timeOptions = {
                timeZone: ianaTimezone,
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            };

            const formattedDate = new Intl.DateTimeFormat('en-GB', dateOptions).format(date);
            const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);

            // Combine date and time
            return `${formattedDate}, ${formattedTime}`;
        } catch (error) {
            console.error('Error converting timestamp:', error, 'for timezone:', selectedTimezone);
            return 'Invalid Date';
        }
    };



    const handleSelectionChange = (e) => {
        const selectedValues = e.value;
        setSelectedRows((prevSelectedRows) => {
            const selectedIds = new Set(selectedValues.map(row => row._id));
            // Create a new selection that merges previous and current selections
            const mergedSelection = [
                ...prevSelectedRows.filter(row => selectedIds.has(row._id) || !recipients.some(currentRow => currentRow._id === row._id)),
                ...selectedValues.filter(row => !prevSelectedRows.some(selectedRow => selectedRow._id === row._id))
            ];
            return mergedSelection;
        });
    };

    // Handle header checkbox selection
    const handleHeaderCheckboxChange = (e) => {
        const allSelected = e.checked;
        if (allSelected) {
            // Add all rows from the current page to the selection
            setSelectedRows((prevSelectedRows) => {
                const newSelectedRows = [...prevSelectedRows];
                recipients.forEach(row => {
                    if (!newSelectedRows.some(selectedRow => selectedRow._id === row._id)) {
                        newSelectedRows.push(row);
                    }
                });
                return newSelectedRows;
            });
        } else {
            // Remove all rows from the current page from the selection
            setSelectedRows((prevSelectedRows) => {
                return prevSelectedRows.filter(row => !recipients.some(currentRow => currentRow._id === row._id));
            });
        }
    };

    const handleSelectionChangeImport = (e) => {
        const selectedValues = e.value;
        setSelectedRowsModal((prevSelectedRows) => {
            const selectedIds = new Set(selectedValues.map(row => row._id));

            // Create a new selection that merges previous and current selections
            const mergedSelection = [
                ...prevSelectedRows.filter(row => selectedIds.has(row._id) || !bulkRecipients?.some(currentRow => currentRow._id === row._id)),
                ...selectedValues.filter(row => !prevSelectedRows.some(selectedRow => selectedRow._id === row._id))
            ];
            return mergedSelection;
        });
    };



    const handleHeaderCheckboxChangeImport = (e) => {
        const allSelected = e.checked;

        if (allSelected) {
            // Add all rows from the current page to the selection
            setSelectedRowsModal((prevSelectedRows) => {
                const newSelectedRows = [...prevSelectedRows];
                bulkRecipients.forEach(row => {
                    if (!newSelectedRows.some(selectedRow => selectedRow._id === row._id)) {
                        newSelectedRows.push(row);
                    }
                });
                return newSelectedRows;
            });
        } else {
            // Remove all rows from the current page from the selection
            setSelectedRowsModal((prevSelectedRows) => {
                return prevSelectedRows.filter(row => !bulkRecipients.some(currentRow => currentRow._id === row._id));
            });
        }
    };




    const generateCSV = (data) => {
        const fields = [
            "first_name",
            "last_name",
            "email",
            "phone",
            "company_name",
            "group_names",
        ];
        const headers = [
            "First Name",
            "Last Name",
            "Email",
            "Phone",
            "Company Name",
            "Group Name",
        ];

        // Extract the rows
        const rows = data
            .map((obj) =>
                fields
                    .map((field) => {
                        // If the field is 'start_date', wrap it in double quotes
                        return field === "group_names" ? `"${obj[field]}"` : obj[field];
                    })
                    .join(",")
            )
            .join("\n");

        return headers.join(",") + "\n" + rows;
    };

    const generateCSVModal = (data) => {
        const fields = [

            "email",
            "status",
        ];
        const headers = [

            "Email",
            "Status",

        ];

        // Extract the rows
        const rows = data
            .map((obj) =>
                fields
                    .map((field) => {
                        // If the field is 'start_date', wrap it in double quotes
                        return field === "group_names" ? `"${obj[field]}"` : obj[field];
                    })
                    .join(",")
            )
            .join("\n");

        return headers.join(",") + "\n" + rows;
    };

    const handleBulkDelete = async (selectionOnly) => {
        if (selectionOnly) {
            const idArray = selectedRows.map(item => item._id);

            try {
                await fetchMethod("DELETE", `${API_GET_BULK_RECIPIENT}`, { ids: idArray })
                setLoading(false);
                toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Leads Deleted Successfully",
                });
                setTotalRecords(totalRecords - idArray.length);
                setSelectedRows([]);
                fetchRecipients(); // Assuming this function fetches the updated recipient list
                setDeleteLoading(false);
            } catch (error) {
                // Handle fetch error...
                console.error('Error during fetch:', error);
                setDeleteLoading(false);
            }
        }
    }

    const deleteSelectedRecords = (selectionOnly) => {
        confirmDialog({
            message: "Do you want to delete these records?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",

            accept: () => {
                setDeleteLoading(true);
                handleBulkDelete(selectionOnly)
            },
            draggable: false
        });
    };

    const exportCSV = (selectionOnly) => {
        if (selectionOnly) {
            // Generate CSV from the selectedRows
            const csvData = generateCSV(selectedRows);

            // Create a blob from the CSV
            const blob = new Blob([csvData], { type: "text/csv" });

            // Create a link element and trigger the download
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "leads.csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            dt.current.exportCSV(selectionOnly);
        }
    };

    const exportCSVModal = (selectionOnly) => {
        if (selectionOnly) {
            // Generate CSV from the selectedRows
            const csvData = generateCSVModal(selectedRowsModal);

            // Create a blob from the CSV
            const blob = new Blob([csvData], { type: "text/csv" });

            // Create a link element and trigger the download
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "email_import_status.csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            dt.current.exportCSV(selectionOnly);
        }
    };

    const headerModal = (
        <div className="d-flex justify-content-between align-items-center">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    className="form-control-sm"
                    value={globalFilterModal}
                    onChange={(e) => {
                        setGlobalFilterModal(e.target.value);
                        setModalLazy((prevState) => ({
                            ...prevState,
                            page: 1,
                            first: 0,
                        }));
                    }}
                    placeholder="Search..."
                />
            </span>
            <div className={""}>

                <Button
                    size="small"
                    onClick={() => exportCSVModal(true)}
                    label="Export"
                    severity="help"
                    icon="uil uil-down-arrow"
                    disabled={!selectedRowsModal || selectedRowsModal.length === 0}
                />

            </div>

        </div>
    );


    const handleGroupSearch = (e) => {
        const selectedValue = e.value;
        setSelectedGroup(selectedValue);

        if (selectedValue) {
            // If a group is selected, update the URL with the group parameter
            navigate(`/leads?group=${selectedValue}`);
        } else {
            // If the search becomes blank, remove the group parameter and redirect to the recipient route
            navigate('/leads');
        }
    };



    const header = (
        <div className="d-flex justify-content-between align-items-center">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    className="form-control-sm"
                    value={globalFilter}
                    onChange={(e) => {
                        setGlobalFilter(e.target.value);
                        setLoading(true);
                        if (e.target.value.trim() === '') {
                            navigate(`/leads`)
                        }
                    }}
                    placeholder="Search..."
                />
            </span>
            <div className={"d-flex w-50 justify-content-end"}>
                {isLoading ? (<span className="w-25"><Skeleton height="35px"></Skeleton></span>) : (<Dropdown
                    value={selectedGroup}
                    options={groups}
                    onChange={handleGroupSearch}
                    optionLabel="label"
                    optionValue="value"
                    showClear
                    filter
                    className="me-2 grp-drpdn-lead"
                    placeholder="Select Group"
                    disabled={isLoading || networkError}
                />)}

                <Button
                    size="small"
                    onClick={() => exportCSV(true)}
                    label="Export"
                    severity="help"
                    icon="uil uil-down-arrow"
                    disabled={!selectedRows || selectedRows.length === 0}
                />
                {userGroup?.recipient?.delete_recipient?.D &&
                    <Button
                        className="ms-2"
                        size="small"
                        onClick={() => deleteSelectedRecords(true)}
                        label="Delete"
                        severity="danger"
                        icon="uil uil-trash"
                        disabled={!selectedRows || selectedRows.length === 0}
                        loading={deleteLoading}
                    />}
            </div>

        </div>
    );



    const handleCall = (rowData) => {
        if (rowData.phone) {
            setcallingId(rowData.phone);
            setrecipientName(rowData.first_name)
            setrecipientId(rowData._id)
            setVisible(true);
        }
    };


    const actionTemplate = (rowData) => {
        const isValidPhoneNumber = () => {
            // This regex allows for optional country code (+ followed by 1-3 digits)
            // followed by the main phone number (allowing various formats)
            const phoneRegex = /^\+?(\d{1,3})?[-.\s]?\(?[0-9]{3}\)?[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}$/;
            return phoneRegex.test(rowData.phone);
        };
        const isButtonDisabled = !isValidPhoneNumber(rowData.phone) || !rowData.phone || !phoneNumber || callStatus == 'connected';

        return (
            <div className="p-d-flex p-flex-wrap" style={{ width: "200px" }}>
                <Tooltip target=".tooltip-text-edit" />
                <Tooltip target=".tooltip-text-send-email" />
                <Tooltip target=".tooltip-text-delete" />
                {userGroup && userGroup?.recipient && userGroup?.recipient?.update_recipient?.C &&
                    <Button
                        className="tooltip-text-edit"
                        icon="uil uil-edit-alt"
                        rounded
                        text
                        aria-label="Edit"
                        data-pr-tooltip="Edit"
                        onClick={() => handleShow(rowData)}
                    />}
                {userGroup && userGroup?.email && userGroup?.email?.send_email?.R &&
                    <Button
                        className="tooltip-text-send-email"
                        icon="pi pi-send"
                        rounded
                        text
                        aria-label="Send Email"
                        data-pr-tooltip="Send Email"
                        onClick={() => {
                            setCurrentRecipient(rowData);
                            setVisibleEmail(true);
                            setDialogState("normal")
                            document.body.style.overflow = 'hidden';
                        }}
                    />}
                {userGroup && userGroup?.twilio && userGroup?.twilio?.make_call?.R &&
                    <Button
                        className="tooltip-text-send-email"
                        icon="pi pi-phone"
                        rounded
                        text
                        aria-label="Phone Call"
                        data-pr-tooltip="Phone Call"
                        onClick={() => handleCall(rowData)}
                        disabled={isButtonDisabled}
                    />}
                {userGroup && userGroup?.recipient && userGroup?.recipient?.delete_recipient?.D &&
                    <Button
                        className="tooltip-text-delete"
                        ref={buttonEl}
                        icon="uil uil-trash"
                        rounded
                        text
                        aria-label="Delete"
                        data-pr-tooltip="Delete"
                        severity="danger"
                        onClick={() => confirmdelete(rowData._id)}
                    />}



            </div>
        );
    };

    const onPage = (event) => {
        setlazyState({
            ...lazyState,
            first: event.first,
            rows: event.rows,
            page: event.page + 1, // Increment the page number by 1 since the event.page is 0-indexed
        });
    };



    const closeCallModal = () => {
        if (callRef.current) {
            callRef.current.disconnectCall();
        }
        setVisible(false)
        setDialogState("normal")
    }




    return (
        <div>
            <div className="content-page">
                <div className="content">
                    <Container fluid>
                        <ConfirmDialog draggable={false} resizable={false} />
                        <Dialog
                            header={
                                <div className="d-flex justify-content-between align-items-center sendMail">
                                    <h5>Send Email</h5>
                                    <div>
                                        {dialogState === "normal" && (
                                            <>
                                                <Button
                                                    icon="pi pi-window-minimize"
                                                    className="p-mr-2"
                                                    text
                                                    onClick={handleMaximize}
                                                />
                                            </>
                                        )}
                                        {dialogState === "minimized" && (
                                            <Button
                                                icon="pi pi-window-maximize"
                                                className="p-mr-2"
                                                text
                                                onClick={handleRestore}
                                            />
                                        )}
                                        {dialogState === "maximized" && (
                                            <Button
                                                icon="pi pi-window-maximize"
                                                className="p-mr-2"
                                                text
                                                onClick={handleRestore}
                                            />
                                        )}
                                    </div>
                                </div>
                            }
                            // className={showModal ? 'disable-drag-resize' : ''}
                            className="sendMailModal"
                            visible={visibleEmail}
                            modal={false}
                            onHide={() => {
                                document.body.style.overflow = 'auto'
                                setVisibleEmail(false);
                                setValidation({
                                    template_subject: "",
                                    template_body: "",
                                });
                                setCurrentTemplate({
                                    template_subject: ""
                                })
                                display_sig()
                                setSelectedTemplate("");

                            }}
                            style={
                                dialogState === "normal"
                                    ? { width: "45vw" }
                                    : dialogState === "minimized"
                                        ? { display: "none" }
                                        : { width: "15vw", height: "7vh", margin: 0 }
                            }
                            position="bottom-right"
                            draggable={false}
                            resizable={false}
                        >
                            <Form className="pb-3 pt-1">
                                {tempLoader ? <Skeleton width='300px' height='44px'></Skeleton> :
                                    <Dropdown
                                        value={selectedTemplate}
                                        onChange={(e) => {
                                            setSelectedTemplate(e.value);

                                            // Find the selected template object using the selected value
                                            const selectedOption = templates.find(
                                                (template) => template.value === e.value
                                            );

                                            if (selectedOption) {
                                                // Make sure the template is found
                                                setselectedFileS3(selectedOption.file)
                                                setCurrentTemplate({
                                                    ...currentTemplate,
                                                    template_subject: selectedOption.subject,
                                                    template_body: selectedOption.body,
                                                    file_link: selectedOption.file,
                                                });
                                            }
                                        }}
                                        options={templates}
                                        placeholder="Select a Template"
                                        className="w-full me-2 col-md-6"
                                        loading={tempLoader}
                                    />
                                }
                                <Form.Group
                                    controlId="formTemplateSubject"
                                    className="mb-3 mt-3"
                                >
                                    <Form.Label>Template Subject</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter template subject"
                                        name="template_subject"
                                        value={currentTemplate.template_subject || ""}
                                        onChange={(e) =>
                                            setCurrentTemplate({
                                                ...currentTemplate,
                                                template_subject: e.target.value,
                                            })
                                        }
                                    />
                                    <div className="text-danger">
                                        {validation.template_subject}
                                    </div>
                                </Form.Group>
                                <Form.Group controlId="formTemplateBody" className="mb-2">
                                    <Form.Label>Template Body</Form.Label>

                                    <TemplateEditor
                                        template_body={currentTemplate.template_body}
                                        onEditorChange={handleEditorChange}
                                        onFileUpload={handleFileUpload}
                                    />
                                    <div className="text-danger" style={{ marginTop: "5rem" }}>
                                        {validation.template_body}
                                    </div>
                                </Form.Group>
                                {selectedFileS3.map((file) => (
                                    <h6>{file?.name || file.key}<i className="pi pi-times p-1 cursor-pointer" onClick={() => removeFile(file)}></i></h6>
                                ))}
                                <p>
                                    <b>Note</b>: You can use {`{{first_name}}`}, {`{{last_name}}`},{" "}
                                    {`{{company_name}}`}, {`{{email}}`}, and {`{{phone}}`} and{" "}
                                    {`{{unsubscribe}}`} as placeholders for recipient details.
                                </p>
                            </Form>
                            <Button
                                type="submit"
                                size="small"
                                label="Send"
                                onClick={handleUploadToS3}
                                icon="uil uil-message ms-2"
                                iconPos="right"
                                loading={isLoading}
                            />
                        </Dialog>
                        <Toast ref={toast} />
                        <Row>
                            <Col>
                                <div className="page-title-box">
                                    <h4 className="page-title">Lead</h4>
                                    <div className="page-title-right">
                                        <div className="row g-2">
                                            <div className="col-md-auto">
                                                {userGroup?.recipient?.add_recipient?.A && (
                                                    <Button
                                                        icon="uil uil-plus"
                                                        size="small"
                                                        label="Add Lead"
                                                        onClick={() =>
                                                            handleShow({
                                                                first_name: "",
                                                                last_name: "",
                                                                email: "",
                                                                phone: "",
                                                                company_name: "",
                                                                group_ids: [],
                                                                group_names: [],
                                                            })
                                                        }
                                                    />
                                                )}

                                                {userGroup && userGroup?.recipient && userGroup?.recipient?.get_and_display_csv?.R &&
                                                    <Button
                                                        severity="secondary"
                                                        size="small"
                                                        icon="uil uil-upload"
                                                        className="ms-2"
                                                        onClick={handleShowModal}
                                                    // () => setShowBulkModal(true)
                                                    />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="page-title-caption-box">
                                    <h6 className="page-title-caption mb-4">Manage and nurture potential customers by tracking their interactions and engagement with your emails.</h6>
                                </div>
                            </Col>
                        </Row>
                        <Card>
                            {/*  table */}
                            {isLoading ? (
                                <DataTable
                                    header={header}
                                    value={[...Array(5)]} // Use an array of length 5 to render 5 rows of skeletons
                                    tableStyle={{ minWidth: "50rem" }}
                                    sortable
                                >
                                    <Column
                                        field="first_name"
                                        header="First Name"
                                        body={() => <Skeleton></Skeleton>}
                                    />
                                    <Column
                                        field="last_name"
                                        header="Last Name"
                                        body={() => <Skeleton></Skeleton>}
                                    />
                                    <Column
                                        field="phone"
                                        header="Phone"
                                        body={() => <Skeleton></Skeleton>}
                                    />
                                    <Column
                                        field="company_name"
                                        header="Company Name"
                                        body={() => <Skeleton></Skeleton>}
                                    />
                                    <Column
                                        field="group_id"
                                        header="Group"
                                        body={() => <Skeleton></Skeleton>}
                                    />
                                    <Column
                                        field="contact_stage"
                                        header="Contact Stage"
                                        body={() => <Skeleton></Skeleton>}
                                    />
                                    <Column
                                        header="Created At"
                                        body={() => <Skeleton></Skeleton>}
                                    />
                                    <Column
                                        header="Last Contacted"
                                        body={() => <Skeleton></Skeleton>}
                                    />
                                    <Column
                                        field="Action"
                                        header="Action"
                                        body={() => <Skeleton></Skeleton>}
                                    />
                                </DataTable>
                            ) : (
                                <>
                                    <DataTable
                                        lazy
                                        first={lazyState.first}
                                        filters={lazyState.filters}
                                        ref={dt}
                                        value={recipients}
                                        globalFilter={globalFilter}
                                        header={header}
                                        removableSort
                                        paginator
                                        onPage={onPage}
                                        selection={selectedRows}
                                        onSelectionChange={handleSelectionChange}
                                        onHeaderCheckboxToggle={handleHeaderCheckboxChange}
                                        onSort={onSort}
                                        sortField={lazyState.sortField}
                                        sortOrder={lazyState.sortOrder}
                                        rows={lazyState.rows}
                                        totalRecords={totalRecords}
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        tableStyle={{ minWidth: "50rem" }}
                                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                    >
                                        <Column
                                            selectionMode="multiple"
                                            headerStyle={{ width: "3rem" }}
                                        ></Column>
                                        <Column
                                            field="first_name"
                                            sortable
                                            header="First Name"
                                            body={bodyTemplate}
                                        ></Column>
                                        <Column
                                            field="last_name"
                                            sortable
                                            header="Last Name"
                                            body={bodyTemplate}
                                        ></Column>
                                        <Column
                                            field="phone"
                                            sortable
                                            header="Phone"
                                            body={phoneTemplate}
                                        ></Column>
                                        <Column
                                            field="company_name"
                                            sortable
                                            header="Company Name"
                                            body={bodyTemplate}
                                        ></Column>
                                        <Column
                                            field="group_names"
                                            sortable
                                            header="Group"
                                            body={groupNameTemplate}
                                        ></Column>
                                        <Column
                                            field="contact_stage"
                                            sortable
                                            header="Contact Stage"
                                            body={contactStageTemplate}
                                        ></Column>
                                        <Column
                                            field="timestamp"
                                            sortable
                                            header="Created At"
                                            body={(rowData) => convertTimestampToTimezone(rowData)}
                                        ></Column>
                                        <Column
                                            sortable
                                            header="Last Contacted"
                                            body={last_contacted_template}
                                        ></Column>
                                        <Column
                                            field="Action"
                                            header="Action"
                                            body={actionTemplate}
                                        ></Column>
                                    </DataTable>
                                </>
                            )}

                        </Card>

                        {/* Form section */}
                        <Dialog
                            header={"Bulk Upload Leads"}
                            visible={showBulkModal}
                            onHide={handleCloseBulkModal}
                            style={{ width: "50vw" }}
                            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                            draggable={false}
                            resizable={false}
                        >
                            {/* <div>
                                <h1>Upload Status</h1>
                                <div>
                                    <progress value={progress} max="100"></progress>
                                    <span>{progress}%</span>
                                </div>
                                <p>{message}</p>
                            </div> */}
                            {/* <div className="mb-4">
                    <ProgressBar 
                        value={progress} 
                        displayValueTemplate={valueTemplate}
                        className="h-8"
                    />
                </div> */}
                            <Form>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label>Upload</Form.Label>
                                    <Form.Control
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleUploadCsv}
                                        accept=".csv"
                                        disabled={fileSelected}
                                    />
                                    <Form.Text id="" muted>
                                        Download a{" "}
                                        <a href={sampleCSV} download>
                                            {" "}
                                            sample CSV file{" "}
                                        </a>{" "}
                                        .
                                    </Form.Text>

                                    <p className="text-danger">{formErrors.bulk_upload_file}</p>
                                </Form.Group>
                            </Form>

                            <div className="page-title-caption-box ">
                                <h6 className="page-title-caption mt-1">Note :- Only valid email addresses that are not already added by you or anyone in your company will be uploaded from your CSV file. All duplicate or invalid emails will be automatically excluded.</h6>
                            </div>

                            {isImportedLoading ? (
                                <DataTable
                                    value={[...Array(5)]} // Use an array of length 5 to render 5 rows of skeletons
                                    tableStyle={{ minWidth: "40rem" }}
                                    header={headerModal}
                                >
                                    <Column
                                        selectionMode="multiple"
                                        headerStyle={{ width: "3rem" }}
                                    ></Column>
                                    <Column
                                        field="email"
                                        header="Email"
                                        body={() => <Skeleton></Skeleton>}
                                    />
                                    <Column
                                        field="status"
                                        header="Status"
                                        body={() => <Skeleton></Skeleton>}
                                    />
                                </DataTable>
                            ) : (
                                <>
                                    {fileSelected ? (
                                        <div style={{ height: '50vh', overflow: "auto" }}>
                                            <DataTable
                                                ref={dt}
                                                value={bulkRecipients}
                                                lazy
                                                first={modalLazy.first}
                                                filters={modalLazy.filters}
                                                globalFilterModal={globalFilterModal}
                                                header={headerModal}
                                                selection={selectedRowsModal}
                                                onSelectionChange={handleSelectionChangeImport}
                                                onHeaderCheckboxToggle={handleHeaderCheckboxChangeImport}
                                                totalRecords={totalRecordsModal}
                                                selectionMode="checkbox"
                                                paginator
                                                rows={modalLazy.rows}
                                                onPage={onPageChange}
                                                // rowsPerPageOptions={[5, 10, 25, 50]}
                                                tableStyle={{ minWidth: "50rem" }}
                                                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                            >

                                                <Column
                                                    selectionMode="multiple"
                                                    headerStyle={{ width: "3rem" }}
                                                ></Column>
                                                <Column
                                                    field="email"
                                                    header="Email"
                                                    body={bodyTemplate}
                                                ></Column>
                                                <Column
                                                    field="status"
                                                    header="Status"
                                                    body={statusTemplate}
                                                ></Column>
                                            </DataTable>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                            <div className="d-flex justify-content-end mt-2">
                                <Button
                                    onClick={() => {
                                        setShowBulkModal(false)
                                        setFileSelected(false)
                                        setDisable(false)
                                        setSelectedRowsModal([])
                                        setGlobalFilterModal("")
                                        setSelectedFile(null)
                                        setBulkRecipients([])
                                    }}
                                    size="small"
                                    outlined
                                >
                                    Close
                                </Button>
                                <Button
                                    loading={fileSelected ? isLoading : isImportedLoading}
                                    label={fileSelected ? "Upload" : "Import"}
                                    onClick={fileSelected ? handleFileUploadOnClickBtn : handleImportCsvFile}
                                    icon="pi pi-check"
                                    size="small"
                                    className="ms-2"
                                />
                            </div>
                        </Dialog>

                        <Dialog
                            style={{ width: "40vw" }}
                            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                            header={currentRecipient._id ? "Update Lead" : "Add Lead"}
                            visible={showModal}
                            draggable={false}
                            resizable={false}
                            onHide={handleClose}
                        >
                            <Form>
                                <Row className="mb-3">
                                    <Col xs={6}>
                                        <Form.Group controlId="formFirstName">
                                            <Form.Label>
                                                First Name <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter First Name"
                                                value={currentRecipient.first_name}
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value &&
                                                        e.target.value.startsWith(" ")
                                                    ) {
                                                        return; // If it starts with a space, do not update the state
                                                    }
                                                    setCurrentRecipient({
                                                        ...currentRecipient,
                                                        first_name: e.target.value,
                                                    });
                                                }}
                                                maxLength={100}
                                            />
                                            {formErrors.first_name && (
                                                <Form.Text className="text-danger">
                                                    {formErrors.first_name}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group controlId="formLastName">
                                            <Form.Label>
                                                Last Name
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Last Name"
                                                value={currentRecipient.last_name}
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value &&
                                                        e.target.value.startsWith(" ")
                                                    ) {
                                                        return; // If it starts with a space, do not update the state
                                                    }
                                                    setCurrentRecipient({
                                                        ...currentRecipient,
                                                        last_name: e.target.value,
                                                    });
                                                }}
                                                maxLength={100}
                                            />
                                            {formErrors.last_name && (
                                                <Form.Text className="text-danger">
                                                    {formErrors.last_name}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs={6}>
                                        <Form.Group controlId="formEmail">
                                            <Form.Label>
                                                Email <span className="text-danger">*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter Email"
                                                value={currentRecipient.email || ""}
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value &&
                                                        e.target.value.startsWith(" ")
                                                    ) {
                                                        return; // If it starts with a space, do not update the state
                                                    } else {
                                                        setCurrentRecipient({
                                                            ...currentRecipient,
                                                            email: e.target.value,
                                                        });
                                                    }
                                                }}
                                            />
                                            {formErrors.email && (
                                                <Form.Text className="text-danger">
                                                    {formErrors.email}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group controlId="formPhone">
                                            <Form.Label>Phone</Form.Label>

                                            <PhoneInput
                                                country={"us"}
                                                inputStyle={{ width: '271px' }}
                                                enableSearch={true}
                                                value={currentRecipient.phone}
                                                countryCodeEditable={false}
                                                onChange={(phone) => {
                                                    setCurrentRecipient(prev => ({ ...prev, phone }))
                                                }}
                                            />


                                            {formErrors.phone && (
                                                <Form.Text className="text-danger">
                                                    {formErrors.phone}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs={6}>
                                        <Form.Group controlId="formCompanyName">
                                            <Form.Label>Company Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Company Name"
                                                value={currentRecipient.company_name}
                                                onChange={(e) => {
                                                    if (
                                                        e.target.value &&
                                                        e.target.value.startsWith(" ")
                                                    ) {
                                                        return; // If it starts with a space, do not update the state
                                                    } else {
                                                        setCurrentRecipient({
                                                            ...currentRecipient,
                                                            company_name: e.target.value,
                                                        });
                                                    }
                                                }}
                                                maxLength={100}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6}>
                                        <Form.Group controlId="formGroup">
                                            <Form.Label>
                                                Group <span className="text-danger">*</span>
                                            </Form.Label>

                                            <CreatableSelect
                                                isMulti
                                                value={groups?.filter(group => currentRecipient?.group_ids?.includes(group.value))}
                                                onChange={(e) => {
                                                    setCurrentRecipient({
                                                        ...currentRecipient,
                                                        group_ids: e.map(group => group.value),
                                                        group_names: e.map(group => group.label),
                                                    });
                                                }
                                                }
                                                options={groups}
                                                // onCreateOption={handleCreate}
                                                onCreateOption={(inputValue) => {
                                                    if (!inputValue.trim()) {
                                                        // alert("Invalid input: Group name cannot be empty or contain only spaces.");
                                                        return;
                                                    }
                                                    handleCreate(inputValue.trim()); // Pass trimmed value to handleCreate
                                                }}
                                            />
                                            {formErrors.group_name && (
                                                <Form.Text className="text-danger">
                                                    {formErrors.group_name}
                                                </Form.Text>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                            <div className="d-flex justify-content-end mt-3">
                                <Button onClick={handleClose} outlined size="small">
                                    Close
                                </Button>
                                <Button
                                    onClick={currentRecipient._id ? handleUpdate : handleAdd}
                                    loading={isLoading}
                                    icon="pi pi-check"
                                    label="Save"
                                    size="small"
                                    className="ms-2"
                                />
                            </div>
                        </Dialog>

                        <Dialog
                            header={
                                <div style={{ marginTop: '-0.5rem' }} className="d-flex justify-content-between align-items-center">
                                    <h5 className='mt-1'>Call</h5>
                                    <div className="d-flex align-items-center">
                                        {dialogState === "normal" && (
                                            <Button
                                                icon="pi pi-window-minimize"
                                                className="p-mr-2"
                                                text
                                                onClick={handleMaximize}
                                            />
                                        )}
                                        {dialogState === "minimized" && (
                                            <Button
                                                icon="pi pi-window-maximize"
                                                className="p-mr-2"
                                                text
                                                onClick={handleRestore}
                                            />
                                        )}
                                        {dialogState === "maximized" && (
                                            <Button
                                                icon="pi pi-window-maximize"
                                                className="p-mr-2"
                                                text
                                                onClick={handleRestore}
                                            />
                                        )}
                                        <span style={{ width: '8px' }}></span>
                                    </div>
                                </div>
                            }
                            visible={visible}
                            modal={true}
                            onHide={closeCallModal}
                            style={
                                dialogState === "normal"
                                    ? { width: "25vw", marginRight: "80px", marginBottom: "0" }
                                    : dialogState === "minimized"
                                        ? { display: "none" }
                                        : { width: "18vw", height: "8vh", marginRight: "80px", marginBottom: "0" }
                            }
                            position={
                                dialogState === "normal"
                                    ? "top-right"
                                    : dialogState === "minimized"
                                        ? ""
                                        : "bottom-right"
                            }
                            draggable={false}
                            resizable={false}
                            className={
                                dialogState === "normal"
                                    ? "dialog-top-right"
                                    : dialogState === "minimized"
                                        ? ""
                                        : "dialog-bottom-right"
                            }
                        >
                            <OutgoingCall
                                ref={callRef}
                                setVisible={setVisible}
                                callingId={callingId}
                                setcallDispositionModal={setcallDispositionModal}
                                setcallSid={setcallSid}
                                setcallDuration={setcallDuration}
                                settoNumber={settoNumber}
                                setfromNumber={setfromNumber}
                            />
                        </Dialog>

                        {callDispositionModal === true && <CallDisposition callDispositionModal={callDispositionModal} setcallDispositionModal={setcallDispositionModal} callSid={callSid} recipientName={recipientName} recipientId={recipientId} callDuration={callDuration} toNumber={toNumber} fromNumber={fromNumber} />}

                    </Container>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Recipients;
