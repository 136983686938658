import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const RealTimeCampaignChart = ({SequenceValues}) => {
    const [chartState, setChartState] = useState({
        series: [
            { name: 'Delivered', data: [] },
            { name: 'Bounces', data: [] },
            { name: 'Not Opened', data: [] }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                stackType: '100%',
                toolbar: {
                    show: false // Hide the toolbar menu
                  }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            title: {},
            xaxis: {
                categories: ['Campaign'],
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                offsetX: 40
            },
            yaxis: {
                labels: {
                    show: false
                }
            },
        },
    });

    const [secondChartState, setSecondChartState] = useState({
        series: [
            { name: 'Click/Open', data: [] },
            { name: 'Unsubscribes', data: [] }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                stackType: '100%',
                toolbar: {
                    show: false // Hide the toolbar menu
                  }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            title: {},
            xaxis: {
                categories: ['Campaign'],
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                offsetX: 40
            },
            yaxis: {
                labels: {
                    show: false
                }
            },
        },
    });

    useEffect(() => {
        if (SequenceValues || SequenceValues !== undefined) {
            setChartState(prevState => ({
                ...prevState,
                series: [
                    { name: 'Not Opened', data: [SequenceValues?.not_opened] },
                    { name: 'Bounces', data: [SequenceValues?.bounced] },
                    { name: 'Delivered', data: [SequenceValues?.sent] }
                  ]
            }));
            setSecondChartState(prevState => ({
                ...prevState,
                series: [
                    { name: 'Click/Open', data: [SequenceValues?.opened] },
                    { name: 'Unsubscribes', data: [SequenceValues?.opt_out] }
                  ]
            }));
        }
    }, [SequenceValues]);

    return (
        <div>
            <div id="first-chart">
                <ReactApexChart
                    options={chartState.options}
                    series={chartState.series}
                    type="bar"
                    height={150}
                />
            </div>
            <div id="second-chart">
                <ReactApexChart
                    options={secondChartState.options}
                    series={secondChartState.series}
                    type="bar"
                    height={150}
                />
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default RealTimeCampaignChart;
