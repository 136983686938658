import React, { useContext } from 'react';
import { Routes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { Container, Col, Row } from 'react-bootstrap';
import { Menu } from 'primereact/menu';
import AccountInfo from './AccountInfo';
import Schedules from './Schedules.js';
import ActivityLogs from './ActivityLogs';
import Contacts from './contacts.js';
import Footer from '../Components/Footer/Footer.js';
import Voice from './Voice.jsx';
import Account from './Account.js';
import { contextVar } from "../Components/ContextVar";

const Settings = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = React.useState('accountinfo'); // default to 'accountinfo'
  const location = useLocation();
  const { userGroup } = useContext(contextVar)

  React.useEffect(() => {
    document.title = 'Configurations'
    const currentPath = location.pathname.split('/').pop();

    switch (currentPath) {
      case 'accountinfo':
        setActiveItem('accountinfo');
        break;
      case 'schedules':
        setActiveItem('schedules');
        break;
      case 'activitylogs':
        setActiveItem('activitylogs');
        break;
      case 'contacts':
        setActiveItem('contacts');
        break;
      case 'callConfiguation':
        setActiveItem('callConfiguation');
        break;
      case 'account':
        setActiveItem('account');
        break;
      case 'voice':
        setActiveItem('voice');
        break;
      default:
        setActiveItem('accountinfo');
    }
  }, [location.pathname]);
  const items = [
    {
      label: 'Configurations',
      items: [
        {
          label: 'Mailboxes',
          icon: 'pi pi-user-edit',
          className: activeItem === 'accountinfo' ? 'active-menu-item' : '',
          command: () => {
            navigate('/settings/accountinfo');
            setActiveItem('accountinfo');
          }
        },
        ...(userGroup.schedules?.get_schedules ? [{
          label: 'Schedules',
          icon: 'pi pi-calendar',
          className: activeItem === 'schedules' ? 'active-menu-item' : '',
          command: () => {
              navigate('/settings/schedules');
              setActiveItem('schedules');
          }
        }] : []),
        ...(userGroup.activity_log?.get_activity_logs ? [{
          label: 'Activity Logs',
          icon: 'pi pi-book',
          className: activeItem === 'activitylogs' ? 'active-menu-item' : '',
          command: () => {
              navigate('/settings/activitylogs');
              setActiveItem('activitylogs');
          }
      }] : []),
        ...(userGroup.contact?.get_all_stages ? [{
          label: 'Contacts',
          icon: 'pi pi-book',
          className: activeItem === 'contacts' ? 'active-menu-item' : '',
          command: () => {
              navigate('/settings/contacts');
              setActiveItem('contacts');
          }
      }] : []),
    //   ...(userGroup.account?.get_all_account_stages ? [{
    //     label: 'Account',
    //     icon: 'pi pi-user-edit',
    //     className: activeItem === 'account' ? 'active-menu-item' : '',
    //     command: () => {
    //         navigate('/settings/account');
    //         setActiveItem('account');
    //     }
    // }] : []),
        // {
        //   label: 'Call Configuration',
        //   icon: 'pi pi-user-edit',
        //   className: activeItem === 'callConfig' ? 'active-menu-item' : '',
        //   command: () => {
        //     navigate('/settings/callConfig');
        //     setActiveItem('callConfig');
        //   }
        // },
        ...(userGroup.twilio?.get_call_list ? [{
          label: 'Voice',
          icon: 'pi pi-microphone',
          className: activeItem === 'voice' ? 'active-menu-item' : '',
          command: () => {
              navigate('/settings/voice');
              setActiveItem('voice');
          }
      }] : []),

      ]
    }
  ];


  return (
    <div className="content-page">
      <div className="content">
        <Container fluid>
          <Row>
            <Col>
              <div className="page-title-box">
                <h4 className="page-title">Settings</h4>
                <div className="page-title-right">
                  <div className="row g-2">
                    <div className="col-md-auto">
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={2} xl={2} md={3}>
              <div className="settings-sidebar">
                <Menu model={items} />
              </div>
            </Col>
            <Col lg={10} xl={10} md={9}>
              <Routes>
                <Route path="/" element={<Navigate to="accountinfo" replace />} />
                <Route path="accountinfo" element={<AccountInfo />} />
                <Route path="contacts" element={<Contacts />} />
                {/* <Route path="account" element={<Account />} /> */}
                <Route path="schedules" element={<Schedules />} />
                <Route path="activitylogs" element={<ActivityLogs />} />
                <Route path="voice" element={<Voice />} />
                {/* <Route path="callConfig" element={<CallConfig />} /> */}
              </Routes>
            </Col>
          </Row>

        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Settings;
