// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MyComponent.css */
.my-component {
    text-align: center;
    margin: 50px auto;
  }
  .logo {
    max-width: 100px;
  }
  
  .banner-img {
    max-width: 100%;
  }
  .LOGO-Template_Dummy_Tepmplate {
    width: 120px;
    /* height: 30px; */
    background: #ccc;
    padding: 6px 4px;
    margin: 0 auto 30px;
}
.banner-Template_Dummy_Tepmplate {
    background: #ccc;
    height: 100px;
    margin-top: 30px;
}
.Template_Dummy_Tepmplate{
    text-align: center;
    border: 1px solid #ccc;
    padding: 22px 28px;
    
}
.Template_Dummy_Tepmplate h2 {
    font-size: 22px;
}
.text-Template_Dummy_Tepmplate {
    text-align: left;
    margin: 41px 0;
}
.text-Template_Dummy_Tepmplate h3 {
    font-size: 18px;
    color: #585858;
}
.text-Template_Dummy_Tepmplate p{
    font-size: 14px;
    color: grey;
}
.Template_Dummy_Tepmplate {
    text-align: center;
    border: 1px solid #ccc;
    padding: 22px 28px;
    margin-right: 25px;
}
footer {
    background: #eff7ff;
    padding: 29px;
}
.Template_Dummy_Tepmplate button {
    background: #484848;
    border: none;
}
.Template_Dummy_Tepmplate button {
    background: #484848;
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/templates/domytemplate/DomyTemplate.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,kBAAkB;IAClB,iBAAiB;EACnB;EACA;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;EACA;IACE,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,aAAa;IACb,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;;AAEtB;AACA;IACI,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,eAAe;IACf,cAAc;AAClB;AACA;IACI,eAAe;IACf,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":["/* MyComponent.css */\n.my-component {\n    text-align: center;\n    margin: 50px auto;\n  }\n  .logo {\n    max-width: 100px;\n  }\n  \n  .banner-img {\n    max-width: 100%;\n  }\n  .LOGO-Template_Dummy_Tepmplate {\n    width: 120px;\n    /* height: 30px; */\n    background: #ccc;\n    padding: 6px 4px;\n    margin: 0 auto 30px;\n}\n.banner-Template_Dummy_Tepmplate {\n    background: #ccc;\n    height: 100px;\n    margin-top: 30px;\n}\n.Template_Dummy_Tepmplate{\n    text-align: center;\n    border: 1px solid #ccc;\n    padding: 22px 28px;\n    \n}\n.Template_Dummy_Tepmplate h2 {\n    font-size: 22px;\n}\n.text-Template_Dummy_Tepmplate {\n    text-align: left;\n    margin: 41px 0;\n}\n.text-Template_Dummy_Tepmplate h3 {\n    font-size: 18px;\n    color: #585858;\n}\n.text-Template_Dummy_Tepmplate p{\n    font-size: 14px;\n    color: grey;\n}\n.Template_Dummy_Tepmplate {\n    text-align: center;\n    border: 1px solid #ccc;\n    padding: 22px 28px;\n    margin-right: 25px;\n}\nfooter {\n    background: #eff7ff;\n    padding: 29px;\n}\n.Template_Dummy_Tepmplate button {\n    background: #484848;\n    border: none;\n}\n.Template_Dummy_Tepmplate button {\n    background: #484848;\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
