import React, { useContext, useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import MainDataTable from '../../Common/DataTable/MainDataTable';
import ApiListFile from '../../Components/ApiListFile';
import ApiHeader, { fetchMethod } from '../../Components/ApiHeader';
import { Skeleton } from 'primereact/skeleton';
import { contextVar } from '../../Components/ContextVar';

const RecipientActivities = (props) => {

    const sequenceId = props?.sequenceId
    const { API_EMAIL_SEQUENCE_STATS } = ApiListFile()
    const { setnetworkError } = useContext(contextVar)

    const [activeTab, setActiveTab] = useState(0);
    const [statsOfRecipient, setstatsOfRecipient] = useState('')
    const [loading, setloading] = useState(true)
    
    const tabHeaders = [
        { label: 'Scheduled', count: (Math.max(statsOfRecipient?.scheduled || 0)) },
        { label: 'Delivered', count: (statsOfRecipient?.sent || 0) },
        { label: 'Opened', count: (statsOfRecipient?.opened || 0) },
        { label: 'Not Opened', count: (statsOfRecipient?.not_opened || 0) },
        { label: 'Replied', count: (statsOfRecipient?.replied || 0) },
        { label: 'Unsubscribed', count: (statsOfRecipient?.opt_out || 0) },
        { label: 'Bounced', count: (statsOfRecipient?.bounced || 0) },
    ];

    const handleTabChange = (event) => {
        setActiveTab(event.index);
    };

    useEffect(() => {
        setloading(true)
        fetchMethod("GET",`${API_EMAIL_SEQUENCE_STATS}/${sequenceId}`,{})
            .then((data) => {
                setstatsOfRecipient(data);
                setloading(false)
            })
            .catch((error) => {
                console.error(error);
                if (error instanceof TypeError) {
                    setnetworkError(true)
                    setloading(false);
                    return;
                }
            });
    }, [])

    return (
        <div className='Recipient-Activities-tabs'>
            {loading ? (
                <>
                    <div className="skeleton-container" style={{ display: 'flex' }}>
                        {tabHeaders.map((header, index) => (
                            <div key={index} style={{ marginRight: '20px' }}>
                                <Skeleton height="20px" width="100px" />
                                {/* Adjust width and styling as needed */}
                            </div>
                        ))}
                    </div>
                    <div className='mt-4'>
                    <MainDataTable />
                    </div>
                </>
            ) : (
                <TabView activeIndex={activeTab} onTabChange={handleTabChange}>
                    {tabHeaders?.map((items, index) => (
                        <TabPanel key={index} header={<>{items?.label} <span className='Recipient-tab-header-span'>{items?.count}</span></>} >
                            <MainDataTable sequenceId={sequenceId} activeTab={index} />
                        </TabPanel>
                    ))}
                </TabView>
            )}
        </div>
    );
};

export default RecipientActivities;
