import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, ButtonGroup, Container, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import moment from 'moment';
import { Button } from 'primereact/button'
import FeatherIcon from "feather-icons-react";
import ComposeDialog from '../utils/ComposeDialog';
import "./Inbox.scss"
import { InputText } from 'primereact/inputtext';
import Footer from '../Components/Footer/Footer';
import ApiListFile from '../Components/ApiListFile';
import ApiHeader, { fetchMethod } from '../Components/ApiHeader';
import Details from './Details';
import { contextVar } from '../Components/ContextVar';

const formatDateDisplay = (dateString) => {
  const date = moment(dateString);

  if (date.isSame(moment(), 'day')) {
    // If the date is today, return the time
    return date.format('HH:mm'); // This will return in 24-hour format like 15:30
  } else {
    // If the date is from a previous day, return the date
    return date.format('D MMM'); // This will return in format like 7 Sep
  }
};

const Inbox = ({ initialFolderId }) => {
  const {
    API_FOLDER_LIST,
    API_SYNC_MAILBOX,
    API_DISPLAY_SIGNATURE,
    API_DELETE_INBOX_MAIL,
    API_INBOX
  } = ApiListFile()

  const { setnetworkError } = useContext(contextVar)

  const [emails, setEmails] = useState([]);
  const [emailIndex, setEmailIndex] = useState(1);
  const [emailId, setEmailId] = useState();
  const [totalEmails, setTotalEmails] = useState(0);
  const [totalSearchCount, setTotalSearchCount] = useState(0);
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(10);
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [loading, setLoading] = useState(true)
  const [folderloading, setFolderLoading] = useState(true)
  const toast = useRef(null);
  const [activeFolder, setActiveFolder] = useState(null)
  const [currentFolderId, setCurrentFolderId] = useState(initialFolderId || null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [visible, setVisible] = useState(false);
  const [currentMailbox, setCurrentMailbox] = useState("")
  const [inputValue, setInputValue] = useState('');
  const { folderId } = useParams();
  const [searchQuery, setSearchQuery] = useState('');
  const [errorMsg, setErrorMsg] = useState("")
  const [syncLoading, setSyncLoading] = useState(false)
  const [email_provider, setEmailProvider] = useState('')
  const [showGmailModal, setShowGmailModal] = useState(false);
  const handleLinkMailbox = () => {
    // handleLinkMailbox 
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // replace with your client id
    const redirectUri = process.env.REACT_APP_URL + '/inbox'; // replace with your redirect uri
    const scope = encodeURIComponent(
      "https://www.googleapis.com/auth/gmail.send " +
      "https://www.googleapis.com/auth/userinfo.email"
    );
    const responseType = "code";
    const accessType = "offline";
    const prompt = "consent";

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&access_type=${accessType}&prompt=${prompt}`;

    // Redirect user to Google's OAuth 2.0 server
    window.location.href = authUrl;
  };


  // Function to handle input changes
  const handleInputChange = (e) => {
    setInputValue(e.target.value); // Update the input state on every key press
  };


  // Function to handle search on Enter press
  const handleSearchOnEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission or any default action
      setSearchQuery(inputValue.trim()); // Update the search query state with the trimmed input value
      setEmailIndex(1);
      setStartIndex(1);
      setEndIndex(10);
    }
  };

  // useEffect(() => {
  //   fetchInbox()
  // }, [emailIndex])

  useEffect(() => {
    if (email_provider == 'google') {
      setShowGmailModal(true);
    }
  }, [email_provider]);

  console.log("email provider", email_provider)
  console.log("email provider loader", showGmailModal)

  const hideGmailModal = () => {
    setShowGmailModal(false)
    setEmailProvider('')
  }
  const GmailModal = () => (
    <Dialog
      visible={showGmailModal}
      onHide={hideGmailModal}
      header="Coming Soon"
      modal
      style={{ width: '50vw' }}
    >
      <p>Inbox in Gmail is coming soon.</p>
    </Dialog>
  );

  const handleLinkOutlookMailbox = () => {
    const clientId = process.env.REACT_APP_OUTLOOK_CLIENT_ID; // replace with your client id
    const redirectUri = process.env.REACT_APP_OUTLOOK_REDIRECT_URI; // replace with your redirect uri
    // const scope = encodeURIComponent(
    //   "https://graph.microsoft.com/Mail.Send " +
    //   "https://graph.microsoft.com/User.Read offline_access https://graph.microsoft.com/Mail.Read"
    // );
    const scope = encodeURIComponent(
      "https://graph.microsoft.com/Mail.Send " +
      "https://graph.microsoft.com/User.Read " +
      "https://graph.microsoft.com/Mail.Read " +
      "https://graph.microsoft.com/Mail.ReadWrite " +
      "offline_access"
    );
    const responseType = "code";
    const responseMode = "query";
    const prompt = "consent";

    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&response_mode=${responseMode}&prompt=${prompt}`;

    // Redirect user to Microsoft's OAuth 2.0 server
    window.location.href = authUrl;
  };

  const handleLinkZohoMailbox = () => {
    const base_url = "https://accounts.zoho.com/oauth/v2/auth";
    const client_id = process.env.REACT_APP_ZOHO_CLIENT_ID; // Replace with your client ID
    const redirect_uri = encodeURIComponent(process.env.REACT_APP_ZOHO_REDIRECT_URI);
    const scope = encodeURIComponent("ZohoMail.messages.ALL");
    const state = "SalesAutomation"; // Replace with your unique state token

    const authUrl = `${base_url}?response_type=code&client_id=${client_id}&scope=${scope}&redirect_uri=${redirect_uri}&access_type=offline&state=${state}`;

    window.location.href = authUrl;
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked && selectedRows) {
      // If checkbox is checked, add its value to the state
      setSelectedRows(prevState => [...prevState, value]);
    } else {
      // If checkbox is unchecked, remove its value from the state
      setSelectedRows(prevState =>
        prevState.filter(item => item !== value)
      );
    }
  };

  const handleCheckboxClick = (event) => {
    event.stopPropagation()
  }

  useEffect(() => {
    if (folderId) {
      setActiveFolder(folderId)
      setCurrentFolderId(folderId);
    }
  }, [folderId]);

  const [folders, setFolders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Set document title
        document.title = "Inbox";

        // Set loading states
        setFolderLoading(true);
        const response = await fetch(API_FOLDER_LIST, {
          method: 'GET',
          headers: ApiHeader()
        });

        if (!response.ok) {
          throw new Error('Failed to fetch folder list');
        }

        const data = await response.json();
        if (data.status === "error") {
          throw new Error(data.message);
        }
        setFolders(data.folders);
        setCurrentMailbox(data.current_mailbox);
        if (!initialFolderId) {
          const inboxFolder = data.folders.find(folder => folder.displayName === "Inbox");
          if (inboxFolder) {
            setCurrentFolderId(inboxFolder.id);
          }
        }

        // Set loading states
        setFolderLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error instanceof TypeError) {
          setnetworkError(true);
          setLoading(false);
        } else {
          setErrorMsg(error.message);
        }
      }
    };

    fetchData();
  }, [currentMailbox, initialFolderId]); // Dependencies


  const handleMailBox = (emailId) => {
    setEmailId(emailId)
    setEmailIndex(2)
  }


  const Email = ({ email, searchTerm }) => {
    const highlightedSubject = highlightMatch(email.subject, searchTerm);
    const highlightedBodyPreview = highlightMatch(email.bodyPreview, searchTerm);
    const highlightedSenderName = email.sender ? highlightMatch(email.sender.emailAddress.name, searchTerm) : '';

    return (
      <li className={!email.isRead ? 'unread' : ''} onClick={() => handleMailBox(email.id)}>
        <>
          <div className="col-mail col-mail-1">
            <input className="form-check-input form-check-input-sales-automation-inbox-page"
              checked={selectedRows.indexOf(email._id) !== -1} onChange={handleCheckboxChange}
              onClick={handleCheckboxClick}
              type="checkbox" value={email._id} id={'mail' + email._id} />

            <label className="toggle" htmlFor={'mail' + email.id}></label>
            {email.sender &&
              <Link to='' className="title">
                {highlightedSenderName}
              </Link>}
          </div>


          <div className="col-mail col-mail-2" >
            <Link to='' className="subject">
              <span>{highlightedSubject}</span> &nbsp;&ndash;&nbsp;
              <span className="teaser">{highlightedBodyPreview}</span>
            </Link>
            <div className="email-details">
              <span className="sender-name">{email?.sender?.name}</span>
              {email.hasAttachments && <FeatherIcon icon="paperclip" size={16} />}
              {email.isImportant && <FeatherIcon icon="star" size={16} style={{ color: 'gold' }} />}
            </div>

            {/* Adjusted position for email date */}
            <div className="date">
              {formatDateDisplay(email.sentDateTime)}
            </div>
          </div>
        </>
      </li>
    );
  };

  const handleFolderClick = (folderId) => {
    // Set the current folder ID
    setCurrentFolderId(folderId);
    setActiveFolder(folderId)
    // Reset the startIndex and endIndex
    setStartIndex(1);
    // setEndIndex(20);
  };

  const renderFolder = (folder, level = 0) => {
    return (
      <React.Fragment key={folder.id}>
        <Link
          onClick={() => handleFolderClick(folder.id)}
          className={"list-group-item border-0 " + (folder.id == activeFolder ? 'active' : '')}
          style={{ paddingLeft: `${level * 20}px` }}
        >
          <i className="uil uil-folder fs-15 me-1"></i>
          {folder.displayName}
        </Link>
        {folder.childFolders?.map(childFolder => renderFolder(childFolder, level + 1))}
      </React.Fragment>
    );
  };

  const display_signature = () => {
    fetch(`${API_DISPLAY_SIGNATURE}`, {
      headers: ApiHeader()
    })
      .then((res) => res.json())
      .then((data) => {
        setCurrentTemplate({ ...currentTemplate, template_body: data.signature });
      })
      .catch((error) => console.error('Error fetching signature:', error.message))
  }

  // const fetchInbox = () => {
  //   if (searchQuery) {
  //     setLoading(true);
  //     fetch(`${API_INBOX}`, {
  //       method: 'POST',
  //       headers: ApiHeader(),
  //       body: JSON.stringify({
  //         searchQuery: searchQuery, skip_count: startIndex - 1,
  //         folder_id: currentFolderId
  //       }),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (!data.error) {
  //           setEmails(data.emails);
  //           setTotalSearchCount(data.count)
  //           setEndIndex(Math.min(startIndex + 19, data.count));
  //         } else {
  //           console.error('Error:', data.error);
  //         }
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         if (error instanceof TypeError) {
  //           setnetworkError(true)
  //           setLoading(false);
  //           return;
  //         }
  //       })
  //       .finally(() => {
  //         setLoading(false); // End loading
  //       });
  //   } else {
  //     if (currentFolderId != null) {
  //       setActiveFolder(currentFolderId)
  //       setLoading(true);
  //       var myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/json");
  //       myHeaders.append("x-api-key", localStorage.getItem("api_key"));

  //       var raw = JSON.stringify({
  //         skip_count: startIndex - 1,
  //         folder_id: currentFolderId
  //       });

  //       var requestOptions = {
  //         method: "POST",
  //         headers: ApiHeader(),
  //         body: raw,
  //         redirect: "follow",
  //       };
  //       fetch(`${process.env.REACT_APP_API_HOST}/inbox`, requestOptions)
  //         .then((response) => response.json())
  //         .then((result) => {
  //           setEmailProvider(result?.email_provider);
  //           setEmails(result?.emails);
  //           setTotalEmails(result.count);
  //           setEndIndex(prevEndIndex => Math.min(prevEndIndex, result.count));
  //           setLoading(false);

  //         })
  //         .catch((error) => {
  //           console.error(error);
  //           if (error instanceof TypeError) {
  //             setnetworkError(true)
  //             setLoading(false);
  //             return;
  //           }
  //         })
  //     }
  //   }
  // }




  useEffect(() => {
    fetchInbox();
    setEmailIndex(1);
  }, [startIndex, endIndex, currentFolderId, searchQuery, emailIndex]);

  const fetchInbox = () => {
    setLoading(true);

    const requestBody = {
      skip_count: startIndex - 1,
      folder_id: currentFolderId,
      ...(searchQuery && { searchQuery }) // Conditionally add searchQuery if it exists
    };

    fetchMethod("POST", `${API_INBOX}`, requestBody)
      .then((data) => {
        if (data) {
          // Set emails based on whether a search query is used
          if (searchQuery) {
            setEmails(data.emails);
            setTotalSearchCount(data.count);
          } else {
            setEmailProvider(data.email_provider);
            setEmails(data.emails);
            setTotalEmails(data.count);
          }

          // Consistent end index setting
          setEndIndex(Math.min(startIndex + 19, data.count));
        } else {
          console.error('Error:', data?.error);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true);
        }
      })
      .finally(() => {
        setLoading(false); // Ensure loading is set to false in all cases
      });
  };





  // useEffect(() => {
  //   fetchInbox();
  //   setEmailIndex(1)
  // }, [startIndex, endIndex, currentFolderId, searchQuery,emailIndex]);

  useEffect(() => {
    // Check if the inputValue is empty and searchQuery is not already empty
    // to prevent unnecessary fetches
    if (inputValue === '' && searchQuery !== '') {
      setSearchQuery(''); // Reset the search query state
      setStartIndex(1); // Reset pagination to the first page
      setEndIndex(10);
      setEmailIndex(1);
    }
  }, [inputValue]); // Depend on inputValue so this effect runs every time it changes

  useEffect(() => {
    display_signature()

  }, [])

  const handleSync = async () => {
    setSyncLoading(true);
    try {
      const syncResponse = await fetch(`${API_SYNC_MAILBOX}/${currentMailbox}`, {
        method: 'GET',
        headers: ApiHeader(),
      });

      const syncResult = await syncResponse.json();
      if (syncResult.status === 'success') {
        // After syncing, fetch the updated list of emails
        fetchInbox(); // This assumes fetchInbox is correctly implemented to update the emails state
      } else {
        // Handle case where sync is not successful
        toast.current.show({
          severity: "error",
          summary: "Sync Failed",
          detail: syncResult.message,
        });
      }
    } catch (error) {
      console.error('Error syncing mailbox:', error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while syncing the mailbox.",
      });
    } finally {
      setSyncLoading(false);
    }
  };

  const handleDeleteMails = () => {
    // setSyncLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", localStorage.getItem('api_key'));
    var raw = JSON.stringify({
      "email_ids": selectedRows
    });
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${API_DELETE_INBOX_MAIL}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status) {
          setLoading(false);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Emails Deleted Successfully",
          });
          setSelectedRows([])
          fetchInbox();
        }
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setLoading(false);
          return;
        }
      })

  }

  const escapeRegExp = (text) => {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  };

  const highlightMatch = (text, query) => {
    if (!query) return text; // If there's no query, return the original text
    const escapedQuery = escapeRegExp(query);
    const parts = text.split(new RegExp(`(${escapedQuery})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ?
        <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> :
        part
    );
  };

  return (
    <>
      <div className="content-page">
        <div className="content">
          <Container fluid>
            {errorMsg ? (
              // <p style={{marginTop: '50px'}}>{errorMsg}</p>
              <div className="NoDefaultMailboxFound">
                <h4>Link your mailbox to get started</h4>
                <p>Setup your mailbox to scale your outbond and do more with sequence,conversations, meeting and other features. </p>
                <button onClick={() => setVisible(true)}><FeatherIcon icon="link-2" size={20} /> Link Mailbox</button>

                <Dialog
                  visible={visible}
                  onHide={() => setVisible(false)}
                  style={{ width: "30vw" }}
                  breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                  draggable={false}
                  resizable={false}
                >
                  <h4>Link your mailbox to get started</h4>
                  <p>
                    By connecting your mailbox, you'll be able to capture all your email
                    engagement in one spot. Based on your plan, you can send emails via
                    using Gmail, Microsoft Exchange, Office 365, Outlook, or your own
                    custom server. Learn more.
                  </p>
                  <Button
                    label="Link Gmail Mailbox"
                    size="small"
                    outlined
                    icon="pi pi-google"
                    className="me-4"
                    onClick={handleLinkMailbox}
                  />
                  <Button
                    label="Link Outlook Mailbox"
                    size="small"
                    outlined
                    className="me-4"
                    icon="pi pi-microsoft"
                    onClick={handleLinkOutlookMailbox}
                  />
                </Dialog>

              </div>

            ) : (
              <>
                <Toast ref={toast} />
                <Row>
                  <Col>
                    <div className="page-title-box">
                      <h4 className="page-title">Email Inbox</h4>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="page-title-caption-box">
                      <h6 className="page-title-caption">A centralized inbox that integrates responses from campaigns and allows direct interaction with contacts for efficient communication management.</h6>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="email-container bg-transparent">
                      {/* Sidebar menu */}
                      {loading &&
                        <Card className="inbox-leftbar">
                          <ComposeDialog />
                          <div className="mail-list mt-4">
                            {folderloading ? (<><Skeleton width="10rem" height="1.5rem" className="mb-3"></Skeleton><Skeleton width="10rem" height="1.5rem" className="mb-3"></Skeleton><Skeleton width="10rem" height="1.5rem" className="mb-3"></Skeleton></>) : <>
                              <p className='mb-3 currentMailbox'>{currentMailbox}</p>
                              {folders.map(folder => renderFolder(folder))}
                            </>}
                          </div>
                        </Card>
                      }
                      <div className="inbox-rightbar">
                        {/* buttons */}
                        <ButtonGroup className="d-inline-block col-md-3 mb-3">
                          <Button onClick={handleSync} variant="light" size="small" className="mx-2">
                            <OverlayTrigger placement="top" overlay={<Tooltip id="spam">Sync</Tooltip>}>
                              <i style={{ height: "21px", lineHeight: "20px" }} className={syncLoading === true ? `pi pi-spin pi-refresh` : `pi pi-refresh `} ></i>
                            </OverlayTrigger>
                          </Button>
                          <OverlayTrigger placement="top" overlay={<Tooltip id="archived">Archived</Tooltip>}>
                            {({ ref, ...triggerHandler }) => (
                              <Button ref={ref} {...triggerHandler} variant="light" size="small">
                                <i className="uil uil-archive-alt"></i>
                              </Button>
                            )}
                          </OverlayTrigger>
                          <Button variant="light" size="small" className="mx-2">
                            <OverlayTrigger placement="top" overlay={<Tooltip id="spam">Spam</Tooltip>}>
                              <i className="uil uil-exclamation-octagon"></i>
                            </OverlayTrigger>
                          </Button>
                          <OverlayTrigger placement="top" overlay={<Tooltip id="delete">Delete</Tooltip>}>
                            <Button variant="light" size="small" onClick={handleDeleteMails}>
                              <i className="uil uil-trash-alt"></i>
                            </Button>
                          </OverlayTrigger>
                        </ButtonGroup>

                        {/* search field */}
                        <div className="d-inline-block align-middle mb-2 col-md-3">
                          <Form.Group className="">
                            <InputText
                              type="search"
                              value={inputValue} // Bind the input field to the inputValue state
                              className="form-control-md p-2"
                              placeholder="Search..."
                              onChange={handleInputChange} // Handle changes to update inputValue
                              onKeyDown={handleSearchOnEnter} // Only trigger search on Enter press
                            />
                          </Form.Group>
                        </div>

                        {loading ? (
                          <>
                            <ul className="message-list">
                              <li>
                                <div className="col-mail col-mail-1">
                                  <Skeleton width="10rem" height="15px" className="m-3" />
                                </div>
                                <div className="col-mail col-mail-2">
                                  <Skeleton width="80%" height="15px" className="my-3" />
                                  <div className="date">
                                    <Skeleton width="5rem" height="15px" className="my-3" />
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="col-mail col-mail-1">
                                  <Skeleton width="10rem" height="15px" className="m-3" />
                                </div>
                                <div className="col-mail col-mail-2">
                                  <Skeleton width="80%" height="15px" className="my-3" />
                                  <div className="date">
                                    <Skeleton width="5rem" height="15px" className="my-3" />
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="col-mail col-mail-1">
                                  <Skeleton width="10rem" height="15px" className="m-3" />
                                </div>
                                <div className="col-mail col-mail-2">
                                  <Skeleton width="80%" height="15px" className="my-3" />
                                  <div className="date">
                                    <Skeleton width="5rem" height="15px" className="my-3" />
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="col-mail col-mail-1">
                                  <Skeleton width="10rem" height="15px" className="m-3" />
                                </div>
                                <div className="col-mail col-mail-2">
                                  <Skeleton width="80%" height="15px" className="my-3" />
                                  <div className="date">
                                    <Skeleton width="5rem" height="15px" className="my-3" />
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="col-mail col-mail-1">
                                  <Skeleton width="10rem" height="15px" className="m-3" />
                                </div>
                                <div className="col-mail col-mail-2">
                                  <Skeleton width="80%" height="15px" className="my-3" />
                                  <div className="date">
                                    <Skeleton width="5rem" height="15px" className="my-3" />
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="col-mail col-mail-1">
                                  <Skeleton width="10rem" height="15px" className="m-3" />
                                </div>
                                <div className="col-mail col-mail-2">
                                  <Skeleton width="80%" height="15px" className="my-3" />
                                  <div className="date">
                                    <Skeleton width="5rem" height="15px" className="my-3" />
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="col-mail col-mail-1">
                                  <Skeleton width="10rem" height="15px" className="m-3" />
                                </div>
                                <div className="col-mail col-mail-2">
                                  <Skeleton width="80%" height="15px" className="my-3" />
                                  <div className="date">
                                    <Skeleton width="5rem" height="15px" className="my-3" />
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="col-mail col-mail-1">
                                  <Skeleton width="10rem" height="15px" className="m-3" />
                                </div>
                                <div className="col-mail col-mail-2">
                                  <Skeleton width="80%" height="15px" className="my-3" />
                                  <div className="date">
                                    <Skeleton width="5rem" height="15px" className="my-3" />
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="col-mail col-mail-1">
                                  <Skeleton width="10rem" height="15px" className="m-3" />
                                </div>
                                <div className="col-mail col-mail-2">
                                  <Skeleton width="80%" height="15px" className="my-3" />
                                  <div className="date">
                                    <Skeleton width="5rem" height="15px" className="my-3" />
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="col-mail col-mail-1">
                                  <Skeleton width="10rem" height="15px" className="m-3" />
                                </div>
                                <div className="col-mail col-mail-2">
                                  <Skeleton width="80%" height="15px" className="my-3" />
                                  <div className="date">
                                    <Skeleton width="5rem" height="15px" className="my-3" />
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </>
                        ) : (
                          <>


                            <div className="d-inline-block align-middle float-lg-end mb-2 col-md-3">
                              <Row>
                                {/* {totalEmails > 0 &&
                                    <> */}
                                {
                                  searchQuery && searchQuery.length > 0 ? (
                                    <Col xs={8} className="align-self-center text-end mail-totalCount">
                                      Showing {startIndex} - {endIndex} of {totalSearchCount}
                                    </Col>
                                  ) :
                                    (
                                      <Col xs={8} className="align-self-center text-end mail-totalCount">
                                        Showing {startIndex} - {endIndex} of {totalEmails}
                                      </Col>
                                    )
                                }

                                <Col xs={4}>
                                  <ButtonGroup className="float-end">
                                    {startIndex === 1 ? (
                                      <Button variant="white" className="btn-sm me-2" disabled>
                                        <i className="uil uil-angle-left"></i>
                                      </Button>
                                    ) : (
                                      <Button variant="primary" className="btn-sm me-2" onClick={() => {
                                        const newStartIndex = Math.max(1, startIndex - 10);
                                        const newEndIndex = Math.max(10, endIndex - 10);

                                        setStartIndex(newStartIndex);
                                        setEndIndex(newEndIndex);
                                      }}>
                                        <i className="uil uil-angle-left"></i>
                                      </Button>

                                    )}
                                    {searchQuery && searchQuery.length > 0 ? (
                                      endIndex >= totalSearchCount ? (
                                        <Button variant="white" className="btn-sm" disabled>
                                          <i className="uil uil-angle-right"></i>
                                        </Button>
                                      ) : (
                                        <Button variant="primary" className="btn-sm" onClick={() => { setStartIndex(prevIndex => prevIndex + 10) }}>
                                          <i className="uil uil-angle-right"></i>
                                        </Button>
                                      )
                                    ) : (
                                      endIndex >= totalEmails ? (
                                        <Button variant="white" className="btn-sm" disabled>
                                          <i className="uil uil-angle-right"></i>
                                        </Button>
                                      ) : (
                                        <Button variant="primary" className="btn-sm" onClick={() => { setStartIndex(startIndex + 10); setEndIndex(endIndex + 10) }}>
                                          <i className="uil uil-angle-right"></i>
                                        </Button>
                                      )
                                    )}
                                  </ButtonGroup>
                                </Col>

                              </Row>
                            </div>

                            {email_provider == "google" ? (
                              <GmailModal />
                            ) :
                              <>

                                {(email_provider == "outlook" && emails.length == 0) ? <h6>Your data is syncing...</h6> :
                                  searchQuery.length > 0 && totalSearchCount === 0 ? (
                                    <div className="text-center no-match-inbox">
                                      <h6>No Matches Found</h6>
                                    </div>
                                  ) : (
                                    <div className="mt-2">
                                      {emailIndex == 1 &&
                                        <ul className="message-list">
                                          {emails && emails?.map((email, idx) => (
                                            <Email key={idx} email={email} searchTerm={searchQuery} />
                                          ))}
                                        </ul>
                                      }
                                      {emailIndex == 2 &&
                                        <>
                                          <Details emailId={emailId} setEmailIndex={setEmailIndex} />
                                        </>
                                      }
                                    </div>
                                  )
                                }
                              </>
                            }
                          </>
                        )}
                        {syncLoading &&
                          <div style={{ position: 'fixed', display: 'inline-block ', backgroundColor: '#454545', padding: '6px', borderRadius: '5px', right: "39%", bottom: '18px', zIndex: 999999 }}>
                            <i className="uil uil-envelope" style={{ color: 'white', fontSize: '15px' }}></i>
                            <h6 style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '4px', marginBottom: '2px', color: 'white' }}>Syncing...</h6>
                          </div>
                        }

                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </Col>
                </Row>
              </>)}
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Inbox;
