import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Form,
  Container,
  Col,
  Row,
  Card,
} from "react-bootstrap";
import Select from "react-select";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { Badge } from "primereact/badge";
import moment from "moment-timezone";
import Footer from "../Components/Footer/Footer";
import ApiListFile from "../Components/ApiListFile";
import ApiHeader, { fetchMethod } from "../Components/ApiHeader";
import { contextVar } from "../Components/ContextVar";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { MdCalendarToday } from "react-icons/md";
import { Dropdown } from "primereact/dropdown";

const Tasks = () => {
  let timezone_mapping = {
    "Pacific Time": "America/Los_Angeles",
    "Mountain Time": "America/Denver",
    "Central Time": "America/Chicago",
    "Eastern Time": "America/New_York",
    "UTC": "UTC",
    "Europe/London": "Europe/London",
    "Paris": "Europe/Paris",
    "Athens": "Europe/Athens",
    "Australia Eastern Standard Time": "Australia/Sydney",
    "Australia Western Standard Time": "Australia/Perth",
    "Australia Central Standard Time": "Australia/Adelaide",
    "Middle East Time": "Asia/Tehran",
    "Near East Time": "Asia/Dubai",
    "Pakistan Lahore Time": "Asia/Karachi",
    "Indian Standard Time": "Asia/Kolkata",
    "Bangladesh Standard Time": "Asia/Dhaka",
    "Vietnam Standard Time": "Asia/Ho_Chi_Minh",
    "Japan Standard Time": "Asia/Tokyo",
    "Solomon Standard Time": "Pacific/Guadalcanal",
    "New Zealand Standard Time": "Pacific/Auckland",
    "Midway Islands Time": "Pacific/Midway",
    "Hawaii Standard Time": "Pacific/Honolulu",
    "Alaska Standard Time": "America/Anchorage",
    "Puerto Rico and US Virgin Islands Time": "America/Puerto_Rico",
    "Canada Newfoundland Time": "America/St_Johns",
    "Brazil Eastern Time": "America/Sao_Paulo"
  }

  const [timezone, setTimezone] = useState('UTC')
  const [timezoneList, settimeZoneList] = useState('')

  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([])
  const [totalCompletedCount, setCompletedCount] = useState(0)
  const [recipients, setRecipients] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const toast = useRef(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const dt = useRef(null);
  const [currentTask, setCurrentTask] = useState({
    task: "",
    recipients: [],
    due_date: null,
    priority: "",
    note: "",
  });
  const [loading, setLoading] = useState(false);
  const [taskLoader, settaskLoader] = useState(true);
  const [recipientLoaded, setRecipientLoaded] = useState(false);
  const [taskLoaded, setTaskLoaded] = useState(false);
  const [validation, setValidation] = useState({
    lead: "",
    task_type: "",
    priority: "",
  });
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [scheduledLazyState, setScheduledLazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "timestamp",
    sortOrder: -1,
  });

  const [completedLazyState, setCompletedLazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: "timestamp",
    sortOrder: -1,
  });
  const [totalRecordsLead, setTotalRecordsLead] = useState(0); // Holds the total record count
  const [hasMoreRecords, setHasMoreRecords] = useState(true); // Track if there are more records to load

  const {
    API_PENDING_TASK_PAGINATED,
    API_COMPLETED_TASK_PAGINATED,
    API_GET_TASKS,
    API_PAGINATED_RECIPIENT,
    API_GET_COMPLETE_TASK,
    API_DELETE_BULK_TASK,
    API_POST_USER_TIMEZONES
  } = ApiListFile()

  const { setnetworkError, userGroup } = useContext(contextVar)


  const [page, setPage] = useState(1);               // Track current page for pagination
  const [visibleRecipients, setVisibleRecipients] = useState([]);

  const PAGE_SIZE = 10;  // Number of records to fetch per page

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
    console.log("Value ", value)
    return (
      <div className="position-relative">
        <input
          className="form-control"
          type="text"
          value={value}
          onClick={onClick}
          readOnly
          ref={ref}
        />
        <MdCalendarToday
          className="position-absolute top-50 end-0 translate-middle-y me-2"
          style={{ fontSize: '1.2rem', cursor: 'pointer' }}
          onClick={onClick}
        />
      </div>
    )
  });

  useEffect(() => {
    fetchMethod("GET", `${API_POST_USER_TIMEZONES}`, {})
      .then((data) => {
        if (data?.item != null) {
          const apiTimezone = data?.item?.timezone;
          if (apiTimezone) {

            settimeZoneList(apiTimezone)
          }
          const validTimezone = timezone_mapping[apiTimezone] || 'UTC';
          setTimezone(validTimezone);

          // Set initial date in the user's timezone
          const initialDate = new Date(moment.tz(new Date(), validTimezone).format('YYYY-MM-DDTHH:mm:ss'));
          setCurrentTask(prev => ({ ...prev, due_date: initialDate }));
        }
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true);
        }
      });
  }, []);


  function fetchCompletedTasks() {
    setLoading(true);
    fetchMethod("POST", `${API_COMPLETED_TASK_PAGINATED}`, {
      page: completedLazyState.page,
      page_size: completedLazyState.rows,
      search_query: globalFilter,
      sort_order: completedLazyState.sortOrder,
      sort_field: completedLazyState.sortField,
    })
      .then((data) => {
        const fetchedTasks = data?.items.map((task) => ({
          ...task,
          task: taskOptions?.find((option) => option?.value === task?.task)?.label,
          priority: priorityOptions?.find((option) => option?.value === task?.priority)?.label,
          due_date: moment(task?.due_date, "YYYY-MM-DD").format("D/M/YYYY"),
        }));
        setLoading(false);
        setCompletedTasks(fetchedTasks); // Make sure you set the tasks
        setCompletedCount(data?.total_count || 0);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setnetworkError(true);
        setLoading(false);
      });
  }


  const taskOptions = [
    { value: "phone call", label: "Phone Call" },
    { value: "manual email", label: "Manual Email" },
    { value: "action item", label: "Action Item" },
  ];

  const priorityOptions = [
    { value: "high priority", label: "High Priority" },
    { value: "medium priority", label: "Medium Priority" },
    { value: "low priority", label: "Low Priority" },
  ];

  const onPage = (event) => {
    const newLazyState = {
      first: event.first,
      rows: event.rows,
      page: event.page + 1,  // 0-indexed in the event, but we store as 1-indexed
    };
    if (activeTabIndex === 0) {
      setScheduledLazyState(prev => ({ ...prev, ...newLazyState }));
    } else {
      setCompletedLazyState(prev => ({ ...prev, ...newLazyState }));
    }
  };

  const onSort = (event) => {
    const newLazyState = {
      sortOrder: event.sortOrder,
      sortField: event.sortField,
    };
    if (activeTabIndex === 0) {
      setScheduledLazyState(prev => ({ ...prev, ...newLazyState }));
    } else {
      setCompletedLazyState(prev => ({ ...prev, ...newLazyState }));
    }
  };

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const data = await fetchMethod("POST", `${API_PENDING_TASK_PAGINATED}`, {
        page: scheduledLazyState.page,
        page_size: scheduledLazyState.rows,
        search_query: globalFilter,
        sort_order: scheduledLazyState.sortOrder,
        sort_field: scheduledLazyState.sortField,
      })
      const fetchedTasks = data?.items.map((task) => ({
        ...task,
        task: taskOptions?.find((option) => option?.value === task?.task)?.label,
        priority: priorityOptions?.find((option) => option?.value === task?.priority)?.label,
        due_date: moment(task?.due_date, "YYYY-MM-DD").format("D/M/YYYY"),
      }));
      setTasks(fetchedTasks);
      setTaskLoaded(true);
      setTotalRecords(data?.total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error signing up", error);
      if (error instanceof TypeError) {
        setnetworkError(true)
        setLoading(false);
        return;
      }
    }
  }

  const fetchRecipient = async (params) => {
    if (loading || !hasMoreRecords) return;  // Prevent fetching if already loading or no more records
    setLoading(true);

    try {
      const data = await fetchMethod("POST", `${API_PAGINATED_RECIPIENT}`, params);

      if (data && data.items) {
        const recipientOptions = data.items.map((recipient) => ({
          label: `${recipient.first_name.trim()} ${recipient.last_name.trim() || ''}`,
          value: recipient._id,
        }));

        // Append new recipients while keeping old ones
        setVisibleRecipients((prevRecipients) => [
          ...prevRecipients,
          ...recipientOptions,
        ]);

        // Update total records count for pagination tracking
        setTotalRecordsLead(data.total_count);

        // Check if all records have been fetched
        if (data.total_count <= (params.page * params.page_size)) {
          setHasMoreRecords(false);  // No more records to load
        }
      }
    } catch (error) {
      console.error("Error fetching recipients:", error);
    } finally {
      setLoading(false);  // End loading state
    }
  };
  // Fetch the first page when the component is mounted (initial load)
  useEffect(() => {
    fetchRecipient({ page: 1, page_size: PAGE_SIZE, sortField: "timestamp", sortOrder: -1 });
  }, []);

  // Fetch new page when page number changes
  useEffect(() => {
    if (page > 1) {  // Ensure we don't refetch the first page
      fetchRecipient({
        page,
        page_size: PAGE_SIZE,
        sortField: "timestamp",
        sortOrder: -1,
      });
    }
  }, [page]);

  // Function to handle scrolling down (fetch the next set of records)
  const handleMenuScrollToBottom = () => {
    if (!loading && hasMoreRecords) {
      setPage((prevPage) => prevPage + 1);  // Increment to next page
    }
  };



  // const fetchRecipient = async () => {
  //   try {
  //     setLoading(true);
  //     const data = await fetchMethod("POST", `${API_PAGINATED_RECIPIENT}`, {})
  //     const recipientOptions = data.items.map((recipient) => ({
  //       label: recipient.first_name.trim() + " " + recipient.last_name.trim(),
  //       value: recipient._id,
  //     }));
  //     setRecipients(recipientOptions);
  //     setRecipientLoaded(true);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error("Error signing up", error);
  //     if (error instanceof TypeError) {
  //       setnetworkError(true)
  //       setLoading(false);
  //       return;
  //     }
  //   }
  // }

  useEffect(() => {
    document.title = "Tasks";
    // fetchRecipient();
  }, []);

  useEffect(() => {
    if (activeTabIndex === 0) {
      fetchTasks(scheduledLazyState);
    } else {
      fetchCompletedTasks(completedLazyState);
    }
  }, [scheduledLazyState, completedLazyState, activeTabIndex, globalFilter, currentTask._id]);


  const bodyTemplate = (rowData, column) => {
    const displayText = rowData[column.field];

    // Truncate the text if it is too long
    const maxLength = 50;
    const truncatedText = displayText?.length > maxLength ? `${displayText.substring(0, maxLength)}...` : displayText;
    // Return the link with a tooltip
    return (
      <><Tooltip target=".tooltip-b" /><span className="tooltip-note-text" data-pr-tooltip={displayText}>{truncatedText}</span></>
    );
  };

  useEffect(() => {
    if (recipientLoaded && taskLoaded) {
      settaskLoader(false);
      // setLoadingDelay(true)
    }
  }, [recipientLoaded, taskLoaded]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let isValid = true;
    let newValidation = { lead: "", task_type: "", priority: "" };

    // Validate the name field
    if (!currentTask.recipients || currentTask.recipients?.length == 0) {
      isValid = false;
      newValidation.lead = "Lead is required";
    }

    // // Validate the subject field
    if (!currentTask.task || currentTask.task?.length == 0) {
      isValid = false;
      newValidation.task_type = "Task Type is required";
    }

    if (!currentTask.priority || currentTask.priority?.length == 0) {
      isValid = false;
      newValidation.priority = "Priority is required";
    }

    // Update the validation state
    setValidation(newValidation);

    if (isValid) {
      setLoading(true);

      const url =
        `${API_GET_TASKS}` +
        (currentTask._id ? `/${currentTask._id}` : "");
      const method = currentTask._id ? "PUT" : "POST";

      fetchMethod(method, `${url}`, {
        task: currentTask.task.value,
        recipients: currentTask.recipients,
        due_date: moment(currentTask.due_date).format('YYYY-MM-DDTHH:mm'),
        priority: currentTask.priority.value,
        note: currentTask.note,
      })
        .then((data) => {
          setLoading(false);
          if (currentTask._id) {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "Task Updated Successfully",
            });

            fetchTasks();
          } else {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: data.message,
            });
            setActiveTabIndex(0);
            setTotalRecords(prevRecords => prevRecords + 1);
            fetchTasks();
          }

          handleClose();
        })
        .catch((error) => {
          console.error(error);
          if (error instanceof TypeError) {
            setnetworkError(true)
            setLoading(false);
            return;
          }
        });
    }
  };

  // useEffect(() => {
  //   const userTimezone = localStorage.getItem('lastSavedTimezone') || 'UTC';
  //   setTimezone(timezone_mapping[userTimezone]);
  // }, []);


  const handleComplete = (id) => {
    const url = `${API_GET_COMPLETE_TASK}/${id}`;
    fetchMethod("PUT", `${url}`, {})
      .then((data) => {
        setTasks(tasks.filter((task) => task._id !== id));
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Task Completed Successfully",
        });
        fetchTasks()
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setLoading(false);
          return;
        }
      });
  };

  const handleDelete = (id) => {
    fetchMethod("DELETE", `${API_GET_TASKS}/${id}`, {})
      .then(() => {
        setSelectedRows(selectedRows.filter((task) => task._id !== id));
        setTasks(tasks.filter((task) => task._id !== id));
        setTotalRecords(totalRecords - 1);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Task Deleted Successfully",
        });
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setLoading(false);
          return;
        }
      });
  };

  const handleClose = () => {
    setCurrentTask({
      task: "",
      recipients: [],
      due_date: null,
      priority: "",
      note: "",
    })
    setValidation({ lead: "", task_type: "", priority: "" });
    setShowModal(false);
  };

  const handleDateSelection = (selectedDateAsString) => {
    // Assuming selectedDateAsString is in "YYYY-MM-DD" format
    const dateInSelectedTimezone = moment.tz(selectedDateAsString, "DD-MM-YYYY", timezone_mapping[timezone]);
    setCurrentTask(prevStep => ({
      ...prevStep,
      due_date: dateInSelectedTimezone.format(),
    }));
  };

  const handleStartDateChange = (date) => {
    console.log("start date change...", date)
    setCurrentTask((prev) => ({
      ...prev,
      due_date: date,
    }));
  };

  const getTodayInSelectedTimezone = () => {
    console.log("Timezone:", timezone);

    const mappedTimezone = timezone_mapping[timezone] || timezone;
    console.log("Mapped Timezone:", mappedTimezone);

    const currentDateInTimezone = moment.tz(mappedTimezone).startOf('day');
    console.log("Current date in timezone:", currentDateInTimezone.format());

    return currentDateInTimezone.toDate();
  };

  const minDateForDatePicker = getTodayInSelectedTimezone();


  const handleShow = (task) => {
    const dateValue = task._id
      ? moment(task.due_date, "D/M/YYYY").format("YYYY-MM-DD")
      : getCurrentDateInTimezone();

    const recipientsList =
      task?.recipients?.label && task?.recipients?.value
        ? {
          label: task.recipients.label,
          value: task.recipients.value.trim(),
        }
        : undefined;

    const priorityOption = priorityOptions.find(
      (priority) => priority.label === task?.priority
    );
    const taskTypeOption = taskOptions.find(
      (task_option) => task_option.label === task?.task
    );

    setCurrentTask({
      ...task,
      recipients: recipientsList,
      due_date: new Date(moment.tz(new Date(), timezone).format('YYYY-MM-DDTHH:mm:ss')),

      priority: priorityOption,
      task: taskTypeOption,
    });

    setShowModal(true);
  };

  const handleEdit = (task) => {
    console.log("edit task", task);

    const recipientsList =
      task?.recipients?.label && task?.recipients?.value
        ? {
          label: task.recipients.label,
          value: task.recipients.value.trim(),
        }
        : undefined;

    const priorityOption = priorityOptions.find(
      (priority) => priority.label === task?.priority
    );
    const taskTypeOption = taskOptions.find(
      (task_option) => task_option.label === task?.task
    );

    console.log("task duedate", task?.due_date);

    const dueDateString = task?.due_date; // Assuming format is "DD/MM/YYYY"
    const dueDate = moment.tz(dueDateString, "DD/MM/YYYY", timezone).toDate(); // Convert to Date object in specified timezone

    const formattedDueDate = moment(dueDate).format("DD-MM-YYYY"); // Format for display
    console.log("formattedDueDate========", formattedDueDate);

    setCurrentTask({
      ...task,
      recipients: recipientsList,
      due_date: dueDate, // Store as Date object for date picker
      priority: priorityOption,
      task: taskTypeOption,
    });

    setShowModal(true);
  };





  const handleBulkDelete = async (selectionOnly) => {
    if (selectionOnly) {
      const idArray = selectedRows.map(item => item._id);
      try {
        await fetchMethod("DELETE", `${API_DELETE_BULK_TASK}`, { ids: idArray })
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Task Deleted Successfully",
        });
        setTotalRecords(totalRecords - idArray.length);
        setSelectedRows([]);
        fetchTasks(); // Assuming this function fetches the updated template list
        setDeleteLoading(false);
      } catch (error) {
        // Handle fetch error...
        console.error('Error during fetch:', error);
        setDeleteLoading(false);
      }
    }
  }

  const deleteSelectedRecords = (selectionOnly) => {
    confirmDialog({
      message: "Do you want to delete these records?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",

      accept: () => {
        setDeleteLoading(true);
        handleBulkDelete(selectionOnly)
      },
      draggable: false
    });
  };


  const generateCSV = (data) => {
    const fields = ["task", "due_date", "priority", "recipients.label", "note"];
    const headers = ["Task", "Due Date", "Priority", "Lead", "Note"];

    // Function to extract value from object based on field path
    const extractValue = (obj, field) => {
      const levels = field.split(".");
      let current = obj;
      for (let level of levels) {
        current = current ? current[level] : "";
        if (current === undefined || current === null) {
          current = "";
          break;
        }
      }
      return current;
    };

    // Extract the rows
    const rows = data
      .map((obj) => {
        return fields.map((field) => {
          let value = extractValue(obj, field);
          if (field === 'note' && value !== "") {
            value = `"${value}"`;
          }
          return value;
        }).join(",");
      })
      .join("\n");

    return headers.join(",") + "\n" + rows;
  };

  const exportCSV = (selectionOnly) => {
    if (selectionOnly) {
      // Generate CSV from the selectedRows
      const csvData = generateCSV(selectedRows);

      // Create a blob from the CSV
      const blob = new Blob([csvData], { type: "text/csv" });

      // Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "tasks.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      dt.current.exportCSV(selectionOnly);
    }
  };

  const priorityTemplate = (rowData) => {
    // Determine the severity based on the priority value
    let severity;
    if (rowData.priority === "High Priority") {
      severity = "danger";
    } else if (rowData.priority === "Medium Priority") {
      severity = "warning";
    } else {
      severity = "info";
    }

    // Return the badge with a tooltip
    return (
      <>
        <Tooltip target=".priority-badge" />
        <Badge
          value={rowData.priority}
          className="ms-auto priority-badge"
          severity={severity}
          data-pr-tooltip={rowData.priority}
        />
      </>
    );
  };

  const leadTemplate = (rowData) => {
    // Retrieve the label, using a fallback if necessary
    const maxLength = 50;
    const displayText = rowData?.recipients?.label  // Default text if label is undefined
    const truncatedText = displayText?.length > maxLength ? `${displayText?.substring(0, maxLength)}...` : displayText;
    return (
      <>
        <Tooltip target=".tooltip-note-text" >
        </Tooltip>
        <span className="tooltip-note-text" data-pr-tooltip={displayText}>
          {truncatedText}
        </span>
      </>
    );
  };

  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          className="form-control-sm"
          onInput={(e) => {
            setGlobalFilter(e.target.value.trim())
            setLoading(true)
          }}
          placeholder="Search..."
        />
      </span>
      <div>
        <Button
          size="small"
          onClick={exportCSV}
          label="Export"
          severity="help"
          icon="uil uil-down-arrow"
          disabled={!selectedRows || selectedRows.length === 0}
        />
        {userGroup && userGroup?.task && userGroup?.task?.bulk_task_delete.D &&
          <Button
            className="ms-2"
            size="small"
            onClick={() => deleteSelectedRecords(true)}
            label="Delete"
            severity="danger"
            icon="uil uil-trash"
            disabled={!selectedRows || selectedRows.length === 0}
            loading={deleteLoading}
          />}
      </div>
    </div>
  );

  const accept = (id) => {
    handleDelete(id);
  };

  const reject = (id) => { };

  const confirmdelete = (id) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => accept(id),
      reject: () => reject(id),
      draggable: false,
    });
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="p-d-flex p-flex-wrap">
        <Tooltip target=".tooltip-edit-text" />
        <Tooltip target=".tooltip-delete-text" />
        <Tooltip target=".tooltip-complete-text" />
        {userGroup && userGroup?.task && userGroup?.task?.complete_task?.A &&
          <Button
            className="tooltip-complete-text"
            icon="pi pi-check"
            rounded
            text
            aria-label="Mark as Complete"
            data-pr-tooltip="Mark as Complete"
            loading={loading}
            onClick={() => handleComplete(rowData._id)}
          />}
        {userGroup && userGroup?.task && userGroup?.task?.delete_task?.D &&
          <Button
            className="tooltip-delete-text"
            icon="uil uil-trash"
            rounded
            text
            aria-label="Delete"
            data-pr-tooltip="Delete"
            severity="danger"
            onClick={() => confirmdelete(rowData._id)}
          />}
        {userGroup && userGroup?.task && userGroup?.task?.update_task?.C &&
          <Button
            className="tooltip-edit-text"
            icon="uil uil-edit-alt"
            rounded
            text
            aria-label="Edit"
            data-pr-tooltip="Edit"
            onClick={() => handleEdit(rowData)}
          />}
      </div>
    );
  };

  const noteTemplate = (rowData, column) => {
    const note = rowData["note"];
    const maxLength = 10;
    let truncatedBody;
    if (note != undefined) {
      const cleanedBody = note.replace(/(<([^>]+)>)/gi, "");
      truncatedBody =
        cleanedBody.length > maxLength
          ? `${cleanedBody.substring(0, maxLength)}...`
          : cleanedBody;
    } else {
      truncatedBody = <span></span>;
    }

    return (
      <>
        <Tooltip target=".tooltip-note-text" />
        <span data-pr-tooltip={note} className="tooltip-note-text">
          {truncatedBody}
        </span>
      </>
    );
  };

  const handleSelectionChange = (e) => {
    const selectedValues = e.value;

    setSelectedRows((prevSelectedRows) => {
      const selectedIds = new Set(selectedValues.map(row => row._id));

      // Create a new selection that merges previous and current selections
      const mergedSelection = [
        ...prevSelectedRows.filter(row => selectedIds.has(row._id) || !tasks.some(currentRow => currentRow._id === row._id)),
        ...selectedValues.filter(row => !prevSelectedRows.some(selectedRow => selectedRow._id === row._id))
      ];

      return mergedSelection;
    });
  };

  // Handle header checkbox selection
  const handleHeaderCheckboxChange = (e) => {
    const allSelected = e.checked;
    if (allSelected) {
      // Add all rows from the current page to the selection
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = [...prevSelectedRows];
        tasks.forEach(row => {
          if (!newSelectedRows.some(selectedRow => selectedRow._id === row._id)) {
            newSelectedRows.push(row);
          }
        });
        return newSelectedRows;
      });
    } else {
      // Remove all rows from the current page from the selection
      setSelectedRows((prevSelectedRows) => {
        return prevSelectedRows.filter(row => !tasks.some(currentRow => currentRow._id === row._id));
      });
    }
  };

  const handleCompletedSelectionChange = (e) => {
    const selectedValues = e.value;

    setSelectedRows((prevSelectedRows) => {
      const selectedIds = new Set(selectedValues.map(row => row._id));

      // Create a new selection that merges previous and current selections
      const mergedSelection = [
        ...prevSelectedRows.filter(row => selectedIds.has(row._id) || !completedTasks.some(currentRow => currentRow._id === row._id)),
        ...selectedValues.filter(row => !prevSelectedRows.some(selectedRow => selectedRow._id === row._id))
      ];

      return mergedSelection;
    });
  };

  // Handle header checkbox selection
  const handleCompletedHeaderCheckboxChange = (e) => {
    const allSelected = e.checked;
    if (allSelected) {
      // Add all rows from the current page to the selection
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = [...prevSelectedRows];
        completedTasks.forEach(row => {
          if (!newSelectedRows.some(selectedRow => selectedRow._id === row._id)) {
            newSelectedRows.push(row);
          }
        });
        return newSelectedRows;
      });
    } else {
      // Remove all rows from the current page from the selection
      setSelectedRows((prevSelectedRows) => {
        return prevSelectedRows.filter(row => !completedTasks.some(currentRow => currentRow._id === row._id));
      });
    }
  };

  function getCurrentDate() {
    const userTimezone = localStorage.getItem('lastSavedTimezone') || 'UTC';
    const mappedTimezone = timezone_mapping[userTimezone];
    if (!mappedTimezone || !moment.tz.zone(mappedTimezone)) {
      // Fallback to UTC or another default timezone if the mapped one is invalid
      return moment().tz('UTC').format('YYYY-MM-DDTHH:mm');
    }
    return moment().tz(mappedTimezone).format('YYYY-MM-DDTHH:mm');
  }

  // Function to get the current date in the selected timezone
  function getCurrentDateInTimezone() {
    const userTimezone = localStorage.getItem('lastSavedTimezone') || 'UTC';

    // Check if the timezone mapping is correct
    const mappedTimezone = timezone_mapping[userTimezone];

    if (!mappedTimezone) {
      return null;

    }

    const currentDate = moment().tz(mappedTimezone).startOf('day');

    return currentDate;
  }


  const isHighlightedDay = (date) => {
    // Check if the current day should be highlighted based on the formatted date and timezone
    const formattedDate = moment.tz(currentTask.due_date, timezone).format('DD-MM-YYYY');
    const dayToCheck = moment(date).format('DD-MM-YYYY');
    return formattedDate === dayToCheck;
  };

  const isHighlightedTime = (time) => {
    // Check if the current time should be highlighted based on the formatted date and timezone
    const formattedTime = moment.tz(currentTask.due_date, timezone).format('hh:mm A');
    const timeToCheck = moment(time).format('hh:mm A');
    return formattedTime === timeToCheck;
  };




  return (
    <div>
      <div className="content-page">
        <div className="content">
          <Container fluid>
            <ConfirmDialog />
            <Toast ref={toast} />
            <Row>
              <Col>
                <div className="page-title-box">
                  <h4 className="page-title">Tasks</h4>
                  <div className="page-title-right">
                    <div className="row g-2">
                      <div className="col-md-auto">
                        {userGroup && userGroup?.task && userGroup?.task && userGroup?.task?.add_task?.A &&
                          <Button
                            label="Add Task"
                            icon="uil uil-plus"
                            size="small"
                            onClick={() =>
                              handleShow({
                                task: "",
                                due_date: new Date(moment.tz(new Date(), timezone).format('YYYY-MM-DDTHH:mm:ss')),
                                priority: "",
                                recipients: [],
                              })
                            }
                          />}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="page-title-caption-box">
                  <h6 className="page-title-caption mb-4">A task management feature to organize and prioritize email-related activities, ensuring nothing slips through the cracks.</h6>
                </div>
              </Col>
            </Row>
            <TabView
              activeIndex={activeTabIndex}
              onTabChange={(e) => {
                setActiveTabIndex(e.index);
                if (e.index === 0) {
                  setScheduledLazyState({ ...scheduledLazyState, page: 1, first: 0 });
                  fetchTasks({ ...scheduledLazyState, page: 1, first: 0 });
                } else {
                  setCompletedLazyState({ ...completedLazyState, page: 1, first: 0 });
                  fetchCompletedTasks({ ...completedLazyState, page: 1, first: 0 });
                }
              }}
            >
              <TabPanel header="Scheduled">
                <Row>
                  <Col>
                    <Card>
                      {loading ? (
                        <DataTable
                          header={header}
                          value={[...Array(5)]} // Use an array of length 5 to render 5 rows of skeletons
                          tableStyle={{ minWidth: "50rem" }}
                        >
                          <Column
                            selectionMode="multiple"
                            headerStyle={{ width: "3rem" }}
                          ></Column>
                          <Column
                            field="task"
                            header="Task"
                            body={() => <Skeleton></Skeleton>}
                          />
                          <Column
                            field="due_date"
                            header="Due Date"
                            body={() => <Skeleton></Skeleton>}
                          />
                          <Column
                            field="priority"
                            header="Priority"
                            body={() => <Skeleton></Skeleton>}
                          />
                          <Column
                            field="lead"
                            header="Lead"
                            body={() => <Skeleton></Skeleton>}
                          />
                          <Column
                            field="Action"
                            header="Action"
                            body={() => <Skeleton></Skeleton>}
                          />
                        </DataTable>
                      ) : (
                        <DataTable ref={dt} value={tasks}
                          lazy
                          selection={selectedRows}
                          onSelectionChange={handleSelectionChange}
                          onHeaderCheckboxToggle={handleHeaderCheckboxChange}
                          selectionMode="checkbox"
                          onSort={onSort} sortField={scheduledLazyState.sortField} sortOrder={scheduledLazyState.sortOrder}
                          first={scheduledLazyState.first}
                          filters={scheduledLazyState.filters}
                          onPage={onPage}
                          globalFilter={globalFilter}
                          header={header}
                          totalRecords={totalRecords}
                          removableSort paginator rows={scheduledLazyState.rows} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                          currentPageReportTemplate="{first} to {last} of {totalRecords}"  >
                          <Column
                            selectionMode="multiple"
                            headerStyle={{ width: "3rem" }}
                          ></Column>
                          <Column
                            field="task"
                            sortable
                            header="Task"
                            body={bodyTemplate}
                          ></Column>
                          <Column
                            field="due_date"
                            sortable
                            header="Due Date"
                            body={bodyTemplate}
                          ></Column>
                          <Column
                            field="priority"
                            sortable
                            header="Priority"
                            body={priorityTemplate}
                          ></Column>
                          <Column
                            field="recipients.label"
                            sortable
                            header="Lead"
                            body={leadTemplate}
                          ></Column>
                          <Column
                            field="note"
                            sortable
                            header="Note"
                            body={bodyTemplate}
                          ></Column>
                          <Column
                            header="Action"
                            body={actionTemplate}
                          ></Column>
                        </DataTable>
                      )}
                    </Card>
                  </Col>
                </Row>
              </TabPanel>
              {userGroup && userGroup?.task && userGroup?.task?.get_paginated_completed_tasks &&
                <TabPanel header="Completed">
                  <Row>
                    <Col>
                      <Card>
                        {loading ? (
                          <DataTable
                            header={header}
                            value={[...Array(5)]} // Use an array of length 5 to render 5 rows of skeletons
                            tableStyle={{ minWidth: "50rem" }}
                          >
                            <Column
                              selectionMode="multiple"
                              headerStyle={{ width: "3rem" }}
                            ></Column>
                            <Column
                              field="task"
                              header="Task"
                              body={() => <Skeleton></Skeleton>}
                            />
                            <Column
                              field="due_date"
                              header="Due Date"
                              body={() => <Skeleton></Skeleton>}
                            />
                            <Column
                              field="priority"
                              header="Priority"
                              body={() => <Skeleton></Skeleton>}
                            />
                            <Column
                              field="contact"
                              header="Lead"
                              body={() => <Skeleton></Skeleton>}
                            />
                            <Column
                              field="note"
                              header="Note"
                              body={() => <Skeleton></Skeleton>}
                            />
                          </DataTable>
                        ) : (
                          <DataTable ref={dt} value={completedTasks}
                            lazy
                            selection={selectedRows}
                            onSelectionChange={handleCompletedSelectionChange}
                            onHeaderCheckboxToggle={handleCompletedHeaderCheckboxChange}
                            selectionMode="checkbox"
                            onSort={onSort} sortField={completedLazyState.sortField} sortOrder={completedLazyState.sortOrder}
                            first={completedLazyState.first}
                            filters={completedLazyState.filters}
                            onPage={onPage}
                            globalFilter={globalFilter}
                            header={header}
                            totalRecords={totalCompletedCount}
                            removableSort paginator rows={completedLazyState.rows} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                            currentPageReportTemplate="{first} to {last} of {totalRecords}"  >
                            <Column
                              selectionMode="multiple"
                              headerStyle={{ width: "3rem" }}
                            ></Column>
                            <Column
                              field="task"
                              sortable
                              header="Task"
                              body={bodyTemplate}
                            ></Column>
                            <Column
                              field="due_date"
                              sortable
                              header="Due Date"
                              body={bodyTemplate}
                            ></Column>
                            <Column
                              field="priority"
                              sortable
                              header="Priority"
                              body={priorityTemplate}
                            ></Column>
                            <Column
                              field="recipients.name"
                              sortable
                              header="Lead"
                              body={leadTemplate}
                            ></Column>
                            <Column
                              field="note"
                              sortable
                              header="Note"
                              body={noteTemplate}
                            ></Column>
                          </DataTable>
                        )}
                      </Card>
                    </Col>
                  </Row>
                </TabPanel>}
            </TabView>
          </Container>
        </div>
        <Footer />
      </div>

      <Dialog
        header={currentTask._id ? "Update Task" : "Add Task"}
        visible={showModal}
        onHide={handleClose}
        style={{ width: "40vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        <Form onSubmit={handleSubmit}>
          <Row className="">
            <Col xs={6}>
              <Form.Group className="mb-3">
                <Form.Label>Lead <span className="text-danger">*</span></Form.Label>
                {/* <Select
                    options={recipients}
                    value={currentTask.recipients}
                    onChange={(value) => {
                      setCurrentTask({ ...currentTask, recipients: value });
                    }}
                    isMulti
                    isSearchable
                    isDisabled={currentTask._id ? true : false}
                  /> */}
                <Select
                  options={visibleRecipients}  // Show the accumulated recipients as they are fetched
                  value={currentTask.recipients}
                  onChange={(value) => {
                    setCurrentTask({ ...currentTask, recipients: value });
                  }}
                  isMulti
                  isSearchable
                  isDisabled={currentTask._id ? true : false}
                  onMenuScrollToBottom={handleMenuScrollToBottom}
                  isLoading={loading}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: '200px',
                      overflowY: 'auto',
                      paddingRight: '10px',
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: '200px',
                      overflowY: 'auto',
                    }),
                  }}
                  menuPosition="fixed"
                />



                <div className="text-danger">{validation.lead}</div>
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Task Type <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  options={taskOptions}
                  value={currentTask.task}
                  onChange={(value) =>
                    setCurrentTask({ ...currentTask, task: value })
                  }
                  isSearchable={false}
                />
                <div className="text-danger">{validation.task_type}</div>
              </Form.Group>
            </Col>

            <Col xs={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Priority <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  options={priorityOptions}
                  value={currentTask.priority}
                  onChange={(value) =>
                    setCurrentTask({ ...currentTask, priority: value })
                  }
                  isSearchable={false}
                />
                <div className="text-danger">{validation.priority}</div>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>
              Due Date <span className="text-danger">*</span>
            </Form.Label>
            {timezone && <DatePicker
              selected={currentTask.due_date ? currentTask.due_date : null}
              onChange={handleStartDateChange}
              // showTimeSelect
              timeFormat="hh:mm aa"
              timeIntervals={1}
              timeCaption="Time"
              dateFormat="dd-MM-yyyy "
              // minDate={currentTask.due_date ? currentTask.due_date : null}
              minDate={minDateForDatePicker} // Set minDate to today's date in the selected timezone
              customInput={<CustomInput />}
              value={currentTask.due_date ? currentTask.due_date : 'Not set'}
              dayClassName={(date) => (isHighlightedDay(date) ? 'highlighted-day' : undefined)}
              timeClassName={(time) => (isHighlightedTime(time) ? 'highlighted-time' : undefined)}
            />}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Note</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={currentTask.note}
              onChange={(event) =>
                setCurrentTask({ ...currentTask, note: event.target.value })
              }
            />
          </Form.Group>

          <div className="d-flex justify-content-end mt-2">
            <Button
              type="button"
              variant="secondary"
              onClick={handleClose}
              size="small"
              outlined
            >
              Close
            </Button>
            <Button
              size="small"
              label="Save"
              icon="pi pi-check"
              type="submit"
              loading={loading}
              className="ms-2"
            />
          </div>
        </Form>
      </Dialog>
    </div>
  );
};

export default Tasks;