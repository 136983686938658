import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import { Form } from 'react-bootstrap'
import ApiListFile from '../ApiListFile'
import { fetchMethod } from '../ApiHeader'
import FeatherIcon from 'feather-icons-react/build/FeatherIcon'
import { isValid } from 'date-fns'
import { contextVar } from '../ContextVar'
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';

function CallDisposition(props) {

    const { API_GET_CALL_RECORD, API_ADD_CALL_DISPOSITION } = ApiListFile()
    const { setnetworkError, setcallDispositionModalStatus, callStatus } = useContext(contextVar)
    const toast = useRef(null);
    const dialogContentRef = useRef(null);
    const saveButtonRef = useRef(null);


    const callDispositionModal = props?.callDispositionModal
    const setcallDispositionModal = props?.setcallDispositionModal

    const [recordedData, setrecordedData] = useState('')
    const [recordPlayer, setrecordPlayer] = useState(false)
    const [callPurpose, setCallPurpose] = useState('');
    const [callDisposition, setCallDisposition] = useState('');
    const [note, setNote] = useState('');
    const [loader, setloader] = useState(false)
    const [recordingLoader, setrecordingLoader] = useState(true)

    const [callPurposeError, setCallPurposeError] = useState('');
    const [callDispositionError, setCallDispositionError] = useState('');
    const [recordError, setrecordError] = useState('');

    const callPurposeOptions = [
        {
            label: 'Prospecting Call',
            value: 'prospectingCall'
        },
        {
            label: 'Opportunity Call',
            value: 'opportunityCall'
        },
        {
            label: 'Support Call',
            value: 'supportCall'
        },
        {
            label: 'Other Call',
            value: 'otherCall'
        }
    ]

    const callDispositionOptions = [
        {
            label: 'No Answer',
            value: 'noAnswer'
        },
        {
            label: 'Left Voicemail',
            value: 'leftVoicemail'
        },
        {
            label: 'Busy',
            value: 'busy'
        },
        {
            label: 'Gatekeeper',
            value: 'gatekeeper'
        },
        {
            label: 'Bad/Wrong Number',
            value: 'bad/WrongNumber'
        },
        {
            label: 'Not in service',
            value: 'notInService'
        },
        {
            label: 'Connected - Positive',
            value: 'connected-positive'
        },
        {
            label: 'Connected - Neutral',
            value: 'connected-neutral'
        },
        {
            label: 'Connected - Negative',
            value: 'connected-negative'
        }
    ]

  

    const handleClose = () => {
        handleSave()

    }


    // const handlePlay = () => {
    //     fetchCallDetails()
    // }

    useEffect(() => {
        setTimeout(() => {
            fetchCallDetails()
        }, 10000);
    }, [props?.callSid, props?.callSid?.CallSid])

    const fetchCallDetails = () => {
        setrecordingLoader(true)
        const callSid = props?.callDirection === 'Incoming' ? props?.callSid : props?.callSid?.CallSid;
        const params = { call_sid: callSid };
        if (callSid) {
            fetchMethod("POST", `${API_GET_CALL_RECORD}`, params)
                .then(data => {
                    if (data?.status === 'success') {
                        console.log("call xetail ", data)
                        setrecordedData(data?.result?.recording_url)
                        setrecordingLoader(false)
                    }
                    if (data?.status === 'error') {
                        setrecordError(data?.message)
                        setrecordingLoader(false)
                    }

                    setrecordPlayer(true)
                })
                .catch((error) => {
                    console.error('Error fetching token:', error);
                    setrecordingLoader(false)
                })
        }
    }

    const validate = () => {
        let isValid = true;

        if (!callPurpose) {
            setCallPurposeError('Please select call purpose');
            isValid = false;
        } else {
            setCallPurposeError('');
        }

        if (!callDisposition) {
            setCallDispositionError('Please select call disposition');
            isValid = false;
        } else {
            setCallDispositionError('');
        }


        // if (!recordedData) {
        //     setrecordError('Click on button');
        //     isValid = false;
        // } else {
        //     setrecordError('');
        // }

        return isValid;
    }


    const handleSubmit = (e) => {
        setloader(true);
        const callSid = props?.callDirection === 'Incoming' ? props?.callSid : props?.callSid?.CallSid;
        const callDirection = props?.callDirection === 'Incoming' ? 'Incoming' : 'Outgoing';
        if (validate()) {
            fetchMethod('POST', `${API_ADD_CALL_DISPOSITION}`, {
                recipient_id: props?.recipientId,
                call_duration: props?.callDuration,
                from_call_number: props?.fromNumber,
                to_call_number: props?.toNumber,
                call_sid: callSid,
                recorded_url: recordedData,
                call_purpose: callPurpose,
                call_disposition: callDisposition,
                note: note,
                call_direction: callDirection,
                call_status: callStatus,
            })
                .then((data) => {
                    if (data.status === 'success') {
                        if (toast.current) {
                            toast.current.show({
                                severity: 'success',
                                summary: 'Success',
                                detail: data.message,
                            });
                        }
                    } else {
                        if (toast.current) {
                            toast.current.show({
                                severity: 'warn',
                                summary: 'Error',
                                detail: data.message,
                            });
                        }
                    }
                    setloader(false);
                    setTimeout(() => {
                        setcallDispositionModal(false);
                        setcallDispositionModalStatus(false)
                        localStorage.setItem('modalState', 'closed');
                    }, 1000);
                })
                .catch((error) => {
                    console.error(error);
                    if (error instanceof TypeError) {
                        setnetworkError(true);
                        setloader(false);
                    }
                });
        } else {
            setloader(false);
        }
    };

    const handleSave = () => {
        setloader(true)
        const callSid = props?.callDirection === 'Incoming' ? props?.callSid : props?.callSid?.CallSid;
        const callDirection = props?.callDirection === 'Incoming' ? 'Incoming' : 'Outgoing';
        fetchMethod("POST", `${API_ADD_CALL_DISPOSITION}`,
            {
                recipient_id: props?.recipientId,
                from_call_number: props?.fromNumber,
                to_call_number: props?.toNumber,
                call_duration: props?.callDuration,
                call_sid: callSid,
                call_direction: callDirection,
            })
            .then(data => {
                // if (data.status == 'success') {
                //     toast.current.show({
                //         severity: 'success',
                //         summary: 'Success',
                //         detail: data.message,
                //     });

                // } else {
                //     toast.current.show({
                //         severity: 'warn',
                //         summary: 'Error',
                //         detail: data.message,
                //     });
                // }
                setloader(false)
                setTimeout(() => {
                    props?.setcallDispositionModal(false)
                    setcallDispositionModalStatus(false)
                    localStorage.setItem('modalState', 'closed');
                }, 1000);

            })
            .catch((error) => {
                console.error(error);
                if (error instanceof TypeError) {
                    // setnetworkError(true)
                    setloader(false);
                    return;
                }
            });
    }

    const handleHide = () => {
        handleSave();
        // setcallDispositionModal(false);
        // localStorage.setItem('modalState', 'closed');
    }

    const handleDocumentClick = (event) => {
        const isInsideModal = dialogContentRef.current && dialogContentRef.current.contains(event.target);
        const isInsideModal2 = event.target.closest('.p-dialog-content') || event.target.closest('.p-dialog-footer');
        const isDropdownClick = event.target.closest('.p-dropdown') || event.target.closest('.p-dropdown-item');
        const isTextareaClick = event.target.closest('textarea');
        const isSaveButtonClick = saveButtonRef.current && saveButtonRef.current.contains(event.target);

        if (
            // !isInsideModal&&
            dialogContentRef.current &&
            !dialogContentRef.current.contains(event.target) &&
            !isInsideModal &&
            !isInsideModal2 &&
            !isDropdownClick &&
            !isTextareaClick &&
            !isSaveButtonClick
        ) {
            handleSave();
            setcallDispositionModal(false);
            localStorage.setItem('modalState', 'closed');
        }
    };


    // useEffect(() => {
    //     if (callDispositionModal) {
    //         document.addEventListener('click', handleDocumentClick);
    //     }
    //     // else {
    //     //     document.removeEventListener('click', handleDocumentClick);
    //     // }
    //     // return () => {
    //     //     document.removeEventListener('click', handleDocumentClick);
    //     // };
    // }, [callDispositionModal]);

    const callWith = props?.callStatus === 'Incoming' ? props?.fromNumber : props?.recipientName

    return (
        <>
            <Toast ref={toast} />
            <Dialog
                header={`Call with ${callWith}`}
                visible={callDispositionModal}
                // onHide={() => setcallDispositionModal(false)}
                onHide={handleHide}
                style={{ width: "30vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                draggable={false}
                resizable={false}
                dismissableMask
            >
                {/* <Button onClick={handlePlay}>play</Button> */}

                <div>
                    <div className=""  >
                        <>
                            <h6>Call Details</h6>
                            {!recordPlayer &&
                                <>
                                    <ProgressSpinner
                                        style={{
                                            width: '30px',
                                            height: '30px'
                                        }}
                                        strokeWidth="4"
                                        fill="var(--surface-ground)"
                                        animationDuration=".9s"
                                    />
                                    <div className="ml-2 mb-4">Recording is being prepared...</div>
                                </>
                            }
                            {/* {!recordPlayer &&
                                <button onClick={handlePlay}><span> Recording <FeatherIcon icon={'headphones'} className="icon-dual" /></span></button>
                            } */}
                            {/* {recordError && <div className="text-danger mb-2">{recordError}</div>} */}
                            {(recordPlayer && !recordError) &&
                                <ReactAudioPlayer
                                    src={recordedData}
                                    controls
                                // onPlay={handlePlay}
                                />
                            }
                            {
                                recordError &&
                                <div className=" ml-2 mb-4">No Recording Found</div>
                            }
                        </>
                    </div>
                    <Form >
                        <Form.Group className="mb-3">
                            <Form.Label>Call Purpose<span className="text-danger">*</span></Form.Label>
                            <Dropdown
                                name="callPurpose"
                                value={callPurpose}
                                onChange={(e) => setCallPurpose(e.target.value)}
                                options={callPurposeOptions}
                                placeholder="Select a purpose"
                                className="w-full col-md-12"
                            />
                            {callPurposeError && <div className="text-danger mb-2">{callPurposeError}</div>}

                            <Form.Label>Call Disposition <span className="text-danger">*</span></Form.Label>
                            <Dropdown
                                name="callDisposition"
                                value={callDisposition}
                                onChange={(e) => setCallDisposition(e.target.value)}
                                options={callDispositionOptions}
                                placeholder="Select a disposition"
                                className="w-full col-md-12"
                            />
                            {callDispositionError && <div className="text-danger mb-2">{callDispositionError}</div>}

                            <Form.Label>Note</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={6}
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                maxLength={500}
                            />
                        </Form.Group>
                    </Form>
                    <div className='d-flex justify-content-end' style={{ marginTop: "20px" }}>
                        <Button label="Close" size='small' outlined onClick={handleClose} />
                        <Button icon="pi pi-check" label="Save" size='small' className='ms-2' loading={loader} disabled={recordingLoader} ref={saveButtonRef} onClick={handleSubmit} />
                    </div>
                </div>
            </Dialog >
        </>
    )
}

export default CallDisposition