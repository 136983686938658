import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Form, Container, Col, Row, Dropdown } from "react-bootstrap";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Skeleton } from "primereact/skeleton";
import moment from "moment-timezone";
import "./SequenceDetails.css";
import ApiListFile from "../Components/ApiListFile";
import ApiHeader, { fetchMethod } from "../Components/ApiHeader";
import DatePicker from 'react-datepicker';
import "./Sequence.css";
import { contextVar } from "../Components/ContextVar";
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Controls,
  MiniMap,
  Background,
  useEdgesState,
  useNodesState,
  getBezierPath, getEdgeCenter,
  Handle
} from 'react-flow-renderer';
import Email from "./Email";

const GridView = (Steps) => {

  const {
    API_POST_USER_TIMEZONES,
    API_GET_SEQUENCE_NAME,
    API_SEQUENCE_STEPS,
    API_TEMPLATE
  } = ApiListFile()

  const { setnetworkError } = useContext(contextVar)

  const [steps, setSteps] = useState(Steps.Steps);
  const [visible, setVisible] = useState(false);
  const { id } = useParams();
  const api_key = localStorage.getItem("api_key");
  const [templates, setTemplates] = useState([]);
  const [dependentOnStepOptions, setDependentOnStepOptions] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("all");
  const [emailDetailsValue, setEmailDetailsValue] = useState({});
  const [templateLoader, settemplateLoader] = useState(true);

  const toast = useRef(null);
  let timezone_mapping = {
    'Pacific Time': 'US/Pacific',
    'Mountain Time': 'US/Mountain',
    'Central Time': 'US/Central',
    'Eastern Time': 'US/Eastern',
    'UTC': 'UTC',
    'London': 'Europe/London',
    'Paris': 'Europe/Paris',
    'Athens': 'Europe/Athens',
    'Australia Eastern Standard Time': 'Australia/Sydney',
    'Australia Western Standard Time': 'Australia/Perth',
    'Australia Central Standard Time': 'Australia/Adelaide',
    'Middle East Time': 'Asia/Beirut',
    'Near East Time': 'Asia/Baghdad',
    'Pakistan Lahore Time': 'Asia/Karachi',
    'Indian Standard Time': 'Asia/Kolkata',
    'Bangladesh Standard Time': 'Asia/Dhaka',
    'Vietnam Standard Time': 'Asia/Ho_Chi_Minh',
    'Japan Standard Time': 'Asia/Tokyo',
    'Solomon Standard Time': 'Pacific/Guadalcanal',
    'New Zealand Standard Time': 'Pacific/Auckland',
    'Midway Islands Time': 'Pacific/Midway',
    'Hawaii Standard Time': 'Pacific/Honolulu',
    'Alaska Standard Time': 'US/Alaska',
    'Puerto Rico and US Virgin Islands Time': 'America/Puerto_Rico',
    'Canada Newfoundland Time': 'America/St_Johns',
    'Brazil Eastern Time': 'America/Sao_Paulo'
  }
  const [timezone, setTimezone] = useState("");

  // Initialize initialDueDate using useEffect
  const [initialDueDate, setInitialDueDate] = useState(null);
  const convertToBrowserLocalTimezone = (selectedTimezoneDate, selectedTimezone) => {
    // Convert the timezone-aware date to the equivalent local date in the browser's timezone
    const localEquivalentDate = moment.tz(selectedTimezoneDate.format('YYYY-MM-DD'), selectedTimezone).clone().tz(moment.tz.guess());
    return localEquivalentDate.format('YYYY-MM-DD'); // Ensure the return is in 'YYYY-MM-DD' format for consistency
  };

  const handleDateSelection = (selectedDateAsString) => {
    // Assuming selectedDateAsString is in "YYYY-MM-DD" format
    const dateInSelectedTimezone = moment.tz(selectedDateAsString, "DD-MM-YYYY", timezone_mapping[timezone]);
    setCurrentStep(prevStep => ({
      ...prevStep,
      due_date: dateInSelectedTimezone.format(),
    }));
  };


  useEffect(() => {
    if (timezone) {
      // This is the moment object for the current date in the selected timezone
      const tzAwareDate = moment.tz(timezone_mapping[timezone]).startOf('day');

      // Convert this date so that it reflects the correct day in the browser's local timezone
      const adjustedDate = convertToBrowserLocalTimezone(tzAwareDate, timezone_mapping[timezone]);
      setInitialDueDate(adjustedDate);
    }
  }, [timezone, timezone_mapping]);



  // Use initialDueDate as the initial value for currentStep
  const [currentStep, setCurrentStep] = useState({
    step_type: "",
    step_condition: { value: "opened", label: "Opened" },
    delay_type: { value: "days", label: "days" },
    delay_duration: "",
    task_priority: "Low Priority",
    due_date: initialDueDate,
    note: "",
    template: null,
    step_value: steps[0] ? 1 : "",
    dependent_on: null,
  });

  const [showPreviewModal, setshowPreviewModal] = useState(false);
  const [sequenceStepsloading, setSequenceStepsLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(true);

  const [sumOpened, setSumOpened] = useState(0);
  const [sumScheduled, setSumScheduled] = useState(0);
  const [sumTaskAdded, setSumTaskAdded] = useState(0);
  const [sumSent, setSumSent] = useState(0);
  const [sumOptOut, setSumOptOut] = useState(0);

  const [sumBounced, setSumBounced] = useState(0);
  const [sumReplied, setSumReplied] = useState(0);

  const [activeTabIndex, setActiveTabIndex] = useState(0); // To track the active tab
  const [formErrors, setFormErrors] = useState({
    step_type: "",
    delay_duration: "",
    delay_type: "",
  });

  const fetchStep = (selectedOption) => {
    const openedDependencies = steps.flatMap(step =>
      step.openedList?.map(opened => opened.dependent_on)).filter(Boolean);
    const notOpenedDependencies = steps.flatMap(step =>
      step.notOpenedList?.map(notOpened => notOpened.dependent_on)).filter(Boolean);
    const currentCondition = selectedOption;

    let options = steps
      .map((item, index) => {
        const stepId = "step_" + (index + 1);

        if (currentCondition === 'opened') {
          if (!openedDependencies.includes(stepId)) {
            return {
              value: stepId,
              label: "Step " + (index + 1),
            };
          }
        }
        else if (currentCondition === 'not_opened') {
          if (!notOpenedDependencies.includes(stepId)) {
            return {
              value: stepId,
              label: "Step " + (index + 1),
            };
          }

        }
        return null;
      })
      .filter(option => option !== null);
    setDependentOnStepOptions(options);
  }


  const delayOptions = [
    { value: "minutes", label: "minutes" },
    { value: "hours", label: "hours" },
    { value: "days", label: "days" },
  ];

  const stepTypesOptions = [
    { value: "automatic_email", label: "Automatic Email" },
    { value: "manual_email", label: "Manual Email" },
    { value: "phone_call", label: "Phone Call" },
    { value: "action_item", label: "Action Item" },
  ];

  const handleShow = (step) => {
    setCurrentStep(step);
    console.log("currentStep...", currentStep)
    setVisible(true);
  };

  const getTemplateData = () => {
    fetchMethod("GET", `${API_TEMPLATE}`, {})
      .then((data) => {
        const templateOptions = data.map((template) => ({
          value: template._id,
          label: template.template_name,
          tempBody: template.template_body,
        }));
        setTemplates(templateOptions);
        settemplateLoader(templateOptions.length === 0);
        setAddLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)

          return;
        }
      });

  };


  const handleClose = () => {
    setCurrentStep({
      step_type: "",
      step_condition: { value: "opened", label: "Opened" },
      delay_type: { value: "days", label: "days" },
      delay_duration: "",
      task_priority: "",
      due_date: moment(new Date(), "YYYY-MM-DD").format("D/M/YYYY"),
      note: "",
      template: null,
      dependent_on: null,
    });
    setFormErrors({
      step_type: "",
      delay_duration: "",
      template: "",
      task_priority: "",
    });
    setVisible(false);

  };

  const shouldShowOpenedOption = () => {

    return steps.some(step => step.step_type === "automatic_email");
  };

  const accept = (id) => {
    handleDelete(id);
  };

  const reject = (id) => { };

  const confirmdelete = (id) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => accept(id),
      reject: () => reject(id),
      draggable: false
    });
  };

  const handleSubmit = async () => {
    setSequenceStepsLoading(true)
    let isValid = true;
    let errors = {
      step_type: "",
      delay_duration: "",
      template: "",
      task_priority: "",
    };

    if (currentStep.step_type == "") {
      errors.step_type = "Step Type is required";
      isValid = false;
    } else {
      if (currentStep.step_type.value == "automatic_email") {
        if (currentStep.template == null) {
          errors.template = "Template is required";
          isValid = false;
        }
      } else {
        if (currentStep.task_priority == "") {
          errors.task_priority = "Task Priority is required";
          isValid = false;
        }
      }
    }

    if (currentStep.step_condition.value !== "none" && steps.length > 0) {
      if (!currentStep.dependent_on) {
        errors.dependent_on = "Dependent On is required";
        isValid = false;
      }
    }

    if (currentStep.delay_duration == "") {
      errors.delay_duration = "Delay Duration is required";
      isValid = false;
    }

    setFormErrors(errors);
    if (isValid) {
      const url = `${API_SEQUENCE_STEPS}/${id}`;
      const method = currentStep._id ? "PUT" : "POST";
      fetchMethod(method, `${url}`, {
        sequence_id: id,
        step_type: currentStep.step_type.value,
        delay_type: currentStep.delay_type.value,
        delay_duration: currentStep.delay_duration,
        task_priority: currentStep.task_priority,
        due_date: currentStep.due_date,
        note: currentStep.note,
        template: currentStep.template ? currentStep.template.value : null,
        step_condition: steps.length > 0 ? currentStep.step_condition?.value : 'none',
        dependent_on: currentStep.dependent_on?.value,
      })
        .then((data) => {
          if (data.status == "success") {
            // Update state to include the new step in both grid view and list view
            // Assuming sequenceSteps is your state containing the steps
            const newStep = {
              _id: data.step_id, // Assuming the response contains the ID of the newly added step
              step_type: currentStep.step_type.value,
              delay_type: currentStep.delay_type.value,
              delay_duration: currentStep.delay_duration,
              task_priority: currentStep.task_priority,
              due_date: currentStep.due_date,
              note: currentStep.note,
              template: currentStep.template ? currentStep.template.tempBody : null,
              template_name: currentStep.template ? currentStep.template.label : null,
              step_condition: currentStep.step_condition.value,
              dependent_on: currentStep.dependent_on ? currentStep.dependent_on.value : null,
              timestamp: new Date(),
              scheduled: 0,
              sent: 0,
              opened: 0,
              opt_out: 0,
              task_added: 0
            };
            setSteps([...steps, newStep])
            handleClose()
            window.location.reload();
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Error",
              detail: data.message,
            });
          }

        })
        .catch((error) => {
          console.error(error);
          if (error instanceof TypeError) {
            setnetworkError(true)
            return;
          }
        });
      setSequenceStepsLoading(false)
    }
  };

  const handleDelete = (id) => {
    setSequenceStepsLoading(true);
    fetchMethod("DELETE", `${API_SEQUENCE_STEPS}/${id}`, {})
      .then(() => {
        // toast.current.show({
        //   severity: "success",
        //   summary: "Success",
        //   detail: "Step Deleted Successfully",
        // });
        setSteps(steps.filter((step) => step._id !== id));
        setSequenceStepsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)

          return;
        }
      });
  };

  const handleTemplateBody = () => {
    setshowPreviewModal(true)
  }

  let viewTemplateBody
  if (currentStep.template != null) {
    viewTemplateBody = currentStep.template.tempBody
  }

  const Card = ({ step, isConnectable }) => {
    // Determine if the plus icon should be displayed
    const showPlusIcon = (step.openedList?.length > 0 && step.notOpenedList?.length > 0);

    return (
      <div className="flow-cards-all">
        {step.order != 1 &&
          <Handle type="target" position="top" style={{ background: '#555' }} isConnectable={isConnectable} />
        }
        <div className="card n-ppost mb-0">
          <div className="card-header">
            <div className="p-panel-header" data-pc-section="header">
              <span
                id="pr_id_226_header"
                className="p-panel-title"
                data-pc-section="title"
              >
                <div className="panel-header ">
                  <div className="panel-header-left-2 d-flex align-items-center">
                    <span>{step.order}</span>
                    <i className="pi pi-envelope"></i>
                    <h4>
                      {step.step_type == "automatic_email" ? "Automatic Email" : step.step_type == "manual_email" ? "Manual Email" : step.step_type == "phone_call" ? "Phone Call" : "Action Item"}
                    </h4>
                    <div className="statistic_left"></div>
                  </div>
                  <div className="panel-header-left d-flex align-items-center">
                    <span className="deliver_time">
                      {step.step_condition == "opened"
                        ? "if opened"
                        : step.step_condition == "not_opened"
                          ? "if not opened"
                          : ""}
                      {step.step_condition != "none" && " depends on Step " + step.dependent_on.split("_")[1]}
                    </span>
                  </div>
                </div>
              </span>
            </div>
          </div>
          <div className="card-body">
            <div className="chart-stats">
              <div class="chart-stats-inner">
                <p>{Math.max(step.scheduled - step.sent || 0)}{" "}</p>
                <h5>Scheduled</h5>
              </div>
              {step.step_type !== "automatic_email" || "Automatic Email" &&
                <div class="chart-stats-inner email-redirect" style={{ cursor: "pointer" }} onClick={() => handleSearchStep('sent', "step" + " " + (step.order))}>
                  <p>{step.sent || 0}</p> <h5>Delivered</h5>
                </div>
              }
              {step.step_type !== "automatic_email" || "Automatic Email" &&
                <div class="chart-stats-inner email-redirect" style={{ cursor: "pointer" }} onClick={() => handleSearchStep('Opened', "step" + " " + (step.order))}>
                  <p>{step.opened || 0}</p> <h5>Opened</h5>
                </div>
              }
              {step.step_type !== "automatic_email" || "Automatic Email" &&
                <div class="chart-stats-inner">
                  <p>{step.opt_out || 0}</p> <h5>Unsubscribed</h5>
                </div>
              }
              {step.step_type == "automatic_email" || "Automatic Email" &&
                <div class="chart-stats-inner">
                  <p>{step.task_added}{" "}</p> <h5>Task added</h5>
                </div>
              }
            </div>
          </div>
          <div className="card-footer d-flex justify-content-center">
            <div className="panel-header-right" >
              <span className="deliver_time tooltipz">
                {step.step_type == "automatic_email" || "Automatic Email" &&
                  <span className="timer-delivery">
                    Add task in {step.delay_duration} {step.delay_type}
                  </span>
                }
                {step.step_type !== "automatic_email" || "Automatic Email" &&
                  <span className="timer-delivery">
                    Deliver Email in {step.delay_duration} {step.delay_type}
                  </span>
                }
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          {step.step_type !== "automatic_email" || "Automatic Email" && !showPlusIcon && !step.openedList?.length &&
            <div className="n-ppost-name">
              <i
                className="pi pi-plus-circle"
                style={{ fontSize: "1rem" }}
                onClick={() => {
                  handleShow({
                    step_type: "",
                    delay_type: { value: "days", label: "days" },
                    delay_duration: "",
                    task_priority: "",
                    note: "",
                    due_date: initialDueDate,
                    template: null,
                    step_condition: { value: "opened", label: "Opened" },
                    dependent_on: !showPlusIcon ? { value: "step_" + step.order, label: "Step " + step.order } : "",
                  });
                  getTemplateData();
                  setVisible(true);
                }}
              ></i>
            </div>
          }
          {step.step_type !== "automatic_email" || "Automatic Email" && !showPlusIcon && !step.notOpenedList?.length &&
            <div className="n-ppost-name2 ">
              <i
                className="pi pi-plus-circle"
                style={{ fontSize: "1rem" }}
                onClick={() => {
                  handleShow({
                    step_type: "",
                    delay_type: { value: "days", label: "days" },
                    delay_duration: "",
                    task_priority: "",
                    note: "",
                    due_date: initialDueDate,
                    template: null,
                    step_condition: { value: "not_opened", label: "Not Opened" },
                    dependent_on: !showPlusIcon ? { value: "step_" + step.order, label: "Step " + step.order } : "",
                  });
                  getTemplateData();
                  setVisible(true);
                }}
              ></i>
            </div>
          }
        </div>
        <Handle type="source" position="bottom" style={{ background: '#555' }} isConnectable={isConnectable} />
      </div>
    )
  }
  const FlowEditor = () => {
    const [nodes, setNodes] = useNodesState([]);
    const [edges, setEdges] = useEdgesState([]);

    useEffect(() => {
      if (steps.length > 0) {
        const initialX = 250; // Central reference position for unlinked nodes
        const xOffset = 200; // Horizontal offset for linked nodes
        const stepY = 300; // Vertical step per node
        const nodePositions = {};
        const minSpacing = 400; // Minimum horizontal spacing between nodes to prevent overlap

        // First pass to create nodes with initial positions
        steps.forEach((step, index) => {
          nodePositions[step._id] = {
            x: initialX,
            y: index * stepY
          };
        });

        // Second pass to adjust positions based on references and conditions
        steps.forEach((step, index) => {
          if (step.reference_id && nodePositions[step.reference_id]) {
            const referenceNodeY = nodePositions[step.reference_id].y;
            nodePositions[step._id].y = referenceNodeY + stepY; // Place it below the referenced node
            nodePositions[step._id].x = step.step_condition === "opened"
              ? nodePositions[step.reference_id].x - xOffset // Place to the left if "opened"
              : nodePositions[step.reference_id].x + xOffset; // Place to the right if "not_opened"
          }
        });

        // Third pass to adjust for overlap
        steps.forEach((step, index) => {
          steps.slice(index + 1).forEach((otherStep, otherIndex) => {
            if (Math.abs(nodePositions[step._id].y - nodePositions[otherStep._id].y) < 150) { // Check for potential overlap in y-axis
              if (Math.abs(nodePositions[step._id].x - nodePositions[otherStep._id].x) < minSpacing) {
                nodePositions[otherStep._id].x = nodePositions[step._id].x + minSpacing; // Adjust x position to remove overlap
              }
            }
          });
        });

        const newNodes = steps.map((step, index) => ({
          id: `node_${index + 1}`,
          type: 'customNode',
          data: { label: <Card step={step} isConnectable={true} /> },
          position: { x: nodePositions[step._id].x, y: nodePositions[step._id].y }
        }));
        setNodes(newNodes);

        // Create edges based on matching _id to reference_id
        const newEdges = [];
        steps.forEach((step, index) => {
          steps.forEach((otherStep, otherIndex) => {
            if (step._id === otherStep.reference_id) {
              newEdges.push({
                id: `e${index}-${otherIndex}`,
                source: `node_${index + 1}`,
                target: `node_${otherIndex + 1}`,
                animated: true,
                style: { stroke: 'green' }
              });
            }
          });
        });
        setEdges(newEdges);
      }
    }, []);

    const handleDrag = (event, node) => {
      setNodes(ns => ns.map(n => {
        if (n.id === node.id) {
          return {
            ...n,
            position: {
              x: node.position.x,
              y: node.position.y
            }
          };
        }
        return n;
      }));
    };

    const handleDragStop = (event, node) => {
      setNodes(ns => ns.map(n => {
        if (n.id === node.id) {
          return {
            ...n,
            position: {
              x: node.position.x,
              y: node.position.y
            }
          };
        }
        return n;
      }));
    };

    const onConnect = (params) => {
      setEdges(eds => addEdge({ ...params, type: 'custom', animated: true, style: { stroke: '#ff0072' } }, eds));
    };

    return (
      <>
        <div className="editor-cards" style={{ height: '100vh' }}>
          {/* <button onClick={exportToJson} className="export-json-btn">Export JSON</button> */}
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodeDrag={handleDrag} // Adding handleDrag to continuously update position
            onNodeDragStop={handleDragStop}
            onConnect={onConnect}
          >
            <MiniMap />
            <Controls />
            <Background color="#aaa" gap={16} />
          </ReactFlow>
          {/* {isModalOpen && <Modal jsonData={jsonData} onClose={handleClose} onCopy={copyToClipboard} />} */}
        </div>
      </>
    );
  };

  useEffect(() => {
    setCurrentFilter('all')
  }, [activeTabIndex]);

  const handleSearchStep = (stepType, stepNum) => {
    // Logic to set the current filter in the Emails component based on the stepType
    if (stepType === 'Opened') {
      setCurrentFilter(
        {
          Status: 'Opened',
          Step: stepNum
        });
      setActiveTabIndex(2)
    } else if (stepType === 'sent') {
      setCurrentFilter({
        Status: 'sent',
        Step: stepNum
      }
      );
      setActiveTabIndex(2)
    }
  };

  return (
    <div>
      <div className="overflow-x-auto overflow-y-hidden">
        {!sumScheduled && sequenceStepsloading ?
          <Skeleton width="100%" height="60px" className="mb-3" />
          :
          (<div className="top__statistics d-flex justify-content-between">
            <div className="statistic_left col-6">
              <ul>
                <li>
                  {sumBounced}
                  <span className="text-danger">Bounced</span>
                </li>
                <li>
                  {sumReplied}
                  <span className="text-success">Replied</span>
                </li>
              </ul>
            </div>

            <div className="statistic_right text-end col-6">
              <ul>
                <li>

                  {sumScheduled - sumSent - sumTaskAdded < 0 ? 0 : sumScheduled - sumSent - sumTaskAdded}
                  <span>Scheduled</span>
                </li>
                <li>
                  {sumTaskAdded}
                  <span>Tasks Added</span>
                </li>
                <li>
                  {sumSent}
                  <span>Delivered</span>

                </li>
                <li>
                  {sumOpened}
                  <span>Opened</span>

                </li>
                <li>
                  {sumOptOut}
                  <span>Unsubscribed</span>
                </li>
              </ul>
            </div>
          </div>)
        }
        {steps.length > 0 ? (
          <FlowEditor />
        ) : (
          <p></p>
        )}
      </div>
      <Dialog
        header="Add Sequence Steps"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={handleClose}
        draggable={false}
        resizable={false}
        className={currentStep.step_type.label === 'Automatic Email' || currentStep.step_type.label === undefined ? 'automaticEmailClass' : 'addSequenceSteps'}
      >
        <Form>
          <Row className="">
            <Col xs={6}>
              <Form.Group controlId="formStepType" className="mb-3">
                <Form.Label>
                  Step Type <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  value={currentStep.step_type}
                  options={stepTypesOptions}
                  onChange={(selectedOption) =>
                    setCurrentStep({
                      ...currentStep,
                      step_type: selectedOption,
                    })
                  }
                  isSearchable={false}
                />
                {formErrors.step_type && (
                  <Form.Text className="text-danger">
                    {formErrors.step_type}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col xs={6}>
              {currentStep.step_type.value === "automatic_email" && (
                <Form.Group controlId="formTemplate" className="mb-3">
                  <Form.Label>
                    Template <span className="text-danger">*</span>
                  </Form.Label>
                  {templateLoader ? <Skeleton height="40px"></Skeleton> :
                    <Select
                      value={currentStep.template}
                      options={templates}
                      onChange={(selectedOption) =>
                        setCurrentStep({
                          ...currentStep,
                          template: selectedOption,
                        })
                      }
                    />
                  }
                  {formErrors.template && (
                    <Form.Text className="text-danger">
                      {formErrors.template}
                    </Form.Text>
                  )}
                </Form.Group>
              )}
            </Col>
            <Col xs={6}>
              <Form.Group
                controlId="formDelayDuration"
                className="mb-3"
              >
                <Form.Label>
                  Delay Duration{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  min={1}
                  placeholder="Enter Duration"
                  value={currentStep.delay_duration}
                  onChange={(e) =>
                    setCurrentStep({
                      ...currentStep,
                      delay_duration: e.target.value,
                    })
                  }
                  onKeyPress={(e) => {
                    if (e.key === "-" || e.key === "e") {
                      e.preventDefault();
                    }
                  }}
                />
                {formErrors.delay_duration && (
                  <Form.Text className="text-danger">
                    {formErrors.delay_duration}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col xs={6}>
              <Form.Group controlId="formDelayType" className="mb-3">
                <Form.Label>
                  Delay Type <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  value={currentStep.delay_type}
                  options={delayOptions}
                  onChange={(selectedOption) =>
                    setCurrentStep({
                      ...currentStep,
                      delay_type: selectedOption,
                    })
                  }
                  isSearchable={false}
                />
              </Form.Group>
            </Col>
            {shouldShowOpenedOption() && (
              <Col xs={6}>
                <Form.Group controlId="formTemplate" className="mb-3">
                  <Form.Label>
                    Step Condition{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Select
                    value={
                      currentStep.step_condition || {
                        value: "opened",
                        label: "Opened",
                      }
                    } // Default value here
                    options={[
                      { value: "none", label: "None" },
                      { value: "opened", label: "Opened" },
                      ...(shouldShowOpenedOption()
                        ? [
                          {
                            value: "not_opened",
                            label: "Not Opened",
                          },
                        ]
                        : []),
                    ]}
                    onChange={(selectedOption) => {
                      setCurrentStep({
                        ...currentStep,
                        step_condition: selectedOption,
                      })
                      fetchStep(selectedOption.value);
                    }
                    }
                  />
                </Form.Group>
              </Col>
            )}

            {steps.length > 0 && (currentStep.step_condition.value == "opened" ||
              currentStep.step_condition.value == "not_opened") && (
                <Col xs={6}>
                  <Form.Group controlId="formTemplate" className="mb-3">
                    <Form.Label>
                      Dependent On
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Select
                      value={currentStep.dependent_on} // Default value here
                      options={dependentOnStepOptions}
                      onChange={(selectedOption) =>
                        setCurrentStep({
                          ...currentStep,
                          dependent_on: selectedOption,
                        })
                      }
                    />
                    {formErrors.dependent_on && (
                      <Form.Text className="text-danger">
                        {formErrors.dependent_on}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              )}

            {(currentStep.step_type.value === "manual_email" ||
              currentStep.step_type.value === "phone_call" ||
              currentStep.step_type.value === "action_item") && (
                <>
                  <Col xs={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Due Date <span className="text-danger">*</span>
                        <DatePicker
                          className="form-control h-4px"
                          selected={moment.tz(currentStep.due_date, timezone_mapping[timezone]).toDate()}
                          onChange={date => handleDateSelection(moment.tz(date, timezone_mapping[timezone]).format("DD-MM-YYYY"))}
                          minDate={new Date(moment().tz(timezone_mapping[timezone])?.startOf('day').format())}
                          dateFormat="dd-MM-yyyy"
                        />
                      </Form.Label>

                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group
                      controlId="formTaskPriority"
                      className="mb-3"
                    >
                      <Form.Label htmlFor="formTaskPriority">
                        Task Priority{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      {[
                        "High Priority",
                        "Medium Priority",
                        "Low Priority",
                      ].map((priority) => (
                        <div key={priority}>
                          <Form.Check
                            type="radio"
                            id={`priority-${priority.toLowerCase()}`} // Corrected input id
                            label={priority}
                            value={priority}
                            checked={
                              currentStep.task_priority === priority
                            }
                            onChange={() =>
                              setCurrentStep({
                                ...currentStep,
                                task_priority: priority,
                              })
                            }
                          />
                        </div>
                      ))}
                      {formErrors.task_priority && (
                        <Form.Text className="text-danger">
                          {formErrors.task_priority}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12}>
                    <Form.Group controlId="formNote" className="mb-3">
                      <Form.Label>Note</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={currentStep.note}
                        onChange={(e) =>
                          setCurrentStep({
                            ...currentStep,
                            note: e.target.value,
                          })
                        }
                        maxLength={500}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
          </Row>
        </Form>
        <div className="d-flex justify-content-end mt-3">
          {
            currentStep.template != null &&
            <Button onClick={handleTemplateBody} outlined size="small">
              Preview
            </Button>
          }
          <Button onClick={handleClose} outlined size="small" className="ms-2">
            Close
          </Button>
          <Button
            onClick={handleSubmit}
            loading={sequenceStepsloading}
            icon="pi pi-check"
            label="Save"
            size="small"
            className="ms-2"
          />
        </div>
        <Dialog
          className="addTemplate"
          style={{ width: "40vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          header={"Template Body"}
          visible={showPreviewModal}
          draggable={false}
          resizable={false}
          onHide={() => setshowPreviewModal(false)}
        >
          <Form.Label dangerouslySetInnerHTML={{ __html: viewTemplateBody }}></Form.Label>
          {/* <Form.Label>Dummy content for template body</Form.Label> */}

        </Dialog>
      </Dialog>
    </div>
  );
};

export default GridView;
