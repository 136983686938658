import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useContext, useEffect, useState } from 'react'
import ApiListFile from './ApiListFile';
import { fetchMethod } from './ApiHeader';
import { contextVar } from './ContextVar';


function LinkedMailboxModal(props) {
  const { API_SAVE_TOKENS } = ApiListFile()
  const { alertGmail, setalertGmail } = useContext(contextVar)
  const pathName = localStorage.getItem('pathName')
  console.log('path from local storage', pathName)

  useEffect(() => {
    if (props?.visibleMailboxModal === true) {
      if (pathName === '/settings/accountinfo') {
        handleRedirectToAccount();
      } else {
        handleRedirectToDash();
      }
    }
  }, [props?.visibleMailboxModal]);

 
  const handleRedirectToDash = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
  
    if (code) {
      // Determine the provider based on the current URL
      let email_provider = window.location.href.includes("googleapis")
        ? "google"
        : window.location.href.includes("zoho")
        ? "zoho"
        : "outlook";
  
      // Send the code to your server
      fetchMethod("POST", `${API_SAVE_TOKENS}`, {
        code: code,
        email_provider: email_provider,
        redirect_uri: process.env.REACT_APP_URL
      })
        .then((data) => {
          console.log('data', data);
          if (data?.success === false) {
            if (data?.message === "Internal Server Error") {
              // Retry on internal server error
              handleRedirectToDash();
            } else if (data?.message === "Permission Denied") {
              // Show alert if permission is denied
              setalertGmail(true);
            }
          } else {
            // Redirect to dashboard on success
            window.location.replace(process.env.REACT_APP_URL + "/");
          }
        })
        .catch((error) => {
          console.error("Error during token saving:", error);
        });
    }
  };


  const handleRedirectToAccount = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      // Check the current window location to determine the provider
      let email_provider = window.location.href.includes("googleapis")
        ? "google"
        : window.location.href.includes("zoho") ? "zoho" : "outlook";

      // Send the code to your server
      fetchMethod("POST", `${API_SAVE_TOKENS}`, {
        code: code,
        email_provider: email_provider,
        redirect_uri: process.env.REACT_APP_URL
      })
        .then((data) => {
          console.log('data', data)
          if (data?.success === false) {
            if (data?.message === "Internal Server Error") {
              // Retry on internal server error
              handleRedirectToDash();
            } else if (data?.message === "Permission Denied") {
              // Show alert if permission is denied
              setalertGmail(true);
            }
          } 
          else {
            window.location.replace(process.env.REACT_APP_URL + "/settings/accountinfo");
          }
        });
    }
  }


  const handleLinkMailbox = () => {
    // handleLinkMailbox 
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // replace with your client id
    const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI; // replace with your redirect uri
    const scope = encodeURIComponent(
      "https://www.googleapis.com/auth/gmail.send " +
      "https://www.googleapis.com/auth/userinfo.email"
    );
    const responseType = "code";
    const accessType = "offline";
    const prompt = "consent";

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&access_type=${accessType}&prompt=${prompt}`;


    props?.setvisibleMailboxModal(false)
    // Redirect user to Google's OAuth 2.0 server
    window.location.href = authUrl;
  };


  const handleLinkOutlookMailbox = () => {
    const clientId = process.env.REACT_APP_OUTLOOK_CLIENT_ID; // replace with your client id
    const redirectUri = process.env.REACT_APP_URL; // replace with your redirect uri
    const scope = encodeURIComponent(
      "https://graph.microsoft.com/Mail.Send " +
      "https://graph.microsoft.com/User.Read offline_access https://graph.microsoft.com/Mail.Read"
    );
    const responseType = "code";
    const responseMode = "query";
    const prompt = "consent";

    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&response_mode=${responseMode}&prompt=${prompt}`;

    if (scope) {
      // OAuth was successful, ensure modal stays closed
      props?.setvisibleMailboxModal(false);
    }
    // Redirect user to Microsoft's OAuth 2.0 server
    window.location.href = authUrl;
  };



  return (
    <>
      <Dialog
        visible={props?.visibleMailboxModal || alertGmail}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
        onHide={() => { }}
        closable={false}
      >
        {alertGmail ? <h6>There is an issue with your email account. PERMISSION_DENIED: Request had insufficient authentication scopes.</h6> :
          <>
            <h4>Link your mailbox to get started</h4>
            <p>
              By connecting your mailbox, you'll be able to capture all your email
              engagement in one spot. Based on your plan, you can send emails via
              using Gmail, Microsoft Exchange, Office 365, Outlook, or your own
              custom server. Learn more.
            </p>
          </>
        }
        <Button
          label="Link Gmail Mailbox"
          size="small"
          outlined
          icon="pi pi-google"
          className="me-4"
          onClick={handleLinkMailbox}
        />
        <Button
          label="Link Outlook Mailbox"
          size="small"
          outlined
          className="me-4"
          icon="pi pi-microsoft"
          onClick={handleLinkOutlookMailbox}
        />
      </Dialog>
    </>
  )
}

export default LinkedMailboxModal