import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { TabPanel } from "primereact/tabview";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Chart } from "react-google-charts";
import "./LocationChart.css"

const LocationChart = () => {
    const dt = useRef(null);

    const data = [
        { id: 1, country: "Germany", popularity: 200, },
        { id: 2, country: "United States", popularity: 600, },
        { id: 3, country: "Brazil", popularity: 600, },
        { id: 4, country: "Canada", popularity: 1000, },
        { id: 5, country: "France", popularity: 500, },
        { id: 6, country: "RU", popularity: 700, },
        { id: 7, country: "India", popularity: 800, },
        { id: 8, country: "Australia", popularity: 200, },
        { id: 9, country: "China", popularity: 900, },
        { id: 10, country: "Japan", popularity: 100, },
    ];


    const detailData = [
        { id: 1, country: "Germany", continent: "Europe", capital: "Berlin" },
        { id: 2, country: "United States", continent: "North America", capital: "Washington D.C." },
        { id: 3, country: "Brazil", continent: "South America", capital: "Brasília" },
        { id: 4, country: "Canada", continent: "North America", capital: "Ottawa" },
        { id: 5, country: "France", continent: "Europe", capital: "Paris" },
        { id: 6, country: "RU", continent: "Europe", capital: "Moscow" },
        { id: 7, country: "India", continent: "Asia", capital: "New Delhi" },
        { id: 8, country: "India", continent: "Asia", capital: "New Delhi" },
        { id: 9, country: "India", continent: "Asia", capital: "New Delhi" },
        { id: 10, country: "Australia", continent: "Australia", capital: "Canberra" },
        { id: 11, country: "China", continent: "Asia", capital: "Beijing" },
        { id: 12, country: "Japan", continent: "Asia", capital: "Tokyo" },
        { id: 13, country: "Canada", continent: "North America", capital: "Ottawa" },
        { id: 14, country: "Canada", continent: "North America", capital: "Ottawa" },
        { id: 15, country: "Canada", continent: "North America", capital: "Ottawa" },
        { id: 16, country: "Canada", continent: "North America", capital: "Ottawa" },
        { id: 17, country: "Canada", continent: "North America", capital: "Ottawa" },
    ];

    const [countryVal, setcountryVal] = useState(data[0].country)
    const [popularityVal, setpopularityVal] = useState(data[0].popularity)
    const [filteredDetailData, setFilteredDetailData] = useState([]);
    const [dynamicColumns, setDynamicColumns] = useState([]);

    useEffect(() => {
        // Set default country to India
        const defaultCountry = "India";
        const defaultIndex = data.findIndex(item => item.country === defaultCountry);
        if (defaultIndex !== -1) {
            const chartWrapper = { getChart: () => ({ getSelection: () => [{ row: defaultIndex }] }) };
            handleChartSelect({ chartWrapper });
        }
    }, []);

    const handleChartSelect = ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length === 0) return;
        const selectedIndex = selection[0].row;
        const selectedCountry = data[selectedIndex].country;

        // Filter detailData based on the selected country
        const filteredData = detailData.filter(item => item.country === selectedCountry);

        // Update filteredDetailData state
        setFilteredDetailData(filteredData);

        // Get keys from the first object in filtered data
        const keys = Object.keys(filteredData[0]);

        // Dynamically generate columns
        const columns = keys.map(key => ({
            field: key,
            header: key.charAt(0).toUpperCase() + key.slice(1)
        }));

        // Update dynamicColumns state
        setDynamicColumns(columns);
    };


    // Transforming data array into an array of arrays
    const chartData = data.map(({ country, popularity }) => [country, popularity]);
    const dataTableData = countryVal
        ? [{ country: countryVal, popularity: popularityVal }]
        : data;


   
    return (
        <>
            <div>
                <Row className="mb-4 d-flex">
                    <Col md={6}>
                        <Panel header={
                            <div className=" ">
                                <h6 className="m-0">Opens By Location</h6>
                            </div>
                        }>
                            <div>
                                <Chart
                                    chartEvents={[
                                        {
                                            eventName: "select",
                                            callback: handleChartSelect,
                                        },
                                    ]}
                                    chartType="GeoChart"
                                    width="100%"
                                    height="300px"
                                    data={[["Country", "Popularity"], ...chartData]}
                                />
                            </div>
                        </Panel>
                    </Col>
                    <Col md={6}>
                        <div className='data-country-wise card p-0' >
                            <div>
                                <DataTable value={filteredDetailData}
                                    responsive
                                    ref={dt}
                                    paginator
                                    rows={5}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    // tableStyle={{ minWidth: "30rem" }}
                                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                    currentPageReportTemplate="{first} to {last} of {totalRecords}">
                                    {dynamicColumns
                                        .filter(column => column.field !== 'id') // Exclude the 'id' column
                                        .map(column => (
                                            <Column key={column.field} field={column.field} header={column.header} />
                                        ))
                                    }
                                </DataTable>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>

        </>
    );
};

export default LocationChart;
