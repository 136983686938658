// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal .modal-content {
    background-color: #c42a2a;
    border-color: #fb2828;
    border-radius: 1px;
}

.custom-modal-body .error-message {
    color: #fefcfc;
    font-size: 1.2rem;
}
.error-message {
    margin-bottom: 5px;
}

.icon-cross {
    position: absolute;
    /* bottom: 16px;
    right: 8px;
    size: 1.2px;
    color: #c11111; */
}
`, "",{"version":3,"sources":["webpack://./src/Components/ErrorBoundaryModal.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB;;;qBAGiB;AACrB","sourcesContent":[".custom-modal .modal-content {\n    background-color: #c42a2a;\n    border-color: #fb2828;\n    border-radius: 1px;\n}\n\n.custom-modal-body .error-message {\n    color: #fefcfc;\n    font-size: 1.2rem;\n}\n.error-message {\n    margin-bottom: 5px;\n}\n\n.icon-cross {\n    position: absolute;\n    /* bottom: 16px;\n    right: 8px;\n    size: 1.2px;\n    color: #c11111; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
