import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { Panel } from "primereact/panel";
import moment from "moment";
import '../assets/css/style.css'
import 'moment-timezone';
import ApiListFile from "../Components/ApiListFile";
import ApiHeader, { fetchMethod } from "../Components/ApiHeader";
import { contextVar } from "../Components/ContextVar";

const ActivityLogs = () => {
  const timezoneMap = {
    "Pacific Time": "America/Los_Angeles",
    "Mountain Time": "America/Denver",
    "Central Time": "America/Chicago",
    "Eastern Time": "America/New_York",
    "UTC": "UTC",
    "Europe/London": "Europe/London",
    "Paris": "Europe/Paris",
    "Athens": "Europe/Athens",
    "Australia Eastern Standard Time": "Australia/Sydney",
    "Australia Western Standard Time": "Australia/Perth",
    "Australia Central Standard Time": "Australia/Adelaide",
    "Middle East Time": "Asia/Tehran",
    "Near East Time": "Asia/Dubai",
    "Pakistan Lahore Time": "Asia/Karachi",
    "Indian Standard Time": "Asia/Kolkata",
    "Bangladesh Standard Time": "Asia/Dhaka",
    "Vietnam Standard Time": "Asia/Ho_Chi_Minh",
    "Japan Standard Time": "Asia/Tokyo",
    "Solomon Standard Time": "Pacific/Guadalcanal",
    "New Zealand Standard Time": "Pacific/Auckland",
    "Midway Islands Time": "Pacific/Midway",
    "Hawaii Standard Time": "Pacific/Honolulu",
    "Alaska Standard Time": "America/Anchorage",
    "Puerto Rico and US Virgin Islands Time": "America/Puerto_Rico",
    "Canada Newfoundland Time": "America/St_Johns",
    "Brazil Eastern Time": "America/Sao_Paulo"
};

  const {
    API_ACTIVITY_LOGS,
    API_POST_USER_TIMEZONES
  } = ApiListFile()

  const { setnetworkError } = useContext(contextVar)

  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [timezone, setTimezone] = useState("UTC")
  const observer = useRef();
  
  const lastLogRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [hasMore]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const data = await fetchMethod("GET", `${API_ACTIVITY_LOGS}?page=${page}&limit=10`, {})
        if (data.length < 10) {
          setHasMore(false);
        }
        setLogs([...logs, ...data]);
        const data2 = await fetchMethod("GET", `${API_POST_USER_TIMEZONES}`, {})
        if (data2.item != null) {
          setTimezone(data2.item.timezone);
        }
      } catch (error) {
        console.error("Error signing up", error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setLoading(false);
          return;
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();  // Call the async function

  }, [page]);

  const convertTimestampToTimezone = (timestamp) => {

    if (!timestamp) {
      console.warn('Missing timestamp');
      return 'Invalid Date';
  }

  try {
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) {
          throw new Error('Invalid date');
      }

      const now = new Date(); // Current date and time
      const diffInMilliseconds = now - date; // Difference in milliseconds

      // Define time units in milliseconds
      const minute = 60 * 1000;
      const hour = 60 * minute;
      const day = 24 * hour;
      const week = 7 * day;

      // Calculate the difference in relative units
      const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
      
      if (diffInMilliseconds < minute) {
          // Less than a minute ago
          return rtf.format(-Math.floor(diffInMilliseconds / 1000), 'second');
      } else if (diffInMilliseconds < hour) {
          // Less than an hour ago
          return rtf.format(-Math.floor(diffInMilliseconds / minute), 'minute');
      } else if (diffInMilliseconds < day) {
          // Less than a day ago
          return rtf.format(-Math.floor(diffInMilliseconds / hour), 'hour');
      } else if (diffInMilliseconds < week) {
          // Less than a week ago
          return rtf.format(-Math.floor(diffInMilliseconds / day), 'day');
      } else {
          // More than a week ago
          return rtf.format(-Math.floor(diffInMilliseconds / week), 'week');
      }
  } catch (error) {
      console.error('Error converting timestamp:', error);
      return 'Invalid Date';
  }
};



  return (
    <Row>
      <Col md={12}>
        <Panel header="Activity Logs">
          <div className="">
            {/* <h5 className="mt-1">This Week</h5> */}
            <div className="left-timeline mt-3 ps-3 mb-3">
              <ul className="list-unstyled events mb-0">
                {logs.map((log, index) => (
                  <div key={index} ref={index === logs.length - 1 ? lastLogRef : null}>
                    <li className="event-list">
                      <div className="pb-4">
                        <div className="d-flex">
                          <div className="event-date text-center me-4 flex-shrink-0">
                            <div className="bg-soft-primary p-1 rounded text-primary fs-14">
                              {/* {moment(log.timestamp)?.tz(timezone_mapping[timezone])?.fromNow()} */}
                              {convertTimestampToTimezone(log.timestamp)}
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="fs-15 mt-0 mb-1">
                              {log.activity_type}
                            </h6>
                            <p className="text-muted fs-14">{log.activity}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>
                ))}

              </ul>
              {/* Loader */}
              {loading && (
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
              )}
            </div>
          </div>
        </Panel>
      </Col>
    </Row>
  );
};

export default ActivityLogs;
