import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, ButtonGroup, Container, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";

import TemplateEditor from "./TemplateEditor";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import ApiListFile from "../Components/ApiListFile";
import ApiHeader, { fetchMethod } from "../Components/ApiHeader";

const ComposeDialog = () => {

  const {
    API_GET_RECIPIENT_LIST,
    API_SEND_EMAIL,
    API_TEMPLATE,
    API_DISPLAY_SIGNATURE
  } = ApiListFile()

  const [dialogState, setDialogState] = useState("normal"); // 'normal', 'minimized', 'maximized'
  const [visibleEmail, setVisibleEmail] = useState(false);
  const [selectedLead, setSelectedLead] = useState([]);
  const [selectedCCLead, setSelectedCCLead] = useState([]);
  const [emailCCHidden, setEmailCCHidden] = useState(true);
  const toast = useRef(null)
  const toggleEmailCCVisibility = () => {
    setEmailCCHidden(!emailCCHidden);
  };
  const [templates, setTemplates] = useState([]);
  const [validation, setValidation] = useState({
    template_name: "",
    template_subject: "",
    template_body: "",
  });
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [leads, setLeads] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [composeLoading, setComposeLoading] = useState(true);

  const handleMaximize = () => {
    setDialogState("maximized");
    document.body.style.overflow = 'visible'
  };

  const handleRestore = () => {
    setDialogState("normal");
    document.body.style.overflow = 'hidden'
  };

  // Email validation function
const isValidEmail = (email) => {
  const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/; // Simple email regex pattern
  return regex.test(email.trim()); // trim to remove any leading/trailing white spaces
};

const handleCreateEmailOption = (inputValue, selectedOptions, updateSelectedOptions) => {
  if (!emailExists(inputValue, selectedOptions) && isValidEmail(inputValue)) {
    const newOption = { label: inputValue, value: { email: inputValue } };
    updateSelectedOptions([...selectedOptions, newOption]);
  }
};

  useEffect(() => {
    setComposeLoading(true);
    fetchMethod("GET", `${API_TEMPLATE}`, {})
      .then((data) => {
        const options = data?.map((template) => {
          let label = template.template_name;
          if (label.length > 40) {
            label = label.substr(0, 40) + "...";
          }
          return {
            value: template._id,
            label: label,
            subject: template.template_subject,
            body: template.template_body,
          };
        });
        setTemplates(options);
        fetchMethod("GET", `${API_GET_RECIPIENT_LIST}`, {})
          .then((data) => {
            const recipientOptions = data.items.map((recipient) => ({
              label: recipient.first_name + " " + recipient.last_name + " (" + recipient.email + ")",
              value: recipient,
            }));
            setLeads(recipientOptions);
            setComposeLoading(false)
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((err) => console.error(err));
  }, []);

  const sendEmailToLead = async () => {
    let isValid = true;
    let newValidation = { template_subject: "", template_body: "" };
  
    // Validate the subject field
    if (!currentTemplate.template_subject) {
      isValid = false;
      newValidation.template_subject = "Template subject is required";
    }
  
    // Validate the body field
    if (!currentTemplate.template_body) {
      isValid = false;
      newValidation.template_body = "Template body is required";
    }
  
    if (!selectedLead || selectedLead?.length === 0) {
      isValid = false;
      newValidation.to = "Recipient is required";
    }
  
    // Update the validation state
    setValidation(newValidation);
    
    if (!isValid) {
      return; // Exit early if validation fails
    }
  
    setComposeLoading(true); // Start loading
  
    const payload = {
      leads: selectedLead?.map((lead) => ({"email": lead.value.email})),
      template_id: selectedTemplate,
      template_subject: currentTemplate.template_subject,
      template_body: currentTemplate.template_body,
      cc: selectedCCLead?.map((lead) => lead.value.email),
    };
  
    try {
      const response = await fetchMethod("POST", `${API_SEND_EMAIL}`, payload);
        if (response.status === "success") {
          setVisibleEmail(false);
          setCurrentTemplate({ template_subject: "", template_body: "" });
          setSelectedLead([]);
          setSelectedCCLead([]);
          setSelectedTemplate("");
            toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Email Sent Successfully",
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.message,
          });
        }
      
    } catch (error) {
      console.error('Error sending email:', error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred while sending the email."
      });
    } finally {
      setComposeLoading(false); // Ensure loading is stopped in any case
    }
  };

  const emailExists = (email, list) => list.some(option => option.value.email === email);
  
  const handleEditorChange = (text) => {
    setCurrentTemplate({ ...currentTemplate, template_body: text })

  };

  const fetchSignature = () => {
    fetchMethod("GET", `${API_DISPLAY_SIGNATURE}`, {})
      .then((data) => {
        setCurrentTemplate({ ...currentTemplate, template_body: data.signature });

      })
      .catch((error) => console.error('Error fetching signature:', error.message))
  }

  useEffect(() => {

    fetchSignature()
  }, [])
  return (
    <>
      <Toast ref={toast} />
      {composeLoading ? (<Skeleton width="100%" height="3rem"></Skeleton>) : (<Link
        to="#"
        className="btn btn-danger d-block composeEmail"
        onClick={() => {
          setDialogState("normal")
          setVisibleEmail(true);
          document.body.style.overflow = 'block';
          setValidation({});
          setSelectedLead([]);
          setSelectedCCLead([])
          setCurrentTemplate({});
          setSelectedTemplate([]);
          setEmailCCHidden(true)
          fetchSignature()
        }}
      >
        Compose
      </Link>)}

      <Dialog
        header={
          <div className="d-flex justify-content-between align-items-center sendMail">
            <h5>Send Email</h5>
            <div>
              {dialogState === "normal" && (
                <>
                  <Button
                    icon="pi pi-window-minimize"
                    className="p-mr-2"
                    text
                    onClick={handleMaximize}
                  />
                </>
              )}
              {dialogState === "minimized" && (
                <Button
                  icon="pi pi-window-maximize"
                  className="p-mr-2"
                  text
                  onClick={handleRestore}
                />
              )}
              {dialogState === "maximized" && (
                <Button
                  icon="pi pi-window-maximize"
                  className="p-mr-2"
                  text
                  onClick={handleRestore}
                />
              )}
            </div>
          </div>
        }
        visible={visibleEmail}
        modal={false}
        onHide={() => { setVisibleEmail(false); document.body.style.overflow = 'visible' }}
        style={
          dialogState === "normal"
            ? { width: "45vw" }
            : dialogState === "minimized"
              ? { display: "none" }
              : { width: "15vw", height: "7vh", margin: 0 }
        }
        position="bottom-right"
        draggable={false}
        resizable={false}
        className="ComposeEmail"
      >
        <Form className="pb-3 pt-1">
          <Form.Group
            controlId="emailTo"
            className="mb-3 position-relative "
          >
            <Form.Label>To</Form.Label>

          <CreatableSelect
              isMulti
              placeholder="Ex: hello@example.com"
              value={selectedLead}
              onChange={(e) => setSelectedLead(e || [])}
              onCreateOption={(inputValue) => handleCreateEmailOption(inputValue, selectedLead, setSelectedLead)}
              options={leads}
              isValidNewOption={(inputValue, selectValue, selectOptions) => {
                return inputValue.trim() !== "" && inputValue && !emailExists(inputValue, selectValue);
              }}
            />
            
            <span className="clickCCdialog" onClick={toggleEmailCCVisibility}>
              CC
            </span>
            <div className="text-danger">{validation.to}</div>
          </Form.Group>
          <Form.Group
            controlId="emailCC"
            className={`mb-3 mt-3 ${emailCCHidden ? "d-none" : ""}`}
          >
            <Form.Label>CC</Form.Label>
            <CreatableSelect
              isMulti
              placeholder="Ex: hello@example.com"
              value={selectedCCLead}
              onChange={(e) => setSelectedCCLead(e || [])}
              onCreateOption={(inputValue) => handleCreateEmailOption(inputValue, selectedCCLead, setSelectedCCLead)}
              options={leads}
              isValidNewOption={(inputValue, selectValue, selectOptions) => {
                return inputValue.trim() !== "" && inputValue && !emailExists(inputValue, selectValue);
              }}
            />
          </Form.Group>

          <Form.Group controlId="templateType" className="mb-3 mt-3">
            <Form.Label>Template Type</Form.Label>
            <Dropdown
              value={selectedTemplate}
              onChange={(e) => {
                setSelectedTemplate(e.value);

                // Find the selected template object using the selected value
                const selectedOption = templates.find(
                  (template) => template.value === e.value
                );

                if (selectedOption) {
                  // Make sure the template is found
                  setCurrentTemplate({
                    ...currentTemplate,
                    template_subject: selectedOption.subject,
                    template_body: selectedOption.body,
                  });
                }
              }}
              options={templates}
              placeholder="Select a Template"
              className="w-full me-2 col-md-6"
            />
          </Form.Group>

          <Form.Group controlId="formTemplateSubject" className="mb-3 mt-3">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter template subject"
              name="template_subject"
              value={currentTemplate.template_subject || ""}
              onChange={(e) =>
                setCurrentTemplate({
                  ...currentTemplate,
                  template_subject: e.target.value,
                })
              }
            />
            <div className="text-danger">{validation.template_subject}</div>
          </Form.Group>

          <Form.Group controlId="formTemplateBody" className="mb-2">
            <Form.Label>Template Body</Form.Label>
            <TemplateEditor
              template_body={currentTemplate.template_body}
              onEditorChange={handleEditorChange}

            />
            <div className="text-danger" style={{ marginTop: "5rem" }}>
              {validation.template_body}
            </div>
          </Form.Group>
          <p>
            <b>Note</b>: You can use {`{{first_name}}`}, {`{{last_name}}`},{" "}
            {`{{company_name}}`}, {`{{email}}`}, and {`{{phone}}`} and{" "}
            {`{{unsubscribe}}`} as placeholders for recipient details.
          </p>
        </Form>
        <Button
          type="submit"
          size="small"
          label="Send"
          onClick={sendEmailToLead}
          icon="uil uil-message ms-2"
          iconPos="right"
          loading={composeLoading}
        />
      </Dialog>
    </>
  );
};
export default ComposeDialog;
