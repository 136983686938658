import React, { useState, useEffect, useRef, useCallback, useContext, useMemo } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import ApiListFile from "../Components/ApiListFile";
import ApiHeader, { fetchMethod } from "../Components/ApiHeader";
import { contextVar } from "../Components/ContextVar";
import { Dropdown } from "primereact/dropdown";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const Contacts = () => {

  const {
    API_DISPLAY_STAGE,
    API_STAGE,
    API_TRIGGER_CONTACT,
    API_UPDATE_STAGE_SEQUENCE
  } = ApiListFile()
  const { setnetworkError, userGroup } = useContext(contextVar)
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0)
  const dt = useRef(null);
  const toast = useRef(null);
  const buttonEl = useRef(null);
  const [updateDialogVisible, setUpdateDialogVisible] = useState(false);
  const [allStage, setAllStage] = useState([])
  const [validation, setValidation] = useState({
    stage_name: ""
  })
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: 'timestamp',
    sortOrder: -1
  });
  const [currentStage, setCurrentStage] = useState({
    stage_name: ""
  })
  const [selectedData, setSelectedData] = useState({});
  const [dropDownData, setdropDownData] = useState([]);
  const [isLoading, setisLoading] = useState(false)
  const [dropdownLoading, setDropdownLoading] = useState(false)
  const [isDisable, setisDisable] = useState(true)
  const [stageLoader, setstageLoader] = useState(true)

  useEffect(() => {
    fetchStages()
  }, []);

  const fetchStages = () => {
    setstageLoader(true)
    fetchMethod("GET", `${API_DISPLAY_STAGE}`, {})
      .then((data) => {
        if (data.status == 'success') {
          setdropDownData(data?.stages)
          setstageLoader(false)
        }
        else {
          setstageLoader(false)
        }
      })
      .catch((error) => {
        if (error instanceof TypeError) {
          setnetworkError(true)
          return;
        }
      });
  }
  useEffect(() => {
    fetchTriggers()
    setLoading(true)
  }, []);
  const fetchTriggers = () => {
    setDropdownLoading(true)
    fetchMethod("GET", `${API_TRIGGER_CONTACT}`, {})
      .then((data) => {
        setSelectedData(data)
        setDropdownLoading(false)
        setLoading(false)
      })
      .catch((error) => {
        if (error instanceof TypeError) {
          setnetworkError(true)
          setLoading(false)
          return;
        }
      });
  }

  const handleDropdownChange = (id, value) => {
    setisDisable(false)
    setSelectedData(prevSelectedData => ({
      ...prevSelectedData,
      [id]: value
    }));
  };

  const handleTriggerSave = () => {
    setisLoading(true)
    const payload = Object.entries(selectedData).reduce((acc, [key, value]) => {
      if (value && value._id) {
        acc[key] = value._id;
      }
      return acc;
    }, {});

    fetchMethod("POST", `${API_TRIGGER_CONTACT}`, payload)
      .then((data) => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Trigger Saved Successfully',
        });
        setisLoading(false)
        setisDisable(true)
      })
      .catch((error) => {
        if (error instanceof TypeError) {
          setisLoading(false)
          setisDisable(true)
        }
      });
  }

  const handleShow = (stages) => {
    setCurrentStage(stages)
  }

  const handleClose = () => {
    setVisible(false);
  }

  const handleSave = async () => {
    let isValid = true;
    let newValidation = { stage_name: "" };
    if (!currentStage.stage_name || currentStage.stage_name.trim() === "") {
      isValid = false;
      newValidation.stage_name = "Stage Name is required";
    }
    if (!currentStage.option) {
      isValid = false;
      newValidation.option = "Select Category";
    }

    setValidation(newValidation);
    if (isValid) {
      setLoading(true);

      currentStage.user_id = localStorage.getItem('user_id')
      try {
        const data = await fetchMethod("POST", API_STAGE, currentStage);
        if (data.status == 'success') {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: data.message,
          });
          setVisible(false);

        } else {
          toast.current.show({
            severity: 'warn',
            summary: 'Error',
            detail: data.message,
          });
        }
        setLoading(false);
        fetchStages();
        setTotalRecords(prevTotal => prevTotal + 1);
        setUpdateDialogVisible(false);

        setUpdateDialogVisible(false);
      } catch (error) {
        setnetworkError(true);
      }
    }
  }

  const handleUpdateStage = (stage) => {
    setValidation({
      stage_name: stage.stage_name ? "" : "Stage name is required",
      option: stage.option ? "" : "Category is required"
    });
    setCurrentStage(stage)
    setUpdateDialogVisible(true);
    setVisible(true)
  };

  const handleUpdate = async () => {
    let isValid = true;
    let newValidation = { stage_name: "" };
    if (!currentStage.stage_name || currentStage.stage_name.trim() === "") {
      isValid = false;
      newValidation.stage_name = "Stage Name is required";
    }
    if (!currentStage.option) {
      isValid = false;
      newValidation.option = "Select Category";
    }
    setValidation(newValidation);
    // if (!validation) return;
    if (isValid) {
      setLoading(true)
      const data = await fetchMethod("PUT", `${API_STAGE}/${currentStage._id}`, currentStage)
      setLoading(false)
      if (data.status == 'success') {
        setAllStage(allStage?.map(stage => stage._id === currentStage._id ? currentStage : stage));
        handleClose();
        fetchStages()
        fetchTriggers()
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: data.message,
        });
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Error",
          detail: data.message,
        });
      }
    }
  }

  const confirmdelete = (id) => {
    confirmDialog({
      message: 'Do you want to delete this stage?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => handleDelete(id),
      draggable: false
    });
  }

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const result = await fetchMethod("DELETE", `${API_STAGE}/${id}`, {});
      if (result.status == 'success') {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Stage Deleted Successfully',
        });
        fetchStages(); // Update the team list after deletion
        fetchTriggers()
        setTotalRecords(totalRecords - 1)
        setLoading(false)
      } else {
        setLoading(false)
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: result.message,
        });
      }
    } catch (error) {
      setnetworkError(true);
    }
  }

  const generateCSV = (data) => {
    const fields = ["stage_name"];
    const headers = ["Stage Name"];

    // Extract the rows
    const rows = data.map((obj) => fields.map((field) => {
      // If the field is 'start_date', wrap it in double quotes
      return field === "group_names" ? `"${obj[field]}"` : obj[field];
    }).join(",")).join("\n");

    return headers.join(",") + "\n" + rows;
  };

  const actionTemplate = (rowData) => {
    return (
      <>
        <div className="p-d-flex p-flex-wrap">
          <Tooltip target=".tooltip-text-edit" />
          <Tooltip target=".tooltip-text-delete" />
          {userGroup?.contact?.edit_stage?.C &&
            <Button
            className="tooltip-text-edit"
            icon="uil uil-edit-alt"
            rounded
            text
            aria-label="Edit"
            data-pr-tooltip="Edit"
            onClick={() => handleUpdateStage(rowData)}
          />}
          {userGroup?.contact?.delete_stage?.D &&
            <Button
            className="tooltip-text-delete"
            ref={buttonEl}
            icon="uil uil-trash"
            rounded
            text
            aria-label="Delete"
            data-pr-tooltip="Delete"
            severity="danger"
            onClick={() => confirmdelete(rowData._id)}
          />}
        </div>
      </>
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) return; // Drag was cancelled

    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const updatedDropDownData = Array.from(dropDownData);
    const [removed] = updatedDropDownData.splice(startIndex, 1);
    updatedDropDownData.splice(endIndex, 0, removed);
    // Extract index and id from updated dropDownData
    const updatedDataWithIndexAndId = updatedDropDownData.map((item, index) => ({
      index: index + 1, // Increment the index by one
      id: item._id // Assuming _id is the property that holds the id
    }));
    handledraggableIndex(updatedDataWithIndexAndId);
    setdropDownData(updatedDropDownData);
  };


  const handledraggableIndex = (param) => {
    let updatedDataWithIndexAndId = {}
    for (const obj of param) {
      updatedDataWithIndexAndId[obj['id']] = obj['index']
    }
    fetchMethod("PUT", `${API_UPDATE_STAGE_SEQUENCE}`, updatedDataWithIndexAndId)
      .then((data) => {
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          console.log("Network error occurred");
          setnetworkError(true)
          return;
        }
      });
  }

  const handleOptionChange = (e) => {
    setCurrentStage({ ...currentStage, option: e.target.value });
  };

  return (
    <>
    {/* <CallDisposition/> */}
      <Row>
        <ConfirmDialog />
        <Col md={12}>
          <TabView>
            <TabPanel header="Stages" reorderableColumns>
              <div className="contact-stage-width">
                <div className="page-title-box mb-3">
                  <h4 className="page-title">Contact Stage</h4>
                  <div className="page-title-right">
                    <div className="row g-2">
                      <div className="col-md-auto">
                        { userGroup?.contact?.add_stage?.A &&
                          <Button
                          icon="uil uil-plus"
                          size="small"
                          label="Add Stage"
                          onClick={() => {
                            setCurrentStage({ stage_name: "" });
                            setVisible(true);
                            setValidation({ "stage_name": "" })
                          }}
                        />}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {stageLoader ? <Skeleton height="200px"></Skeleton> :
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {dropDownData.map((stage, index) => (
                              <Draggable key={stage._id} draggableId={stage._id} index={index}>
                                {(provided) => (
                                  <div
                                    className="card"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                  >
                                    <div className="d-flex justify-content-between align-items-center p-2">
                                      <div className="d-flex align-items-center">
                                        <span
                                          className="stage-card-dragable"
                                          {...provided.dragHandleProps} // Apply drag handle props here
                                        >
                                          <i className="pi pi-bars"></i>
                                        </span>
                                        {stage.option === 'inProgress' && <span className="stage-indecator-yellow"></span>}
                                        {stage.option === 'succeeded' && <span className="stage-indecator-green"></span>}
                                        {stage.option === 'notSucceeded' && <span className="stage-indecator-red"></span>}
                                        {stage.option === 'noCategory' && <span className="stage-indecator-gray"></span>}
                                        {/* Truncate the stage name and add a tooltip */}
                                        <Tooltip target={`.tooltip-b-${stage.stage_id}`} />
                                        <span className={`tooltip-note-text tooltip-b-${stage.stage_id}`} data-pr-tooltip={stage.stage_name}>
                                          {stage.stage_name.length > 30 ? `${stage.stage_name.slice(0, 50)}...` : stage.stage_name}
                                        </span>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        {/* <span style={{ fontSize: "10px", marginRight: "20px" }}>Do not override with event triggers if contact is manually set to this stage.</span> */}
                                        <div>
                                          {actionTemplate(stage)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  }
                </div>
              </div>

            </TabPanel>

            {userGroup?.contact?.get_all_triggers?.R &&
              <TabPanel header="Triggers">
              <div className="Header-trigger-name">
                <h5>Email Triggers</h5>
              </div>
              <div className="Triggers-heading">
                <div className="Triggers-left">
                  <h6>When...</h6>
                </div>
                <div className="Triggers-right">
                  <h6>Change contact stage to...</h6>
                </div>
              </div>
              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>Prospect created</p>
                </div>

                <div className="Triggers-right">
                  {!dropdownLoading ? (<Dropdown
                    id="prospect_created"
                    value={selectedData["prospect_created"] || null}
                    onChange={(e) => handleDropdownChange("prospect_created", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."
                    // className="w-100 triggerDropdown"
                    style={{ width: '400px' }}
                  />) : (<Skeleton width="200px" height="40px" />)}
                </div>
              </div>

              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>Email delivered</p>
                </div>
                {!dropdownLoading ? <div className="Triggers-right">
                  <Dropdown
                    id="email_delivered"
                    value={selectedData["email_delivered"] || null}
                    onChange={(e) => handleDropdownChange("email_delivered", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."

                    style={{ width: '400px' }} />
                </div>
                  :
                  <Skeleton width="200px" height="40px" />}
              </div>
              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>Email bounced</p>
                </div>
                {!dropdownLoading ? <div className="Triggers-right">
                  <Dropdown
                    id="email_bounced"
                    value={selectedData["email_bounced"] || null}
                    onChange={(e) => handleDropdownChange("email_bounced", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."

                    style={{ width: '400px' }} />
                </div>
                  :
                  <Skeleton width="200px" height="40px" />}
              </div>
              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>Spam blocked</p>
                </div>
                {!dropdownLoading ? <div className="Triggers-right">
                  <Dropdown
                    id="spam_blocked"
                    value={selectedData["spam_blocked"] || null}
                    onChange={(e) => handleDropdownChange("spam_blocked", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."

                    style={{ width: '400px' }} />
                </div>
                  :
                  <Skeleton width="200px" height="40px" />}
              </div>
              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>Prospect unsubscribed</p>
                </div>
                {!dropdownLoading ? <div className="Triggers-right">
                  <Dropdown
                    id="prospect_unsubscribed"
                    value={selectedData["prospect_unsubscribed"] || null}
                    onChange={(e) => handleDropdownChange("prospect_unsubscribed", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."

                    style={{ width: '400px' }} />
                </div>
                  :
                  <Skeleton width="200px" height="40px" />}
              </div>
              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>Prospect replied</p>
                </div>
                {!dropdownLoading ? <div className="Triggers-right">
                  <Dropdown
                    id="prospect_replied"
                    value={selectedData["prospect_replied"] || null}
                    onChange={(e) => handleDropdownChange("prospect_replied", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."

                    style={{ width: '400px' }} />
                </div>
                  :
                  <Skeleton width="200px" height="40px" />}
              </div>
              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>Appointment or email marked as interested</p>
                </div>
                {!dropdownLoading ? <div className="Triggers-right">
                  <Dropdown
                    id="appointment"
                    value={selectedData["appointment"] || null}
                    onChange={(e) => handleDropdownChange("appointment", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."

                    style={{ width: '400px' }} />
                </div>
                  :
                  <Skeleton width="200px" height="40px" />}
              </div>
              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>Unresponsive X days after sequence last step (X configurable in each sequence)</p>
                </div>
                {!dropdownLoading ? <div className="Triggers-right">
                  <Dropdown
                    id="unresponsive"
                    value={selectedData["unresponsive"] || null}
                    onChange={(e) => handleDropdownChange("unresponsive", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."

                    style={{ width: '400px' }} />

                </div>
                  :
                  <Skeleton width="200px" height="40px" />}
              </div>

              {/* //twilio trigger */}

              <div className="mt-4 Header-trigger-name">
                <h5>Call Triggers</h5>
              </div>
              <div className="Triggers-heading">
                <div className="Triggers-left">
                  <h6>When a phone call is marked as…</h6>
                </div>
                <div className="Triggers-right">
                  <h6>Change contact stage to…</h6>
                </div>
              </div>
              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>Connected - Positive</p>
                </div>

                <div className="Triggers-right">
                  {!dropdownLoading ? (<Dropdown
                    id="connected_positive"
                    value={selectedData["connected_positive"] || null}
                    onChange={(e) => handleDropdownChange("connected_positive", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."
                    // className="w-100 triggerDropdown"
                    style={{ width: '400px' }}
                  />) : (<Skeleton width="200px" height="40px" />)}
                </div>
              </div>

              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>Connected - Neutral</p>
                </div>
                {!dropdownLoading ? <div className="Triggers-right">
                  <Dropdown
                    id="connected_neutral"
                    value={selectedData["connected_neutral"] || null}
                    onChange={(e) => handleDropdownChange("connected_neutral", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."

                    style={{ width: '400px' }} />
                </div>
                  :
                  <Skeleton width="200px" height="40px" />}
              </div>
              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>Not in Service</p>
                </div>
                {!dropdownLoading ? <div className="Triggers-right">
                  <Dropdown
                    id="not_in_service"
                    value={selectedData["not_in_service"] || null}
                    onChange={(e) => handleDropdownChange("not_in_service", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."

                    style={{ width: '400px' }} />
                </div>
                  :
                  <Skeleton width="200px" height="40px" />}
              </div>
              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>Bad / Wrong Number</p>
                </div>
                {!dropdownLoading ? <div className="Triggers-right">
                  <Dropdown
                    id="bad_number"
                    value={selectedData["bad_number"] || null}
                    onChange={(e) => handleDropdownChange("bad_number", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."

                    style={{ width: '400px' }} />
                </div>
                  :
                  <Skeleton width="200px" height="40px" />}
              </div>
              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>Connected - Negative</p>
                </div>
                {!dropdownLoading ? <div className="Triggers-right">
                  <Dropdown
                    id="connected_negative"
                    value={selectedData["connected_negative"] || null}
                    onChange={(e) => handleDropdownChange("connected_negative", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."

                    style={{ width: '400px' }} />
                </div>
                  :
                  <Skeleton width="200px" height="40px" />}
              </div>
              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>Left Voicemail</p>
                </div>
                {!dropdownLoading ? <div className="Triggers-right">
                  <Dropdown
                    id="left_voicemail"
                    value={selectedData["left_voicemail"] || null}
                    onChange={(e) => handleDropdownChange("left_voicemail", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."

                    style={{ width: '400px' }} />
                </div>
                  :
                  <Skeleton width="200px" height="40px" />}
              </div>
              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>Busy</p>
                </div>
                {!dropdownLoading ? <div className="Triggers-right">
                  <Dropdown
                    id="busy"
                    value={selectedData["busy"] || null}
                    onChange={(e) => handleDropdownChange("busy", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."

                    style={{ width: '400px' }} />
                </div>
                  :
                  <Skeleton width="200px" height="40px" />}
              </div>
              <div className="Triggers-data">
                <div className="Triggers-left">
                  <p>No Answer</p>
                </div>
                {!dropdownLoading ? <div className="Triggers-right">
                  <Dropdown
                    id="no_answer"
                    value={selectedData["no_answer"] || null}
                    onChange={(e) => handleDropdownChange("no_answer", e.value)}
                    showClear
                    filter
                    options={dropDownData}
                    optionLabel="stage_name"
                    placeholder="Select contact stage..."

                    style={{ width: '400px' }} />
                </div>
                  :
                  <Skeleton width="200px" height="40px" />}
              </div>
              {userGroup && userGroup?.contact && userGroup?.contact?.add_trigger_contacts &&
                <Button label="Save" className="EmailTriggersSave" onClick={handleTriggerSave} loading={isLoading} disabled={isDisable} />}
            </TabPanel>}
          </TabView>
        </Col>
      </Row>

      <Toast ref={toast} />
      <Dialog header={currentStage._id ? 'Update Stage' : 'Add Stage'}
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        draggable={false}
        resizable={false}
      >
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Stage Name<span className="text-danger">*</span></Form.Label>
            <InputText value={currentStage.stage_name} className="w-100" style={{ height: "40px" }}
              onChange={e => {
                const value = e.target.value;
                if ((/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/.test(value) && value[0] !== ' ') || value === '') {
                  setCurrentStage({ ...currentStage, stage_name: value });
                }
              }}
              maxLength={100}
            />

            <div className="text-danger mb-2">{validation.stage_name}</div>
            <Form.Label>Category <span className="text-danger">*</span></Form.Label>
            <div>
              <Form.Check
                type="radio"
                label="In Progress"
                value="inProgress"
                checked={currentStage.option === 'inProgress'}
                onChange={handleOptionChange}
              />
              <Form.Check
                type="radio"
                label="Succeeded"
                value="succeeded"
                checked={currentStage.option === 'succeeded'}
                onChange={handleOptionChange}
              />
              <Form.Check
                type="radio"
                label="Not Succeeded"
                value="notSucceeded"
                checked={currentStage.option === 'notSucceeded'}
                onChange={handleOptionChange}
              />
              <Form.Check
                type="radio"
                label="No Category"
                value="noCategory"
                checked={currentStage.option === 'noCategory'}
                onChange={handleOptionChange}
              />
            </div>
            <div className="text-danger mb-2">{validation.option}</div>
          </Form.Group>
        </Form>

        <div className='d-flex justify-content-end' style={{ marginTop: "20px" }}>
          <Button onClick={handleClose} label="Close" size='small' outlined />
          <Button icon="pi pi-check" onClick={currentStage._id ? handleUpdate : handleSave}
            loading={loading} label="Save" size='small' className='ms-2' />
        </div>
      </Dialog>
    </>
  );
};

export default Contacts;
