import React, { useEffect, useState, useRef, useContext } from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import { Skeleton } from "primereact/skeleton";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import CreatableSelect from "react-select/creatable";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { contextVar } from "../Components/ContextVar";
import ApiListFile from "../Components/ApiListFile";
import { fetchMethod } from "../Components/ApiHeader";

const People = () => {
  const { API_PEOPLE_GROUP } = ApiListFile()

  const [peopleData, setPeopleData] = useState([]);
  const [currentPerson, setCurrentPerson] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [groups, setGroups] = useState([]);
  const [visible, setVisible] = useState(false);
  const dt = useRef(null);
  const toast = useRef(null);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
  });

  const [formErrors, setFormErrors] = useState({
    group_name: ''
  });

  const isFormValid = () => {
    let isValid = true;
    let errors = {
      group_name: ''
    };
    if (selectedGroup.length == 0) {
      errors.group_name = 'Group is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const { setnetworkError } = useContext(contextVar)

  useEffect(() => {
    fetch(
      process.env.REACT_APP_API_HOST +
      "/group/" +
      localStorage.getItem("user_id"),
      {
        headers: {
          "x-api-key": localStorage.getItem("api_key"),
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((group) => ({
          value: group._id,
          label: group.group_name,
        }));
        setGroups(options);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setIsLoading(false);
          return;
        }
      });
  }, []);

  const fetchPeople = () => {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", localStorage.getItem("api_key"));

    var raw = JSON.stringify({
      page: lazyState.page,
      page_size: lazyState.rows,
      search_query: search,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_API_HOST + "/people", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPeopleData(result.results);
        setTotalRecords(result.total_count);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setIsLoading(false);
          return;
        }
      });
  };

  useEffect(() => {
    fetchPeople();
  }, [lazyState, search]);

  const addToRecipientsList = async (person) => {
    if (!isFormValid()) return;
    setIsLoading(true)
    const payload = {
      first_name: person.person.first_name ? person.person.first_name : "",
      last_name: person.person.last_name ? person.person.last_name : "",
      email: person.person.email ? person.person.email : "",
      phone: person.person.organization.phone
        ? person.person.organization.phone
        : "",
      company_name: person.person.organization.name
        ? person.person.organization.name
        : "",
      user_id: localStorage.getItem("user_id"),
      group_id: selectedGroup.value,
      added_date: new Date().toISOString(),
    };
    const response = await fetch(
      process.env.REACT_APP_API_HOST + "/recipient",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": localStorage.getItem("api_key"),
        },
        body: JSON.stringify(payload),
      }
    );
    if (response.ok) {
      const data = await response.json();
      if (data.status == 'success') {
        setIsLoading(false)
        setVisible(false)
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Lead Added Successfully",
        });
      } else {
        setIsLoading(false)
        toast.current.show({
          severity: "warn",
          summary: "Error",
          detail: data.message
        });
      }
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <Button
        size="small"
        onClick={() => { setCurrentPerson(rowData); setVisible(true); }}
        label="Add to Lead"
        outlined
      />
    );
  };

  const handleCheckboxChange = (e) => {
    setSelectedRows(e.value);
  };
  const exportCSV = () => {
    dt.current.exportCSV();
  };
  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          className="form-control-sm"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
          onChange={(e) => setSearch(e.target.value)}
        />
      </span>
      <div>
        <Button
          size="small"
          onClick=""
          label="Add to lead"
          icon="uil uil-plus"
          className="me-3"
        />
        <Button
          size="small"
          severity="help"
          onClick={exportCSV}
          icon="uil uil-down-arrow"
          label="Export"
        />
      </div>
    </div>
  );

  const emailTemplate = (person) => {
    return person.person.email;
  };

  const industryTemplate = (person) => {
    return person.person.organization.industry;
  };

  const nameTemplate = (person) => {
    return person.person.name;
  };

  const titleTemplate = (person) => {
    return person.person.title;
  };

  const companyNameTemplate = (person) => {
    return person.person.organization.name;
  };

  const locationTemplate = (person) => {
    return person.person.country;
  };

  const onPage = (event) => {
    setlazyState({
      ...lazyState,
      first: event.first,
      rows: event.rows,
      page: event.page + 1 // Increment the page number by 1 since the event.page is 0-indexed
    });
  };

  const onSort = (event) => {
    setlazyState(event);
  };

  const handleCreate = async (option) => {
    setIsLoading(true);
    try {
      const body = {
        'user_id': localStorage.getItem('user_id'),
        'group_name': option,
      };

      const newGroupId = await fetchMethod('POST', API_PEOPLE_GROUP, body);

      setGroups([...groups, { value: newGroupId, label: option }]);
      setSelectedGroup({ value: newGroupId, label: option });
    } catch (error) {
      console.error('Error creating group:', error);
      // Handle the error, e.g., show a notification to the user
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="content-page">
      <div className="content">
        <Container fluid>
          <Toast ref={toast} />
          <Dialog
            header="Add To Lead"
            visible={visible}
            onHide={() => setVisible(false)}
          >
            <CreatableSelect
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e)}
              options={groups}
              className="w-full me-2"
              onCreateOption={handleCreate}
            />
            {formErrors.group_name && <div className="text-danger">{formErrors.group_name}</div>}
            <Button
              type="submit"
              size="small"
              label="Save"
              onClick={() => addToRecipientsList(currentPerson)}
              icon="pi pi-check"
              loading={isLoading}
            />
          </Dialog>
          <Row>
            <Col>
              <div className="page-title-box">
                <h4 className="page-title">People</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                {/*  table */}
                {isLoading ? (
                  <DataTable
                    value={[...Array(5)]} // Use an array of length 5 to render 5 rows of skeletons
                    tableStyle={{ minWidth: "50rem" }}
                    header={header}
                  >
                    <Column header="Name" body={() => <Skeleton></Skeleton>} />
                    <Column header="Title" body={() => <Skeleton></Skeleton>} />
                    <Column
                      header="Company Name"
                      body={() => <Skeleton></Skeleton>}
                    />
                    <Column
                      header="Location"
                      body={() => <Skeleton></Skeleton>}
                    />
                    <Column
                      header="Industry"
                      body={() => <Skeleton></Skeleton>}
                    />
                    <Column
                      header="Keywords"
                      body={() => <Skeleton></Skeleton>}
                    />
                    <Column
                      header="Action"
                      body={() => <Skeleton></Skeleton>}
                    />
                  </DataTable>
                ) : (
                  <DataTable
                    ref={dt}
                    value={peopleData}
                    lazy
                    first={lazyState.first}
                    selection={selectedRows}
                    onSelectionChange={handleCheckboxChange}
                    selectionMode="checkbox"
                    onSort={onSort}
                    onPage={onPage}
                    totalRecords={totalRecords}
                    globalFilter={globalFilter}
                    header={header}
                    removableSort
                    paginator
                    rows={lazyState.rows}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: "50rem" }}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  >
                    <Column
                      selectionMode="multiple"
                      headerStyle={{ width: "3rem" }}
                    ></Column>
                    <Column
                      field="name"
                      header="Name"
                      body={nameTemplate}
                    ></Column>
                    <Column
                      field="title"
                      header="Title"
                      body={titleTemplate}
                    ></Column>
                    <Column
                      field="company_name"
                      header="Company Name"
                      body={companyNameTemplate}
                    ></Column>
                    <Column
                      field="country"
                      header="Location"
                      body={locationTemplate}
                    ></Column>
                    <Column
                      field="industry"
                      header="Industry"
                      body={industryTemplate}
                    ></Column>
                    <Column
                      field="email"
                      header="Email"
                      body={emailTemplate}
                    ></Column>
                    <Column header="Action" style={{ width: '10%' }} body={actionTemplate}></Column>
                  </DataTable>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">&copy; Copyright 2023 Dietary Business Intelligence LLC. All Rights Reserved. </div>

          </div>
        </div>
      </footer>
    </div>
  );
};

export default People;
