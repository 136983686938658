import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Container,
  Form,
} from "react-bootstrap";
import { Button } from "primereact/button";
import TemplateEditor from "../utils/TemplateEditor";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import CreatableSelect from "react-select/creatable";
import { Dialog } from "primereact/dialog";
import ApiListFile from "../Components/ApiListFile";
import ApiHeader, { fetchMethod } from "../Components/ApiHeader";
import { contextVar } from "../Components/ContextVar";
import "./details.css"
import parse from "html-react-parser"

const Details = (props) => {
  const {
    API_SEND_EMAIL,
    API_DISPLAY_SIGNATURE,
    API_TEMPLATE,
    API_GET_RECIPIENT_LIST,
    API_FOLDER_LIST,
    API_SEND_REPLY,
    API_EMAIL_DETAILS,
    API_SUGGESTION_EMAIL
  } = ApiListFile()
  const { setnetworkError } = useContext(contextVar);
  const [email, setEmail] = useState({});
  const [replyMsg, setReplyMsg] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [visibleEmail, setVisibleEmail] = useState(false);
  const [composeLoading, setComposeLoading] = useState(true);
  const toast = useRef(null);
  const [sendLoading, setSendLoading] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [emailCCHidden, setEmailCCHidden] = useState(true);
  const toggleEmailCCVisibility = () => {
    setEmailCCHidden(!emailCCHidden);
  };
  const sendEmailToLead = async () => {
    let isValid = true;
    let newValidation = { template_subject: "", template_body: "" };
    // Validate the subject field
    if (!currentTemplate.template_subject) {
      isValid = false;
      newValidation.template_subject = "Template subject is required";
    }

    // Validate the body field
    if (!currentTemplate.template_body) {
      isValid = false;
      newValidation.template_body = "Template body is required";
    }

    if (!selectedLead || selectedLead.length == 0) {
      isValid = false;
      newValidation.to = "Recipient is required";
    }

    // Update the validation state
    setValidation(newValidation);

    if (isValid) {
      setComposeLoading(true);
      const payload = {
        lead: selectedLead.value,
        template_id: selectedTemplate,
        template_subject: currentTemplate.template_subject,
        template_body: currentTemplate.template_body,
      };
      const response = await fetch(`${API_SEND_EMAIL}`,
        {
          method: "POST",
          headers: ApiHeader(),
          body: JSON.stringify(payload),
        }
      );
      if (response.ok) {
        const data = await response.json();
        setComposeLoading(false);
        if (data.status == "success") {
          setVisibleEmail(false);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Email Sent Successfully",
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: data.message,
          });
        }
      }
    }
  };

  const [validation, setValidation] = useState({
    template_name: "",
    template_subject: "",
    template_body: "",
  });
  const [currentTemplate, setCurrentTemplate] = useState({});

  const [selectedLead, setSelectedLead] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [emailSuggestion, setemailSuggestion] = useState([])
  const [emailSuggestionLoader, setemailSuggestionLoader] = useState(false)
  const [emailContent, setemailContent] = useState('')

  const [dialogState, setDialogState] = useState("normal"); // 'normal', 'minimized', 'maximized'

  const handleMaximize = () => {
    setDialogState("maximized");
  };

  const handleRestore = () => {
    setDialogState("normal");
  };
  const [leads, setLeads] = useState([]);

  const handleEditorChange = (text) => {
    setReplyMsg(text)
  };

  const display_signature = () => {
    fetch(`${API_DISPLAY_SIGNATURE}`, {
      headers: ApiHeader(),
    })
      .then((res) => res.json())
      .then((data) => {
        setReplyMsg(data.signature)

      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setIsLoading(false);
          return;
        }
      });
  }

  useEffect(() => {
    setComposeLoading(true);
    fetch(
      `${API_TEMPLATE}`,
      {
        headers: ApiHeader(),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((template) => {
          let label = template.template_name;
          if (label.length > 40) {
            label = label.substr(0, 40) + "...";
          }
          return {
            value: template._id,
            label: label,
            subject: template.template_subject,
            body: template.template_body,
          };
        });
        // Fetching recipients on page load
        fetch(`${API_GET_RECIPIENT_LIST}`,
          {
            headers: ApiHeader(),
          }
        ) 
          .then((response) => response.json())
          .then((data) => {
            const recipientOptions = data.items.map((recipient) => ({
              label: recipient.first_name + " " + recipient.last_name,
              value: recipient,
            }));
            setComposeLoading(false);
            setLeads(recipientOptions);
          })
          .catch((error) => {
            console.error(error);
            if (error instanceof TypeError) {
              setnetworkError(true)
              setComposeLoading(false);
              return;
            }
          });
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          return;
        }
      });

    // Fetching tasks on page load
  }, []);

  useEffect(() => {
    (async () => {
      document.title = "Inbox";
    })();
  }, []);

  const renderFolder = (folder, level = 0) => {
    return (
      <React.Fragment key={folder.id}>
        <Link
          // onClick={() => handleFolderClick(folder.id)}
          to={`/inbox/${folder.id}`}
          className={
            "list-group-item border-0 " +
            (email.email_info.parentFolderId == folder.id ? "active" : "")
          }
          style={{ paddingLeft: `${level * 20}px` }}
        >
          <i className="uil uil-folder fs-15 me-1"></i>
          {folder.displayName}
        </Link>
        {folder.childFolders?.map((childFolder) =>
          renderFolder(childFolder, level + 1)
        )}
      </React.Fragment>
    );
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setemailSuggestionLoader(true)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-api-key", localStorage.getItem("api_key"));

      var requestOptions = {
        method: "POST",
        headers: ApiHeader(),
        redirect: "follow",
      };

      fetch(`${API_EMAIL_DETAILS}/${props?.emailId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setEmail(result);
          setIsLoading(false);
          fetchSuggestion(result)
        })
        .catch((error) => {
          console.error(error);
          if (error instanceof TypeError) {
            setnetworkError(true)
            setIsLoading(false);
            return;
          }
        });
    })();
  }, []);

  const sendReply = () => {
    setSendLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", localStorage.getItem("api_key"));

    var raw = JSON.stringify({
      reply_content: replyMsg,
    });

    var requestOptions = {
      method: "POST",
      headers: ApiHeader(),
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_SEND_REPLY}/${props?.emailId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSendLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Replied Successfully",
        });
        setReplyMsg("");
        setIsLoading(false);
        setShowReply(false);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setIsLoading(false);
          return;
        }
      });
    };

  useEffect(() => {
    display_signature()

  }, [])

  const handleBackButton = () => {
    props.setEmailIndex(1)
  }

  function extractTextFromHTML(html) {
    // Create a temporary div element
    const tempDiv = document.createElement('div');
    // Set the HTML content
    tempDiv.innerHTML = html;
    // Extract and return the text content
    return tempDiv.textContent || tempDiv.innerText || '';
  }

  const fetchSuggestion = (email_info) => {
    setemailSuggestionLoader(true)
    fetchMethod("POST", `${API_SUGGESTION_EMAIL}`, {
      subject: email_info.email_info.subject,
      body_html: extractTextFromHTML(email_info.email_info.body.content)
    })
      .then((data) => {
        setemailSuggestion(data.reply_suggestions)
        setemailSuggestionLoader(false)
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          console.log("Network error occurred");
        }
        setemailSuggestionLoader(false)
      });

  }

  const handleSuggestionPanel = (data) => {
    setShowReply(true)
    setemailContent(data)
  }

  const toggleReply = () => {
    try {
    setShowReply(!showReply);
    } catch (error) {
      console.log("error :", error.message)
    }
    
  }

  return (
    <>
      <div>
        <div>
          <Container>
            <Toast ref={toast} />
            <Row>
              <Col>
                <div className="email-container">

                  <div className="p-4">
                    {!IsLoading && (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="back-inbox">Back to List</Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <button onClick={handleBackButton}
                            className="btn btn-light"
                            ref={ref}
                            {...triggerHandler}
                          >
                            <i className="uil  uil-arrow-left"></i>
                          </button>
                        )}

                      </OverlayTrigger>
                    )}
                    <div className="mt-2">
                      {IsLoading ? (
                        <Skeleton width="60%" height="25px" className="mb-2" />
                      ) : (
                        <h5>{email.email_info?.subject}</h5>
                      )}

                      <hr />

                      <div className="d-sm-flex mb-4 mt-1">
                        {IsLoading ? (
                          <>
                            <div>
                              <Skeleton
                                width="15rem"
                                className="mb-2"
                              ></Skeleton>
                              <Skeleton
                                width="10rem"
                                className="mb-2"
                              ></Skeleton>
                            </div>
                          </>
                        ) : (
                          <>
                            {email.email_info?.sender && (
                              <div className="flex-grow-1">
                                <h6 className="m-0">
                                  {email.email_info.sender.emailAddress.name} {"<"}{email.email_info.sender.emailAddress.address}{">"}
                                </h6>
                               
                                <small className="text-muted">
                                  To:{" "}
                                  {email.email_info.toRecipients
                                    .map(
                                      (recipient) => recipient.emailAddress.name
                                    )
                                    .join(", ")} {"<"}{email.email_info.toRecipients
                                      .map(
                                        (recipient) => recipient.emailAddress.address
                                      )
                                      .join(", ")}{">"}
                                </small>
                                      <br/>
                                      {email.email_info?.ccRecipients && email.email_info.ccRecipients.length > 0 && (
                                        <small className="text-muted">
                                          CC:{" "}
                                          {email.email_info.ccRecipients
                                            .map(
                                              (ccRecipient) => ccRecipient.emailAddress.name
                                            )
                                            .join(", ")} {"<"}{email.email_info.ccRecipients
                                              .map(
                                                (ccRecipient) => ccRecipient.emailAddress.address
                                              )
                                              .join(", ")}{">"}
                                        </small>
                                      )}
                              </div>
                            )}
                          </>
                        )}
                      </div>

                      {IsLoading ? (
                        <Skeleton width="100%" height="200px" />
                      ) : (
                        <>
                          <div className="emailBody">
                            {email.email_info?.body && parse(email.email_info.body.content)}
                          </div>
                        </>
                      )}

                      <hr />
                      <div className="d-flex flex-wrap align-items-center gap-3">
                        <Button
                          severity="secondary"
                          text
                          raised
                          className="rounded-pill width-xs ComposeEmail replay-mail"
                          icon="pi pi-reply"
                          label="Reply"
                          onClick={() => toggleReply()}
                        />

                        {emailSuggestionLoader ?
                          <>
                            <Skeleton height="20px" width="200px"></Skeleton>
                            <Skeleton height="20px" width="200px"></Skeleton>
                            <Skeleton height="20px" width="200px"></Skeleton>
                          </>
                          :
                          <>
                            {emailSuggestion?.map((data) => (
                              <Button label={data} className="auto-suggestion-button" onClick={() => handleSuggestionPanel(data)} />
                            ))}
                          </>
                        }

                      </div>
                      {showReply &&
                        <>
                          <div className="d-flex mb-0 mt-3">
                            <div className="flex-grow-1">
                              <div className="mb-2">
                                <TemplateEditor
                                  template_body={emailContent}
                                  onEditorChange={handleEditorChange} />

                              </div>
                            </div>
                          </div>

                          <div className="text-end">
                            <Button
                              variant="primary"
                              className="mt-5"
                              onClick={sendReply}
                              label="Send"
                              loading={sendLoading}
                              icon="uil uil-message ms-2"
                              size="small"
                            />
                            <Button
                              outlined
                              className="mt-5 ms-2"
                              onClick={() => { setCurrentTemplate({ template_body: '' }); setShowReply(false) }}
                              label="Close"
                              size="small"
                            />
                          </div>
                        </>
                      }

                      <Dialog
                        header={
                          <div className="d-flex justify-content-between align-items-center sendMail">
                            <h5>Send Email</h5>
                            <div>
                              {dialogState === "normal" && (
                                <>
                                  <Button
                                    icon="pi pi-window-minimize"
                                    className="p-mr-2"
                                    text
                                    onClick={handleMaximize}
                                  />
                                </>
                              )}
                              {dialogState === "minimized" && (
                                <Button
                                  icon="pi pi-window-maximize"
                                  className="p-mr-2"
                                  text
                                  onClick={handleRestore}
                                />
                              )}
                              {dialogState === "maximized" && (
                                <Button
                                  icon="pi pi-window-maximize"
                                  className="p-mr-2"
                                  text
                                  onClick={handleRestore}
                                />
                              )}
                            </div>
                          </div>
                        }
                        visible={visibleEmail}
                        modal={false}
                        onHide={() => setVisibleEmail(false)}
                        style={
                          dialogState === "normal"
                            ? { width: "45vw" }
                            : dialogState === "minimized"
                              ? { display: "none" }
                              : { width: "15vw", height: "7vh", margin: 0 }
                        }
                        position="bottom-right"
                        draggable={false}
                        resizable={false}
                        className="ComposeEmail"
                      >
                        <Form className="pb-3 pt-1">
                          <Form.Group
                            controlId="emailTo"
                            className="mb-3 position-relative mailTo"
                          >
                            <Form.Label>To</Form.Label>
                            <CreatableSelect
                              placeholder="Ex: hello@example.com"
                              value={selectedLead}
                              onChange={(e) => {
                                setSelectedLead(e);
                              }}
                              options={leads}
                            />
                            <span
                              className="clickCC d-none"
                              onClick={toggleEmailCCVisibility}
                            >
                              CC
                            </span>
                            <div className="text-danger">{validation.to}</div>
                          </Form.Group>
                          <Form.Group
                            controlId="emailCC"
                            className={`mb-3 mt-3 ${emailCCHidden ? "d-none" : ""
                              } emailCC`}
                          >
                            <Form.Label>CC</Form.Label>
                            <Form.Control
                              type="text"
                              id="email-cc"
                              placeholder="Ex: hello@example.com"
                            />
                          </Form.Group>

                          <Form.Group
                            controlId="formTemplateSubject"
                            className="mb-3 mt-3"
                          >
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter template subject"
                              name="template_subject"
                              value={currentTemplate.template_subject || ""}
                              onChange={(e) =>
                                setCurrentTemplate({
                                  ...currentTemplate,
                                  template_subject: e.target.value,
                                })
                              }
                            />
                            <div className="text-danger">
                              {validation.template_subject}
                            </div>
                          </Form.Group>

                          <Form.Group
                            controlId="formTemplateBody"
                            className="mb-2"
                          >
                            <Form.Label>Template Body</Form.Label>

                            <TemplateEditor
                              template_body={currentTemplate.template_body}
                              onEditorChange={handleEditorChange} />
                            <div
                              className="text-danger"
                              style={{ marginTop: "5rem" }}
                            >
                              {validation.template_body}
                            </div>
                          </Form.Group>
                          <h3>AI assistant</h3>
                          <p>
                            <b>Note</b>: You can use {`{{first_name}}`}, {`{{last_name}}`},{" "}
                            {`{{company_name}}`}, {`{{email}}`}, and{" "}
                            {`{{phone}}`} and {`{{unsubscribe}}`} as
                            placeholders for recipient details.
                          </p>
                        </Form>
                        <Button
                          type="submit"
                          size="small"
                          label="Send"
                          onClick={sendEmailToLead}
                          icon="uil uil-message ms-2"
                          iconPos="right"
                          loading={composeLoading}
                        />
                      </Dialog>
                    </div>
                  </div>

                  <div className="clearfix"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <Footer/> */}
      </div >
    </>
  );
  // }
};

export default Details;
