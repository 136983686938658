import React, { useState, useEffect, useRef, useContext } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import ApiListFile from "../Components/ApiListFile";
import { fetchMethod } from "../Components/ApiHeader";
import { contextVar } from "../Components/ContextVar";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


const Account = () => {

    const {
        API_ACCOUNT_STAGE,
        API_UPDATE_ACCOUNT_STAGE_SEQUENCE
    } = ApiListFile()

    const { setnetworkError, userGroup } = useContext(contextVar)
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0)
    const toast = useRef(null);
    const buttonEl = useRef(null);
    const [allStage, setAllStage] = useState([])
    const [validation, setValidation] = useState({
        stage_name: ""
    })
    const [currentStage, setCurrentStage] = useState({
        stage_name: ""
    })
    const [dropDownData, setdropDownData] = useState([]);
    const [stageLoader, setstageLoader] = useState(true)

    useEffect(() => {
        fetchStages()
    }, []);

    const fetchStages = () => {
        setstageLoader(true)
        fetchMethod("GET", `${API_ACCOUNT_STAGE}`, {})
            .then((data) => {
                if (data.status == 'success') {
                    setdropDownData(data?.stages)
                    setstageLoader(false)
                }
                else {
                    setstageLoader(false)
                }
            })
            .catch((error) => {
                if (error instanceof TypeError) {
                    setnetworkError(true)
                    return;
                }
            });
    }

    const handleClose = () => {
        setVisible(false);
    }

    const handleSave = async () => {
        let isValid = true;
        let newValidation = { stage_name: "" };
        if (!currentStage.stage_name || currentStage.stage_name.trim() === "") {
            isValid = false;
            newValidation.stage_name = "Stage Name is required";
        }
        if (!currentStage.option) {
            isValid = false;
            newValidation.option = "Select Category";
        }

        setValidation(newValidation);
        if (isValid) {
            setLoading(true);

            currentStage.user_id = localStorage.getItem('user_id')
            try {
                const data = await fetchMethod("POST", API_ACCOUNT_STAGE, currentStage);
                if (data.status == 'success') {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: data.message,
                    });
                    setVisible(false);

                } else {
                    toast.current.show({
                        severity: 'warn',
                        summary: 'Error',
                        detail: data.message,
                    });
                }
                setLoading(false);
                fetchStages();
                setTotalRecords(prevTotal => prevTotal + 1);
            } catch (error) {
                setnetworkError(true);
            }
        }
    }

    const handleUpdateStage = (stage) => {
        setValidation({
            stage_name: stage.stage_name ? "" : "Stage name is required",
            option: stage.option ? "" : "Category is required"
        });
        setCurrentStage(stage)
        setVisible(true)
    };

    const handleUpdate = async () => {
        let isValid = true;
        let newValidation = { stage_name: "" };
        if (!currentStage.stage_name || currentStage.stage_name.trim() === "") {
            isValid = false;
            newValidation.stage_name = "Stage Name is required";
        }
        if (!currentStage.option) {
            isValid = false;
            newValidation.option = "Select Category";
        }
        setValidation(newValidation);
        // if (!validation) return;
        if (isValid) {
            setLoading(true)
            const data = await fetchMethod("PUT", `${API_ACCOUNT_STAGE}/${currentStage._id}`, currentStage)
            setLoading(false)
            if (data.status == 'success') {
                setAllStage(allStage?.map(stage => stage._id === currentStage._id ? currentStage : stage));
                handleClose();
                fetchStages()
                toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: data.message,
                });
            } else {
                toast.current.show({
                    severity: "warn",
                    summary: "Error",
                    detail: data.message,
                });
            }
        }
    }

    const confirmdelete = (id) => {
        confirmDialog({
            message: 'Do you want to delete this stage?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => handleDelete(id),
            draggable: false
        });
    }

    const handleDelete = async (id) => {
        setLoading(true);
        try {
            const result = await fetchMethod("DELETE", `${API_ACCOUNT_STAGE}/${id}`, {});
            if (result.status == 'success') {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Stage Deleted Successfully',
                });
                fetchStages(); // Update the team list after deletion
                setTotalRecords(totalRecords - 1)
                setLoading(false)
            } else {
                setLoading(false)
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: result.message,
                });
            }
        } catch (error) {
            setnetworkError(true);
        }
    }

    const actionTemplate = (rowData) => {
        return (
            <>
                <div className="p-d-flex p-flex-wrap">
                    <Tooltip target=".tooltip-text-edit" />
                    <Tooltip target=".tooltip-text-delete" />
                    {userGroup?.account?.edit_account_stage?.C &&
                        <Button
                        className="tooltip-text-edit"
                        icon="uil uil-edit-alt"
                        rounded
                        text
                        aria-label="Edit"
                        data-pr-tooltip="Edit"
                        onClick={() => handleUpdateStage(rowData)}
                    />}
                    {userGroup?.account?.delete_stage?.D &&
                        <Button
                        className="tooltip-text-delete"
                        ref={buttonEl}
                        icon="uil uil-trash"
                        rounded
                        text
                        aria-label="Delete"
                        data-pr-tooltip="Delete"
                        severity="danger"
                        onClick={() => confirmdelete(rowData._id)}
                    />}
                </div>
            </>
        );
    };

    const onDragEnd = (result) => {
        if (!result.destination) return; // Drag was cancelled

        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const updatedDropDownData = Array.from(dropDownData);
        const [removed] = updatedDropDownData.splice(startIndex, 1);
        updatedDropDownData.splice(endIndex, 0, removed);
        // Extract index and id from updated dropDownData
        const updatedDataWithIndexAndId = updatedDropDownData.map((item, index) => ({
            index: index + 1, // Increment the index by one
            id: item._id // Assuming _id is the property that holds the id
        }));
        handledraggableIndex(updatedDataWithIndexAndId);
        setdropDownData(updatedDropDownData);
    };

    const handledraggableIndex = (param) => {
        let updatedDataWithIndexAndId = {}
        for (const obj of param) {
            updatedDataWithIndexAndId[obj['id']] = obj['index']
        }
        fetchMethod("PUT", `${API_UPDATE_ACCOUNT_STAGE_SEQUENCE}`, updatedDataWithIndexAndId)
            .then((data) => {
            })
            .catch((error) => {
                console.error(error);
                if (error instanceof TypeError) {
                    console.log("Network error occurred");
                    setnetworkError(true)
                    return;
                }
            });
    }

    const handleOptionChange = (e) => {
        setCurrentStage({ ...currentStage, option: e.target.value });
    };

    return (
        <>
            <Row>
                <ConfirmDialog />
                <Col md={12}>
                    <TabView>
                        <TabPanel header="Stages" reorderableColumns>
                            <div className="contact-stage-width">
                                <div className="page-title-box mb-3">
                                    <h4 className="page-title">Account Stage</h4>
                                    <div className="page-title-right">
                                        <div className="row g-2">
                                            <div className="col-md-auto">
                                                {userGroup?.account?.add_account_stage?.A &&
                                                    <Button
                                                    icon="uil uil-plus"
                                                    size="small"
                                                    label="Add Stage"
                                                    onClick={() => {
                                                        setCurrentStage({ stage_name: "" });
                                                        setVisible(true);
                                                        setValidation({ "stage_name": "" })
                                                    }}
                                                />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {stageLoader ? <Skeleton height="200px"></Skeleton> :
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="droppable">
                                                {(provided) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                        {dropDownData.map((stage, index) => (
                                                            <Draggable key={stage._id} draggableId={stage._id} index={index}>
                                                                {(provided) => (
                                                                    <div
                                                                        className="card"
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                    >
                                                                        <div className="d-flex justify-content-between align-items-center p-2">
                                                                            <div className="d-flex align-items-center">
                                                                                <span
                                                                                    className="stage-card-dragable"
                                                                                    {...provided.dragHandleProps} // Apply drag handle props here
                                                                                >
                                                                                    <i className="pi pi-bars"></i>
                                                                                </span>
                                                                                {stage.option === 'inProgress' && <span className="stage-indecator-yellow"></span>}
                                                                                {stage.option === 'succeeded' && <span className="stage-indecator-green"></span>}
                                                                                {stage.option === 'notSucceeded' && <span className="stage-indecator-red"></span>}
                                                                                {stage.option === 'noCategory' && <span className="stage-indecator-gray"></span>}
                                                                                {/* Truncate the stage name and add a tooltip */}
                                                                                <Tooltip target={`.tooltip-b-${stage.stage_id}`} />
                                                                                <span className={`tooltip-note-text tooltip-b-${stage.stage_id}`} data-pr-tooltip={stage.stage_name}>
                                                                                    {stage.stage_name.length > 30 ? `${stage.stage_name.slice(0, 50)}...` : stage.stage_name}
                                                                                </span>
                                                                            </div>
                                                                            <div className="d-flex align-items-center">
                                                                                {/* <span style={{ fontSize: "10px", marginRight: "20px" }}>Do not override with event triggers if contact is manually set to this stage.</span> */}
                                                                                <div>
                                                                                    {actionTemplate(stage)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    }
                                </div>
                            </div>

                        </TabPanel>
                    </TabView>
                </Col>
            </Row>

            <Toast ref={toast} />
            <Dialog header={currentStage._id ? 'Update Stage' : 'Add Stage'}
                visible={visible}
                onHide={() => setVisible(false)}
                style={{ width: "30vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                draggable={false}
                resizable={false}
            >
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Stage Name<span className="text-danger">*</span></Form.Label>
                        <InputText value={currentStage.stage_name} className="w-100" style={{ height: "40px" }}
                            onChange={e => {
                                const value = e.target.value;
                                if ((/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/.test(value) && value[0] !== ' ') || value === '') {
                                    setCurrentStage({ ...currentStage, stage_name: value });
                                }
                            }}
                            maxLength={100}
                        />

                        <div className="text-danger mb-2">{validation.stage_name}</div>
                        <Form.Label>Category <span className="text-danger">*</span></Form.Label>
                        <div>
                            <Form.Check
                                type="radio"
                                label="In Progress"
                                value="inProgress"
                                checked={currentStage.option === 'inProgress'}
                                onChange={handleOptionChange}
                            />
                            <Form.Check
                                type="radio"
                                label="Succeeded"
                                value="succeeded"
                                checked={currentStage.option === 'succeeded'}
                                onChange={handleOptionChange}
                            />
                            <Form.Check
                                type="radio"
                                label="Not Succeeded"
                                value="notSucceeded"
                                checked={currentStage.option === 'notSucceeded'}
                                onChange={handleOptionChange}
                            />
                            <Form.Check
                                type="radio"
                                label="No Category"
                                value="noCategory"
                                checked={currentStage.option === 'noCategory'}
                                onChange={handleOptionChange}
                            />
                        </div>
                        <div className="text-danger mb-2">{validation.option}</div>
                    </Form.Group>
                </Form>

                <div className='d-flex justify-content-end' style={{ marginTop: "20px" }}>
                    <Button onClick={handleClose} label="Close" size='small' outlined />
                    <Button icon="pi pi-check" onClick={currentStage._id ? handleUpdate : handleSave}
                        loading={loading} label="Save" size='small' className='ms-2' />
                </div>
            </Dialog>
        </>
    );
};

export default Account;
