import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const ContactsChart = ({ contactsDetails }) => {
    const [contactChart, setContactChart] = useState({
        series: [
            {
                name: 'New Contacts',
                type: 'column',
                data: []
            },
            {
                name: 'Existing Contacts',
                type: 'line',
                data: []
            }
        ],
        options: {
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
                toolbar: {
                    show: false
                }
            },
            stroke: {
                width: [0, 2],
                curve: 'smooth'
            },
            plotOptions: {
                bar: {
                    columnWidth: '50%'
                }
            },
            fill: {
                opacity: [0.85, 0.25, 1],
                gradient: {
                    inverseColors: false,
                    shade: 'light',
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100]
                }
            },
            xaxis: {
                categories: generateMonthLabels(),  // Use month labels as categories
                labels: {
                    format: 'MMM'
                }
            },
            yaxis: {
                title: {
                    text: 'Points',
                },
                min: 0
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0);
                        }
                        return y;
                    }
                }
            }
        }
    });

    // Generate simple month labels ['Jan', 'Feb', ..., 'Dec']
    function generateMonthLabels() {
        return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    }

    useEffect(() => {
        if (contactsDetails) {
            const newContactsData = [];
            const existingContactsData = [];

            // Iterate through each month and extract data from contactsDetails
            generateMonthLabels().forEach((month, index) => {
                const monthData = contactsDetails[(index + 1).toString()];
                if (monthData) {
                    newContactsData.push(monthData.new_count || 0);
                    existingContactsData.push(monthData.prev_count || 0);
                } else {
                    newContactsData.push(0);
                    existingContactsData.push(0);
                }
            });

            setContactChart(prevState => ({
                ...prevState,
                series: [
                    { ...prevState.series[0], data: newContactsData },
                    { ...prevState.series[1], data: existingContactsData }
                ]
            }));
        }
    }, [contactsDetails]);

    return (
        <div>
            <div id="chart">
                <ReactApexChart
                    options={contactChart.options}
                    series={contactChart.series}
                    type="line"
                    height={250}
                />
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default ContactsChart;


