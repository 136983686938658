import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, NavLink, Link, useLocation, useParams } from 'react-router-dom';
import SuccessPage from './Components/SuccessPage';
import CancelPage from './Components/CancelPage';
import Tour from 'reactour';
import { jwtDecode } from "jwt-decode";
import Dashboard from './dashboard/Dashboard';
import Campaigns from './campaigns/Campaigns';
import Templates from './templates/Templates';
import People from './people/People';
import Groups from './groups/Groups';
import Sequences from './sequences/Sequences';
import SequenceDetails from './sequences/SequenceDetails';
import Settings from './settings/Settings';
import Unsubscribe from './utils/Unsubscribe';
import './assets/scss/Theme.scss';
import './assets/css/icon-style.css';
import Tasks from './tasks/Tasks';
import Inbox from './email/Inbox';
import Details from './email/Details';
import Prospect from './prospect/Prospect';
import Reports from './admin/Reports'
import AdminReports from './adminreports/Reports'
import SalesExecutionReport from './salesExecutionReport/SalesExecutionReport';
import SequenceReport from './SequenceReport/SequenceReport';
import ErrorBoundaryModal from './Components/ErrorBoundaryModal';
import { contextVar } from './Components/ContextVar';
import { fetchMethod } from './Components/ApiHeader';
import AddTemplate from './templates/AddTemplate';
import PreFormatTemplate from './templates/PreFormatTemplate';
import PricingSection from './settings/PricingSection';
import IncomingCall from './Components/LeadCalling/IncomingCall';
import Recipients from './recipients/Recipients';
import ApiListFile from './Components/ApiListFile';
// import LinkedMailboxModal from './Components/LinkedMailboxModal';
import TopNavbar from './Components/TopNavbar';
import Cookies from 'js-cookie';
import Calls from './Calls/Calls';
import CallsDetails from './Calls/CallsDetails';
import CallDisposition from './Components/LeadCalling/CallDisposition';
import { ProgressSpinner } from 'primereact/progressspinner';
import './CustomProgressSpinner.css';
import PlanUpgradeModal from './Components/PlanUpgradeModal';

function App() {

  const { API_GET_PURCHASE_NUMBER, API_SAVE_TOURSTATUS, API_GET_USER_CREDENTIAL_DATA, API_ADD_CALL_DISPOSITION } = ApiListFile()
  const publicPaths = ['/unsubscribe']
  const steps = [
    {
      selector: '#analytics-tab',
      content: 'Track email performance and engagement metrics effortlessly.',
    },
    {
      selector: '#templates-tab',
      content: 'Create reusable email templates for efficient communication.',
    },
    {
      selector: '#groups-tab',
      content: 'Organize your contacts into segmented groups for targeted outreach.',
    },
    {
      selector: '#recipients-tab',
      content: 'Manage and nurture your leads with personalized communication.',
    },
    {
      selector: '#sequences-tab',
      content: 'Automate follow-up emails and tasks to streamline your workflow.',
    },
    {
      selector: '#tasks-tab',
      content: 'Stay organized with task management integrated into your email workflow.',
    },
    {
      selector: '#calls-tab',
      content: 'Easily track all call interactions to enhance your sales follow-up process.',
    },
    {
      selector: '#reports-tab',
      content: 'Gain insights with comprehensive analytics and reporting features.',
    },
    {
      selector: '#configuration-tab',
      content: 'Customize and configure your email automation preferences to tailor the application to your specific needs.',
    }
  ];

  const [networkError, setnetworkError] = useState(false);
  const [internetConnection, setInternetConnection] = useState(navigator.onLine);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourDone, settourDone] = useState()
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [admin, setAdmin] = useState('');
  const [userId, setUserId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const [profilePic, setProfilePic] = useState('')
  const [callDispositionModal, setcallDispositionModal] = useState(false)
  const [callDispositionModalStatus, setcallDispositionModalStatus] = useState(false)
  const [callSid, setcallSid] = useState('')
  const [callDuration, setcallDuration] = useState(0)
  const [toNumber, settoNumber] = useState('')
  const [fromNumber, setfromNumber] = useState('')
  const [callStatus, setcallStatus] = useState('')
  const [visibleMailboxModal, setvisibleMailboxModal] = useState(false);
  const [userGroup, setUserGroup] = useState({})
  const { API_USERS } = ApiListFile()
  const [userGroupId, setUserGroupId] = useState(null)
  const [emptyLoader, setemptyLoader] = useState(true)
  const [groupData, setgroupData] = useState('')
  const [alertGmail, setalertGmail] = useState(false)
  const [justCreatedGroup, setJustCreatedGroup] = useState(false);
  const [phoneNumberDetail, setphoneNumberDetail] = useState('')
  const [parentSid, setparentSid] = useState(false)
  const [updateList, setupdateList] = useState(false)
  const [encryptedUserId, setEncryptedUserId] = useState("")
  const [userCredential, setuserCredential] = useState('')
  const [planVisibleModal, setplanVisibleModal] = useState(false)
  const [planUpgradeMessage, setplanUpgradeMessage] = useState('')

  const location = useLocation();

  const values = {
    networkError,
    setnetworkError,
    userCredential,
    setuserCredential,
    planVisibleModal,
    setplanVisibleModal,
    planUpgradeMessage,
    setplanUpgradeMessage,
    name,
    setName,
    email,
    setEmail,
    admin,
    setAdmin,
    userId,
    setUserId,
    companyId,
    setCompanyId,
    setcallStatus,
    callStatus,
    setphoneNumber,
    phoneNumber,
    profilePic,
    setProfilePic,
    userGroup,
    callDispositionModal,
    visibleMailboxModal,
    setvisibleMailboxModal,
    callDispositionModalStatus,
    setcallDispositionModalStatus,
    settoNumber,
    alertGmail,
    setalertGmail,
    phoneNumberDetail,
    updateList,
    setparentSid,
    setupdateList,
    encryptedUserId,
    setEncryptedUserId
  }

  // useEffect(() => {
  //   fetchLinkedMailboxes()
  // }, [visibleMailboxModal])

  useEffect(() => {
    if (callDispositionModal === true) {
      const handleStorageChange = (event) => {
        if (event.key === 'modalState') {
          if (event.newValue === 'open') {
            setcallDispositionModal(true);
          } else if (event.newValue === 'closed') {
            setcallDispositionModal(false);
          }
        }
      };

      // Add the event listener for storage changes
      window.addEventListener('storage', handleStorageChange);

      // const initialState = localStorage.getItem('modalState');
      // if (initialState === 'open') {
      //   setcallDispositionModal(true); // Set to open if localStorage says 'open'
      // } else {
      //   setcallDispositionModal(false); // Ensure it's closed initially
      // }

      // Cleanup the event listener when the component unmounts
      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }
  }, []);

  useEffect(() => {
    const token = isAuthenticated();
    const currPath = location.pathname
    if (publicPaths.includes(currPath)) return

    if (!token) {
      window.location.href = 'https://app.' + process.env.REACT_APP_DOMAIN + '.com/'
    } else {
      if (!userId) {
        const decoded = jwtDecode(token);
        setUserId(decoded.user_id);
        setCompanyId(decoded.userid);
        fetchUserDetails()
      }

      fetchUserCredential();
      const handleOffline = () => {
        setInternetConnection(false);
      };

      const handleOnline = () => {
        setInternetConnection(true);
      };

      window.addEventListener("offline", handleOffline);
      window.addEventListener("online", handleOnline);

      const handleClick = () => {
        setnetworkError(false);
      };
      window.addEventListener('click', handleClick);

      return () => {
        window.removeEventListener("offline", handleOffline);
        window.removeEventListener("online", handleOnline);
        window.removeEventListener('click', handleClick);
      };
    }
  }, []);

  useEffect(() => {
    const token = isAuthenticated();

    const currPath = location.pathname;
    if (publicPaths.includes(currPath)) return

    if (!token) {
      window.location.href = 'https://app.' + process.env.REACT_APP_DOMAIN + '.com/'
    } else {
      // fetchGroupId()
    }
  }, [])


  useEffect(() => {
    fetchPuchaseNumber()
  }, [phoneNumber])

  useEffect(() => {
    if (justCreatedGroup) {
      // fetchGroupId();
      setJustCreatedGroup(false);
    }
  }, [justCreatedGroup, encryptedUserId]);

  useEffect(() => {
    if (encryptedUserId) {
      fetchGroupId();
    }
  }, [encryptedUserId]);

  useEffect(() => {
    if (parentSid == true && callStatus == 'missed') {
      handleCallStatus()
    }
  }, [parentSid])
  const isAuthenticated = () => {
    return Cookies.get('accessToken');
  }

  // const fetchLinkedMailboxes = async () => {
  //   const data = await fetchMethod("GET", `${API_USERS}/mailboxes`, {})
  //   if (data?.linked_mailboxes?.length == 0 || data?.linked_mailboxes?.length == undefined) {
  //     // setvisibleMailboxModal(true)
  //     // handleSaveToken(window.location.pathname)
  //   }
  //   else {
  //     fetchUserCredential()
  //     localStorage.removeItem('pathName')
  //   }
  // };

  async function fetchUserDetails() {
    const myHeaders = new Headers();
    myHeaders.append("X-Database-Id", "test");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + Cookies.get('accessToken'));

    const raw = JSON.stringify({
      "token": Cookies.get('accessToken')
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    const globalapi = process.env.REACT_APP_GLOBAL_API
    console.log("globalapi", globalapi)
    fetch(`${globalapi}/api/v1/token/verify/`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setEncryptedUserId(result.user_id)
        setEmail(result.email)
        setName(result.firstname + ' ' + result.lastname)
        setProfilePic(result.profile_image_url)
        setAdmin(0);
      })
      .catch((error) => console.error(error));
  }

  async function fetchGroupId() {
    const myHeaders = new Headers();
    myHeaders.append("X-Database-Id", "test");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + Cookies.get('accessToken'));
    const decoded = jwtDecode(Cookies.get('accessToken'));
    // const userId = decoded.user_id;
    const userId = encryptedUserId
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    try {
      const globalapi = process.env.REACT_APP_GLOBAL_API
      const response = await fetch(`${globalapi}/api/v1/settings/usertogroup/get/?userid=${userId}&product_code=${process.env.REACT_APP_PRODUCT_CODE}`, requestOptions);
      const data = await response.json();
      setgroupData(data);

      if (data.count === 0 || !data.results || data.results.length === 0) {
        await createUserGroup(encryptedUserId);
      } else {
        const userGroupItem = data.results.find(item => item.user_id == userId);
        if (userGroupItem) {
          const userGroup = userGroupItem.user_group;
          setUserGroupId(userGroup);
          await getUserGroup(userGroup);
        } else {
          console.log("User found in results, but no matching user_id. Creating new group...");
          await createUserGroup(userId);
        }
      }
    } catch (error) {
      console.error("Error fetching group ID:", error);
      setemptyLoader(false);
    }
  }

  async function createUserGroup() {
    if (!encryptedUserId) {
      console.error("Cannot create user group: encryptedUserId is not set");
      return;
    }

    const data = {
      app_module: null,
      sub_module: null,
      user_id: encryptedUserId,
      user_group: process.env.REACT_APP_USERGROUP,
      expiry_date: process.env.REACT_APP_EXPIRY_DATE,
      created_by: encryptedUserId,
      product_code: process.env.REACT_APP_PRODUCT_CODE,
      updated_by: encryptedUserId
    };

    const myHeaders = new Headers();
    myHeaders.append("X-Database-Id", "test");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + Cookies.get('accessToken'));

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(data),
      headers: myHeaders,
      redirect: "follow"
    };

    try {
      const globalapi = process.env.REACT_APP_GLOBAL_API;
      const response = await fetch(`${globalapi}/api/v1/settings/usertogroup/create/`, requestOptions);
      const responseData = await response.json();

      if (responseData && responseData.message === "User assigned successfully") {
        const assignedGroupId = data.user_group;
        setUserGroupId(assignedGroupId);
        await getUserGroup(assignedGroupId);
        setJustCreatedGroup(true);
      } else {
        console.error("Failed to create user group or response is invalid");
        setemptyLoader(false);
      }
    } catch (error) {
      console.error("Error creating user group:", error);
      setemptyLoader(false);
    }
  }

  async function getUserGroup(userGroupId) {
    if (!userGroupId) {
      console.error("getUserGroup called with undefined userGroupId");
      setemptyLoader(false);
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "d5089df7e10c85e7603b5a4786598cbe");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    try {
      const response = await fetch(`https://etl.nexus-data.io/api/user_group_information?product_code=${process.env.REACT_APP_PRODUCT_CODE}&grp_id=${userGroupId}`, requestOptions);
      const data = await response.json();
      setUserGroup(data);
      setemptyLoader(false)
    } catch (error) {
      console.error("Error fetching user group information:", error);
    } finally {
      setemptyLoader(false);
    }
  }

  const fetchPuchaseNumber = () => {
    fetchMethod("GET", `${API_GET_PURCHASE_NUMBER}`, {})
      .then((data) => {
        if (data.status === 'success') {
          setphoneNumber(data?.data?.phone_number)
          setphoneNumberDetail(data?.data)
        }
      })
      .catch((error) => {
        if (error instanceof TypeError) {
          return;
        }
      });
  }

  const handleCloseTour = () => {
    setIsTourOpen(false)
    handleTourStatus('completed')
  }

  const fetchUserCredential = () => {
    fetchMethod("GET", `${API_GET_USER_CREDENTIAL_DATA}`, {})
      .then((data) => {
        console.log("user credential data", data.data)
        setuserCredential(data.data)
        settourDone(data?.data?.tourStatus)
        if (data?.data?.tourStatus != 'completed') {
          setIsTourOpen(true)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleTourStatus = (tourDoneStatus) => {
    fetchMethod("POST", `${API_SAVE_TOURSTATUS}`, { tourStatus: tourDoneStatus })
      .then((data) => {
        fetchUserCredential()
      })
      .catch((error) => {
        console.error(error);

      });
  }

  console.log("parent sid", parentSid)

  const handleCallStatus = () => {
    fetchMethod("POST", `${API_ADD_CALL_DISPOSITION}`,
      {
        from_call_number: fromNumber,
        to_call_number: toNumber,
        call_duration: callDuration,
        call_sid: callSid,
        call_direction: 'Incoming',
        call_status: callStatus,
      })
      .then(data => {
        console.log("data...1", data)
        setupdateList(true)
        setcallStatus('')
        setparentSid(false)
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {

          return;
        }
      });
  }

  function RenderInbox() {
    const { folderId } = useParams();
    return <Inbox initialFolderId={folderId} />;
  }

  return (
    <div>

      <contextVar.Provider value={values}>

        {(!internetConnection || networkError) && <ErrorBoundaryModal internetConnection={internetConnection} />}
        <div>
      <PlanUpgradeModal />

          {/* {!publicPaths.includes(location.pathname) && <LinkedMailboxModal
            visibleMailboxModal={false}
            setvisibleMailboxModal={setvisibleMailboxModal}
          />} */}
          {!publicPaths.includes(location.pathname) && emptyLoader || groupData?.count == 0 ?
            <div className='spinner-container'>
              <ProgressSpinner
                style={{
                  width: '50px',
                  height: '50px'
                }}
                strokeWidth="4"
                fill="var(--surface-ground)"
                animationDuration=".7s"
              />

            </div>
            :
            <>
              <Tour
                steps={steps}
                isOpen={isTourOpen}
                onRequestClose={handleCloseTour}
                accentColor="#007bff"
                className="helper"
                rounded={5}
                closeWithMask={false}
              />
              <div id="wrapper">
                {!publicPaths.includes(location.pathname) && <div className="">
                  <TopNavbar name={name} />
                </div>}
                {isAuthenticated() ? <IncomingCall setcallDispositionModal={setcallDispositionModal} setcallSid={setcallSid} setcallDuration={setcallDuration} setfromNumber={setfromNumber} /> : <></>}
                {callDispositionModal === true && <CallDisposition callDispositionModal={callDispositionModal} setcallDispositionModal={setcallDispositionModal} callSid={callSid} callDirection={'Incoming'} callDuration={callDuration} toNumber={phoneNumber} fromNumber={fromNumber} />}

                <Routes>
                  <Route path="/templates" element={<Templates />} />
                  <Route path="/add-template/:templateType/:id" element={<AddTemplate />} />
                  <Route path="/pre-template" element={<PreFormatTemplate />} />
                  <Route path="/campaigns" element={<Campaigns />} />
                  <Route path="/leads" element={<Recipients />} />
                  <Route path="/leads/:grpName/:grpId" element={<Recipients />} />
                  <Route path="/people" element={<People />} />
                  <Route path="/groups" element={<Groups />} />
                  <Route path="/prospect" element={<Prospect />} />
                  <Route path="/sequences" element={<Sequences />} />
                  <Route path="/sequence_details/:id" element={<SequenceDetails />} />
                  <Route path="/settings/*" element={<Settings />} />
                  <Route path="/tasks" element={<Tasks />} />
                  <Route path="/inbox" element={<Inbox />} />
                  <Route path="/email/details/:id" element={<Details />} />
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/unsubscribe" element={<Unsubscribe />} />
                  <Route path="/inbox/:folderId" element={<RenderInbox />} />
                  <Route path='/reports' element={<Reports />} />
                  <Route path="/pricing" element={<PricingSection />} />
                  <Route path='/adminreports' element={<AdminReports />} />
                  <Route path='/sales-execution-report' element={<SalesExecutionReport />} />
                  <Route path="/success" element={<SuccessPage />} />
                  <Route path="/cancel" element={<CancelPage />} />
                  <Route path="/calls" element={<Calls />} />
                  <Route path="/call-details/:id" element={<CallsDetails />} />
                  <Route path='/sequenceReport/:sequenceId/:selectedSequenceName' element={<SequenceReport />} />
                  <Route path="/sales-execution-report" element={<SalesExecutionReport />} />
                </Routes>

              </div>
            </>

          }
        </div>

      </contextVar.Provider>
    </div>
  );
}

export default App;