import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';
import { Form } from 'react-bootstrap';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from "primereact/tooltip";
import ApiListFile from '../Components/ApiListFile';
import { contextVar } from '../Components/ContextVar';
import { fetchMethod } from '../Components/ApiHeader';

const CallsDetails = () => {


  const timezone_mapping = {
    "Pacific Time": "America/Los_Angeles",
    "Mountain Time": "America/Denver",
    "Central Time": "America/Chicago",
    "Eastern Time": "America/New_York",
    "UTC": "UTC",
    "Europe/London": "Europe/London",
    "Paris": "Europe/Paris",
    "Athens": "Europe/Athens",
    "Australia Eastern Standard Time": "Australia/Sydney",
    "Australia Western Standard Time": "Australia/Perth",
    "Australia Central Standard Time": "Australia/Adelaide",
    "Middle East Time": "Asia/Tehran",
    "Near East Time": "Asia/Dubai",
    "Pakistan Lahore Time": "Asia/Karachi",
    "Indian Standard Time": "Asia/Kolkata",
    "Bangladesh Standard Time": "Asia/Dhaka",
    "Vietnam Standard Time": "Asia/Ho_Chi_Minh",
    "Japan Standard Time": "Asia/Tokyo",
    "Solomon Standard Time": "Pacific/Guadalcanal",
    "New Zealand Standard Time": "Pacific/Auckland",
    "Midway Islands Time": "Pacific/Midway",
    "Hawaii Standard Time": "Pacific/Honolulu",
    "Alaska Standard Time": "America/Anchorage",
    "Puerto Rico and US Virgin Islands Time": "America/Puerto_Rico",
    "Canada Newfoundland Time": "America/St_Johns",
    "Brazil Eastern Time": "America/Sao_Paulo"
  };


  const { API_LIST_CALL_RECORD, API_POST_USER_TIMEZONES } = ApiListFile();
  const { id } = useParams();
  const toast = useRef(null);
  const navigate = useNavigate();
  const { setnetworkError, userGroup } = useContext(contextVar);
  const [callRecord, setCallRecord] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [callPurpose, setCallPurpose] = useState('');
  const [callDisposition, setCallDisposition] = useState('');
  const [note, setNote] = useState('');
  const [timezone, settimeZone] = useState()
  const [formattedTimestamp, setFormattedTimestamp] = useState('')
  const [initialData, setInitialData] = useState({
    callPurpose: '',
    callDisposition: '',
    note: ''
  });

  const [callPurposeError, setCallPurposeError] = useState('');
  const [callDispositionError, setCallDispositionError] = useState('');

  const callPurposeOptions = [
    { label: 'Prospecting Call', value: 'prospectingCall' },
    { label: 'Opportunity Call', value: 'opportunityCall' },
    { label: 'Support Call', value: 'supportCall' },
    { label: 'Other Call', value: 'otherCall' }
  ];

  const callDispositionOptions = [
    {
      label: 'No Answer',
      value: 'noAnswer'
    },
    {
      label: 'Left Voicemail',
      value: 'leftVoicemail'
    },
    {
      label: 'Busy',
      value: 'busy'
    },
    {
      label: 'Gatekeeper',
      value: 'gatekeeper'
    },
    {
      label: 'Bad/Wrong Number',
      value: 'bad/WrongNumber'
    },
    {
      label: 'Not in service',
      value: 'notInService'
    },
    {
      label: 'Connected - Positive',
      value: 'connected-positive'
    },
    {
      label: 'Connected - Neutral',
      value: 'connected-neutral'
    },
    {
      label: 'Connected - Negative',
      value: 'connected-negative'
    }
  ]


  const camelCaseToReadable = (str) => {
    if (!str || typeof str !== 'string') {
      return '';
    }

    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before uppercase letters
      .replace(/(^\w|\b\w)/g, match => match.toUpperCase()) // Capitalize the first letter of each word
      .replace(/-/g, match => match.toUpperCase()); // Capitalize after hyphen
  };


  useEffect(() => {
    fetchMethod("GET", `${API_POST_USER_TIMEZONES}`, {})
      .then((data) => {
        if (data?.item != null) {
          settimeZone(data.item.timezone);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          return;
        }
      });
  }, []);




  useEffect(() => {
    fetchCallDetails();
  }, []);

  const fetchCallDetails = () => {
    setDataLoading(true);
    fetchMethod("GET", `${API_LIST_CALL_RECORD}/${id}`, {})
      .then((data) => {
        setCallRecord(data?.data);
        setCallPurpose(data?.data?.call_purpose);
        setCallDisposition(data?.data?.call_disposition);
        setNote(data?.data?.note);
        setInitialData({
          callPurpose: data?.data?.call_purpose,
          callDisposition: data?.data?.call_disposition,
          note: data?.data?.note
        });
        setDataLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true);
          setDataLoading(false);
        }
      });
  };

  const handleEditClick = () => {
    setEditable(!editable);
    setCallPurpose(initialData.callPurpose);
    setCallDisposition(initialData.callDisposition);
    setNote(initialData.note);
    setCallPurposeError('')
    setCallDispositionError('')
  };


  const closeEdit = () => {
    setEditable(false);
    setCallPurpose(initialData.callPurpose);
    setCallDisposition(initialData.callDisposition);
    setNote(initialData.note);
    setCallPurposeError('')
    setCallDispositionError('')
  };
  console.log("call disposition",callDisposition)

  const validate = () => {
    let isValid = true;

    if (!callPurpose) {
      setCallPurposeError('Please select call purpose');
      isValid = false;
    } else {
      setCallPurposeError('');
    }
    if (!callDisposition || callDisposition=='noDisposition' ) {
      setCallDispositionError('Please select call disposition');
      isValid = false;
    } else {
      setCallDispositionError('');
    }


    // if (!recordedData) {
    //     setrecordError('Click on button');
    //     isValid = false;
    // } else {
    //     setrecordError('');
    // }

    return isValid;
  }

  const handleSubmit = () => {

    if (validate()) {
      setLoading(true);
      fetchMethod("PUT", `${API_LIST_CALL_RECORD}`, {
        id: id,
        call_purpose: callPurpose,
        call_disposition: callDisposition,
        recipient_id: callRecord?.recipient_id,
        call_direction: callRecord?.call_direction,
        from_call_number: callRecord?.from_call_number,
        note: note
      })
        .then((data) => {
          if (data.status === 'success') {
            toast.current.show({ severity: 'success', summary: 'Success', detail: data?.message });
          } else {
            toast.current.show({ severity: 'warn', summary: 'Error', detail: data?.message });
          }
          fetchCallDetails();
          setEditable(false);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          if (error instanceof TypeError) {
            setnetworkError(true);
            setLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    if (callRecord?.timestamp && timezone) {
      const converted = convertTimestampToTimezone(callRecord.timestamp, timezone);
      setFormattedTimestamp(converted);
    }
  }, [callRecord, timezone]);


  const convertTimestampToTimezone = (timestamp) => {

    if (!timestamp || !timezone) {
      console.warn('Missing timestamp or selectedTimezone');
      return 'Invalid Date';
    }

    try {
      const date = new Date(timestamp + 'Z');
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }

      const ianaTimezone = timezone_mapping[timezone] || timezone;
      const dateOptions = {
        timeZone: ianaTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };

      const timeOptions = {
        timeZone: ianaTimezone,
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      };

      const formattedDate = new Intl.DateTimeFormat('en-GB', dateOptions).format(date);
      const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);

      // Combine date and time
      return `${formattedDate}, ${formattedTime}`;
    } catch (error) {
      console.error('Error converting timestamp:', error, 'for timezone:', timezone);
      return 'Invalid Date';
    }
  };



  // const formattedDate = new Date(callRecord?.timestamp).toLocaleDateString('en-US', {
  //   year: 'numeric',
  //   month: 'short',
  //   day: '2-digit',
  //   hour: 'numeric',
  //   minute: '2-digit'
  // });

  const Breadcrumb = ({ items }) => {
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {items.map((item, index) => (
            <li key={index} className="breadcrumb-item">
              {item.url ? (
                <Link to={item.url}>{item.label}</Link>
              ) : (
                item.label
              )}
            </li>
          ))}
        </ol>
      </nav>
    );
  };
  console.log("call direction", callRecord?.call_direction, '=======', callRecord)
  return (
    <>
      <Toast ref={toast} />
      <div className='content-page calls-main'>
        <div className='container-fluid mt-3'>
          <Breadcrumb
            items={[
              { label: 'Calls', url: '/calls' },
              {
                label: `To : ${(callRecord?.call_direction == 'Incoming' && callRecord?.to_call_number) || ((callRecord?.call_direction == 'Outgoing' && callRecord?.recipient_id == null) ? callRecord?.to_call_number : camelCaseToReadable(callRecord?.recipient_name))}`,
                url: '#', // Current page, no link
              },
            ]}
          />

          <h4>
            To : {dataLoading ? (
              <Skeleton />
            ) : (
              <>
                {(callRecord?.call_direction == 'Incoming' && callRecord?.to_call_number) || ((callRecord?.call_direction == 'Outgoing' && callRecord?.recipient_id == null) ? callRecord?.to_call_number : camelCaseToReadable(callRecord?.recipient_name))}
              </>
            )}
          </h4>

          <div className="d-flex">
            <div className='card call-leftbar'>
              <div class="">{formattedTimestamp}</div>
              <div className='d-flex align-items-start justify-content-between mt-4'>
                <div className='call-details-profile '>
                  <h5>GREEN JEEVA LLC  </h5>
                </div>
                {userGroup?.twilio?.update_call_detail?.C &&
                  <span className='call-profile-edit me-3' onClick={handleEditClick}>
                    {editable ? <i className="pi pi-times"></i> : <i className="pi pi-pencil"></i>}
                  </span>}
              </div>
              <div className='call-details-user mt-3'>
                <h6>Phone: {dataLoading ? <Skeleton /> : <span><b>{callRecord?.call_direction === "Incoming" ? callRecord?.from_call_number : callRecord?.to_call_number}</b></span>}</h6>
                <h6>Direction: {dataLoading ? <Skeleton /> : <span><b>{callRecord?.call_direction}</b></span>}</h6>
                <h6>Call Duration: {dataLoading ? <Skeleton /> : <span><b>{callRecord?.call_duration}</b></span>}</h6>
              </div>
              <div className='call-details-user'>
                <h6>Call Purpose: {dataLoading ? <Skeleton /> : <>
                  {editable ? (
                    <Dropdown
                      name="callPurpose"
                      value={callPurpose}
                      onChange={(e) => setCallPurpose(e.target.value)}
                      options={callPurposeOptions}
                      placeholder="Select a purpose"
                      className="w-full col-md-12 mt-2"
                    />
                  ) : (
                    <b>{camelCaseToReadable(callPurpose)}</b>
                  )}
                </>}
                  {callPurposeError && <div className="text-danger mb-2">{callPurposeError}</div>}</h6>
              </div>
              <div className='call-details-user'>
                <h6>Call Disposition: {dataLoading ? <Skeleton /> : <>
                  {editable ? (
                    <Dropdown
                      name="callDisposition"
                      value={callDisposition}
                      onChange={(e) => setCallDisposition(e.target.value)}
                      options={callDispositionOptions}
                      placeholder="Select a disposition"
                      className="w-full col-md-12 mt-2"
                    />
                  ) : (
                    <b>{camelCaseToReadable(callDisposition)}</b>
                  )}
                </>}{callDispositionError && <div className="text-danger mb-2">{callDispositionError}</div>}</h6>
              </div>
              <div className='call-details-user cursor-pointer'>
                <h6>Note: {dataLoading ? <Skeleton /> : <>
                  {editable ? (
                    <Form.Control
                      as="textarea"
                      name="note"
                      className='mt-2'
                      rows={6}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      maxLength={500}
                    />
                  ) : (
                    <b>
                      {/** Sanitize the `note` value for valid CSS selector usage **/}
                      <Tooltip target={`.tooltip-note-${note?.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '')}`} />
                      <span
                        className={`tooltip-note-text tooltip-note-${note?.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '')}`}
                        data-pr-tooltip={note}
                      >
                        {note?.length > 30 ? `${note?.slice(0, 30)}...` : note}
                      </span>
                    </b>
                  )}
                </>}</h6>
              </div>
              {editable &&
                <div className='d-flex justify-content-end'><Button size="small" label="Close" severity="secondary" outlined className='me-2' onClick={closeEdit} />
                  <Button size="small" label="Submit" loading={loading} onClick={handleSubmit} />
                </div>}
            </div>
            <div className='card call-RightPannel'>
              <div className="">
                {dataLoading ? <Skeleton height='48px' /> : (
                  <>
                    {callRecord?.recording_url === undefined ? (
                      <>
                        <ReactAudioPlayer
                          style={{ width: "70%", margin: "30px auto" }}
                          src={callRecord?.recording_url}
                          controls
                        />
                        <div className=" ml-2">No Recording Found</div>
                      </>
                    ) : (
                      <ReactAudioPlayer
                        style={{ width: "70%", margin: "30px auto" }}
                        src={callRecord?.recording_url}
                        controls
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallsDetails;