// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Define styles for disabled input */
.disabled-input {
    opacity: 0.5; /* Adjust the opacity as needed */
    cursor: not-allowed;
  }
input.disabled-input.form-control {
    opacity: 0.6 !important;
}
  
  /* Optional: Define transition for a smoother effect */
  .disabled-input {
    transition: opacity 0.3s ease-in-out; /* Adjust the duration and easing function as needed */
  }
  .p-dialog-mask.p-dialog-bottom-right.p-dialog-visible {
    z-index: 1002 !important;
}
.contact-stage-indicator-yellow {
  padding: 3px 11px;
  border: 1px solid #e5e502;
  background-color: yellow;
  font-size: 15px;
  border-radius: 3px;
}
.contact-stage-indicator-green {
  padding: 3px 11px;
  border: 1px solid #02c71f;
  background-color: rgb(6, 249, 6);
  font-size: 15px;
  border-radius: 3px;
}
.contact-stage-indicator-red {
  padding: 3px 11px;
  border: 1px solid #cf0606;
  background-color: red;
  font-size: 15px;
  border-radius: 3px;
}
.contact-stage-indicator-grey {
  padding: 3px 11px;
  border: 1px solid #5c5c5a;
  background-color: grey;
  font-size: 15px;
  border-radius: 3px;
}
.grp-drpdn-lead span.p-dropdown-label.p-inputtext.p-placeholder {
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/recipients/Recipients.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,YAAY,EAAE,iCAAiC;IAC/C,mBAAmB;EACrB;AACF;IACI,uBAAuB;AAC3B;;EAEE,sDAAsD;EACtD;IACE,oCAAoC,EAAE,sDAAsD;EAC9F;EACA;IACE,wBAAwB;AAC5B;AACA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,wBAAwB;EACxB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,gCAAgC;EAChC,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,qBAAqB;EACrB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,sBAAsB;EACtB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,YAAY;AACd","sourcesContent":["/* Define styles for disabled input */\n.disabled-input {\n    opacity: 0.5; /* Adjust the opacity as needed */\n    cursor: not-allowed;\n  }\ninput.disabled-input.form-control {\n    opacity: 0.6 !important;\n}\n  \n  /* Optional: Define transition for a smoother effect */\n  .disabled-input {\n    transition: opacity 0.3s ease-in-out; /* Adjust the duration and easing function as needed */\n  }\n  .p-dialog-mask.p-dialog-bottom-right.p-dialog-visible {\n    z-index: 1002 !important;\n}\n.contact-stage-indicator-yellow {\n  padding: 3px 11px;\n  border: 1px solid #e5e502;\n  background-color: yellow;\n  font-size: 15px;\n  border-radius: 3px;\n}\n.contact-stage-indicator-green {\n  padding: 3px 11px;\n  border: 1px solid #02c71f;\n  background-color: rgb(6, 249, 6);\n  font-size: 15px;\n  border-radius: 3px;\n}\n.contact-stage-indicator-red {\n  padding: 3px 11px;\n  border: 1px solid #cf0606;\n  background-color: red;\n  font-size: 15px;\n  border-radius: 3px;\n}\n.contact-stage-indicator-grey {\n  padding: 3px 11px;\n  border: 1px solid #5c5c5a;\n  background-color: grey;\n  font-size: 15px;\n  border-radius: 3px;\n}\n.grp-drpdn-lead span.p-dropdown-label.p-inputtext.p-placeholder {\n  padding: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
