import moment from 'moment';
import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Container } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Col } from "react-bootstrap"

import ApiListFile from "../Components/ApiListFile";
import { fetchMethod } from "../Components/ApiHeader";
import {useDebounce} from "../utils/hooks"

import "./admin.css";


export default function Reports() {

  const dateRangeOptions = [
    { label: 'Weekly', value: 'Weekly' },
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Quarterly', value: 'Quarterly' },
    { label: 'Yearly', value: 'Yearly' }
  ];
  const rangeMapper = {
    Weekly: 'week',
    Monthly: 'month',
    Quarterly: 'quarter',
    Yearly: 'year'
  }

  const {
    API_GET_REPORT_DROPDOWN,
    API_GET_TEAM_MEMBERS_REPORT
  } = ApiListFile()

  const [visibleBtn, setvisibleBtn] = useState(false);
  const dt = useRef(null);
  
  
  const [emailSentTotal, setEmailSentTotal] = useState(0);
  const [emailOpenTotal, setEmailOpenTotal] = useState(0)
  const [outboundCallsTotal, setOutboundCallsTotal] = useState(0);
  const [pendingTasksTotal, setPendingTasksTotal] = useState(0);
  const [leadsAddedTotal, setLeadsAddedTotal] = useState(0);
  const [overdueTaskTotal, setOverdueTaskTotal] = useState(0);
  
  const [loading, setLoading] = useState(true);
  const [dropDownLoader, setDropDownLoader] = useState(true);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dateRangeOption, setDateRangeOption] = useState("Monthly");
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: '',
    sortOrder: -1
  });
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([])
  const [dropDownData, setDropDownData] = useState({
    teams: null,
    users: null,
    companies: null,
    is_admin: false,
    is_superuser: false,
    is_team_lead: false
  });
  const [membersData, setmembersData] = useState({
    members: [],
    totalRecords: 0
  });

  const debouncedSearch = useDebounce(globalFilter)

  useEffect(() => {
    document.title = "Reports";
    const fetchDropDownData = async () => {
      try {
        const data = await fetchMethod("GET", `${API_GET_REPORT_DROPDOWN}`, {})
        setDropDownData((prev) => ({
          ...prev,
          teams: data.teams,
          users: data.users,
          companies: data.companies,
          is_admin: data.is_admin,
          is_superuser: data.is_superuser,
          is_team_lead: data.is_team_lead
        }));
        
        if (data.is_superuser || data.is_admin || data.is_team_lead) {
          setTeams(data.teams);
          setUsers(data.users);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setDropDownLoader(false);
      }
    };

    fetchDropDownData();
  }, []);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      if (!startDate || !endDate) {
        return
      }
      setLoading(true);
      setDropDownLoader(true);
      try {
        const data = await fetchMethod("POST", `${API_GET_TEAM_MEMBERS_REPORT}`, {
          page_size: lazyState.rows,
          page: lazyState.page,
          search_query: debouncedSearch,
          start_date: startDate,
          end_date: endDate,
          cmp_id: selectedCompany,
          team_leadid: selectedTeam,
          user_id: selectedUser,
      })
        setmembersData((prev)=>({
          ...prev,
          members: data.members,
          totalRecords: data.total
        }))
        setEmailSentTotal(data.tot_email_sent)
        setOutboundCallsTotal(data.tot_outbound_calls)
        setPendingTasksTotal(data.tot_pending_tasks)
        setLeadsAddedTotal(data.tot_lead_added)
        setOverdueTaskTotal(data.tot_overdue_tasks)
        setEmailOpenTotal(data.tot_email_opened)
        
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
        setDropDownLoader(false);
      }
    };

    fetchTeamMembers();
  }, [dropDownData, selectedCompany, selectedTeam, selectedUser, dateRangeOption, debouncedSearch, lazyState.page, lazyState.rows, startDate, endDate]);

  const getTeamHierarchyUsers = (teamId, companyId, users, visited = new Set())=>{
    // base case for recursion
    if (teamId===0 || teamId===null|| visited.has(teamId)) return [];

    // Mark this teamId as visited
    visited.add(teamId);
    let result = [];
    const teamUsers = users.filter((user) => user.team_leadid === teamId && (!companyId ? true : user.company_id === companyId));
    teamUsers.map((user) => {
      const r = getTeamHierarchyUsers(user.user_id, companyId, users, visited);
      result.push(...r, user);
    });

    return result;
    
  }
  const handleCompanyChange = (e) => {
    const value = e.value
    let allTeams;
    let allUsers;
    if (value) {
      // Filter teams based on selected company
      allTeams = dropDownData.teams.filter((team) => team.company_id === value);
      // Filter users based on selected company
      allUsers = dropDownData.users.filter((user) => user.company_id === value);
    } else {
      allTeams = dropDownData.teams;
      allUsers = dropDownData.users;
    }

    const isCompanyTeam = allTeams.some((team) => team?.team_leadid === selectedTeam)
    if (!isCompanyTeam || !value){
      setSelectedTeam(null);
      setSelectedUser(null);
    }else{
      allUsers = getTeamHierarchyUsers(selectedTeam, value, allUsers, new Set());
      const leader = dropDownData.users.find((user) => user?.user_id === selectedTeam);
      allUsers = [...allUsers, leader];
      allUsers.sort((a, b) => a.user_name.localeCompare(b.user_name));
    }
    console.log("Team users:", allUsers.length);
    console.log("Company teams:", allTeams.length);
    

    setTeams(allTeams);
    setUsers(allUsers);

    setSelectedCompany(value)
    setLazyState({ ...lazyState, page: 1 })
  }

  const handleTeamSelection = (e) => {
    const value = e.value
    let allUsers;
    
    if (value) {
      const leader = dropDownData.users.find((user) => user?.user_id === value);
      allUsers = getTeamHierarchyUsers(value, leader?.company_id, dropDownData.users); 
      allUsers = [...allUsers, leader];
    }
    else if(selectedCompany) allUsers = dropDownData.users.filter((user) => user.company_id === selectedCompany);
    else allUsers = dropDownData.users;
    
    allUsers.sort((a, b) => a.user_name.localeCompare(b.user_name));
    console.log("Team users:", allUsers.length);
    
    setUsers(allUsers);
    
    const isTeamUser = allUsers.some((user) => user?.user_id === selectedUser)    
    
    if (!isTeamUser || !value) setSelectedUser(null);
    setSelectedTeam(e.value)
    setLazyState({ ...lazyState, page: 1 })
  };

  const handleDateRangeSelection = (e) => {    
    const value = e.value
    setStartDate(moment().startOf(rangeMapper[value]).format('YYYY-MM-DD'))
    setEndDate(moment().endOf(rangeMapper[value]).format('YYYY-MM-DD'))
    setDateRangeOption(value)
    setLazyState({ ...lazyState, page: 1 })
  }

  const handleDateSelection = (e)=>{
    const value = e.value;
    
    if (startDate && endDate) {
      setStartDate(null);
      setEndDate(null);
      setStartDate(moment(value[0]).format('YYYY-MM-DD'));
    } else if (startDate){
      const sDt = new Date(startDate)
      const nDt = new Date(value[0])
      if (sDt.getTime() > nDt.getTime()) {
        setStartDate(moment(value[0]).format('YYYY-MM-DD'));
        return
      }
      setEndDate(moment(value[0]).format('YYYY-MM-DD'));
      setvisibleBtn(true);
    }
   
    setLazyState({ ...lazyState, page: 1 })
  }

  const handleReset = ()=>{
    setvisibleBtn(false)
    setStartDate(moment().startOf(rangeMapper[dateRangeOption]).format('YYYY-MM-DD'))
    setEndDate(moment().endOf(rangeMapper[dateRangeOption]).format('YYYY-MM-DD'))
  }

  const renderHeader = () => {
    return (
      <div className="d-flex flex-wrap gap-2 justify-content-between align-items-center">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            className="form-control-sm"
            onInput={(e) => { setGlobalFilter(e.target.value); setLoading(true) }}
            placeholder="Search..."
          />
        </span>
        {  dropDownLoader ? (
          <div className="d-flex">
            <Skeleton width="10rem" height="2.5rem" className="me-3" />
            <Skeleton width="10rem" height="2.5rem" className="me-3" />
            <Skeleton width="10rem" height="2.5rem" className="me-3" />
            <Skeleton width="10rem" height="2.5rem" className="me-3" />
            <Skeleton width="10rem" height="2.5rem" className="me-3" />
          </div>
        ): (
          <div className="d-flex">

          {dropDownData && dropDownData.is_superuser && <Dropdown
            value={selectedCompany}
            onChange={handleCompanyChange}
            options={dropDownData.companies}
            optionLabel="company_name"
            optionValue="company_id"
            placeholder="Select a company"
            showClear
            filter
            className="w-full md:w-14rem me-3"
            panelClassName="dropdown-panel"
          />
          }

          {dropDownData && (dropDownData.is_admin || dropDownData.is_superuser) &&
            (
              <Dropdown
                value={selectedTeam}
                onChange={handleTeamSelection}
                options={teams}
                optionLabel="team_lead_name"
                optionValue="team_leadid"
                showClear
                filter
                className="me-2"
                placeholder="Select a Team"
              />
            )
          }

          {dropDownData && (dropDownData.is_admin || dropDownData.is_superuser || dropDownData.is_team_lead) && (
            <Dropdown
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.value)}
              options={users}
              optionLabel="user_name"
              optionValue="user_id"
              placeholder="Select a User"
              filter
              className="w-full md:w-14rem me-2"
              panelClassName="dropdown-panel"
              showClear
            >
              {selectedUser && (
                <button
                  className="p-link p-clear-button"
                  onClick={() => setSelectedUser(null)}
                >
                  <i className="pi pi-times" />
                </button>
              )}
            </Dropdown>
          )}


          <div className="calendar_select">
          
            <Dropdown
              value={dateRangeOption}
              options={dateRangeOptions}
              onChange={handleDateRangeSelection}
              className="me-3"
              placeholder="Select Range"
            />
            <Calendar
              value={[moment(startDate, 'YYYY-MM-DD').toDate(), moment(endDate, 'YYYY-MM-DD').toDate()]}
              onChange={handleDateSelection}
              // onClearButtonClick={(e)=> {console.log("clear click");}}
              // showClear={true}
              selectionMode="range"
              showIcon
              className="me-3"
              dateFormat="dd/mm/yy"
              readOnlyInput
            />
            {visibleBtn &&
              <span className="select-calender-cross" onClick={handleReset} >x</span>
            }
          </div>
        </div>
        )}
        
      </div>
    );
  };
  const header = renderHeader();

  const onPage = (event) => {
    setLazyState({
      ...lazyState,
      first: event.first,
      rows: event.rows,
      page: event.page + 1,
    });
  };

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Totals:" footerStyle={{ textAlign: 'left' }} />
        <Column footer={emailSentTotal} />
        <Column footer={emailOpenTotal} />
        <Column footer={outboundCallsTotal} />
        <Column footer={pendingTasksTotal} />
        <Column footer={leadsAddedTotal} />
        <Column footer={overdueTaskTotal} />
      </Row>
    </ColumnGroup>
  );

  return (
    <div className="content-page">
      <div className="content">
        <Container fluid className="">
          <Row>
            <Col>
              <div className="page-title-box">
                <h4 className="page-title">Reports</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="page-title-caption-box">
                <h6 className="page-title-caption">Generate detailed reports to analyze the effectiveness of your campaigns and make data-driven decisions.</h6>
              </div>
            </Col>
          </Row>

          <div className="card">
            {loading ? (
              <DataTable
                header={header}
                value={[...Array(5)]}
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column field="name" header="Team Member" body={() => <Skeleton></Skeleton>} />
                <Column field="emails_count" header="Emails Sent" body={() => <Skeleton></Skeleton>} />
                <Column field="opened_count" header="Emails Opened" body={() => <Skeleton></Skeleton>} />
                <Column field="outbound_calls" header="Outbound Calls" body={() => <Skeleton></Skeleton>} />
                <Column field="pending_tasks_count" header="Pending tasks" body={() => <Skeleton></Skeleton>} />
                <Column field="leads_count" header="Leads added" body={() => <Skeleton></Skeleton>} />
                <Column field="overdue_tasks_count" header="Overdue tasks" body={() => <Skeleton></Skeleton>} />
                <Column field="Total" />
              </DataTable>
            )
              : (
                <DataTable
                  lazy
                  first={lazyState.first}
                  rows={lazyState.rows}
                  filters={lazyState.filters}
                  ref={dt}
                  value={membersData.members}
                  globalFilter={globalFilter}
                  header={header}
                  removableSort
                  paginator
                  onPage={onPage}
                  totalRecords={membersData.totalRecords}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  tableStyle={{ minWidth: "50rem" }}
                  paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  footerColumnGroup={footerGroup}

                >
                  <Column field="user_name" header="Team Member" style={{ width: '25%' }}></Column>
                  <Column field="email_sent" header="Emails Sent"></Column>
                  <Column field="email_opened" header="Emails Opened" ></Column>
                  <Column field="outbound_calls" header="Outbound Calls" ></Column>
                  <Column field="pending_tasks" header="Pending tasks"></Column>
                  <Column field="lead_added" header="Leads added"></Column>
                  <Column field="overdue_tasks" header="Overdue tasks"></Column>
                </DataTable>
              )}
          </div>
        </Container>
      </div>
    </div>
  );


}