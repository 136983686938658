import React, { useEffect, useState } from 'react';
import {Container} from "react-bootstrap";
import success from "../assets/images/success.png"

function SuccessPage() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Extract the session_id from the URL
    const query = new URLSearchParams(window.location.search);
    const sessionId = query.get('session_id');

    if (sessionId) {
      fetch(process.env.REACT_APP_API_HOST + `/session-details?session_id=${sessionId}`, {
        headers: {
            'x-api-key': localStorage.getItem('api_key'),
        }
    })
        .then(response => response.json())
        .then(data => {
          setSession(data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching session details:', error);
          setLoading(false);
        });
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!session) {
    return <div>Session not found. Please check if the payment was successful or try again.</div>;
  }

  return (
    <div>
      <div className="content-page">
        <div className="content">
          <Container fluid>
            <div className="payment-message">
              <img src={success} width={100}/>
              <h3>Payment Successful!</h3>
              <p>Thank you for your subscription. Here are your payment details:</p>
              {/* <ul>
                <li>Session ID: {session.id}</li>
                <li>Status: {session.payment_status}</li>
              </ul> */}
            </div>
          </Container>
    </div></div></div>
  );
}

export default SuccessPage;
