// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.form-check-input-sales-automation-inbox-page {
  margin-top: 18px !important;
  margin-left: 12px;
  width: 16px;
  height: 16px;
  border: 1px solid #c1c1c1;
}

.auto-suggestion-button {
  background: #f1f1f1;
  color: #5e5e5e;
  border: 1px solid #f1f1f1;
  padding: 6px 13px;
  font-size: 14px;
  font-weight: 400 !important;
}
.auto-suggestion-button span {
  font-weight: 500;
}

.auto-suggestion-button.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
  background: #e4e4e4 !important;
  color: #525252;
  border-color: #dfdfdf;
}`, "",{"version":3,"sources":["webpack://./src/email/Inbox.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;AACJ;;AACE;EACE,mBAAA;EACA,cAAA;EACA,yBAAA;EACA,iBAAA;EACA,eAAA;EACA,2BAAA;AAEJ;AADI;EACE,gBAAA;AAGN;;AAIA;EACE,8BAAA;EACA,cAAA;EACA,qBAAA;AADF","sourcesContent":["input.form-check-input-sales-automation-inbox-page{\n    margin-top: 18px !important;\n    margin-left: 12px;\n    width: 16px;\n    height: 16px;\n    border: 1px solid #c1c1c1;\n  }\n  .auto-suggestion-button {\n    background: #f1f1f1;\n    color: #5e5e5e;\n    border: 1px solid #f1f1f1;\n    padding: 6px 13px;\n    font-size: 14px;\n    font-weight: 400 !important;\n    span {\n      font-weight: 500;\n    }\n}\n// .auto-suggestion-button :hover{\n//   background: #e9e9e9;\n//     color: #4f4f4f;\n// }\n.auto-suggestion-button.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {\n  background: #e4e4e4 !important;\n  color: #525252;\n  border-color: #dfdfdf;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
