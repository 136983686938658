import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React, { useEffect, useRef, useState } from "react";
import { FiBell } from "react-icons/fi";
import { Link } from "react-router-dom";

const DropdownNotification = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="position-relative notification-header">
  <Link
    ref={trigger}
    onClick={() => {
      setDropdownOpen(!dropdownOpen);
    }}
    to="#"
    className="position-relative d-flex text-lg"
  >
      <FeatherIcon icon="bell" color="#1c2434" size={20} /> 
    {/* <span className="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle"> */}
      <span className="position-absolute top-0 start-0 translate-middle p-1 bg-danger animate-ping border border-light rounded-circle" style={{ animation: "ping 1s infinite" }}></span>
    {/* </span> */}
  </Link>

  <div
    ref={dropdown}
    onFocus={() => setDropdownOpen(true)}
    onBlur={() => setDropdownOpen(false)}
    className={`dropdown-menu p-0 shadow ${dropdownOpen === true ? "show" : ""}`}
    style={{ right: "0", top: "40px", width: "300px" }}
  >
    <div className="px-4 py-3 border-bottom">
      <h5 className="font-weight-medium mb-0">Notification</h5>
    </div>

    <ul className="list-unstyled overflow-auto" style={{ maxHeight: "400px" }}>
        <li >
          <Link
            className="d-flex flex-column gap-2.5 border-top border-secondary px-4 py-3 text-decoration-none text-dark"
            // to={item.to}
          >
            <p className="mb-1">
              <span className="font-weight-bold">Edit your information in a swipe</span>{" "}
              Sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.
            </p>
            <p className="text-muted small">12 May, 2025</p>
          </Link>
        </li>
    </ul>
  </div>
</div>

  );
};

export default DropdownNotification;
