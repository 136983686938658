import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';
import Unsubscribed from '../assets/images/unsubscribed.gif';
import ApiListFile from "../Components/ApiListFile";
import ApiHeader from "../Components/ApiHeader";
import '../CustomProgressSpinner.css';

const Unsubscribe = () => {

  const args = new URLSearchParams(useLocation().search);
  const { API_UNSUBSCRIBE } = ApiListFile()

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${API_UNSUBSCRIBE}`, {
      method: "POST",
      headers: ApiHeader(),
      body: JSON.stringify({
        to: args.get("to"),
        company_id: args.get("company_id"),
        sequence_id: args.get("sequence_id"),
        step_id: args.get("step_id"),
        user_id: args.get("user_id"),
      }),
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className='spinner-container'>
          <ProgressSpinner
            style={{
              width: '50px',
              height: '50px'
            }}
            strokeWidth="4"
            fill="var(--surface-ground)"
            animationDuration=".7s"
          />

        </div>
      ) : (
        <div className="content-page" style={{ marginTop: '0px' }}>
          <div className="content">
            <Container fluid>
              <Row className="justify-content-md-center my-5">
                <Col xs={12} md={7}>
                  <Card>
                    <img src={Unsubscribed} alt="" height="600" width="auto" />
                    <h3 className="text-center py-5">Unsubscribed Successfully</h3>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </div>
  );
};

export default Unsubscribe;
