import React, { useState, useEffect, useRef, useContext } from 'react';
import { Form, Modal, Container, Col, Row, Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from "primereact/dialog";
import moment from 'moment-timezone';
import ApiListFile from '../Components/ApiListFile';
import ApiHeader, { fetchMethod } from '../Components/ApiHeader';
import Footer from '../Components/Footer/Footer';
import { contextVar } from '../Components/ContextVar';
import "./Sequence.css";
import { MdCalendarToday } from "react-icons/md";

// Debounce function
const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const Sequences = () => {

  const timezoneMap = {
    "Pacific Time": "America/Los_Angeles",
    "Mountain Time": "America/Denver",
    "Central Time": "America/Chicago",
    "Eastern Time": "America/New_York",
    "UTC": "UTC",
    "Europe/London": "Europe/London",
    "Paris": "Europe/Paris",
    "Athens": "Europe/Athens",
    "Australia Eastern Standard Time": "Australia/Sydney",
    "Australia Western Standard Time": "Australia/Perth",
    "Australia Central Standard Time": "Australia/Adelaide",
    "Middle East Time": "Asia/Tehran",
    "Near East Time": "Asia/Dubai",
    "Pakistan Lahore Time": "Asia/Karachi",
    "Indian Standard Time": "Asia/Kolkata",
    "Bangladesh Standard Time": "Asia/Dhaka",
    "Vietnam Standard Time": "Asia/Ho_Chi_Minh",
    "Japan Standard Time": "Asia/Tokyo",
    "Solomon Standard Time": "Pacific/Guadalcanal",
    "New Zealand Standard Time": "Pacific/Auckland",
    "Midway Islands Time": "Pacific/Midway",
    "Hawaii Standard Time": "Pacific/Honolulu",
    "Alaska Standard Time": "America/Anchorage",
    "Puerto Rico and US Virgin Islands Time": "America/Puerto_Rico",
    "Canada Newfoundland Time": "America/St_Johns",
    "Brazil Eastern Time": "America/Sao_Paulo"
  };


  const { API_LIST_SEQUENCE, API_PAGINATED_SEQUENCE, API_UPDATE_GROUPS_LIST, API_DELETE_BULK, API_POST_USER_TIMEZONES } = ApiListFile()

  const { setnetworkError, userGroup } = useContext(contextVar)

  const [sequences, setSequences] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [groups, setGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const buttonEl = useRef(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const dt = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [timezone, settimeZone] = useState(null)
  const [timezoneList, settimeZoneList] = useState('UTC')
  const [currentSequence, setCurrentSequence] = useState({
    sequence_name: '',
    group: '',
    start_date: null,
  });

  const [loading, setLoading] = useState(true);
  const [loader, setloader] = useState(false)
  const [formErrors, setFormErrors] = useState({
    sequence_name: '',
    group: '',
    start_date: ''
  });
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: 'timestamp',
    sortOrder: -1
  });



  const onPage = (event) => {
    setlazyState({
      ...lazyState,
      first: event.first,
      rows: event.rows,
      page: event.page + 1
    });
  };

  const onSort = (event) => {
    setlazyState({ ...lazyState, sortOrder: event.sortOrder, sortField: event.sortField });
  }


  useEffect(() => {
    fetchSequences();
  }, [lazyState, globalFilter]);

  const fetchSequences = () => {
    setLoading(true)
    fetchMethod("POST", `${API_PAGINATED_SEQUENCE}`, {
      page: lazyState.page,
      page_size: lazyState.rows,
      search_query: globalFilter,
      sort_order: lazyState.sortOrder,
      sort_field: lazyState.sortField
    })
      .then(data => {
        setTotalRecords(data.total_count)
        setSequences(data.items);
        setLoading(false)
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setLoading(false);
          return;
        }
      });
  }

  useEffect(() => {
    document.title = "Sequences";
    setLoading(true)
    fetchMethod("GET", `${API_UPDATE_GROUPS_LIST}`, {})
      .then(data => {
        const groupOptions = data.map(group => ({ value: group._id, label: group.group_name }));
        setGroups(groupOptions);
        setLoading(false)
        fetchSequences()
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setLoading(false);
          return;
        }
      });

  }, []);

  const handleShow = () => {
    setShowModal(true);
  }

  const handleClose = () => {
    setCurrentSequence({
      sequence_name: '',
      group: '',
      start_date: null,
    });
    setFormErrors({
      sequence_name: '',
      group: '',
      start_date: '',
    })
    setShowModal(false);
  }

  const isFormValid = () => {
    let isValid = true;
    let errors = {
      sequence_name: '',
      group: '',
      start_date: ''
    };

    if (!currentSequence.sequence_name.trim()) {
      errors.sequence_name = 'Sequence name is required';
      isValid = false;
    }

    if (currentSequence.group == '') {
      errors.group = 'Group is required';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };


  const handleSubmit = async (event) => {
    // event.preventDefault();
    if (!isFormValid()) return;
    setloader(true);

    const url = `${API_LIST_SEQUENCE}` + (currentSequence._id ? `/${currentSequence._id}` : '');
    const method = currentSequence._id ? 'PUT' : 'POST';
    
    const payload = {
      sequence_name: currentSequence.sequence_name,
      group: currentSequence.group.value,
      start_date: moment(currentSequence.start_date).format('YYYY-MM-DDTHH:mm')
    };

    try {
      const response = await fetchMethod(method, `${url}`, payload);

      if (response.status === 'error') {
        toast.current.show({
          severity: 'warn',
          summary: 'Error',
          detail: response.message,
        });
      } else {
        const sequenceId = response; // Assuming the server returns the new sequence ID

        const newSequence = {
          _id: sequenceId,
          sequence_name: currentSequence.sequence_name,
          group_name: currentSequence.group.label,
          start_date: currentSequence.start_date.toLocaleString(),
        };

        setSequences((prevSequences) => [newSequence, ...prevSequences]);
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: currentSequence._id ? 'Sequence Updated Successfully' : 'Sequence Added Successfully',
        });
        setShowModal(false)
        fetchSequences()
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An error occurred while processing the request. Please try again.',
      });
    } finally {
      setloader(false);
    }
  };


  const handleBulkDelete = async (selectionOnly) => {
    if (selectionOnly) {
      const idArray = selectedRows.map(item => item._id);
      try {
        await fetchMethod("DELETE", `${API_DELETE_BULK}`, { ids: idArray })
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Leads Deleted Successfully",
        });
        setTotalRecords(totalRecords - idArray.length);
        setSelectedRows([]);
        fetchSequences(); // Assuming this function fetches the updated template list
        setDeleteLoading(false);
      } catch (error) {
        // Handle fetch error...
        console.error('Error during fetch:', error);
        setDeleteLoading(false);
      }
    }
  }

  const bodyTemplate = (rowData, column) => {
    const templateBody = rowData[column.field];
    const cleanedBody = templateBody.replace(/(<([^>]+)>)/gi, '');
    const maxLength = 50;
    const truncatedBody = cleanedBody.length > maxLength ? `${cleanedBody.substring(0, maxLength)}...` : cleanedBody;

    return <span>{truncatedBody}</span>;
  };

  const generateCSV = (data) => {
    const fields = ['sequence_name', 'group_name', 'start_date'];
    const headers = ['Sequence Name', 'Group', 'Start Date'];

    // Extract the rows
    const rows = data.map(obj => fields.map(field => {
      // If the field is 'start_date', wrap it in double quotes
      return field === 'start_date' ? `"${obj[field]}"` : obj[field];
    }).join(',')).join('\n');

    return headers.join(',') + '\n' + rows;
  };

  const exportCSV = (selectionOnly) => {
    if (selectionOnly) {
      // Generate CSV from the selectedRows
      const csvData = generateCSV(selectedRows);

      // Create a blob from the CSV
      const blob = new Blob([csvData], { type: "text/csv" });

      // Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "sequences.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      dt.current.exportCSV(selectionOnly);
    }
  };

  const deleteSelectedRecords = (selectionOnly) => {
    confirmDialog({
      message: "Do you want to delete these records?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",

      accept: () => {
        setDeleteLoading(true);
        handleBulkDelete(selectionOnly)
      },
      draggable: false
    });
  };

  const handleSelectionChange = (e) => {
    const selectedValues = e.value;

    setSelectedRows((prevSelectedRows) => {
      const selectedIds = new Set(selectedValues.map(row => row._id));

      // Create a new selection that merges previous and current selections
      const mergedSelection = [
        ...prevSelectedRows.filter(row => selectedIds.has(row._id) || !sequences.some(currentRow => currentRow._id === row._id)),
        ...selectedValues.filter(row => !prevSelectedRows.some(selectedRow => selectedRow._id === row._id))
      ];

      return mergedSelection;
    });
  };

  // Handle header checkbox selection
  const handleHeaderCheckboxChange = (e) => {
    const allSelected = e.checked;
    if (allSelected) {
      // Add all rows from the current page to the selection
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = [...prevSelectedRows];
        sequences.forEach(row => {
          if (!newSelectedRows.some(selectedRow => selectedRow._id === row._id)) {
            newSelectedRows.push(row);
          }
        });
        return newSelectedRows;
      });
    } else {
      // Remove all rows from the current page from the selection
      setSelectedRows((prevSelectedRows) => {
        return prevSelectedRows.filter(row => !sequences.some(currentRow => currentRow._id === row._id));
      });
    }
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    setGlobalFilter(e.target.value.trim()); // Trim if necessary
    setLoading(true);
  };



  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          className="form-control-sm"
          onInput={handleSearchChange}
          placeholder="Search..."
        />

      </span>
      <div>
        <Button
          size="small"
          onClick={() => exportCSV(true)}
          label="Export"
          severity="help"
          icon="uil uil-down-arrow"
          disabled={!selectedRows || selectedRows.length === 0}
        />
        {userGroup && userGroup?.sequence && userGroup?.sequence?.bulk_sequence_delete &&
          <Button
            className="ms-2"
            size="small"
            onClick={() => deleteSelectedRecords(true)}
            label="Delete"
            severity="danger"
            icon="uil uil-trash"
            disabled={!selectedRows || selectedRows.length === 0}
            loading={deleteLoading}
          />}
      </div>
    </div>
  );


  const actionTemplate = (rowData) => {
    return (
      <div className="p-d-flex p-flex-wrap">
        <Tooltip target=".tooltip-text" />
        {/* <Link to={`/sequence_details/${rowData._id}`} className="btn text-primary tooltip-text" data-pr-tooltip="Go to Steps"> <i className="uil uil-eye"></i> </Link> */}
        {/* <Button className='tooltip-text' icon="uil uil-edit-alt" rounded text aria-label="Edit" data-pr-tooltip="Edit" loading={loading} onClick={() => handleShow(rowData)} /> */}
        {userGroup && userGroup?.sequence && userGroup?.sequence && userGroup?.sequence?.delete_sequence &&
          <Button className='tooltip-text' ref={buttonEl} icon="uil uil-trash" rounded text aria-label="Delete" data-pr-tooltip="Delete" severity="danger" onClick={() => confirmdelete(rowData._id)} />}
      </div>
    );
  };

  const accept = (id) => {
    handleDelete(id)
  }

  const reject = (id) => {

  }

  const confirmdelete = (id) => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => accept(id),
      reject: () => reject(id),
      draggable: false
    });
  };


  const handleDelete = (id) => {
    fetchMethod("DELETE", `${API_LIST_SEQUENCE}/${id}`, {})
      .then(() => {
        fetchSequences()
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Sequence Deleted Successfully",
        });
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true)
          setLoading(false);
          return;
        }
      });
  }

  const groupTemplate = (rowData) => {
    // Determine the maximum length for display
    const maxLength = 50; // Adjust as needed
    const displayText = rowData.group_name;
    const truncatedText = displayText.length > maxLength ? `${displayText.substring(0, maxLength)}...` : displayText;

    return (
      <>
        <Tooltip target=".tooltip-note-text">
        </Tooltip>
        <span className="tooltip-note-text" data-pr-tooltip={displayText}>
          {truncatedText}
        </span>
      </>
    );
  };
  const showToast = (status, message) => {
    toast.current.show({
      severity: status,
      summary: "Error",
      detail: message,
    });
  }
  const AllStats = () => {
    return <>
      <div class="top__statistics d-flex justify-content-between">
        <div class="statistic_left">
          <ul>
            <li>
              0 <span class="text-danger">Bounced</span>
            </li>
            <li>
              0 <span class="text-success">Replied</span>
            </li>
            {/* </ul>
                          </div>
                          <div class="statistic_right">
                            <ul> */}
            <li>
              0 <span>Scheduled</span>
            </li>
            <li>
              0 <span>Delivered</span>
            </li>
            <li>
              0 <span>Opened</span>
            </li>
            <li>
              0 <span>Unsubscribed</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  }

  const sequenceNameTemplate = (rowData) => {
    // Determine the maximum length for display
    const maxLength = 20;
    const displayText = rowData.sequence_name;
    const truncatedText = displayText.length > maxLength ? `${displayText.substring(0, maxLength)}...` : displayText;

    // Generate the link URL
    const url = `/sequence_details/${rowData._id}`;

    // Return the Link with Tooltip
    return (
      <>
        <Tooltip target=".tooltip-note-text" />
        <a href={url} className="tooltip-note-text" data-pr-tooltip={displayText}>
          {truncatedText}
        </a>
      </>
    );
  };


  const ClickableError = ({ message, path }) => {
    const navigate = useNavigate();

    const handleNavigate = () => {
      navigate(path);
    };

    return (
      <span style={{ color: 'blue', textDecoration: 'none', cursor: 'pointer', background: "#5369f8", padding: "10px 15px", color: "white", borderRadius: "6px" }} onClick={handleNavigate}>
        {message}
      </span>
    );
  };



  useEffect(() => {
    fetchMethod("GET", `${API_POST_USER_TIMEZONES}`, {})
      .then((data) => {
        if (data?.item != null) {
          const apiTimezone = data?.item?.timezone;
          if (apiTimezone) {

            settimeZoneList(apiTimezone)
          }
          const validTimezone = timezoneMap[apiTimezone] || 'UTC';
          settimeZone(validTimezone);

          // Set initial date in the user's timezone
          const initialDate = new Date( moment.tz( new Date(), validTimezone).format('YYYY-MM-DDTHH:mm:ss'));
          setCurrentSequence(prev => ({ ...prev, start_date: initialDate }));
        }
      })
      .catch((error) => {
        console.error(error);
        if (error instanceof TypeError) {
          setnetworkError(true);
        }
      });
  }, []);

  const getTodayInSelectedTimezone = () => {
    console.log("Timezone:", timezone);

    const mappedTimezone = timezoneMap[timezone] || timezone;
    console.log("Mapped Timezone:", mappedTimezone);

    const currentDateInTimezone = moment.tz(mappedTimezone).startOf('day');
    console.log("Current date in timezone:", currentDateInTimezone.format());

    return currentDateInTimezone.toDate();
  };

  const minDateForDatePicker = getTodayInSelectedTimezone();

  const convertTimestampToTimezone = (timestamp) => {
    if (!timestamp) {
      return 'Invalid Date';
    }

    try {
      // Parse the timestamp from 'DD/MM/YYYY, HH:MM:SS' to 'YYYY-MM-DDTHH:MM:SS'
      const [datePart, timePart] = timestamp.split(', ');
      const [day, month, year] = datePart.split('/');
      const reformattedTimestamp = `${year}-${month}-${day}T${timePart}`;

      const date = new Date(reformattedTimestamp);
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }

      // Convert to the target timezone using Intl API
      const ianaTimezone = timezoneMap[timezoneList] || timezoneList;
      const dateOptions = {
        timeZone: ianaTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };

      const timeOptions = {
        timeZone: ianaTimezone,
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      };

      const formattedDate = new Intl.DateTimeFormat('en-GB', dateOptions).format(date);
      const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);
      // Combine date and time
      return `${formattedDate}, ${formattedTime}`;
    } catch (error) {
      console.error('Error converting timestamp:', error, 'for timezone:', timezoneList);
      return 'Invalid Date';
    }
  };

  const startDateTemplate = (rowData) => {
    // Example, update this as needed
    let startDate = rowData.start_date
    const formattedDate = startDate;
    // Determine the maximum length for display
    const maxLength = 50;
    const truncatedText = formattedDate?.length > maxLength ? `${formattedDate?.substring(0, maxLength)}...` : formattedDate;

    return (
      <>
        <Tooltip target=".tooltip-note-text">
        </Tooltip>
        <span className="tooltip-note-text" data-pr-tooltip={formattedDate}>
          {truncatedText}
        </span>
      </>
    );
  };



  const formatDate = (date) => {
    if (!date) return '';
    return moment.tz(date, timezone).format('DD-MM-YYYY hh:mm A');
  };

  const handleStartDateChange = (date) => {
    setCurrentSequence((prev) => ({
      ...prev,
      start_date: date,
    }));
  };

  const isHighlightedDay = (date) => {
    if (!currentSequence.start_date) return false;
    const selectedDate = moment.tz(currentSequence.start_date, timezone).startOf('day');
    const dayToCheck = moment.tz(date, timezone).startOf('day');
    return selectedDate.isSame(dayToCheck);
  };
  
  const isHighlightedTime = (time) => {
    if (!currentSequence.start_date) return false;
    const selectedTime = moment.tz(currentSequence.start_date, timezone);
    const timeToCheck = moment.tz(time, timezone);
    return selectedTime.hour() === timeToCheck.hour() && selectedTime.minute() === timeToCheck.minute();
  };

  // Custom input component for DatePicker
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="position-relative">
    <input
      className="form-control"
      type="text"
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
    />
    <MdCalendarToday 
    className="position-absolute top-50 end-0 translate-middle-y me-2" 
    style={{ fontSize: '1.2rem', cursor: 'pointer' }}
    onClick={onClick}
  />
  </div>
  ));




  return (
    <div>
      <div>
        <div className="content-page">
          <div className="content">
            <Container fluid>
              <ConfirmDialog />
              <Toast ref={toast} />
              <Row>
                <Col>
                  <div className="page-title-box">
                    <h4 className="page-title">Sequences</h4>
                    <div className="page-title-right">
                      <div className="row g-2">
                        <div className="col-md-auto">
                          {userGroup && userGroup?.sequence && userGroup?.sequence?.create_sequence && timezone &&
                            <Button
                              label="Add Sequence"
                              icon="uil uil-plus"
                              size="small"
                              onClick={() => {
                                setCurrentSequence({
                                  sequence_name: '',
                                  group: '',
                                  start_date: new Date(moment.tz(new Date(), timezone).format('YYYY-MM-DDTHH:mm:ss')),
                                });
                                handleShow()
                              }}
                            />}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="page-title-caption-box">
                    <h6 className="page-title-caption mb-4">Automate your email outreach with timed sequences that ensure consistent follow-up and engagement.</h6>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    {/*  table */}
                    {loading ? (
                      <DataTable
                        header={header}
                        value={[...Array(5)]} // Use an array of length 5 to render 5 rows of skeletons
                        tableStyle={{ minWidth: '50rem' }}
                      >
                        <Column
                          selectionMode="multiple"
                          headerStyle={{ width: "3rem" }}
                        ></Column>
                        <Column
                          field="sequence_name"
                          header="Sequence Name"
                          body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                          field="group"
                          header="Group"
                          body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                          field="start_date"
                          header="Start Date"
                          body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                          field="Action"
                          header="Action"
                          body={() => <Skeleton ></Skeleton>}
                        />
                      </DataTable>
                    ) : (
                      <DataTable ref={dt} value={sequences}
                        lazy
                        first={lazyState.first}
                        onSelectionChange={handleSelectionChange}
                        onHeaderCheckboxToggle={handleHeaderCheckboxChange}
                        onPage={onPage}
                        onSort={onSort}
                        sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        rows={lazyState.rows}
                        totalRecords={totalRecords}
                        selection={selectedRows}
                        filters={lazyState.filters}
                        selectionMode="checkbox"
                        globalFilter={globalFilter}
                        header={header}
                        removableSort paginator rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}" >
                        <Column
                          selectionMode="multiple"
                          headerStyle={{ width: "3rem" }}
                        ></Column>
                        <Column field="sequence_name" sortable header="Sequence Name" body={sequenceNameTemplate}></Column>
                        <Column field="group_name" sortable header="Group" style={{ width: "22%" }} body={groupTemplate}></Column>
                        {/* <Column field="stats" sortable header="Stats" body={AllStats}></Column> */}
                        <Column field="start_date" sortable header="Start Date" body={startDateTemplate}></Column>
                        <Column header="Action" body={actionTemplate}></Column>
                      </DataTable>
                    )}
                  </Card>
                </Col>
              </Row>

              {/* Form section */}
              <Dialog header={currentSequence._id ? 'Update Sequence' : 'Add Sequence'}
                visible={showModal}
                onHide={handleClose}
                style={{ width: "30vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                draggable={false}
                resizable={false}
                className="addSequenceModal"
              >
                <Form>
                  <Form.Group controlId="formSequenceName">
                    <Form.Label>Sequence Name <span className="text-danger">*</span></Form.Label>
                    <Form.Control type="text" placeholder="Enter Sequence Name" value={currentSequence.sequence_name} onChange={(e) => {
                      if (e.target.value && e.target.value.startsWith(' ')) {
                        return;  // If it starts with a space, do not update the state
                      } else { setCurrentSequence({ ...currentSequence, sequence_name: e.target.value }) }
                    }} maxLength={150} />
                    {formErrors.sequence_name && <Form.Text className="text-danger">{formErrors.sequence_name}</Form.Text>}
                  </Form.Group>
                  <Form.Group controlId="formGroup">
                    <Form.Label>Group <span className="text-danger">*</span></Form.Label>
                    <Select
                      value={currentSequence.group}
                      options={groups}
                      onChange={(selectedOption) => setCurrentSequence({ ...currentSequence, group: selectedOption })}
                    />
                    {formErrors.group && <Form.Text className="text-danger">{formErrors.group}</Form.Text>}
                  </Form.Group>
                  {!currentSequence._id && <Form.Group controlId="formStartDate">
                    <Form.Label>Start Date  <span className="text-danger">*</span></Form.Label><br />

                    {timezone && (
                      <DatePicker
                        selected={currentSequence.start_date ? currentSequence.start_date : null}
                        onChange={handleStartDateChange}
                        showTimeSelect
                        timeFormat="hh:mm aa"
                        timeIntervals={1}
                        timeCaption="Time"
                        dateFormat="dd-MM-yyyy hh:mm aa"
                        minDate={minDateForDatePicker}
                        customInput={<CustomInput />}
                        value={currentSequence.start_date ? currentSequence.start_date : 'Not set'}
                        dayClassName={(date) => (isHighlightedDay(date) ? 'highlighted-day' : undefined)}
                        timeClassName={(time) => (isHighlightedTime(time) ? 'highlighted-time' : undefined)}
                      />
                    )}

                    {formErrors.start_date && <Form.Text className="text-danger">{formErrors.start_date}</Form.Text>}
                  </Form.Group>}
                </Form>
                <div className='d-flex justify-content-end mt-3'>
                  <Button onClick={handleClose} size="small" className='me-2' outlined>Close</Button>
                  <Button onClick={handleSubmit} size="small" icon="pi pi-check" label='Save' loading={loader} />
                </div>
              </Dialog>
            </Container>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );

}

export default Sequences;
