import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import ApiListFile from '../Components/ApiListFile';
import { fetchMethod } from '../Components/ApiHeader';
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from 'primereact/toast';
import { Tooltip } from "primereact/tooltip";
import { contextVar } from '../Components/ContextVar';
import { useNavigate } from 'react-router-dom';
import FeatherIcons from 'feather-icons-react';

const CallRecordTable = (props) => {

    const timezoneMap = {
        "Pacific Time": "America/Los_Angeles",
        "Mountain Time": "America/Denver",
        "Central Time": "America/Chicago",
        "Eastern Time": "America/New_York",
        "UTC": "UTC",
        "Europe/London": "Europe/London",
        "Paris": "Europe/Paris",
        "Athens": "Europe/Athens",
        "Australia Eastern Standard Time": "Australia/Sydney",
        "Australia Western Standard Time": "Australia/Perth",
        "Australia Central Standard Time": "Australia/Adelaide",
        "Middle East Time": "Asia/Tehran",
        "Near East Time": "Asia/Dubai",
        "Pakistan Lahore Time": "Asia/Karachi",
        "Indian Standard Time": "Asia/Kolkata",
        "Bangladesh Standard Time": "Asia/Dhaka",
        "Vietnam Standard Time": "Asia/Ho_Chi_Minh",
        "Japan Standard Time": "Asia/Tokyo",
        "Solomon Standard Time": "Pacific/Guadalcanal",
        "New Zealand Standard Time": "Pacific/Auckland",
        "Midway Islands Time": "Pacific/Midway",
        "Hawaii Standard Time": "Pacific/Honolulu",
        "Alaska Standard Time": "America/Anchorage",
        "Puerto Rico and US Virgin Islands Time": "America/Puerto_Rico",
        "Canada Newfoundland Time": "America/St_Johns",
        "Brazil Eastern Time": "America/Sao_Paulo"
    };

    const dt = useRef(null);
    const toast = useRef(null);
    const { API_LIST_CALL_RECORD, API_POST_USER_TIMEZONES } = ApiListFile();
    const { setnetworkError, userGroup, callDispositionModal,updateList,setupdateList } = useContext(contextVar)
    const [data, setData] = useState([]);
    const [totalRecords, settotalRecords] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [timezone, settimeZone] = useState()
    const [globalFilter, setGlobalFilter] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: 'timestamp',
        sortOrder: -1
    });

    const navigate = useNavigate()

    useEffect(() => {
        fetchMethod("GET", `${API_POST_USER_TIMEZONES}`, {})
            .then((data) => {
                if (data?.item != null) {
                    settimeZone(data.item.timezone);
                }
            })
            .catch((error) => {
                console.error(error);
                if (error instanceof TypeError) {
                    setnetworkError(true)
                    return;
                }
            });
    }, []);

    useEffect(() => {
        fetchCallRecordList()
    }, [props?.activeTab, globalFilter, lazyState, callDispositionModal,updateList]);

    const convertTimestampToTimezone = (timestamp) => {

        if (!timestamp || !timezone) {
            console.warn('Missing timestamp or timezone');
            return 'Invalid Date';
        }

        try {
            const date = new Date(timestamp);
            if (isNaN(date.getTime())) {
                throw new Error('Invalid date');
            }

            const ianaTimezone = timezoneMap[timezone] || timezone;

            const dateOptions = {
                timeZone: ianaTimezone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            };

            const timeOptions = {
                timeZone: ianaTimezone,
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            };

            const formattedDate = new Intl.DateTimeFormat('en-GB', dateOptions).format(date);
            const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);

            // Combine date and time
            return `${formattedDate}, ${formattedTime}`;
        } catch (error) {
            console.error('Error converting timestamp:', error, 'for timezone:', timezone);
            return 'Invalid Date';
        }
    };

    const fetchCallRecordList = () => {
        setisLoading(true);
        let filterValue = '';

        if (props?.activeTab === 0) {
            filterValue = 'all';
            // } else if (props?.activeTab === 1) {
            //     filterValue = 'activeCall';
        } else if (props?.activeTab === 1) {
            filterValue = 'noAnswer';
        } else if (props?.activeTab === 2) {
            filterValue = 'gatekeeper';
        } else if (props?.activeTab === 3) {
            filterValue = 'leftVoicemail';
        } else if (props?.activeTab === 4) {
            filterValue = 'busy';
        } else if (props?.activeTab === 5) {
            filterValue = 'bad/WrongNumber';
        } else if (props?.activeTab === 6) {
            filterValue = 'notInService';
        } else if (props?.activeTab === 7) {
            filterValue = 'connected-positive';
        } else if (props?.activeTab === 8) {
            filterValue = 'connected-neutral';
        } else if (props?.activeTab === 9) {
            filterValue = 'connected-negative';
        } else if (props?.activeTab === 10) {
            filterValue = 'noDisposition';
        }

        if (filterValue !== '') {
            fetchMethod("POST", `${API_LIST_CALL_RECORD}`, {
                filter_data: filterValue,
                page: lazyState.page,
                page_size: lazyState.rows,
                search_query: globalFilter,
                sort_order: lazyState.sortOrder,
                sort_field: lazyState.sortField
            })
                .then((data) => {
                    setData(data?.data);
                    settotalRecords(data?.total_count)
                    setisLoading(false);
                    setupdateList(false)
                    // setSelectedRows([])
                })
                .catch((err) => console.error(err));
        }
    }

    const onPage = (event) => {
        setlazyState({
            ...lazyState,
            first: event.first,
            rows: event.rows,
            page: event.page + 1
        });
    };


    const onSort = (event) => {
        let newSortField = event.sortField;
        let newSortOrder = event.sortOrder;

        // Toggle the sorting behavior
        if (newSortField !== lazyState.sortField) {
            // If a new column is clicked, start sorting ascending (1)
            newSortOrder = 1;
        } else {
            // If the same column is clicked again, toggle between ascending, descending, and unsorted
            if (lazyState.sortOrder === 1) {
                newSortOrder = -1;  // Switch to descending
            } else if (lazyState.sortOrder === -1) {
                newSortOrder = 0;   // Remove sorting (unsorted)
                newSortField = null; // Set field to null to remove sorting
            } else {
                newSortOrder = 1;    // Back to ascending
            }
        }

        // Update the lazyState with the new sorting state
        setlazyState({
            ...lazyState,
            sortField: newSortField,
            sortOrder: newSortOrder,
        });
    };



    const handleSelectionChange = (e) => {
        const selectedValues = e.value;

        setSelectedRows((prevSelectedRows) => {
            const selectedIds = new Set(selectedValues.map(row => row._id));

            // Create a new selection that merges previous and current selections
            const mergedSelection = [
                ...prevSelectedRows.filter(row => selectedIds.has(row._id) || !data.some(currentRow => currentRow._id === row._id)),
                ...selectedValues.filter(row => !prevSelectedRows.some(selectedRow => selectedRow._id === row._id))
            ];

            return mergedSelection;
        });
    };





    // Persist selection state across pages
    useEffect(() => {
        if (dt.current) {
            dt.current.value = selectedRows;
        }
    }, [selectedRows]);

    const handleHeaderCheckboxChange = (e) => {
        const allSelected = e.checked;
        if (allSelected) {
            // Add all rows from the current page to the selection
            setSelectedRows((prevSelectedRows) => {
                const newSelectedRows = [...prevSelectedRows];
                data.forEach(row => {
                    if (!newSelectedRows.some(selectedRow => selectedRow._id === row._id)) {
                        newSelectedRows.push(row);
                    }
                });
                return newSelectedRows;
            });
        } else {
            // Remove all rows from the current page from the selection
            setSelectedRows((prevSelectedRows) => {
                return prevSelectedRows.filter(row => !data.some(currentRow => currentRow._id === row._id));
            });
        }
    };

    const generateCSV = (data) => {
        const fields = ['call_disposition', 'call_purpose', 'call_direction', 'from_call_number', 'to_call_number', 'call_duration', 'note', 'timestamp'];
        const headers = ['Call Disposition', 'Call Purpose', 'Call Direction', 'From', 'To', 'Call Duration', 'Note', 'Date & Time'];

        // Extract the rows
        const rows = data.map(obj => fields.map(field => {
            let value = obj[field];
            if (value === null || value === undefined) {
                value = '';
            }
            if (field === 'timestamp') {
                value = value ? convertTimestampToTimezone(value, timezone) : '';
            } else {
                value = camelCaseToReadable(value.toString());
            }
            // Wrap each value in double quotes
            return `"${value}"`;
        }).join(',')).join('\n');

        return headers.join(',') + '\n' + rows;
    };

    // Function to export CSV with custom filename
    const exportCSV = () => {
        // Generate CSV from the selectedRows
        const csvData = generateCSV(selectedRows);

        // Create a blob from the CSV
        const blob = new Blob([csvData], { type: 'text/csv' });

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Call Record.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const toCamelCase = (str) => {
        return str
            .toLowerCase()
            .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
                index === 0 ? match.toLowerCase() : match.toUpperCase()
            )
            .replace(/\s+/g, '');
    }

    const camelCaseToReadable = (str) => {
        if (!str || typeof str !== 'string') {
            return '';
        }

        return str
            .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before uppercase letters
            .replace(/(^\w|\b\w)/g, match => match.toUpperCase()) // Capitalize the first letter of each word
            .replace(/-/g, match => match.toUpperCase()); // Capitalize after hyphen
    };


    const columns = [
        { field: 'call_disposition', header: 'Call Disposition', body: (rowData) => camelCaseToReadable(rowData?.call_disposition), sortable: true },
        { field: 'call_purpose', header: 'Call Purpose', body: (rowData) => camelCaseToReadable(rowData?.call_purpose), sortable: true },
        {
            field: 'call_direction', header: 'Direction', body: (rowData) => (
                <div className="flex items-center">
                {rowData?.call_status === 'missed' && (
                    <>
                        <FeatherIcons 
                            icon="phone-missed" 
                            className="text-danger cursor-pointer mb-2" 
                            style={{ width: '18px' }}
                            data-tooltip-id={`missed-call-${rowData?.id}`} // Unique id for each row's icon
                        />
                        <Tooltip 
                            target={`[data-tooltip-id="missed-call-${rowData?.id}"]`} // Tooltip targets this icon
                            content="Missed Call" // Tooltip content
                            position="top" // Optional: Set the position of the tooltip
                        />
                    </>
                )}
                <span>
                    {camelCaseToReadable(rowData?.call_direction)}
                </span>
            </div>
            ), sortable: true
        },
        { field: 'from_call_number', header: 'From', body: (rowData) => camelCaseToReadable(rowData?.from_call_number), sortable: true },
        { field: 'to_call_number', header: 'To', body: (rowData) => camelCaseToReadable(rowData?.to_call_number), sortable: true },
        { field: 'call_duration', header: 'Call Duration', sortable: true },
        {
            field: 'note',
            header: 'Note',
            body: (rowData) => {
                const sanitizedId = rowData?.note?.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '');
                return (
                    <>
                        <Tooltip target={`[data-tooltip="${sanitizedId}"]`} />
                        <span
                            className={`tooltip-note-text tooltip-note-${sanitizedId}`}
                            data-pr-tooltip={rowData?.note}
                            data-tooltip={sanitizedId}
                        >
                            {rowData?.note?.length > 10 ? `${rowData?.note?.slice(0, 10)}...` : rowData?.note}
                        </span>
                    </>
                );
            }
        },
        {
            field: 'date',
            header: () => <span>Date & Time</span>,
            body: (rowData) => convertTimestampToTimezone(rowData?.timestamp, timezone),
            sortable: true
        },
        // {
        //     field: 'date',
        //     header: () => <span>Date & Time</span>, // Header function
        //     body: (rowData) => {
        //         convertTimestampToTimezone(rowData.timestamp)
        //         // if (!rowData?.timestamp) return '';
        //         // const date = new Date(rowData.timestamp);
        //         // return date.toLocaleString('en-GB', {
        //         //     year: 'numeric',
        //         //     month: '2-digit',
        //         //     day: '2-digit',
        //         //     hour: '2-digit',
        //         //     minute: '2-digit',
        //         //     second: '2-digit',
        //         //     hour12: false
        //         // }); // 'en-GB' locale formats date as dd/mm/yyyy and uses a 24-hour clock
        //     }
        // },
        {
            header: 'Actions', body: (rowData) => (
                <>
                    <div className="d-flex flex-row flex-wrap action-tables-calls">
                        {userGroup?.twilio?.update_call_detail?.C &&
                            <Button type="button" icon="pi pi-pencil" className='button-action-table text-secondary' rounded onClick={() => navigate(`/call-details/${rowData?._id}`)}></Button>}
                        {userGroup?.twilio?.delete_call_record?.D &&
                            <Button type="button" icon="pi pi-trash" severity="danger" className='button-action-table text-danger' rounded onClick={() => confirmdelete(rowData?._id)}></Button>}
                    </div>
                </>
            )
        },
    ];

    const accept = (id) => {
        handleDelete(id)
    }

    const confirmdelete = (id) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => accept(id),
            draggable: false
        });
    };

    const handleDelete = (recordId) => {
        setisLoading(true);
        fetchMethod("DELETE", `${API_LIST_CALL_RECORD}/${recordId}`, {})
            .then(() =>
                setisLoading(false)
            )
            .then((data) => {
                toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Record Deleted Successfully",
                });
                // setSelectedRows([])
                fetchCallRecordList()
                props?.fetchCallCount()
            })

            .catch((error) => {
                console.error(error);
                if (error instanceof TypeError) {
                    setnetworkError(true)
                    setisLoading(false);
                    return;
                }
            });
    };


    const handleBulkDelete = async (selectionOnly) => {
        if (selectionOnly) {
            const idArray = selectedRows.map(item => item._id);

            try {
                await fetchMethod("DELETE", `${API_LIST_CALL_RECORD}`, { ids: idArray })
                setisLoading(false);
                toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Leads Deleted Successfully",
                });
                settotalRecords(totalRecords - idArray.length);
                setSelectedRows([]);
                fetchCallRecordList(); // Assuming this function fetches the updated recipient list
                setDeleteLoading(false);
                props?.fetchCallCount()
            } catch (error) {
                // Handle fetch error...
                console.error('Error during fetch:', error);
                setDeleteLoading(false);
            }
        }
    }

    const deleteSelectedRecords = (selectionOnly) => {
        confirmDialog({
            message: "Do you want to delete these records?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",

            accept: () => {
                setDeleteLoading(true);
                handleBulkDelete(selectionOnly)
            },
            draggable: false
        });
    };


    return (
        <>
            <ConfirmDialog />
            <Toast ref={toast} />
            <div className="export-button-sequence">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type='search' placeholder="Search" className="form-control-sm" onInput={(e) => setGlobalFilter(toCamelCase(e.target.value))} />
                </span>
                {/* Filter button here */}
                <div className={"d-flex w-50 justify-content-end"}>
                    <Button
                        type="button"
                        size="small"
                        icon="pi pi-download"
                        label="Export"
                        className="p-button-primary btn-small"
                        onClick={exportCSV}
                        disabled={!selectedRows || selectedRows?.length === 0}
                    />
                    <Button
                        className=""
                        size="small"
                        onClick={() => deleteSelectedRecords(true)}
                        label="Delete"
                        severity="danger"
                        icon="uil uil-trash"
                        disabled={!selectedRows || selectedRows?.length === 0}
                        loading={deleteLoading}
                    />
                </div>

            </div>
            {isLoading ? (
                <div className="skeleton-container">
                    <DataTable
                        value={[{}, {}, {}, {}, {}]}
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                        {columns.map((column, index) => (
                            <Column key={index} header={column?.header} body={<Skeleton />} />
                        ))}
                    </DataTable>
                </div>
            ) : (
                <DataTable
                    style={{ minWidth: '50rem' }}
                    ref={dt}
                    value={data}
                    selectionMode="checkbox"
                    selection={selectedRows}
                    onSelectionChange={handleSelectionChange}
                    onHeaderCheckboxToggle={handleHeaderCheckboxChange}
                    dataKey="_id"
                    tableStyle={{ minWidth: '50rem' }}
                    lazy
                    first={lazyState.first}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    globalFilter={globalFilter}
                    onSort={onSort}   // Bind sorting event to your handler
                    sortField={lazyState.sortField}  // Ensure the sorted field is applied
                    sortOrder={lazyState.sortOrder}  // Ensure the sort order is applied
                    rows={lazyState.rows}
                    paginator
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                    {columns.map((column, index) => (
                        <Column
                            key={index}
                            field={column?.field}
                            header={column?.header}
                            body={column?.body}
                            className='cursor-pointer'
                            sortable={column?.sortable}
                        />
                    ))}
                </DataTable>
            )}
        </>

    );
};

export default CallRecordTable;
