import React, { useState, useEffect, lazy } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Skeleton } from 'primereact/skeleton';
import { fetchMethod } from "../Components/ApiHeader";
import ApiListFile from "../Components/ApiListFile";
import {useDebounce} from "../utils/hooks"


const Emails = ({ onSearchStep, onGlobalSearchStep }) => {    
    const {
        API_EMAIL_SEQUENCE_STATS
    } = ApiListFile();



    const { id } = useParams();
    // const args = new URLSearchParams(useLocation().search);
    const [statsBySequence, setStatsBySequence] = useState([]);
    const [emails, setEmails] = useState([]);
    const [currentFilter, setCurrentFilter] = useState(onSearchStep || "all")
    const [dataLoading, setDataLoading] = useState(false)
    const [stepEmailCount, setStepEmailCount] = useState({})
    const [stepEmailsLoading, setStepEmailsLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [globalFilter, setGlobalFilter] = useState(onGlobalSearchStep || "");
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null
    });
    const [totalRecords, setTotalRecords] = useState(0)
    const debouncedSearch = useDebounce(globalFilter)
    

    const onPage = (event) => {
        setlazyState({
            ...lazyState,
            first: event.first,
            rows: event.rows,
            page: event.page + 1
        });
    };

    const onSort = (event) => {
        setlazyState({ ...lazyState, sortOrder: event.sortOrder, sortField: event.sortField });
    }
    useEffect(() => {
        setDataLoading(true)
        fetchSequenceEmail()
    }, [currentFilter, lazyState, debouncedSearch, onSearchStep]);

    useEffect(() => {
        setStepEmailsLoading(true)
        fetchSequenceEmailsCount()
    }, [debouncedSearch])

    const fetchSequenceEmailsCount = () => {
        let gFilter = debouncedSearch.trim()
        gFilter = debouncedSearch.startsWith("Step ") ? debouncedSearch.replace("Step ", "step_" ) : debouncedSearch
        fetchMethod("GET", `${API_EMAIL_SEQUENCE_STATS}/${id}?search_query=${gFilter}`, {})
            .then((data) => {
                if (data.status == "error") {
                    setErrorMsg(data.message)
                    return
                }
                setStepEmailCount(data)
                
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setStepEmailsLoading(false))
    }

    const fetchSequenceEmail = () => {
        setDataLoading(true)
        let gFilter = debouncedSearch.trim()
        gFilter = debouncedSearch.startsWith("Step ") ? debouncedSearch.replace("Step ", "step_") : debouncedSearch
        fetchMethod("POST", `${process.env.REACT_APP_API_HOST +
            "/sequence_emails/" +
            id + '/' + currentFilter}`, {
            page: lazyState.page,
            page_size: lazyState.rows,
            search_query: gFilter,
            sort_order: lazyState.sortOrder,
            sort_field: lazyState.sortField,
        })
            .then((data) => {
                setTotalRecords(data.total_count)
                setEmails(data.items);
                if (data?.items) {
                    setDataLoading(false)
                }
                if (!onSearchStep) {
                    let active_item = document.getElementById('total')
                    active_item.classList.add('active')
                }
                else {
                    let active_item = document.getElementById(onSearchStep)
                    if (active_item) active_item.classList.add('active')
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setDataLoading(false))
    }

    const header = () => {
        return (
            <div className="flex justify-content-between">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" className="form-control-sm" value={globalFilter} onInput={(e) => { setGlobalFilter(e.target.value); setDataLoading(true) }} placeholder={"Search..."} />
                </span>
            </div>
        );
    };

    const mailtoTemplate = (rowData) => {
        return (
            <div>
                <h4>To</h4>
                <p>{rowData.to}</p>
            </div>
        );
    };

    const senderTemplate = (rowData) => {
        return (
            <div>
                <h4>From: </h4>
                <p>{rowData.from}</p>
            </div>
        );
    };
    const emailStepTemplate = (rowData) => {
        return (
            <div>
                <p>{rowData.step.startsWith("step_")? rowData.step.replace("step_", "Step ") : rowData.step}</p>
            </div>
        );
    }

    const emailStatusTemplate = (rowData) => {

        return (
            <div className="d-flex gap-3 text-center align-items-center justify-content-around position-relative">
                {rowData?.email_statuses.map((items) => (
                    <div className="stepper-section">

                        <span className={items.status == 1 ? 'border rounded-status-icon' : 'border rounded-status-icon-deactive '} style={{}}>{items?.status == 1 ? <i className="pi pi-check" style={{ color: 'slateblue', fontSize: '0.9rem' }}></i> : <i className="pi pi-times" style={{ color: 'gray', fontSize: '0.9rem' }}></i>}</span>
                        <p className="label-status-text">{items?.key}</p>
                    </div>
                ))}
            </div>
        );
    }

    // const emailStatusTemplate = (rowData) => {
    //     if (rowData.email_status == 'opt_out') {
    //         return <p>Unsubscribed</p>
    //     } else {
    //         return (
    //             <p style={{ 'text-transform': 'capitalize' }}>{rowData.email_status}</p>
    //         )
    //     }
    // }

    const actionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Dropdown className="float-end action-more" align="end">
                    <Dropdown.Toggle as="a" className="arrow-none text-muted cursor-pointer" >
                        <i className="uil uil-ellipsis-v"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item> Mark Interested </Dropdown.Item>
                        <Dropdown.Item> Reply to Thread </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </React.Fragment>
        );
    };


    function changeFilter(event) {
        // setGlobalFilter('')
        const clickedLi = event.currentTarget;

        // Remove active class from all siblings
        const parent = clickedLi.parentNode;
        for (let sibling of parent.children) {
            sibling.classList.remove('active');
        }

        // Add active class to the clicked li
        clickedLi.classList.add('active');

        const filterValue = clickedLi.getAttribute('data-filter');
        setCurrentFilter(filterValue);
    }

    const dateTemplate = (rowData) => {

        return (
            <div>
                <p>{new Date(rowData.timestamp).toLocaleString()}</p>
            </div>
        );
    }

    return (
        <>
            <div className="top__statistics d-flex justify-content-start">
                <div className="statistic_left col-6">
                    {/* {dataLoading ? <Skeleton></Skeleton> : */}
                    <ul>
                        <li onClick={changeFilter} data-filter="all" className={currentFilter === "all" ? "active me-2" : "me-2"}>
                            {stepEmailsLoading ? <Skeleton height="44px"></Skeleton> : <a href="#">{stepEmailCount?.all || 0} <span>Total</span></a>}
                        </li>
                        <li onClick={changeFilter} data-filter="sent" className={currentFilter === "sent" ? "active me-2" : "me-2"}>
                            {stepEmailsLoading ? <Skeleton height="44px"></Skeleton> : <a href="#">{stepEmailCount?.sent || 0} <span>Delivered</span></a>}
                        </li>
                        <li onClick={changeFilter} data-filter="opened" className={currentFilter === "opened" ? "active me-2" : "me-2"}>
                            {stepEmailsLoading ? <Skeleton height="44px"></Skeleton> : <a href="#">{stepEmailCount?.opened || 0} <span>Opened</span></a>}
                        </li>

                        <li onClick={changeFilter} data-filter="replied" className={currentFilter === "replied" ? "active me-2" : "me-2"}>
                            {stepEmailsLoading ? <Skeleton height="44px"></Skeleton> : <a href="#">{stepEmailCount?.replied || 0} <span>Replied</span></a>}
                        </li>
                        <li onClick={changeFilter} data-filter="bounced" className={currentFilter === "bounced" ? "active me-2" : "me-2"}>
                            {stepEmailsLoading ? <Skeleton height="44px"></Skeleton> : <a href="#">{stepEmailCount?.bounced || 0} <span>Bounced</span></a>}
                        </li>
                        <li onClick={changeFilter} data-filter="opt_out" className={currentFilter === "opt_out" ? "active me-2" : "me-2"}>
                            {stepEmailsLoading ? <Skeleton height="44px"></Skeleton> : <a href="#">{stepEmailCount?.opt_out || 0} <span>Unsubscribe</span></a>}
                        </li>
                        <li onClick={changeFilter} data-filter="unopened" className={currentFilter === "unopenedCount" ? "active me-2" : "me-2"}>
                            {stepEmailsLoading ? <Skeleton height="44px"></Skeleton> : <a href="#">{stepEmailCount?.not_opened || 0} <span>Not Opened</span></a>}
                        </li>
                        <li onClick={changeFilter} data-filter="clicked" className={currentFilter === "clicked" ? "active me-2" : "me-2"}>
                            {stepEmailsLoading ? <Skeleton height="44px"></Skeleton> : <a href="#">{stepEmailCount?.clicked || 0} <span>Clicked</span></a>}
                        </li>
                    </ul>

                    {/* } */}
                </div>

            </div>
            <div className="card mt-3 sequenceEmail">
                {dataLoading ? (
                    <DataTable
                        value={[...Array(5)]} // Use an array of length 5 to render 5 rows of skeletons
                        tableStyle={{ minWidth: "50rem" }}
                        header={header}
                    >
                        <Column
                            field="mailto"
                            body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                            field="emaildetails"
                            body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                            field="sender"
                            body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                            field="step"
                            body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                            field="date"
                            body={() => <Skeleton></Skeleton>}
                        />
                        <Column
                            field="action"
                            body={() => <Skeleton></Skeleton>}
                        />
                    </DataTable>
                ) : (
                    <DataTable
                        lazy
                        first={lazyState.first}
                        totalRecords={totalRecords} onPage={onPage} globalFilter={globalFilter}
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        value={emails} header={header} rows={lazyState.rows} paginator rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }} paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}">
                        <Column field="to" body={mailtoTemplate} style={{ width: '20%' }}></Column>
                        <Column field="from" body={senderTemplate} style={{ width: '30%' }}></Column>
                        {/* <Column field="sender" body={senderTemplate} style={{ width: '20%' }}></Column> */}
                        <Column field="step" body={emailStepTemplate} style={{ width: '20%' }}></Column>
                        <Column body={emailStatusTemplate} style={{ width: '20%' }}></Column>
                        <Column field="date" body={dateTemplate}></Column>
                        {/* <Column field="action" body={actionTemplate}></Column> */}
                    </DataTable>
                )}
            </div>
        </>
    )
}

export default Emails;