import React from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import MainDataTable from '../../Common/DataTable/MainDataTable';


const BounceAutoReplay = () => {
  return (
    <div className='Recipient-Activities-tabs'>
        <TabView>
                <TabPanel className='Recipient-tab-header' header={<>Hard Bounce <span className='Recipient-tab-header-span'>(5)</span></>}>
                    <MainDataTable/>
                </TabPanel>
                <TabPanel  className='Recipient-tab-header' header={<>Soft Bounce <span className='Recipient-tab-header-span'>(57)</span></>}>
                    <MainDataTable/>
                </TabPanel>
                <TabPanel  className='Recipient-tab-header' header={<>Auto Replay <span className='Recipient-tab-header-span'>(1)</span></>}>
                    <MainDataTable/>
                </TabPanel>
            </TabView>
    </div>
  )
}

export default BounceAutoReplay