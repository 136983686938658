// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.p-button.p-component.cross-btn {
  position: absolute;
  right: 65px;
  background: none;
  border: none;
  padding: 13px;
  color: rgb(141, 141, 236);
}
span.p-calendar.p-component.p-inputwrapper.me-3.p-calendar-w-btn.p-calendar-w-btn-right.p-inputwrapper-filled {
  width: 286px;
}
.calendar_select {
  position: relative;
}
.select-calender-cross {
  position: absolute;
  right: 72px;
  background: none;
  border: none;
  color: #6e6e81;
  font-size: 22px;
  top: 7px;
  transform: rotateX(39deg);
  font-weight: 400;
  padding: 0 !important;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/admin/admin.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,YAAY;AACd;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,eAAe;EACf,QAAQ;EACR,yBAAyB;EACzB,gBAAgB;EAChB,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":["button.p-button.p-component.cross-btn {\n  position: absolute;\n  right: 65px;\n  background: none;\n  border: none;\n  padding: 13px;\n  color: rgb(141, 141, 236);\n}\nspan.p-calendar.p-component.p-inputwrapper.me-3.p-calendar-w-btn.p-calendar-w-btn-right.p-inputwrapper-filled {\n  width: 286px;\n}\n.calendar_select {\n  position: relative;\n}\n.select-calender-cross {\n  position: absolute;\n  right: 72px;\n  background: none;\n  border: none;\n  color: #6e6e81;\n  font-size: 22px;\n  top: 7px;\n  transform: rotateX(39deg);\n  font-weight: 400;\n  padding: 0 !important;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
