import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Col, Row } from 'react-bootstrap';

const SubjectSenderDetail = () => {


    return (
        <div>
            <Row className="mb-4">
                <Col md={3} className='d-flex justify-content-center align-items-center p-2'>
                  <img src='https://cdn.dribbble.com/users/1819889/screenshots/9932434/media/b5b67c05352f2eab7d7e892153ae6df8.png?resize=400x300&vertical=center'  style={{ width: '100px', height: '150px' }}/>
                </Col>
                <Col md={9} className='p-2'>
                    <Row>
                        <Col md={3} >Subject :</Col>
                        <Col md={9} ><h6>Discover effortless Hr and payroll management with conflexHR!</h6></Col>
                    </Row>
                    <Row>
                        <Col md={3} >Sender Name:</Col>
                        <Col md={9} ><h6>Sai Akansha</h6></Col>
                    </Row>
                    <Row>
                        <Col md={3} >Sender Address :</Col>
                        <Col md={9} ><h6>care@conflexhr.com</h6></Col>
                    </Row>
                    <Row>
                        <Col md={3} >Reply Tracking :</Col>
                        <Col md={9} ><h6>Disabled</h6></Col>
                    </Row>
                    <Row>
                        <Col md={3} >Reply-to-address :</Col>
                        <Col md={9} ><h6>enquiry@confluxhr.com</h6></Col>
                    </Row>
                    <Row>
                        <Col md={3} >Content Type :</Col>
                        <Col md={9} ><h6>HTML and Plain Text</h6></Col>
                    </Row>
                    <Row>
                        <Col md={3} >Created on :</Col>
                        <Col md={9} ><h6>Feb 13,2024</h6></Col>
                    </Row>
                    <Row>
                        <Col md={3} >Recipient Personalized :</Col>
                        <Col md={9} ><h6>yes</h6></Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default SubjectSenderDetail;
